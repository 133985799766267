import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PersonIcon from '@mui/icons-material/Person';
import FilterListIcon from '@mui/icons-material/FilterList';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ScaleIcon from '@mui/icons-material/Scale';
import InfoIcon from '@mui/icons-material/Info';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import { Loading } from '../../Common/Loading';
import { PageHeader } from '../../Common/PageHeader/PageHeader';
import { PageHeaderButtonProps } from '../../../interfaces/PageHeaderInterface';
import { HourProject, UpdateTask } from '../../../containers/HourProject/ApportionmentAssets';
import { AddUsersToProjectTasksParams, HourProjectQueryParams, initialValuesLoad } from '../../../interfaces/HourProject';
import HourProjectFilter from './HourProjectFilter';
import DrawerFilter from '../../Common/DrawerFilter';
import { UserClock } from '../../../containers/HourProject/ClockHoursAssets';
import { formatDateAndHours, formatHoursMinutes } from '../../../helpers/Utils';
import NoDataPage from '../../Common/NoDataPage';
import ProjectTaskUsersModal from './ProjectTaskUsersModal';
import { UserWithProjectTaskStatus } from '../../../containers/User/UserAssets';
import Pagination from '../../Common/Pagination';
import TaskAccordion from './TaskAccordion';
import { BranchContext } from '../../../contexts/BranchContext';

interface HourProjectDashboardProps {
	loading: boolean;
	projects: HourProject[];
	projectsPages: number;
	projectsPage: number;
	projectsTake: number;
	users: UserWithProjectTaskStatus[];
	usersClock: UserClock[];
	getProjects(queryParams?: HourProjectQueryParams): void;
	getUsersWithTaskHours(): void;
	addUsersToProjectTasks(
		projectId: string,
		data: AddUsersToProjectTasksParams,
	): void;
	getUsers(): void;
	updateProjectTask(
		id: string,
		data: UpdateTask,
	): void;
	exportHourProjects(): void;
}

interface SelectedProjectInfo {
	projectId: string | null;
	userIds: string[];
}

const HourProjectDashboard = (props: HourProjectDashboardProps): JSX.Element => {
	const {
		loading,
		projects,
		projectsPages,
		projectsPage,
		projectsTake,
		users,
		usersClock,
		getProjects,
		getUsersWithTaskHours,
		addUsersToProjectTasks,
		getUsers,
		updateProjectTask,
		exportHourProjects,
	} = props;
	const [isFilterDrawerOpen, setFilterDrawerOpen] = useState(false);
	const [filter, setFilter] = useState<HourProjectQueryParams>(initialValuesLoad);
	const [
		selectedProjectInfo,
		setSelectedProjectInfo,
	] = useState<SelectedProjectInfo>({ projectId: null, userIds: [] });
	const [open, setOpen] = useState(false);
	const { refreshTriggerBranch } = useContext(BranchContext);

	useEffect(() => {
		getProjects(filter);
		getUsersWithTaskHours();
		getUsers();
	}, [getProjects, filter, getUsersWithTaskHours, getUsers, refreshTriggerBranch.branchId]);

	const sendFilter = useCallback((values: any) => {
		setFilterDrawerOpen(false);
		setFilter({ ...values, skip: 0 });
	}, []);

	const toggleFilterDrawer = useCallback(() => {
		setFilterDrawerOpen(!isFilterDrawerOpen);
	}, [isFilterDrawerOpen]);

	const handleOpenModal = useCallback((project: HourProject) => {
		const userIds = project.projectTaskUsers.map((user) => user.userId);

		setSelectedProjectInfo({
			projectId: project.id,
			userIds,
		});
		setOpen(true);
	}, []);

	const handleAddUsersToProject = useCallback((selectedUserIds: string[]) => {
		if (selectedProjectInfo.projectId) {
			addUsersToProjectTasks(selectedProjectInfo.projectId, { userIds: selectedUserIds });
			setOpen(false);
		}
	}, [selectedProjectInfo, addUsersToProjectTasks]);

	const onChangePagination = useCallback((page, take) => {
		const skip = (page - 1) * take;
		setFilter((prevFilters) => ({
			...prevFilters,
			skip,
			take,
		}));
	}, [setFilter]);

	const headerButtonsProps = useMemo((): PageHeaderButtonProps[] => [
		{
			component: Link,
			variant: 'contained',
			color: 'primary',
			to: 'manage-hours',
			text: 'Controle de apontamento de horas',
			startIcon: <FilterListIcon />,
		},
		{
			component: Link,
			variant: 'contained',
			to: 'clock-hours',
			startIcon: <PersonIcon />,
			text: 'Ponto de Usuários',
		},
		{
			variant: 'contained',
			color: 'primary',
			onClick: toggleFilterDrawer,
			text: 'Filtro',
			startIcon: <FilterListIcon />,
		},
		// {
		// 	variant: 'contained',
		// 	color: 'secondary',
		// 	onClick: () => exportHourProjects(),
		// 	text: 'Exportar Relatório',
		// 	startIcon: <DescriptionIcon />,
		// },
	], [toggleFilterDrawer]);

	const pageHeaderMemo = useMemo(
		() => (
			<PageHeader
				title="Gestão de horas"
				buttons={headerButtonsProps}
			/>
		),
		[headerButtonsProps],
	);

	const hourProjectFilterMemo = useMemo(() => (
		<DrawerFilter open={isFilterDrawerOpen} onClose={toggleFilterDrawer}>
			<HourProjectFilter
				sendFilter={sendFilter}
				initialValues={filter}
				users={usersClock}
			/>
		</DrawerFilter>
	), [filter, isFilterDrawerOpen, sendFilter, toggleFilterDrawer, usersClock]);

	const taskAccordionsMemo = useMemo(() => projects.flatMap(
		(project) => project.tasks
			.filter((task) => !task.projectTaskId
				|| !project.tasks.some((parentTask) => parentTask.id === task.projectTaskId))
			.map((task) => (
				<TaskAccordion
					key={task.id}
					projectId={project.id}
					task={task}
					allTasks={project.tasks}
					updateProjectTask={updateProjectTask}
				/>
			)),
	), [projects, updateProjectTask]);

	return (
		<Box>
			{pageHeaderMemo}
			{hourProjectFilterMemo}
			{loading && <Loading />}
			{!loading && projects.length > 0 ? (
				projects.map((project) => (
					<Accordion key={project.id} sx={{ marginBottom: 2 }}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls={`${project.id}-content`}
							id={`${project.id}-header`}
						>
							<Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
								<Box display="flex" alignItems="center" gap={2} flex="1">
									<Tooltip
										title={(
											<>
												<Typography color="inherit">Informações do Projeto</Typography>
												<Box>
													<Typography variant="body2">
														Descrição:
														{project.description}
													</Typography>
													<Typography variant="body2">
														Início:
														{formatDateAndHours(project.startDate)}
													</Typography>
													<Typography variant="body2">
														Término:
														{formatDateAndHours(project.endDate)}
													</Typography>
													<Typography variant="body2">
														Cliente:
														{project.client}
													</Typography>
													<Typography variant="body2">
														Loja:
														{project.store}
													</Typography>
													<Typography variant="body2">
														Revisão:
														{project.revision}
													</Typography>
													<Typography variant="body2">
														Fase:
														{project.phase}
													</Typography>
													<Typography variant="body2">
														Concluído:
														{project.finished ? 'Sim' : 'Não'}
													</Typography>
												</Box>
											</>
										)}
									>
										<Typography
											variant="h6"
											sx={{
												cursor: 'help',
											}}
										>
											{`${project.code} - ${project.name}`}
										</Typography>
									</Tooltip>
								</Box>
								<Tooltip title="Adicionar Usuários">
									<IconButton
										onClick={(event) => {
											event.stopPropagation();
											handleOpenModal(project);
										}}
										size="small"
									>
										<GroupAddIcon color="primary" />
									</IconButton>
								</Tooltip>
								<Tooltip
									title={(
										<Box>
											<Box display="flex" alignItems="center" gap={0.5}>
												<AccessTimeIcon />
												<Typography variant="subtitle2">
													Horas previstas:
													{' '}
													{formatHoursMinutes(project.quantity)}
												</Typography>
											</Box>
											<Box display="flex" alignItems="center" gap={0.5}>
												<CheckCircleIcon />
												<Typography variant="subtitle2">
													Horas realizadas:
													{' '}
													{formatHoursMinutes(project.workedHours)}
												</Typography>
											</Box>
											<Box display="flex" alignItems="center" gap={0.5}>
												<ScaleIcon />
												<Typography variant="subtitle2">
													Saldo de Horas:
													{' '}
													{formatHoursMinutes(project.hoursBalance)}
												</Typography>
											</Box>
											<Box display="flex" alignItems="center" gap={0.5}>
												<AlarmOnIcon />
												<Typography variant="subtitle2">
													% Horas Realizadas:
													{' '}
													{`${project.completionPercentage || 0}%`}
												</Typography>
											</Box>
										</Box>
									)}
									arrow
								>
									<InfoIcon />
								</Tooltip>
								<Tooltip title={`Mostrando ${project.periodClockInOutCount} registros para o período selecionado. Total de apontamentos para o projeto: ${project.totalClockInOutCount}.`}>
									<Typography variant="subtitle2" sx={{ cursor: 'pointer' }}>
										{project.periodClockInOutCount}
										/
										{project.totalClockInOutCount}
									</Typography>
								</Tooltip>
							</Box>
						</AccordionSummary>
						<AccordionDetails>
							{project.tasks.length > 0 ? (
								taskAccordionsMemo.filter((accordionElement) => accordionElement.props.projectId
									=== project.id)
							) : (
								<Typography sx={{ color: 'warning.main' }}>
									Não há tarefas para exibir, se necessário refaça o filtro!
								</Typography>
							)}
						</AccordionDetails>
					</Accordion>
				))
			) : (
				!loading && <NoDataPage />
			)}
			<Pagination
				loading={loading}
				count={projectsPages}
				page={projectsPage}
				take={projectsTake}
				onChange={onChangePagination}
			/>
			<ProjectTaskUsersModal
				open={open}
				onClose={() => setOpen(false)}
				users={users}
				selectedProjectInfo={selectedProjectInfo}
				handleAddUsersToProject={handleAddUsersToProject}
			/>
		</Box>
	);
};

export default HourProjectDashboard;

import React, {	useCallback } from 'react';
import {
	Form, FormikContext, useFormik,
} from 'formik';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Input from '../Common/Form/Input';
import { ConsumptionProductionTaskQueryParams } from '../../interfaces/ConsumptionProductionTaskQueryParams';
import { InventoryTaskAction } from '../../enums/InventoryTaskAction';
import { DatePicker } from '../Common/Form/DatePicker';
import { defaultConsumptionTaskQueryParams } from '../../containers/Consumption/ConsumptionAssets';

interface FilterProps {
	handleConsumptionProductionFilter: (
		values: ConsumptionProductionTaskQueryParams
	) => void;
	initialValues?: ConsumptionProductionTaskQueryParams;
}

const ConsumptionProductionTaskFilter = ({
	handleConsumptionProductionFilter,
	initialValues,
}: FilterProps): JSX.Element => {
	const onSubmit = useCallback((values: ConsumptionProductionTaskQueryParams) => {
		handleConsumptionProductionFilter({ ...values, skip: 0 });
	}, [handleConsumptionProductionFilter]);

	const formik = useFormik({
		initialValues: initialValues || defaultConsumptionTaskQueryParams,
		onSubmit,
	});

	const onReset = useCallback((resetForm) => {
		const preservedParams = {
			skip: 0,
			action: InventoryTaskAction.CONSUMPTION,
			type: initialValues?.type,
			statusHeader: initialValues?.statusHeader,
		};

		handleConsumptionProductionFilter(preservedParams);
		resetForm({ values: preservedParams });
	}, [handleConsumptionProductionFilter, initialValues?.statusHeader, initialValues?.type]);

	return (
		<FormikContext.Provider value={formik}>
			<Form>
				<Grid container spacing={2} sx={{ p: 2, marginTop: 6 }}>
					<Grid item xs={6}>
						<DatePicker
							name="startDate"
							label="Data Início"
						/>
					</Grid>
					<Grid item xs={6}>
						<DatePicker
							name="endDate"
							label="Data Fim"
						/>
					</Grid>
					<Grid item xs={12}>
						<Input.InputField
							id="operatorName"
							name="operatorName"
							label="Requisitante"
							autoComplete="off"
							fullWidth
						/>
					</Grid>
					<Grid item xs={12}>
						<Input.InputField
							id="code"
							name="code"
							label="Código"
							autoComplete="off"
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
						<Button variant="outlined" onClick={() => onReset(() => formik.resetForm())}>
							Restaurar
						</Button>
						<Button variant="contained" type="submit">
							Filtrar
						</Button>
					</Grid>
				</Grid>
			</Form>
		</FormikContext.Provider>
	);
};

ConsumptionProductionTaskFilter.defaultProps = {
	initialValues: {},
};

export default ConsumptionProductionTaskFilter;

import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import BusinessPartnerForm from './BusinessPartnerForm';
import { IBusinessPartner } from '../../containers/BusinessPartner/BusinessPartnerAssets';
import { Loading } from '../Common/Loading';
import { IBusinessPartnerActions, IState } from '../../containers/BusinessPartner/BusinessPartnerEdit';

interface BusinessPartnerEditProps extends IState, IBusinessPartnerActions {
	loading: boolean;
	businessPartner?: IBusinessPartner;
}

const BusinessPartnerEdit = ({
	loading,
	businessPartner,
	salesmans,
	cep,
	unitCep,
	products,
	typeContacts,
	countries,
	states,
	cities,
	users,
	getSalesmans,
	getBusinessPartnerById,
	getProducts,
	getAddressByCep,
	handleEditBusinessPartner,
	handleDeleteProductBusinessPartner,
	getTypeContacts,
	getCountries,
	getStates,
	getCities,
	getUsers,
}: BusinessPartnerEditProps): JSX.Element => {
	const { id } = useParams();

	useEffect(() => {
		if (id) {
			getBusinessPartnerById(id);
		}
		getSalesmans();
		getTypeContacts();
		getStates();
		getUsers();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (businessPartner?.id) {
			getCountries(businessPartner?.country);
			getCities(businessPartner?.city);
		}
		if (
			businessPartner?.productBusinessPartners?.length
			|| businessPartner?.units?.some((unit) => unit.productBusinessPartners?.length)
		) {
			const productIds = [
				...(businessPartner?.productBusinessPartners?.map((pbp) => pbp.productId) || []),
				...(businessPartner?.units?.flatMap((unit) => unit
					.productBusinessPartners?.map((pbp) => pbp.productId) || []) || [])]
				.filter((productId): productId is string => Boolean(productId));

			if (productIds.length > 0) {
				getProducts({ productIds });
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [businessPartner]);

	const handleCepBlur = useCallback((cepValue: string, unitIndex?: number) => {
		if (cepValue) {
			getAddressByCep(cepValue, unitIndex);
		}
	}, [getAddressByCep]);

	const handleSubmit = useCallback((values: IBusinessPartner) => {
		const updatedUnits = values.units.map((unit) => {
			const { tempId, unitIndex, ...unitWithoutTempIdAndIndex } = unit;
			const unitContacts = unit.contacts || [];
			const unitProductBPs = unit.productBusinessPartners || [];

			const updatedUnitContacts = unitContacts.map((contact) => ({
				...contact,
				businessPartnerId: contact.businessPartnerId === tempId ? '' : contact.businessPartnerId,
			})).filter((contact) => contact.businessPartnerId !== '');

			const updatedUnitProductBPs = unitProductBPs.map((productBP) => ({
				...productBP,
				businessPartnerId: productBP.businessPartnerId === tempId ? '' : productBP.businessPartnerId,
			})).filter((productBP) => productBP.businessPartnerId !== '');

			const productsBP = values.productBusinessPartners
				.filter((productBP) => productBP.businessPartnerId === (unit.id || tempId))
				.concat(updatedUnitProductBPs.filter(
					(up) => !values.productBusinessPartners.some((p) => p.id === up.id),
				));

			return {
				...unitWithoutTempIdAndIndex,
				approved: true,
				businessPartners: undefined,
				contacts: values.contacts
					.filter((contact) => contact.businessPartnerId === (unit.id || tempId))
					.concat(updatedUnitContacts.filter((uc) => !values.contacts.some((c) => c.id === uc.id))),
				productBusinessPartners: {
					...productsBP,
					hasTask: undefined,
				},
			};
		});

		const updatedProductBusinessPartners = values.productBusinessPartners.filter(
			(productBP) => productBP.businessPartnerId === values.id,
		);

		const tempIds = values.units.map((unit) => unit.tempId);

		const { salesmans: localSalesmans, ...restValues } = values;

		const updatedValues = {
			...restValues,
			approved: true,
			businessPartners: undefined,
			units: updatedUnits,
			contacts: values.contacts.map((contact) => ({
				...contact,
				businessPartnerId: contact.businessPartnerId === values.name ? '' : contact.businessPartnerId,
			})).filter((contact) => !tempIds.includes(contact.businessPartnerId)
			&& contact.businessPartnerId !== values.name),
			productBusinessPartners: updatedProductBusinessPartners.map((productBP) => ({
				...productBP,
				hasTask: undefined,
				businessPartnerId: productBP.businessPartnerId === values.name ? '' : productBP.businessPartnerId,
			})),
		};

		handleEditBusinessPartner({ id: businessPartner?.id, data: updatedValues });
	}, [handleEditBusinessPartner, businessPartner]);

	if (id && !businessPartner) {
		return <Loading />;
	}

	return (
		<BusinessPartnerForm
			loading={loading}
			businessPartner={businessPartner}
			salesmans={salesmans}
			cep={cep}
			unitCep={unitCep}
			products={products}
			typeContacts={typeContacts}
			countries={countries}
			states={states}
			cities={cities}
			users={users}
			getCountries={getCountries}
			getCities={getCities}
			getProducts={getProducts}
			handleCepBlur={handleCepBlur}
			handleSubmit={handleSubmit}
			handleDeleteProductBusinessPartner={handleDeleteProductBusinessPartner}
		/>
	);
};

BusinessPartnerEdit.defaultProps = {
	businessPartner: undefined,
};

export default BusinessPartnerEdit;

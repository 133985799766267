import React, { useMemo } from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grow from '@mui/material/Grow';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import CountUp from 'react-countup';

interface StatusCardProps {
  title: string;
  count: number;
  icon: React.ReactNode;
  color: string;
  onClick: () => void;
}

export const StatusCard: React.FC<StatusCardProps> = ({
	title,
	count,
	icon,
	color,
	onClick,
}) => {
	const theme = useTheme();

	const styles = useMemo(() => ({
		card: {
			height: '100%',
			border: `0.1px solid ${color}`,
			borderLeft: `6px solid ${color}`,
			transition: 'all 0.3s ease',
			cursor: 'pointer',
			borderRadius: '12px',
			'&:hover': {
				transform: 'translateY(-5px)',
				boxShadow: theme.shadows[8],
			},
		},
		icon: {
			color,
			fontSize: '2rem',
		},
		count: {
			color,
			fontWeight: 'bold',
			textAlign: 'right',
			fontSize: '2.3rem',
		},
		title: {
			mt: 2,
			fontWeight: 500,
			color: theme.palette.text.secondary,
		},
	}), [color, theme.shadows, theme.palette.text.secondary]);

	return (
		<Grow in style={{ transformOrigin: '0 0 0' }} timeout={500}>
			<Card
				onClick={onClick}
				sx={styles.card}
			>
				<CardContent>
					<Box display="flex" alignItems="center" justifyContent="space-between">
						<Box sx={{ color }}>
							{React.cloneElement(icon as React.ReactElement, { sx: styles.icon })}
						</Box>
						<Typography variant="h3" sx={styles.count}>
							<CountUp end={count} duration={1.5} />
						</Typography>
					</Box>
					<Typography variant="subtitle1" sx={styles.title}>
						{title}
					</Typography>
				</CardContent>
			</Card>
		</Grow>
	);
};

import React, {
	Dispatch, Reducer, useMemo, useReducer,
} from 'react';
import {
	OptionsObject,
	SnackbarKey,
	SnackbarMessage,
	useSnackbar,
} from 'notistack';
import { AxiosError } from 'axios';
import InventoryPendingTaskPresentational from '../../components/InventoryTaskInvoice/InventoryTaskInvoice';
import {
	InventoryTaskInvoiceQueryParams,
	InventoryTaskTransferParams,
	InventoryTaskTransferProductData,
	ReleaseTasksParams,
	RemoveTasksParams,
	ReprocessTasksParams,
} from '../../interfaces/InventoryTaskInvoiceQueryParams';
import {
	getInventoryTaskInvoice,
	releaseTasks,
	reprocessLocationTasks,
	finishTask,
	transferUserTask,
	createTransferProductTask,
	getInventoryTaskInvoiceStatusCount,
} from '../../services/inventoryTask';
import { IInventoryTaskInvoice, InvoiceStatusCountResponse } from './InventoryTaskInvoiceAssets';
import { ProductQueryParams } from '../../interfaces/ProductQueryParams';
import {
	getProductLocationData,
	getProductsWithoutDetails,
} from '../../services/product';
import {
	ILocationProductData,
	IProductWithoutDetails,
} from '../Product/ProductAssets';
import { ErrorResponse } from '../../interfaces/ErrorResponse';
import { removeDuplicates } from '../../helpers/Utils';
import {
	getLocationProductWithoutProduct,
	getLocations,
} from '../../services/location';
import {
	ILocationProductWithoutProduct,
	LocationProductQueryParams,
	LocationProductWithoutProductQueryParams,
} from '../../interfaces/LocationProduct';
import { ILocation } from '../Location/LocationAssets';

enum ActionType {
  LOADING,
  INVENTORY_TASK_INVOICE,
  UPDATE_USER,
  FILTERS,
  PRODUCTS,
  PRODUCT_LOCATIONS,
  LOCATION_PRODUCT,
  LOCATION,
  STATUS_COUNT,
}

interface IState {
  loading: boolean;
  inventoryTaskInvoices: IInventoryTaskInvoice[];
  inventoryTaskInvoicesPages: number;
  inventoryTaskInvoicesPage: number;
  inventoryTaskInvoicesTake: number;
  products: IProductWithoutDetails[];
  productLocations: ILocationProductData[];
  locationWithoutProducts: ILocationProductWithoutProduct[];
  locations: ILocation[];
  statusCount: InvoiceStatusCountResponse;
}

type TAction =
  | { type: ActionType.LOADING; payload: { loading: boolean } }
  | {
      type: ActionType.INVENTORY_TASK_INVOICE;
      payload: {
        inventoryTaskInvoices: IInventoryTaskInvoice[];
        inventoryTaskInvoicesPages: number;
        inventoryTaskInvoicesPage: number;
        inventoryTaskInvoicesTake: number;
      };
    }
  | { type: ActionType.PRODUCTS; payload: { products: IProductWithoutDetails[] } }
  | { type: ActionType.LOCATION; payload: { locations: ILocation[] } }
  | {
      type: ActionType.PRODUCT_LOCATIONS;
      payload: { productLocations: ILocationProductData[] };
    }
  | {
      type: ActionType.LOCATION_PRODUCT;
      payload: { locationWithoutProducts: ILocationProductWithoutProduct[] }
   }
  | { type: ActionType.STATUS_COUNT; payload: { statusCount: InvoiceStatusCountResponse } };

interface ITaskActions {
  setLoading(loading: boolean): void;
  getInventoryTaskInvoice(queryParams: InventoryTaskInvoiceQueryParams): void;
  releaseTasks: (data: ReleaseTasksParams) => void;
  reprocessLocationTasks: (data: ReprocessTasksParams) => void;
  finishTask: (data: RemoveTasksParams) => void;
  transferUserTask: (userId: string, data: InventoryTaskTransferParams) => void;
  getProducts: (queryParams: ProductQueryParams) => void;
  getProductLocationsData: (productId: string) => Promise<void>;
  getLocationProductWithoutProduct: (params: LocationProductWithoutProductQueryParams) => void;
  createTransferProductTask: (data: InventoryTaskTransferProductData) => void;
  getLocations: (queryParams?: LocationProductQueryParams) => void;
  getInventoryTaskInvoiceStatusCount: () => void;
}

const initialState: IState = {
	loading: false,
	inventoryTaskInvoices: [],
	inventoryTaskInvoicesPages: 0,
	inventoryTaskInvoicesPage: 0,
	inventoryTaskInvoicesTake: 10,
	products: [],
	productLocations: [],
	locationWithoutProducts: [],
	locations: [],
	statusCount: {
		supply: {
			pending: 0,
			inProgress: 0,
			concluded: 0,
			canceled: 0,
		},
		move: {
			pending: 0,
			inProgress: 0,
			concluded: 0,
			canceled: 0,
		},
		dispatch: {
			pending: 0,
			inProgress: 0,
			concluded: 0,
			canceled: 0,
		},
	},
};

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.INVENTORY_TASK_INVOICE:
			return {
				...state,
				inventoryTaskInvoices: action.payload.inventoryTaskInvoices,
				inventoryTaskInvoicesPages: action.payload.inventoryTaskInvoicesPages,
				inventoryTaskInvoicesPage: action.payload.inventoryTaskInvoicesPage,
				inventoryTaskInvoicesTake: action.payload.inventoryTaskInvoicesTake,
			};
		case ActionType.PRODUCTS:
		{
			const newProducts = action.payload.products;
			const updatedProducts = removeDuplicates([...state.products, ...newProducts], 'id');
			return { ...state, products: updatedProducts };
		}
		case ActionType.LOCATION:
			return { ...state, locations: action.payload.locations };
		case ActionType.PRODUCT_LOCATIONS:
			return { ...state, productLocations: action.payload.productLocations };
		case ActionType.LOCATION_PRODUCT:
		{
			const newLocations = action.payload.locationWithoutProducts;
			const updatedLocations = removeDuplicates([...state.locationWithoutProducts, ...newLocations], 'locationId');
			return { ...state, locationWithoutProducts: updatedLocations };
		}
		case ActionType.STATUS_COUNT:
			return { ...state, statusCount: action.payload.statusCount };
		default:
			throw new Error();
	}
};

let lastQueryParams: InventoryTaskInvoiceQueryParams;

const TaskActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject | undefined
  ) => SnackbarKey,
): ITaskActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		getInventoryTaskInvoice(queryParams: InventoryTaskInvoiceQueryParams) {
			actions.setLoading(true);

			const take = queryParams.take ?? 10;
			const skip = queryParams.skip ?? 0;
			const params: InventoryTaskInvoiceQueryParams = {
				...queryParams,
				skip: skip * take,
			};
			getInventoryTaskInvoice(params)
				.then((response) => {
					dispatch({
						type: ActionType.INVENTORY_TASK_INVOICE,
						payload: {
							inventoryTaskInvoices: response.data.data,
							inventoryTaskInvoicesPages: Math.ceil(response.data.count / take),
							inventoryTaskInvoicesPage: queryParams.skip,
							inventoryTaskInvoicesTake: take,
						},
					});
					lastQueryParams = queryParams;
				})
				.catch((error) => {
					enqueueSnackbar(
						error.response?.data.message || 'Erro ao obter informações',
						{
							variant: 'error',
						},
					);
				});

			actions.setLoading(false);
		},
		releaseTasks(data: ReleaseTasksParams) {
			actions.setLoading(true);
			releaseTasks(data)
				.then((response) => {
					enqueueSnackbar(
						response.data.message || 'Tarefas liberadas com sucesso!',
						{ variant: 'success' },
					);
				})
				.catch((error) => {
					enqueueSnackbar(
						error.response?.data.message || 'Erro ao reprocessar localizações',
						{
							variant: 'error',
						},
					);
				})
				.finally(() => {
					if (lastQueryParams) {
						actions.getInventoryTaskInvoice({
							...lastQueryParams,
							skip: 0,
						});
					}
					actions.setLoading(false);
				});
		},
		reprocessLocationTasks(data: ReprocessTasksParams) {
			actions.setLoading(true);
			reprocessLocationTasks(data)
				.then((response) => {
					enqueueSnackbar(
						response.data.message || 'Tarefas reprocessadas com sucesso!',
						{ variant: 'success' },
					);
				})
				.catch((error) => {
					enqueueSnackbar(
						error.response?.data.message || 'Erro ao reprocessar localizações',
						{
							variant: 'error',
						},
					);
				})
				.finally(() => {
					if (lastQueryParams) {
						actions.getInventoryTaskInvoice(lastQueryParams);
					}
					actions.setLoading(false);
				});
		},
		finishTask(data: RemoveTasksParams) {
			actions.setLoading(true);
			finishTask(data)
				.then((response) => {
					enqueueSnackbar(
						response.data.message || 'Tarefa finalizada com sucesso!',
						{ variant: 'success' },
					);
				})
				.catch((error) => {
					enqueueSnackbar(
						error.response?.data.message || 'Erro ao finalizar tarefa',
						{
							variant: 'error',
						},
					);
				})
				.finally(() => {
					if (lastQueryParams) {
						actions.getInventoryTaskInvoice(lastQueryParams);
					}
					actions.setLoading(false);
				});
		},
		transferUserTask(userId: string, data: InventoryTaskTransferParams) {
			actions.setLoading(true);
			transferUserTask(userId, data)
				.then((response) => {
					enqueueSnackbar(
						response.data.message || 'Tarefa transferida com sucesso!',
						{ variant: 'success' },
					);
				})
				.catch((error) => {
					enqueueSnackbar(
						error.response?.data.message || 'Erro ao transferir tarefa',
						{
							variant: 'error',
						},
					);
				});
			actions.setLoading(false);
		},
		getProducts(queryParams: ProductQueryParams) {
			getProductsWithoutDetails(queryParams).then((response) => {
				dispatch({
					type: ActionType.PRODUCTS,
					payload: {
						products: response.data.data,
					},
				});
			}).catch((error: AxiosError) => {
				enqueueSnackbar(
					error.response?.data.message || 'Algum erro ocorreu ao obter as informações dos produtos, tente novamente ou contate um administrador.',
					{
						variant: 'error',
					},
				);
			});
		},
		getLocationProductWithoutProduct(params: LocationProductWithoutProductQueryParams) {
			getLocationProductWithoutProduct(params).then((response) => {
				dispatch({
					type: ActionType.LOCATION_PRODUCT,
					payload: {
						locationWithoutProducts: response.data.data,
					},
				});
			}).catch((error: AxiosError) => {
				enqueueSnackbar(
					error.response?.data.message || 'Algum erro ocorreu ao obter as informações das localizações, tente novamente ou contate um administrador.',
					{
						variant: 'error',
					},
				);
			});
		},
		async getProductLocationsData(productId: string) {
			actions.setLoading(true);
			try {
				const response = await getProductLocationData(productId);

				dispatch({
					type: ActionType.PRODUCT_LOCATIONS,
					payload: {
						productLocations: response.data,
					},
				});
			} catch (error) {
				const axiosError = error as AxiosError;
				enqueueSnackbar(
					axiosError.response?.data.message
            || 'Algum erro ocorreu, tente novamente ou contate um administrador.',
					{ variant: 'error' },
				);
			} finally {
				actions.setLoading(false);
			}
		},
		createTransferProductTask(data: InventoryTaskTransferProductData) {
			actions.setLoading(true);
			createTransferProductTask(data)
				.then((response) => {
					enqueueSnackbar(
						response.data.message || 'Transferência iniciada com sucesso!',
						{ variant: 'success' },
					);
				})
				.catch((error: AxiosError<ErrorResponse>) => {
					enqueueSnackbar(
						error.response?.data.message
              || 'Erro ao iniciar processo de transferência',
						{
							variant: 'error',
						},
					);
				})
				.finally(() => {
					if (lastQueryParams) {
						actions.getInventoryTaskInvoice(lastQueryParams);
					}
					actions.setLoading(false);
				});
		},
		getLocations(queryParams?: LocationProductQueryParams) {
			actions.setLoading(true);

			getLocations(queryParams)
				.then((response) => {
					dispatch({
						type: ActionType.LOCATION,
						payload: {
							locations: response.data.locations,
						},
					});
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(
						error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.',
						{ variant: 'error' },
					);
				})
				.finally(() => {
					actions.setLoading(false);
				});
		},
		getInventoryTaskInvoiceStatusCount() {
			getInventoryTaskInvoiceStatusCount()
				.then((response) => {
					dispatch({
						type: ActionType.STATUS_COUNT,
						payload: {
							statusCount: response.data,
						},
					});
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(
						error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.',
						{ variant: 'error' },
					);
				})
				.finally(() => {
					actions.setLoading(false);
				});
		},
	};

	return actions;
};

const InventoryPendingTaskContainer = (): JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(
		reducer,
		initialState,
	);
	const { enqueueSnackbar } = useSnackbar();
	const actions = useMemo(
		() => TaskActions(dispatch, enqueueSnackbar),
		[dispatch, enqueueSnackbar],
	);

	// eslint-disable-next-line react/jsx-props-no-spreading
	return <InventoryPendingTaskPresentational {...state} {...actions} />;
};

export default InventoryPendingTaskContainer;

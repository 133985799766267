import React, {
	createContext, useState, useCallback, useMemo,
} from 'react';
import { IBranchTrigger } from '../interfaces/BranchTrigger';

export const BranchContext = createContext({
	refreshTriggerBranch: {
		branchId: '',
		branchName: '',
	},
	updateBranch: (trigger: IBranchTrigger) => (
		{
			branchId: trigger.branchId,
			branchName: trigger.branchName,
		}
	),
});

export const BranchProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const [refreshTriggerBranch, setRefreshTriggerBranch] = useState(() => {
		const savedBranch = localStorage.getItem('refreshTriggerBranch');
		return savedBranch ? JSON.parse(savedBranch) : { branchId: '', branchName: '' };
	});

	const updateBranch = useCallback((branch: IBranchTrigger) => {
		setRefreshTriggerBranch(branch);
		localStorage.setItem('refreshTriggerBranch', JSON.stringify(branch));
		return branch;
	}, []);

	const value = useMemo(() => ({
		refreshTriggerBranch,
		updateBranch,
	}), [refreshTriggerBranch, updateBranch]);

	return (
		<BranchContext.Provider value={value}>
			{children}
		</BranchContext.Provider>
	);
};

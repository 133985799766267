import React, { useCallback, useState } from 'react';
import {
	DataGrid,
	GridColDef,
	GridSortModel,
	GridValueFormatterParams,
} from '@mui/x-data-grid';
import {
	CalendarMonth,
	Close,
	Delete,
	Description,
	Info,
	Inventory2,
} from '@mui/icons-material';
import {
	IconButton,
	Stack,
	Tooltip,
	Popover,
	MenuList,
	MenuItem,
	Box,
	Paper,
} from '@mui/material';
import { IInventory, InventoryQueryParams, InventoryType } from '../../containers/Inventory/InventoryAssets';
import { formatUTCDateToLocal } from '../../helpers/DateUtils';
import ModalEditInventoryDate from './Modals/ModalEditInventoryDate';
import { InventoryStatus } from '../../enums/inventory';
import useConfirmationDialog from '../../hooks/useConfirmationDialog';
import ActionJustificativeModal from '../Common/ActionJustificativeModal';
import useInventoryNavigation from '../../hooks/useInventoryNavigation';
import { Loading } from '../Common/Loading';

interface CurrentView {
	type: InventoryType;
	status: InventoryStatus;
}

interface InventoryTableProps {
	loading: boolean;
	inventories: IInventory[];
	inventoryPage: number;
	inventoryCount: number;
	inventoryTake: number;
	sortModel: GridSortModel;
	onChangePage(productsTake: number): void;
	onChangePageSize(pageSize: number): void;
	onSortModelChange(sortModel: GridSortModel): void;
	handleChangeDateInventERP: (inventoryId: string, dateInventERP: Date | string | null) => void;
	deleteInventory(inventoryId: string): void;
	cancelInventory: (inventoryId: string, justification: string) => void;
	filter: InventoryQueryParams;
	currentView: CurrentView | null;
	expandedSection: InventoryType | null;
}

const InventoryTable = ({
	loading,
	inventories,
	inventoryPage,
	inventoryTake,
	inventoryCount,
	sortModel,
	onChangePage,
	onChangePageSize,
	onSortModelChange,
	handleChangeDateInventERP,
	deleteInventory,
	cancelInventory,
	filter,
	currentView,
	expandedSection,
}: InventoryTableProps): JSX.Element => {
	const [openModalDateInventERP, setOpenModalDateInventERP] = useState(false);
	const [openCancelModal, setOpenCancelModal] = useState(false);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [activeId, setActiveId] = useState('');
	const [dateInventERP, setDateInventERP] = useState<Date | string | null>(null);
	const [showJustificativeModal, setShowJustificativeModal] = useState(false);
	const [selectedJustificative, setSelectedJustificative] = useState('');
	const { requestConfirm, confirmationDialog } = useConfirmationDialog();

	const {
		navigateToInventoryTasks,
		navigateToReport,
	} = useInventoryNavigation();

	const handleDetailsInventory = useCallback((id: string) => {
		navigateToInventoryTasks(id, {
			filters: filter,
			type: currentView?.type,
			status: currentView?.status,
			originalExpandedSection: expandedSection,
		});
	}, [navigateToInventoryTasks, filter, currentView, expandedSection]);

	const handleReportsClick = useCallback(
		(event: any, id: string): void => {
			setActiveId(id);
			setAnchorEl(event.currentTarget);
		},
		[],
	);

	const onClose = useCallback((): void => {
		setOpenModalDateInventERP(false);
	}, []);

	const handleReportsClose = useCallback((): void => {
		setAnchorEl(null);
		setActiveId('');
	}, []);

	const handleOpenCancelModal = useCallback((id: string): void => {
		setActiveId(id);
		setOpenCancelModal(true);
	}, []);

	const handleCloseCancelModal = useCallback((): void => {
		setOpenCancelModal(false);
	}, []);

	const handleShowJustificative = useCallback((id: string, justificative: string): void => {
		setActiveId(id);
		setSelectedJustificative(justificative);
		setShowJustificativeModal(true);
	}, []);

	const handleCloseJustificativeModal = useCallback((): void => {
		setShowJustificativeModal(false);
		setSelectedJustificative('');
	}, []);

	const columns: GridColDef<(typeof inventories)[number]>[] = [
		{
			field: 'document',
			headerName: 'Documento',
			flex: 1,
		},
		{
			field: 'date',
			headerName: 'Data de Criação',
			flex: 1,
			align: 'center',
			headerAlign: 'center',
			valueFormatter: (
				params: GridValueFormatterParams<string>,
			) => formatUTCDateToLocal(params.value, 'dd/MM/yyyy'),
		},
		{
			field: 'finishedAt',
			headerName: 'Data de Finalização',
			flex: 1,
			align: 'center',
			headerAlign: 'center',
			valueFormatter: (
				params: GridValueFormatterParams<string>,
			) => (params.value ? formatUTCDateToLocal(params.value, 'dd/MM/yyyy') : '-'),
		},
		{
			field: 'dateInventERP',
			headerName: 'Data Invent. ERP',
			flex: 1,
			align: 'center',
			headerAlign: 'center',
			valueFormatter: (
				params: GridValueFormatterParams<string>,
			) => (params.value ? formatUTCDateToLocal(params.value, 'dd/MM/yyyy') : '-'),
		},
		{
			field: 'integratedAt',
			headerName: 'Data da Integração',
			flex: 1,
			align: 'center',
			headerAlign: 'center',
			valueFormatter: (
				params: GridValueFormatterParams<string>,
			) => (params.value ? formatUTCDateToLocal(params.value, 'dd/MM/yyyy') : '-'),
		},
		{
			field: 'id',
			headerName: 'Ações',
			width: 255,
			renderCell: (params) => (
				<Stack direction="row">
					<Tooltip title="Tarefas do Inventário">
						<Box>
							<IconButton
								aria-label="Tarefas do Inventário"
								onClick={() => handleDetailsInventory(params.id as string)}
								disabled={params.row.status === InventoryStatus.CANCELED}
							>
								<Inventory2 color={params.row.status === InventoryStatus.CANCELED
									? 'disabled' : 'primary'}
								/>
							</IconButton>
						</Box>
					</Tooltip>
					<Tooltip title="Relatórios">
						<Box>
							<IconButton
								aria-label="Relatórios"
								onClick={(event) => handleReportsClick(event, params.id as string)}
							>
								<Description color="primary" />
							</IconButton>
						</Box>
					</Tooltip>
					<Tooltip title="Editar Data do Inventário ERP">
						<Box>
							<IconButton
								aria-label="Editar Data do Inventário ERP"
								onClick={() => {
									setOpenModalDateInventERP(true);
									setDateInventERP(params.row.dateInventERP);
									setActiveId(params.id as string);
								}}
								disabled={params.row.status === InventoryStatus.CANCELED}
							>
								<CalendarMonth color={params.row.status === InventoryStatus.CANCELED ? 'disabled' : 'primary'} />
							</IconButton>
						</Box>
					</Tooltip>
					<Tooltip title="Ver motivo do cancelamento">
						<Box>
							<IconButton
								onClick={() => handleShowJustificative(params.id as string, params.row.actionJustificative || '')}
								disabled={params.row.status !== InventoryStatus.CANCELED
								|| !params.row.actionJustificative}
							>
								<Info color={params.row.status !== InventoryStatus.CANCELED
								|| !params.row.actionJustificative ? 'disabled' : 'primary'}
								/>
							</IconButton>
						</Box>
					</Tooltip>
					<Tooltip title="Cancelar Inventário">
						<Box>
							<IconButton
								aria-label="Cancelar Inventário"
								onClick={() => handleOpenCancelModal(params.id as string)}
								disabled={(params.row.status === InventoryStatus.CANCELED
									|| params.row.status === InventoryStatus.FINISHED
								)}
							>
								<Close
									color={
										(params.row.status === InventoryStatus.CANCELED
											|| params.row.status === InventoryStatus.FINISHED
										) ? 'disabled' : 'error'
									}
								/>
							</IconButton>
						</Box>
					</Tooltip>
					<Tooltip title="Excluir Inventário">
						<Box>
							<IconButton
								aria-label="Excluir Inventário"
								onClick={() => {
									requestConfirm({
										title: 'Excluir Inventário',
										description: 'Tem certeza que deseja excluir este inventário?',
										callback: () => deleteInventory(params.id as string),
									});
								}}
								disabled={params.row.status !== InventoryStatus.PENDING}
							>
								<Delete color={params.row.status !== InventoryStatus.PENDING ? 'disabled' : 'error'} />
							</IconButton>
						</Box>
					</Tooltip>

					<Popover
						open={Boolean(anchorEl)}
						anchorEl={anchorEl}
						onClose={handleReportsClose}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						elevation={1}
					>
						<MenuList>
							<MenuItem onClick={() => navigateToReport('summary', activeId, {
								filters: filter,
								type: currentView?.type,
								status: currentView?.status,
								originalExpandedSection: expandedSection,
							})}
							>
								Resumo do inventário
							</MenuItem>
							<MenuItem onClick={() => navigateToReport('report-by-operator', activeId, {
								filters: filter,
								type: currentView?.type,
								status: currentView?.status,
								originalExpandedSection: expandedSection,
							})}
							>
								Resumo do inventário por operador
							</MenuItem>
							<MenuItem onClick={() => navigateToReport('difference', activeId, {
								filters: filter,
								type: currentView?.type,
								status: currentView?.status,
								originalExpandedSection: expandedSection,
							})}
							>
								Diferenças do inventário
							</MenuItem>
						</MenuList>
					</Popover>
				</Stack>
			),
		},
	];

	return (
		<>
			{loading && <Loading />}
			{!loading && (
				<Box component={Paper} sx={{ width: '100%' }}>
					<DataGrid
						autoHeight
						rows={inventories || []}
						columns={columns}
						page={inventoryPage}
						pageSize={inventoryTake}
						pagination
						paginationMode="server"
						sortingMode="server"
						rowCount={inventoryCount}
						rowsPerPageOptions={[10, 25, 50, 100]}
						onPageChange={onChangePage}
						onPageSizeChange={onChangePageSize}
						onSortModelChange={onSortModelChange}
						sortModel={sortModel}
						disableSelectionOnClick
					/>
					<ModalEditInventoryDate
						openModalDateInventERP={openModalDateInventERP}
						onClose={onClose}
						dateInventERP={dateInventERP}
						handleChangeDateInventERP={handleChangeDateInventERP}
						inventoryId={activeId}
					/>
					<ActionJustificativeModal
						id={activeId}
						open={openCancelModal}
						onClose={handleCloseCancelModal}
						title="Cancelar Inventário"
						onConfirm={(justificate: string, id?: string) => {
							if (id) {
								cancelInventory(id, justificate);
							}
						}}
					/>
					<ActionJustificativeModal
						id={activeId}
						open={showJustificativeModal}
						onClose={handleCloseJustificativeModal}
						title="Motivo do Cancelamento"
						readonly
						initialJustificative={selectedJustificative}
					/>
					{confirmationDialog}
				</Box>
			)}
		</>
	);
};

export default InventoryTable;

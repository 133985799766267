import React, {
	useCallback, useEffect, useMemo, useState,
} from 'react';
import { Form, FormikContext, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { ITask } from '../../../containers/Mobile/TaskAssets';
import ExpeditionStart from './ExpeditionStart';
import ExpeditionList from './ExpeditionList';
import ExpeditionProducts from './ExpeditionProducts';
import ExpeditionProductDetails from './ExpeditionProductDetails';
import { ISaleOrderLocations } from '../../../containers/Mobile/Expedition/ExpeditionAssets';
import ExpeditionFinish from './ExpeditionFinish';
import { groupTasksBySaleOrder } from '../../../helpers/Utils';
import { SaveConsumptionCountBody } from '../../../containers/Mobile/Consumption/ConsumptionAssets';
import { SaveTaskType } from '../../../containers/InventoryTaskInvoice/InventoryTaskInvoiceAssets';

export enum StepsExpedition {
    START,
    PRODUCTS,
    LOCATIONS,
    PRODUCT_DETAILS,
    FINISH
}

const initialValues = {
	expeditionTasks: [],
	locationSearch: '',
	productsSearch: '',
	locationDestinyConfirmation: '',
	locationDestiny: '',
	barcodeProduct: '',
	tasksPending: [],
	tasksPendingPost: [],
	productQuantity: 0,
	productSelected: null,
	locationSelected: null,
};

interface ExpeditionProps {
    loading: boolean;
    getLocationDestiny: () => void;
    setLoading: (loading: boolean) => void;
    expeditionTasks: ITask[];
    saveTakeTaskBatch: (tasks: SaveTaskType[]) => void;
    saveTaskCountBatch: (data: SaveConsumptionCountBody) => void;
	activeStep: StepsExpedition;
	setActiveStep: (step: StepsExpedition) => void;
}

export interface GroupedTasks {
    [saleOrder: string]: ISaleOrderLocations;
}

const Expedition = ({
	loading,
	setLoading,
	getLocationDestiny,
	saveTakeTaskBatch,
	saveTaskCountBatch,
	expeditionTasks,
	activeStep,
	setActiveStep,
}: ExpeditionProps): JSX.Element => {
	const [saleOrder, setSaleOrder] = useState<string>('');
	const [groupedTasks, setGroupedTasks] = useState<GroupedTasks | null>(null);
	const [tasksPendingView, setTasksPendingView] = useState<ITask[]>([]);
	const [locationSelected, setLocationSelected] = useState<string>('');
	const [isBarCode, setIsBarCode] = useState<boolean>(false);

	const navigate = useNavigate();

	useEffect(() => {
		getLocationDestiny();
	}, [getLocationDestiny]);

	useEffect(() => {
		const grouped = groupTasksBySaleOrder(expeditionTasks);
		setGroupedTasks(grouped);
		setTasksPendingView(expeditionTasks);
	}, [expeditionTasks]);

	const formik = useFormik({
		initialValues,
		onSubmit: (values, { resetForm }) => {
			saveTaskCountBatch({ tasks: values.tasksPendingPost });
			resetForm();
		},
	});

	const handleLocationStep = useCallback((order: ISaleOrderLocations): void => {
		setLoading(true);
		const filteredTasks = expeditionTasks
			.filter((task) => order.saleOrder === task.inventoryTaskInvoice.saleOrder)
			.map((task) => ({ taskId: task.id, quantity: Number(task.quantity) }));
		setLoading(false);

		saveTakeTaskBatch(filteredTasks);
		setSaleOrder(order.saleOrder);
		setActiveStep(StepsExpedition.LOCATIONS);
	}, [expeditionTasks, saveTakeTaskBatch, setLoading, setActiveStep]);

	const handleExpeditionLocation = useCallback((location: string): void => {
		setLocationSelected(location);
		setActiveStep(StepsExpedition.PRODUCTS);
	}, [setActiveStep]);

	const handleProductDetails = useCallback((detailsBarCode: boolean): void => {
		setIsBarCode(detailsBarCode);
		setActiveStep(StepsExpedition.PRODUCT_DETAILS);
	}, [setActiveStep]);

	const handleFinishTask = useCallback((): void => {
		saveTaskCountBatch({ tasks: formik.values.tasksPendingPost });
		formik.resetForm();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [saveTaskCountBatch, formik.values.tasksPendingPost]);

	const handleConfirmQuantity = useCallback((task: SaveTaskType): void => {
		formik.setFieldValue('productQuantity', '');
		formik.setFieldValue('barcodeProduct', '');
		formik.setFieldValue('tasksPendingPost', [...formik.values.tasksPendingPost, task]);
		const updatedTasks = tasksPendingView.filter((t) => t.id !== task.taskId);
		setTasksPendingView(updatedTasks);

		const grouped = groupTasksBySaleOrder(updatedTasks);
		setGroupedTasks(grouped);

		if (!grouped[saleOrder]) {
			setActiveStep(StepsExpedition.FINISH);
			return;
		}
		if (!grouped[saleOrder].locations[locationSelected]) {
			setActiveStep(StepsExpedition.LOCATIONS);
			return;
		}
		if (grouped[saleOrder].locations[locationSelected].tasks.length > 0) {
			setActiveStep(StepsExpedition.PRODUCTS);
		}
	}, [formik, saleOrder, locationSelected, setActiveStep, tasksPendingView]);

	const memoizedGroupedTasks = useMemo(() => groupedTasks, [groupedTasks]);

	return (
		<FormikContext.Provider value={formik}>
			<Form>
				{activeStep === StepsExpedition.START && (
					<ExpeditionStart
						loading={loading}
						handleLocationStep={handleLocationStep}
						groupedTasks={memoizedGroupedTasks}
						handleBackStart={() => navigate('/app')}
					/>
				)}

				{activeStep === StepsExpedition.LOCATIONS && (
					<ExpeditionList
						loading={loading}
						saleOrder={saleOrder}
						groupedTasks={memoizedGroupedTasks}
						handleExpeditionLocation={handleExpeditionLocation}
						handleBackStart={() => setActiveStep(StepsExpedition.START)}
					/>
				)}

				{activeStep === StepsExpedition.PRODUCTS && (
					<ExpeditionProducts
						loading={loading}
						saleOrder={saleOrder}
						location={locationSelected}
						groupedTasks={memoizedGroupedTasks}
						handleBackList={() => setActiveStep(StepsExpedition.LOCATIONS)}
						handleProductDetails={handleProductDetails}

					/>
				)}

				{activeStep === StepsExpedition.PRODUCT_DETAILS && (
					<ExpeditionProductDetails
						loading={loading}
						location={locationSelected}
						handleConfirmQuantity={handleConfirmQuantity}
						saleOrder={saleOrder}
						isBarCode={isBarCode}
					/>
				)}

				{activeStep === StepsExpedition.FINISH && (
					<ExpeditionFinish
						saleOrder={saleOrder}
						handleFinishTask={handleFinishTask}
						tasksPendingPost={formik.values.tasksPendingPost}
					/>
				)}
			</Form>
		</FormikContext.Provider>
	);
};

export default Expedition;

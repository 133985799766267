import React, { useCallback, useState, useContext } from 'react';
import { styled, Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import MuiAppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import {
	Business,
	Home,
	Lock,
} from '@mui/icons-material';
import {
	Box,
	Drawer,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Divider,
	Avatar,
	Typography,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { logout } from '../../services/auth';
import { BranchContext } from '../../contexts/BranchContext';
import useCurrentUser from '../../hooks/useCurrentUser';
import Button from './Button/Button';

interface StyledAppBarProps {
	theme: Theme;
	open?: boolean;
}

const navbar: SxProps<Theme> = {
	boxShadow: '0 1px 3px rgba(0,0,0,0.08)',
	backgroundColor: '#fff',
	borderBottom: '1px solid rgba(0,0,0,0.08)',
};

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }: StyledAppBarProps) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
	[theme.breakpoints.down('md')]: {
		width: '100%',
		marginLeft: 0,
	},
}));

const toolbar: SxProps<Theme> = {
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	padding: (theme) => theme.spacing(1),
	minHeight: '64px',
	'& .MuiIconButton-root': {
		transition: 'transform 0.2s ease',
		'&:hover': {
			transform: 'scale(1.05)',
		},
	},
};

const branchButton: SxProps<Theme> = {
	borderColor: 'rgba(0, 0, 0, 0.12)',
	color: '#444',
	minWidth: 'auto',
	maxWidth: {
		xs: '150px',
		sm: '200px',
		md: '250px',
	},
	px: 1.5,
	py: 0.75,
	borderRadius: 2,
	backgroundColor: 'rgba(240, 249, 255, 0.8)',
	boxShadow: '0px 1px 3px rgba(0,0,0,0.05)',
	'& .MuiButton-startIcon': {
		mr: 0.5,
		marginY: 'auto',
		display: 'flex',
		alignItems: 'center',
		color: 'primary.main',
	},
	'& span': {
		display: 'block',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		lineHeight: 1.5,
		color: 'primary.main',
		fontWeight: 500,
	},
	'&:hover': {
		backgroundColor: 'rgba(240, 249, 255, 0.95)',
		borderColor: 'rgba(25, 118, 210, 0.25)',
		boxShadow: '0px 2px 5px rgba(0,0,0,0.08)',
	},
	'&.Mui-disabled': {
		backgroundColor: 'rgba(0, 0, 0, 0.04)',
		color: 'rgba(0, 0, 0, 0.38)',
		borderColor: 'rgba(0, 0, 0, 0.08)',
		'& span': {
			color: 'rgba(0, 0, 0, 0.38)',
		},
		'& .MuiButton-startIcon': {
			color: 'primary.main',
		},
	},
};

const drawerWidth = 280;

const drawerPaper: SxProps<Theme> = {
	width: drawerWidth,
	backgroundColor: '#fff',
	borderRight: '1px solid rgba(0, 0, 0, 0.08)',
};

const menuHeader: SxProps<Theme> = {
	mt: '64px',
	p: 3,
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
	backgroundColor: 'rgba(240, 249, 255, 0.5)',
	backdropFilter: 'blur(8px)',
	'& .MuiTypography-root': {
		textAlign: 'center',
		transition: 'color 0.2s ease',
	},
};

const menuAvatar: SxProps<Theme> = {
	width: 64,
	height: 64,
	bgcolor: 'primary.main',
	fontSize: '1.5rem',
	mb: 1,
	transition: 'transform 0.2s ease',
	'&:hover': {
		transform: 'scale(1.05)',
	},
};

const menuButton: SxProps<Theme> = {
	py: 1.5,
	px: 2,
	transition: 'all 0.2s ease',
	'&:hover': {
		backgroundColor: 'rgba(0, 0, 0, 0.04)',
		'& .MuiListItemIcon-root': {
			color: 'primary.main',
			transform: 'scale(1.1)',
		},
	},
	'& .MuiListItemIcon-root': {
		color: '#666',
		minWidth: '40px',
		transition: 'all 0.2s ease',
	},
	'&.active': {
		backgroundColor: 'rgba(25, 118, 210, 0.08)',
		'& .MuiListItemIcon-root': {
			color: 'primary.main',
		},
		'& .MuiListItemText-primary': {
			color: 'primary.main',
			fontWeight: 500,
		},
	},
};

const Navbar = (): JSX.Element => {
	const [openDrawer, setOpenDrawer] = useState(false);
	const navigate = useNavigate();
	const { userName, userInitials } = useCurrentUser();
	const location = useLocation();
	const { refreshTriggerBranch } = useContext(BranchContext);
	const currentPath = location.pathname;

	const toggleDrawer = useCallback((newOpen: boolean) => () => {
		setOpenDrawer(newOpen);
	}, []);

	const handleMenu = useCallback((): void => {
		setOpenDrawer((prevState) => !prevState);
	}, []);

	const handleLogout = useCallback(() => {
		setOpenDrawer(false);
		logout();
	}, []);

	return (
		<>
			<AppBar color="primary" sx={navbar}>
				<Toolbar sx={toolbar}>
					<Box sx={{
						display: 'flex',
						alignItems: 'center',
						gap: 2,
						width: '100%',
					}}
					>
						<IconButton
							size="large"
							aria-label="menu"
							onClick={handleMenu}
							sx={{ color: '#666' }}
						>
							<MenuIcon />
						</IconButton>
						<Box
							component="img"
							alt="Logo"
							src="/api/configuration/logo"
							sx={{
								height: 'auto',
								width: '8rem',
								transition: 'opacity 0.2s ease',
								'&:hover': {
									opacity: 0.9,
								},
							}}
						/>

						<Box sx={{ ml: 'auto' }}>
							<Button
								variant="outlined"
								size="small"
								startIcon={<Business />}
								onClick={() => navigate('company-branch-selection')}
								disabled={!refreshTriggerBranch.branchName || (currentPath !== '/app' && currentPath !== '/app/company-branch-selection')}
								sx={branchButton}
							>
								<Box component="span">{refreshTriggerBranch.branchName || 'Selecione uma filial'}</Box>
							</Button>
						</Box>
					</Box>
				</Toolbar>
			</AppBar>
			<Drawer
				open={openDrawer}
				onClose={toggleDrawer(false)}
				PaperProps={{
					sx: drawerPaper,
				}}
			>
				<Box
					sx={{
						width: drawerWidth,
						display: 'flex',
						flexDirection: 'column',
						height: '100%',
					}}
					role="presentation"
				>
					<Box sx={menuHeader}>
						<Avatar sx={menuAvatar}>{userInitials}</Avatar>
						<Typography variant="subtitle1" sx={{ fontWeight: 500, mb: 0.5 }}>
							{userName}
						</Typography>
						<Typography variant="body2" color="text.secondary">
							{refreshTriggerBranch.branchName || 'Selecione uma filial'}
						</Typography>
					</Box>

					<Box sx={{ flex: 1, py: 2 }}>
						<List>
							<ListItem disablePadding>
								<ListItemButton
									onClick={() => {
										navigate('');
										setOpenDrawer(false);
									}}
									sx={{ ...menuButton, ...(currentPath === '/' && { '&.MuiListItemButton-root': { bgcolor: 'rgba(25, 118, 210, 0.08)' } }) }}
								>
									<ListItemIcon>
										<Home />
									</ListItemIcon>
									<ListItemText
										primary="Início"
									/>
								</ListItemButton>
							</ListItem>
						</List>
					</Box>

					<Box>
						<Divider sx={{ mb: 1 }} />
						<List>
							<ListItem disablePadding>
								<ListItemButton
									onClick={() => {
										navigate('change-password');
										setOpenDrawer(false);
									}}
									sx={menuButton}
								>
									<ListItemIcon>
										<Lock />
									</ListItemIcon>
									<ListItemText
										primary="Alterar Senha"
									/>
								</ListItemButton>
							</ListItem>
						</List>

						<Box sx={{ p: 2 }}>
							<Button
								variant="contained"
								onClick={handleLogout}
								fullWidth
							>
								Sair
							</Button>
						</Box>
					</Box>
				</Box>
			</Drawer>
		</>
	);
};

export default Navbar;

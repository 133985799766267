import React from 'react';
import {
	GridColDef,
} from '@mui/x-data-grid';
import { format } from 'date-fns';
import { Decimal } from 'decimal.js';
import TruncateTooltipCell from '../components/Common/Datagrid/TruncateTooltipCell';
import { currencyBRLMask } from '../helpers/intl';

export const fiscalDocumentEntryColumns: GridColDef[] = [
	{
		field: 'nrInvoice',
		headerName: 'Nº Nota / Série',
		flex: 1,
		renderCell: (params) => <TruncateTooltipCell value={params.value ? `${params.value}/${params.row.serie}` : '-'} />,
	},
	{
		field: 'businessPartnerName',
		headerName: 'Empresa',
		flex: 1,
		sortable: false,
		renderCell: (params) => <TruncateTooltipCell value={params.value ? params.value : '-'} />,
	},
	{
		field: 'value',
		headerName: 'Valor Total',
		type: 'number',
		flex: 1,
		renderCell: (params) => <TruncateTooltipCell value={params.value ? currencyBRLMask(new Decimal(params.value as string)) : '-'} />,
	},
	{
		field: 'emissionDate',
		headerName: 'Data de emissão',
		flex: 1,
		type: 'date',
		align: 'center',
		headerAlign: 'center',
		renderCell: (params) => <TruncateTooltipCell value={params.value ? format(new Date(params.value), 'dd/MM/yyyy') : '-'} />,
	},
];

import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import {
	DataGrid,
	GridSortModel,
} from '@mui/x-data-grid';
import { User, CurrentUserProps } from '../../containers/User/UserAssets';
import { ToolbarComponent } from '../Common/Datagrid/DataGridToolBar';
import { useManageColumns } from '../../hooks/useManageColumns';
import useColumns from '../../hooks/useColumns';
import { userColumns } from '../../constants/userColumns';

interface UserTableProps {
	loading?: boolean;
	rows: User[];
	usersPages: number;
	usersPage: number;
	usersTake: number;
	sortModel: GridSortModel;
	onChangePage(usersTake: number): void;
	onChangePageSize(pageSize: number): void;
	onSortModelChange(sortModel: GridSortModel): void;
	onResetPassword: (user: CurrentUserProps) => void;
	onChangeActiveStatus(id: string, active: boolean): void;
	handleEdit(id: string): void;
}

const UserTable = ({
	rows,
	loading,
	usersPages,
	usersPage,
	usersTake,
	sortModel,
	onChangePage,
	onChangePageSize,
	onSortModelChange,
	onResetPassword,
	handleEdit,
	onChangeActiveStatus,
}: UserTableProps): JSX.Element => {
	const localStorageKey = 'userColumns';

	const columnsVisibility = [
		'name',
		'login',
		'email',
		'phone',
		'actions',
	];

	const optionalColumnsVisibility = [
		'createdAt',
		'updatedAt',
		'dateDeactivated',
		'dateActivated',
		'userCreated',
		'userUpdated',
		'userActivated',
		'userDeactivated',
	];

	const { columns, defaultVisibility } = useColumns({
		columnsVisibility,
		defaultColumnsOverride: userColumns,
		onEditClick: handleEdit,
		onResetPassword,
		optionalColumnsVisibility,
		onChangeActiveStatus,
	});

	const {
		currentColumns,
		columnVisibilityModel,
		handleColumnVisibilityModelChange,
		onRestoreClick,
	} = useManageColumns({
		initialColumns: columns,
		initialVisibility: defaultVisibility,
		localStorageKey,
		isDraggable: true,
	});

	return (
		<Box component={Paper} sx={{ width: '100%' }}>
			<DataGrid
				autoHeight
				rows={rows}
				rowCount={usersPages}
				pagination
				paginationMode="server"
				sortingMode="server"
				columns={currentColumns}
				page={usersPage}
				pageSize={usersTake}
				rowsPerPageOptions={[10, 25, 50, 100]}
				loading={loading}
				sortModel={sortModel}
				onPageChange={onChangePage}
				onPageSizeChange={onChangePageSize}
				onSortModelChange={onSortModelChange}
				columnVisibilityModel={columnVisibilityModel}
				onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
				components={{ Toolbar: ToolbarComponent(onRestoreClick) }}
				disableSelectionOnClick
				disableColumnMenu
			/>
		</Box>
	);
};

UserTable.defaultProps = {
	loading: false,
};

export default UserTable;

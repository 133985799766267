import React, {
	Dispatch,
	Reducer,
	useMemo,
	useReducer,
} from 'react';
import {
	OptionsObject,
	SnackbarKey,
	SnackbarMessage,
	useSnackbar,
} from 'notistack';
import { AxiosError } from 'axios';
import JsBarcode from 'jsbarcode';
import { EditProductFormProps, ILocationWithPagination } from './LocationAssets';
import LocationPresentational from '../../components/Location/Location';
import {
	deleteLocationProduct,
	getLocations,
	handleActiveLocation,
	handleProductLocationActiveStatus,
	saveLocationProduct,
	updateLocationProduct,
} from '../../services/location';
import { ProductQueryParams } from '../../interfaces/ProductQueryParams';
import { getProductsWithoutDetails } from '../../services/product';
import { IProductWithoutDetails } from '../Product/ProductAssets';
import { LocationProductQueryParams } from '../../interfaces/LocationProduct';
import PrintLabelTemplate from '../../constants/printLabelTemplate';
import { filterObject } from '../../helpers/Utils';

enum ActionType {
	LOADING,
	LOCATION,
	PRODUCT,
	LOCATIONS_FILTER,
	LOADING_PRODUCTS,
	UPDATE_LOCATION,
	UPDATE_LOCATION_ACTIVE,
}

interface IState {
	loading: boolean;
	loadingProducts: boolean;
	location: ILocationWithPagination;
	products: IProductWithoutDetails[];
	locationsFilter: ILocationWithPagination;
}

type TAction =
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| { type: ActionType.LOADING_PRODUCTS; payload: { loadingProducts: boolean } }
	| { type: ActionType.PRODUCT; payload: { products: IProductWithoutDetails[] } }
	| { type: ActionType.LOCATION; payload: { location: ILocationWithPagination } }
	| { type: ActionType.UPDATE_LOCATION; payload: {
		locationId: string,
		productId: string,
		activeProduct: boolean
	}}
	| { type: ActionType.UPDATE_LOCATION_ACTIVE; payload: { locationId: string, active: boolean } }
	| { type: ActionType.LOCATIONS_FILTER; payload: { locationsFilter: ILocationWithPagination } };

interface ILocationActions {
    setLoading(loading: boolean): void;
    setLoadingProducts(loadingProducts: boolean): void;
	getLocations: (queryParams: LocationProductQueryParams, setLoading?: false) => void;
	printLabel(barCode: string): void;
	updateLocationProduct(
		productId: string,
		locationId: string,
        values: EditProductFormProps,
	): Promise<void>;
	saveLocationProduct(
		productId: string,
		locationId: string,
        valuedQuantity: string
	): void;
	handleProductLocationActiveStatus(
		locationId: string,
		productId: string,
		activeProduct: boolean,
	): void;
	getProductsWithoutDetails(queryParams: ProductQueryParams): void;
	deleteLocationProduct(productId: string, locationId: string): void;
	handleActiveLocation(locationId: string, active: boolean): void;
}

const initialState: IState = {
	loading: false,
	loadingProducts: false,
	location: { count: 0, locations: [] },
	products: [],
	locationsFilter: { count: 0, locations: [] },
};

let lastQueryParams: LocationProductQueryParams;

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.LOADING_PRODUCTS:
			return { ...state, loadingProducts: action.payload.loadingProducts };
		case ActionType.PRODUCT:
			return { ...state, products: action.payload.products };
		case ActionType.LOCATION:
			return {
				...state,
				location: action.payload.location,
			};
		case ActionType.LOCATIONS_FILTER:
			return {
				...state,
				locationsFilter: action.payload.locationsFilter,
			};
		case ActionType.UPDATE_LOCATION:
			return {
				...state,
				location: {
					...state.location,
					locations: state.location.locations.map((location) => {
						if (location.id === action.payload.locationId) {
							return {
								...location,
								products: (location.products ?? []).map((product) => (product.productId
							=== action.payload.productId
									? { ...product, ...action.payload }
									: product)),
							};
						}
						return location;
					}),
				},
			};
		case ActionType.UPDATE_LOCATION_ACTIVE:
			return {
				...state,
				location: {
					...state.location,
					locations: state.location.locations.map((location) => {
						if (location.id === action.payload.locationId) {
							return {
								...location,
								active: action.payload.active,
								products: (location.products ?? []).map((product) => ({
									...product,
									activeProduct: action.payload.active,
								})),
							};
						}
						return location;
					}),
				},
			};
		default:
			throw new Error();
	}
};

const LocationActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
): ILocationActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		setLoadingProducts(loadingProducts: boolean) {
			dispatch({ type: ActionType.LOADING_PRODUCTS, payload: { loadingProducts } });
		},
		getLocations(queryParams: LocationProductQueryParams, setLoading?: boolean) {
			actions.setLoading(setLoading !== false);
			let params = filterObject(queryParams);
			const take = queryParams.take ?? 10;
			const skip = queryParams.skip ? queryParams.skip - 1 : 0;
			params = {
				...params,
				skip: skip * take,
				take: queryParams.take ?? 10,
			};
			getLocations(params)
				.then((response) => {
					dispatch({
						type: ActionType.LOCATION,
						payload: {
							location: response.data,
						},
					});
					lastQueryParams = queryParams;
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(
						error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.',
						{ variant: 'error' },
					);
				})
				.finally(() => {
					actions.setLoading(false);
				});
		},
		async updateLocationProduct(
			productId: string,
			locationId: string,
			values: EditProductFormProps,
		) {
			try {
				const result = await updateLocationProduct(productId, locationId, values);

				enqueueSnackbar(
					result.data.message || 'Produto atualizado com sucesso.',
					{ variant: 'success' },
				);
				actions.getLocations(lastQueryParams, false);
			} catch (e) {
				const error = e as AxiosError;
				enqueueSnackbar(
					error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.',
					{ variant: 'error' },
				);
			}
		},
		saveLocationProduct(
			productId: string,
			locationId: string,
			valuedQuantity: string,
		) {
			saveLocationProduct(productId, locationId, valuedQuantity).then((response) => {
				enqueueSnackbar(
					response.data.message || 'Produto atualizado com sucesso.',
					{ variant: 'success' },
				);
				actions.getLocations(lastQueryParams, false);
			}).catch((error: AxiosError) => {
				enqueueSnackbar(
					error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.',
					{ variant: 'error' },
				);
			});
		},
		handleProductLocationActiveStatus(
			locationId: string,
			productId: string,
			activeProduct: boolean,
		): void {
			handleProductLocationActiveStatus(locationId, productId, activeProduct)
				.then((response) => {
					enqueueSnackbar(response.data.message, { variant: 'success' });
					dispatch({
						type: ActionType.UPDATE_LOCATION,
						payload: {
							locationId,
							productId,
							activeProduct,
						},
					});
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu ao alterar o status do produto, tente novamente ou contate um administrador.', {
						variant: 'error',
					});
				});
		},
		getProductsWithoutDetails(queryParams: ProductQueryParams) {
			actions.setLoadingProducts(true);
			getProductsWithoutDetails(queryParams).then((response) => {
				dispatch({
					type: ActionType.PRODUCT,
					payload: {
						products: response.data.data,
					},
				});
			}).catch((error: AxiosError) => {
				enqueueSnackbar(
					error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.',
					{ variant: 'error' },
				);
			})
				.finally(() => {
					actions.setLoadingProducts(false);
				});
		},
		deleteLocationProduct(productId: string, locationId: string) {
			deleteLocationProduct(productId, locationId)
				.then((response) => {
					enqueueSnackbar(response?.data.message, { variant: 'success' });
					actions.getLocations(lastQueryParams, false);
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
						variant: 'error',
					});
				});
		},
		printLabel(barCode: string) {
			const svgElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg');

			JsBarcode(svgElement, barCode, {
				format: 'CODE128',
				displayValue: true,
				width: 2,
				height: 80,
				fontSize: 40,
			});

			const svgContent = svgElement.outerHTML;

			const templateString = PrintLabelTemplate({ svgContent });

			const printWindow = window.open('', '_blank');
			if (printWindow) {
				printWindow.document.open();
				printWindow.document.write(templateString);
				printWindow.document.close();
				printWindow.onload = () => printWindow.print();
			} else {
				enqueueSnackbar('Não foi possível abrir a janela de impressão.', {
					variant: 'error',
				});
			}
		},
		handleActiveLocation(locationId: string, active: boolean) {
			handleActiveLocation(locationId, active)
				.then((response) => {
					enqueueSnackbar(response.data.message, { variant: 'success' });
					dispatch({
						type: ActionType.UPDATE_LOCATION_ACTIVE,
						payload: {
							active,
							locationId,
						},
					});
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu ao alterar o status do local, tente novamente ou contate um administrador.', {
						variant: 'error',
					});
				});
		},
	};

	return actions;
};

const Location = ():JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(
		reducer,
		initialState,
	);
	const { enqueueSnackbar } = useSnackbar();
	const actions = useMemo(
		() => LocationActions(dispatch, enqueueSnackbar),
		[dispatch, enqueueSnackbar],
	);

	// eslint-disable-next-line react/jsx-props-no-spreading
	return (<LocationPresentational {...state} {...actions} />);
};

export default Location;

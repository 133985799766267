/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo, useState, useCallback } from 'react';
import {
	TextField,
	Autocomplete as MuiAutocomplete,
	AutocompleteProps,
	CircularProgress,
} from '@mui/material';
import { useField } from 'formik';
import { formatBarCode as format } from '../../../helpers/masks';

interface IAutocompleteProps<T>
  extends Omit<
    AutocompleteProps<any, boolean, undefined, undefined, 'div'>,
    'renderInput'
  > {
  options: T[];
  labelKey: keyof T;
  valueKey: keyof T;
  label: string;
  name: string;
  valueLabel?: keyof T;
  required?: boolean;
  disableAllOptions?: boolean;
  disableOptions?: string[];
  closeOnSelect?: boolean;
  loading?: boolean;
  formatBarCode?: boolean;
  onInputChange?: (
    event: React.SyntheticEvent,
    value: string,
    reason: 'input' | 'reset' | 'clear'
  ) => void;
}

const Autocomplete = <T, >({
	options,
	labelKey,
	valueKey,
	valueLabel,
	label,
	name,
	required,
	disableAllOptions,
	disableOptions,
	closeOnSelect,
	loading,
	formatBarCode,
	onInputChange,
	...props
}: IAutocompleteProps<T>): JSX.Element => {
	const [field, meta, helpers] = useField({ name });
	const [localInputValue, setLocalInputValue] = useState('');

	const inputValue = formatBarCode ? localInputValue : undefined;

	const value = useMemo(() => {
		if (props.multiple) {
			return field.value || [];
		}
		return field.value || null;
	}, [field.value, props.multiple]);

	const optionsValues = useMemo(
		() => options.map((option) => option[valueKey]),
		[options, valueKey],
	);

	const error = useMemo(() => {
		if (Boolean(meta.touched) && Boolean(meta.error)) {
			return meta.error;
		}

		return null;
	}, [meta.error, meta.touched]);

	const handleChange = (event: any, newValue: any): void => {
		helpers.setValue(newValue);
	};

	const handleInputChange = useCallback(
		(
			event: React.SyntheticEvent,
			newValue: string,
			reason: 'input' | 'reset' | 'clear',
		) => {
			if (formatBarCode) {
				if (reason === 'input') {
					const formattedValue = format(newValue);
					setLocalInputValue(formattedValue);
					if (onInputChange) {
						onInputChange(event, formattedValue, reason);
					}
				} else {
					let formattedValue = newValue;
					if (newValue) {
						if (props.multiple) {
							formattedValue = format(newValue);
						} else {
							formattedValue = `${format(newValue.split('-')[0])} - ${newValue.split('-')[1]}`;
						}
					}

					setLocalInputValue(formattedValue);
					if (onInputChange) {
						onInputChange(event, formattedValue, reason);
					}
				}
			} else if (onInputChange) {
				onInputChange(event, newValue, reason);
			}
		},
		[formatBarCode, onInputChange, props.multiple],
	);

	return (
		<MuiAutocomplete
			{...field}
			value={value}
			inputValue={inputValue}
			onInputChange={handleInputChange}
			autoHighlight
			disableCloseOnSelect={!closeOnSelect}
			filterSelectedOptions
			options={optionsValues}
			loading={loading}
			onChange={handleChange}
			renderInput={(params) => (
				<TextField
					{...params}
					label={label}
					placeholder={label}
					required={required}
					error={Boolean(error)}
					helperText={error}
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<>
								{loading ? (
									<CircularProgress color="inherit" size={26} />
								) : null}
								{params.InputProps.endAdornment}
							</>
						),
					}}
				/>
			)}
			getOptionDisabled={(option) => disableAllOptions
        || (disableOptions || []).includes(option)
        || false}
			getOptionLabel={(option) => {
				const optionObject = options.find((item) => item[valueKey] === option);

				if (optionObject) {
					const formattedValueLabel = valueLabel
						? String(optionObject?.[valueLabel]) : String(optionObject?.[valueKey]);
					const finalValueLabel = formatBarCode ? format(formattedValueLabel) : formattedValueLabel;
					return `${finalValueLabel} - ${optionObject?.[labelKey]}`;
				}

				return '';
			}}
			noOptionsText={loading ? 'Carregando...' : 'Não há opções'}
			{...props}
		/>
	);
};

Autocomplete.defaultProps = {
	valueLabel: null,
	required: false,
	disableAllOptions: false,
	disableOptions: [],
	closeOnSelect: false,
	loading: false,
	formatBarCode: false,
	onInputChange: undefined,
};

export default Autocomplete;

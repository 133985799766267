import React from 'react';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import { ConsumptionType } from '../enums/ConsumptionType';
import { InventoryTaskStatusHeader } from '../enums/InventoryTaskStatusHeader';

interface ViewTitleResult {
  title: string;
  icon: React.ReactNode;
}

interface CurrentView {
	type: ConsumptionType;
	status: InventoryTaskStatusHeader;
}

interface IconOptions {
  color?: 'inherit' | 'primary' | 'secondary' | 'action' | 'disabled' | 'error';
  fontSize?: 'small' | 'medium' | 'large' | 'inherit';
}

export const getConsumptionViewTitle = (
	currentView: CurrentView | null,
	iconOptions: IconOptions = { color: 'primary', fontSize: 'large' },
): ViewTitleResult => {
	if (!currentView) {
		return {
			title: 'Kanban de Consumo',
			icon: <ViewKanbanIcon color={iconOptions.color} fontSize={iconOptions.fontSize} />,
		};
	}

	let typeText = '';
	let typeIcon: React.ReactNode = null;

	switch (currentView.type) {
		case ConsumptionType.REQUISITION:
			typeText = 'Consumo (Requisição)';
			typeIcon = <AssignmentIcon color={iconOptions.color} fontSize={iconOptions.fontSize} />;
			break;
		case ConsumptionType.DEVOLUTION:
			typeText = 'Consumo (Devolução)';
			typeIcon = <AssignmentReturnIcon color={iconOptions.color} fontSize={iconOptions.fontSize} />;
			break;
		default:
			typeText = 'Consumo';
			typeIcon = <ViewKanbanIcon color={iconOptions.color} fontSize={iconOptions.fontSize} />;
	}

	let statusText = '';
	let statusIcon: React.ReactNode = null;

	switch (currentView.status) {
		case InventoryTaskStatusHeader.PENDING:
			statusText = 'Pendente';
			statusIcon = (
				<PendingActionsOutlinedIcon
					color={iconOptions.color}
					fontSize={iconOptions.fontSize}
				/>
			);
			break;
		case InventoryTaskStatusHeader.IN_PROGRESS:
			statusText = 'Em Andamento';
			statusIcon = (
				<HourglassTopIcon
					color={iconOptions.color}
					fontSize={iconOptions.fontSize}
				/>
			);
			break;
		case InventoryTaskStatusHeader.COMPLETED:
			statusText = 'Concluido';
			statusIcon = (
				<CheckCircleOutlineIcon
					color={iconOptions.color}
					fontSize={iconOptions.fontSize}
				/>
			);
			break;
		case InventoryTaskStatusHeader.CANCELED:
			statusText = 'Cancelado';
			statusIcon = (
				<CancelOutlinedIcon
					color={iconOptions.color}
					fontSize={iconOptions.fontSize}
				/>
			);
			break;
		default:
			statusText = '';
	}
	return {
		title: statusText ? `${typeText} - ${statusText}` : typeText,
		icon: typeIcon,
	};
};

import React, { useCallback, useEffect, useState } from 'react';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	Typography,
} from '@mui/material';
import {
	Form,
	FormikContext,
	useFormik,
} from 'formik';
// eslint-disable-next-line import/no-duplicates
import { format, isValid } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import ptBR from 'date-fns/locale/pt-BR';
import { InventoryType, ParamsStartInventory } from '../../../containers/Inventory/InventoryAssets';
import {
	BRANCH_CODE_KEY,
	BRANCH_NAME_KEY,
	COMPANY_CODE_KEY,
	COMPANY_NAME_KEY,
} from '../../../services/auth';
import { DatePicker } from '../../Common/Form/DatePicker';
import Input from '../../Common/Form/Input';
import Select from '../../Common/Form/Select';

interface StartInventoryModalProps {
    open: boolean;
    onClose: () => void;
    handleConfirmStartInventory: (params: ParamsStartInventory) => void;
}

const initialValues: ParamsStartInventory = {
	type: null,
	dateInventERP: '',
};

const types = [
	{ label: 'Parcial / Cíclico', id: InventoryType.PARTIAL },
	{ label: 'Inventário Geral', id: InventoryType.FULL },
];

const StartInventoryModal = ({
	open,
	onClose,
	handleConfirmStartInventory,
}: StartInventoryModalProps): JSX.Element => {
	const [showConfirmation, setShowConfirmation] = useState(false);

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			...initialValues,
			branch: `${localStorage.getItem(BRANCH_CODE_KEY)} - ${localStorage.getItem(BRANCH_NAME_KEY)}`,
			company: `${localStorage.getItem(COMPANY_CODE_KEY)} - ${localStorage.getItem(COMPANY_NAME_KEY)}`,
		},
		onSubmit: handleConfirmStartInventory,
	});

	useEffect(() => {
		if (!open) {
			setShowConfirmation(false);
		}
	}, [open]);

	const handleFirstConfirm = useCallback((e: React.FormEvent): void => {
		e.preventDefault();
		setShowConfirmation(true);
	}, []);

	const handleFinalConfirm = useCallback((): void => {
		formik.handleSubmit();
		onClose();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formik.handleSubmit, onClose]);

	const handleCancel = useCallback((): void => {
		if (showConfirmation) {
			setShowConfirmation(false);
		} else {
			onClose();
		}
	}, [showConfirmation, onClose]);

	const type = types.find((t) => t.id === formik.values.type);

	return (
		<FormikContext.Provider value={formik}>
			<Dialog open={open} fullWidth>
				<Form onSubmit={handleFirstConfirm}>
					<DialogTitle>
						{showConfirmation ? 'Tem certeza que deseja iniciar este inventário?' : 'Iniciar inventário'}
					</DialogTitle>
					<DialogContent>
						{!showConfirmation ? (
							<Grid container spacing={2} sx={{ mt: 0.5 }}>
								<Grid item xs={12}>
									<Input.InputField
										label="Empresa"
										name="company"
										disabled
									/>
								</Grid>
								<Grid item xs={12}>
									<Input.InputField
										label="Filial"
										name="branch"
										disabled
									/>
								</Grid>
								<Grid item xs={12}>
									<Select
										label="Tipo de Inventário"
										options={types}
										name="type"
										labelKey="label"
										valueKey="id"
										required
									/>
								</Grid>
								<Grid item xs={12}>
									<DatePicker
										name="dateInventERP"
										label="Data Invent. ERP"
										required
									/>
								</Grid>
							</Grid>
						) : (
							<Box>
								<Typography>
									Empresa:
									{' '}
									{formik.values.company}
								</Typography>
								<Typography>
									Filial:
									{' '}
									{formik.values.branch}
								</Typography>
								<Typography>
									Tipo de Inventário:
									{' '}
									{type?.label}
								</Typography>
								<Typography>
									Data do Inventário no ERP:
									{' '}
									{formik.values.dateInventERP
										? format(new Date(formik.values.dateInventERP), 'dd/MM/yyyy', { locale: ptBR })
										: '-'}
								</Typography>
							</Box>
						)}
					</DialogContent>
					<DialogActions sx={{ gap: 1 }}>
						<Button onClick={handleCancel}>Cancelar</Button>
						{!showConfirmation ? (
							<Button
								type="submit"
								variant="contained"
								disabled={
									formik.values.type === null
									|| !isValid(formik.values.dateInventERP)
								}
							>
								Confirmar
							</Button>
						) : (
							<Button
								onClick={handleFinalConfirm}
								variant="contained"
								color="warning"
							>
								Iniciar Inventário
							</Button>
						)}
					</DialogActions>
				</Form>
			</Dialog>
		</FormikContext.Provider>
	);
};

export default StartInventoryModal;

import React, {
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { Link } from 'react-router-dom';
import { GridSortDirection, GridSortModel } from '@mui/x-data-grid';
import FilterListIcon from '@mui/icons-material/FilterList';
import BusinessPartnerTable from './BusinessPartnerTable';
import { PageHeader } from '../Common/PageHeader/PageHeader';
import { PageHeaderButtonProps } from '../../interfaces/PageHeaderInterface';
import { IBusinessPartner } from '../../containers/BusinessPartner/BusinessPartnerAssets';
import DetailsDrawer from './Drawers/DetailsDrawer';
import { BusinessPartnerQueryParams } from '../../interfaces/BusinessPartnerQueryParams';
import useDataGridManagement from '../../hooks/useDataGridManagement';
import { normalizeDataGridOrderBy } from '../../helpers/Utils';
import BusinessPartnerFilter from './BusinessPartnerFilter';
import DrawerFilter from '../Common/DrawerFilter';
import { ICountry } from '../../services/country';
import { IRegionState } from '../../services/state';
import { ICity } from '../../services/city';

interface BusinessPartnerProps {
    loading: boolean;
    businessPartners: IBusinessPartner[];
    countries: ICountry[];
    states: IRegionState[];
    cities: ICity[];
    businessPartnersPages: number;
    businessPartnersPage: number;
    businessPartnersTake: number;
    getBusinessPartners(params: BusinessPartnerQueryParams): void;
    getCountries(): void;
    getStates(): void;
    getCities(): void;
    handleEdit(id: string): void;
    handleDeleteBusinessPartner: (id: string) => void;
	handleBusinessPartnerActiveStatus(id: string, active: boolean): void;
}

const initialSortModel: GridSortModel = [
	{ field: 'marketName', sort: 'asc' },
];

const BusinessPartner = ({
	loading,
	businessPartners,
	countries,
	states,
	cities,
	businessPartnersPages,
	businessPartnersPage,
	businessPartnersTake,
	getBusinessPartners,
	getCountries,
	getStates,
	getCities,
	handleEdit,
	handleDeleteBusinessPartner,
	handleBusinessPartnerActiveStatus,
}: BusinessPartnerProps): JSX.Element => {
	const [openDetails, setOpenDetails] = useState<IBusinessPartner | undefined>(undefined);
	const [isFilterDrawerOpen, setFilterDrawerOpen] = useState(false);
	const [filterValues, setFilterValues] = useState<Partial<BusinessPartnerQueryParams>>({});

	useEffect(() => {
		getStates();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const {
		setFilter,
		sortModel,
		onChangePage,
		onChangePageSize,
		onSortModelChange,
	} = useDataGridManagement<BusinessPartnerQueryParams>({
		initialSortModel,
		initialPageSize: businessPartnersTake,
		initialPage: businessPartnersPage,
		fetchData: getBusinessPartners,
	});

	const sendFilter = useCallback((values: Partial<BusinessPartnerQueryParams>) => {
		const pageFilter = {
			...values,
			skip: 0,
			orderBy: normalizeDataGridOrderBy(sortModel) as {
                marketName: GridSortDirection,
                BPLegalEntity: GridSortDirection,
            },
		};
		setFilter(pageFilter);
	}, [setFilter, sortModel]);

	const toggleFilterDrawer = useCallback(() => {
		setFilterDrawerOpen(!isFilterDrawerOpen);
	}, [isFilterDrawerOpen]);

	const handleFilterSubmit = useCallback((newFilterValues: Partial<BusinessPartnerQueryParams>) => {
		setFilterValues(newFilterValues);
		setFilterDrawerOpen(false);
		sendFilter(newFilterValues);
	}, [sendFilter]);

	const onDetailsClick = useCallback((id: string) => {
		const selectedBusinesPartner = businessPartners
			.find((businessPartner) => businessPartner.id === id);
		setOpenDetails(selectedBusinesPartner);
	}, [businessPartners]);

	const onCloseDetails = useCallback(() => {
		setOpenDetails(undefined);
	}, []);

	const countActiveFilters = useCallback((filters: Partial<BusinessPartnerQueryParams>): number => Object.values(filters).filter((value) => value !== '' && value !== undefined).length, []);

	const headerButtonsProps = useMemo((): PageHeaderButtonProps[] => [
		{
			variant: 'contained',
			color: 'primary',
			onClick: toggleFilterDrawer,
			badgeContent: countActiveFilters(filterValues),
			text: 'Filtro',
			startIcon: <FilterListIcon />,
		},
		{
			variant: 'contained',
			color: 'primary',
			type: 'submit',
			fullWidth: true,
			component: Link,
			to: 'edit',
			text: 'Novo',
		},
	], [countActiveFilters, filterValues, toggleFilterDrawer]);

	const pageHeaderMemo = useMemo(() => (
		<PageHeader title="Business Partner" buttons={headerButtonsProps} />
	), [headerButtonsProps]);

	const filterMemo = useMemo(() => (
		<DrawerFilter open={isFilterDrawerOpen} onClose={toggleFilterDrawer}>
			<BusinessPartnerFilter
				sendFilter={handleFilterSubmit}
				getCountries={getCountries}
				getCities={getCities}
				countries={countries}
				states={states}
				cities={cities}
				initialValues={filterValues}
			/>
		</DrawerFilter>
	), [
		getCountries,
		getCities,
		cities,
		countries,
		filterValues,
		handleFilterSubmit,
		isFilterDrawerOpen,
		states,
		toggleFilterDrawer,
	]);

	return (
		<div className="content">
			{pageHeaderMemo}
			{filterMemo}
			<BusinessPartnerTable
				loading={loading}
				rows={businessPartners}
				businessPartnersPages={businessPartnersPages}
				businessPartnersPage={businessPartnersPage}
				businessPartnersTake={businessPartnersTake}
				sortModel={sortModel}
				onChangePageSize={onChangePageSize}
				onChangePage={onChangePage}
				onSortModelChange={onSortModelChange}
				handleEdit={handleEdit}
				handleDeleteBusinessPartner={handleDeleteBusinessPartner}
				onDetailsClick={onDetailsClick}
				onChangeActiveStatus={handleBusinessPartnerActiveStatus}
			/>
			<DetailsDrawer
				openDetails={openDetails}
				onClose={onCloseDetails}
			/>
		</div>
	);
};

export default BusinessPartner;

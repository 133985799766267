import { AxiosResponse } from 'axios';
import { StatusSaleOrderQueryParams } from '../interfaces/StatusSaleOrderParams';
import { StatusSaleOrderResponse } from '../containers/StatusSaleOrder/StatusSaleOrderAssets';
import api from './api';

export const getStatusSaleOrder = (params: StatusSaleOrderQueryParams): Promise<AxiosResponse<StatusSaleOrderResponse>> => api.get<StatusSaleOrderResponse>('/status-sale-order', {
	params,
});

export const getStatusSaleOrderCache = (): Promise<AxiosResponse<{
	hasStatusSaleOrder: boolean,
}>> => api.get<{ hasStatusSaleOrder: boolean }>('/status-sale-order/has-status');

/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import omit from 'lodash/omit';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import { Skeleton, SxProps, Theme } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import {
	Buttons,
	PageHeaderButtonProps,
	IPageHeaderProps,
} from '../../../interfaces/PageHeaderInterface';
import { PageHeaderMenu } from './PageHeaderMenu';
import Button from '../Button/Button';

const headerStyle: SxProps<Theme> = {
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	mb: 5,
};

const titleContainerStyle: SxProps<Theme> = {
	display: 'flex',
	flexDirection: 'column',
};

const titleWrapperStyle: SxProps<Theme> = {
	display: 'flex',
	alignItems: 'center',
	gap: 2,
	mb: 0.5,
};

const titleStyle: SxProps<Theme> = {
	fontWeight: 500,
	background: (theme) => theme.palette.primary.main,
	WebkitBackgroundClip: 'text',
	WebkitTextFillColor: 'transparent',
	textShadow: '0px 1px 1px rgba(0,0,0,0.05)',
};

const subtitleStyle: SxProps<Theme> = {
	fontWeight: 500,
	color: (theme) => theme.palette.text.secondary,
	lineHeight: 1.4,
	maxWidth: '800px',
	opacity: 0.9,
};

const badgeStyle: SxProps<Theme> = {
	'& .MuiBadge-badge': {
		right: 5,
		top: 5,
		padding: '0 4px',
		boxShadow: '0 0 0 2px white',
	},
};

const getButtons = (
	buttons: PageHeaderButtonProps[] | undefined,
): Buttons | null => {
	if (!buttons) return null;

	const buttonsToShow: PageHeaderButtonProps[] = buttons.filter(
		({ show = true }) => show,
	);

	const menuButtons: PageHeaderButtonProps[] = [...buttonsToShow];
	const primaryButtons: PageHeaderButtonProps[] = menuButtons.splice(0, 10);
	return { primaryButtons, menuButtons };
};

export const PageHeader = ({
	title,
	subtitle,
	buttons,
	loading,
	icon,
}: IPageHeaderProps): JSX.Element => {
	const buttonsMemo = useMemo(() => getButtons(buttons), [buttons]);

	const getButtonProps = (
		buttonProps: PageHeaderButtonProps,
	): Omit<
		PageHeaderButtonProps,
		'text' | 'show' | 'badgeContent' | 'badgeTitle'
	> => omit(buttonProps, ['text', 'show', 'badgeContent', 'badgeTitle']);

	const primaryButtonsMemo = useMemo(() => {
		if (loading) {
			return [0, 1, 2].map((item) => (
				<Skeleton
					key={item}
					variant="rectangular"
					sx={{ width: 200, height: '100%', borderRadius: 1 }}
				/>
			));
		}

		return buttonsMemo?.primaryButtons.map((buttonProps) => {
			const button = (
				<Badge
					key={buttonProps.text}
					badgeContent={buttonProps.badgeContent}
					color={buttonProps.badgecolor || 'secondary'}
					componentsProps={{
						badge: {
							title: buttonProps.badgeTitle || '',
						},
					}}
					sx={badgeStyle}
				>
					{buttonProps.isCustomComponent ? (
						buttonProps.component
					) : (
						<Button
							{...getButtonProps(buttonProps)}
							sx={{
								width: 'fit-content',
								height: 'fit-content',
								minWidth: { xs: 'fit-content', lg: 200 },
								margin: '4px',
								...(buttonProps.sx || {}),
							}}
						>
							{buttonProps.text}
						</Button>
					)}
				</Badge>
			);

			return buttonProps.tooltip ? (
				<Tooltip key={buttonProps.text} title={buttonProps.tooltip}>
					{button}
				</Tooltip>
			) : button;
		});
	}, [buttonsMemo?.primaryButtons, loading]);

	const renderSubtitle = subtitle?.split('\n').map((line, index) => (
		<Typography
			// eslint-disable-next-line react/no-array-index-key
			key={index}
			variant="subtitle1"
			sx={subtitleStyle}
		>
			{line}
		</Typography>
	));

	return (
		<Box sx={headerStyle}>
			<Box sx={titleContainerStyle}>
				<Box sx={titleWrapperStyle}>
					{icon && (
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							{icon}
						</Box>
					)}
					<Typography variant="h4" sx={titleStyle}>
						{title}
					</Typography>
				</Box>
				{renderSubtitle}
			</Box>
			{buttonsMemo && (
				<Stack direction="row" spacing={2}>
					{primaryButtonsMemo}
					{buttonsMemo.menuButtons.length > 0 && (
						<PageHeaderMenu options={buttonsMemo.menuButtons} />
					)}
				</Stack>
			)}
		</Box>
	);
};

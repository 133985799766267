/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-mixed-spaces-and-tabs */
import React, {
	Dispatch,
	Reducer,
	useMemo,
	useReducer,
} from 'react';
import {
	OptionsObject,
	SnackbarKey,
	SnackbarMessage,
	useSnackbar,
} from 'notistack';
import { AxiosError } from 'axios';
import {
	NavigateFunction,
	useNavigate,
} from 'react-router-dom';
import { IProductWithoutDetails } from '../Product/ProductAssets';
import { ProductQueryParams } from '../../interfaces/ProductQueryParams';
import { getProductsWithoutDetails } from '../../services/product';
import InventoryStartPresentational from '../../components/Inventory/InventoryStart';
import {
	ILocationProduct,
	LocationProductQueryParams,
} from '../../interfaces/LocationProduct';
import {
	getLocations,
	getLocationsProducts,
} from '../../services/location';
import { ILocationWithPagination } from '../Location/LocationAssets';
import { ParamsStartInventoryPartial } from './InventoryAssets';
import { startInventoryPartial } from '../../services/inventory';
import { removeDuplicates } from '../../helpers/Utils';

enum ActionType {
	LOADING,
	PRODUCT,
	LOCATION,
	LOCATION_PRODUCT
}

interface IState {
	loading: boolean;
	products: IProductWithoutDetails[];
	location: ILocationWithPagination;
	locationsProducts: ILocationProduct[];
	locationsProductsCount: number;
	locationsProductsPage: number;
	locationsProductsTake: number;
}

type TAction =
| { type: ActionType.LOADING; payload: { loading: boolean } }
| { type: ActionType.LOCATION; payload: { location: ILocationWithPagination } }
| { type: ActionType.PRODUCT; payload: { products: IProductWithoutDetails[] } }
| { type: ActionType.LOCATION_PRODUCT; payload: {
	locationsProducts: ILocationProduct[]
	locationsProductsCount: number
	locationsProductsPage: number
	locationsProductsTake: number
}}

interface IInventoryStartActions {
	setLoading(loading: boolean): void;
	getProducts(queryParams: ProductQueryParams): void;
	getLocationsProducts(queryParams: LocationProductQueryParams): void;
	startInventoryPartial(params: ParamsStartInventoryPartial): void;
}

const initialState: IState = {
	loading: false,
	products: [],
	locationsProducts: [],
	location: { count: 0, locations: [] },
	locationsProductsCount: 0,
	locationsProductsPage: 0,
	locationsProductsTake: 10,
};

const reducer: Reducer<IState, TAction> = (state, action) => {
    	switch (action.type) {
    		case ActionType.LOADING:
    			return { ...state, loading: action.payload.loading };
		case ActionType.PRODUCT: {
			const newProducts = action.payload.products;
			const updatedProducts = removeDuplicates([...state.products, ...newProducts], 'id');
			return { ...state, products: updatedProducts };
		}
		case ActionType.LOCATION_PRODUCT:
			return {
				...state,
				locationsProducts: action.payload.locationsProducts,
				locationsProductsCount: action.payload.locationsProductsCount,
				locationsProductsTake: action.payload.locationsProductsTake,
				locationsProductsPage: action.payload.locationsProductsPage,
			};
		case ActionType.LOCATION:
			return {
				...state,
				location: action.payload.location,
			};
    		default:
    			throw new Error();
    	}
};

const InventoryStartActions = (
	dispatch: Dispatch<TAction>,
	navigate: NavigateFunction,
	enqueueSnackbar: (
        message: SnackbarMessage,
        options?: OptionsObject | undefined
    ) => SnackbarKey,
): IInventoryStartActions => {
    	const actions = {
    		setLoading(loading: boolean) {
    			dispatch({ type: ActionType.LOADING, payload: { loading } });
    		},

		getLocationsProducts(queryParams: LocationProductQueryParams) {
			if (!queryParams.productIds?.length && !queryParams.locationValue) {
				dispatch({
					type: ActionType.LOCATION_PRODUCT,
					payload: {
						locationsProducts: [],
						locationsProductsCount: 0,
						locationsProductsPage: 0,
						locationsProductsTake: 10,
					},
				});
				return;
			}

			actions.setLoading(true);

			const take = queryParams.take ?? 10;
			const skip = queryParams.skip ?? 0;
			const params: LocationProductQueryParams = {
				...queryParams,
				skip: skip * take,
				take: queryParams.take ?? 10,
				orderBy: 'asc',
			};

			getLocationsProducts(params)
				.then((response) => {
					dispatch({
						type: ActionType.LOCATION_PRODUCT,
						payload: {
							locationsProducts: response.data.data,
							locationsProductsCount: response.data.count,
							locationsProductsPage: queryParams.skip ?? 0,
							locationsProductsTake: take,
						},
					});
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(
						error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.',
						{ variant: 'error' },
					);
				})
				.finally(() => {
					actions.setLoading(false);
				});
		},

		startInventoryPartial(params: ParamsStartInventoryPartial) {
			actions.setLoading(true);
			startInventoryPartial(params).then((response) => {
				enqueueSnackbar('Tarefa do Inventário criada com sucesso!', { variant: 'success' });
				navigate(response.data.inventoryId);
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message
				|| 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
					variant: 'error',
				});
			}).finally(() => actions.setLoading(false));
		},

		getLocations(queryParams?: LocationProductQueryParams) {
			actions.setLoading(true);
			const take = queryParams?.take ?? 10;

			const dataParams = {
				take,
				orderBy: 'asc',
				skip: queryParams?.skip ? (queryParams.skip - 1) * take : 0,
				currentQuantity: queryParams?.currentQuantity ? queryParams?.currentQuantity : undefined,
				locationValue: queryParams?.locationValue ? queryParams.locationValue : undefined,
				productIds: queryParams?.productIds?.length ? queryParams.productIds.join(',') : undefined,
				available: queryParams?.available !== undefined && (queryParams?.available !== '' as any) ? queryParams?.available : undefined,
			};

			getLocations(dataParams as LocationProductQueryParams)
				.then((response) => {
					dispatch({
						type: ActionType.LOCATION,
						payload: {
							location: response.data,
						},
					});
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(
						error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.',
						{ variant: 'error' },
					);
				})
				.finally(() => {
					actions.setLoading(false);
				});
		},

		getProducts(queryParams: ProductQueryParams) {
			if (!queryParams) return;
			getProductsWithoutDetails(queryParams).then((response) => {
				dispatch({
					type: ActionType.PRODUCT,
					payload: {
						products: response.data.data,
					},
				});
			}).catch((error: AxiosError) => {
				enqueueSnackbar(
					error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.',
					{ variant: 'error' },
				);
			});
		},

    	};

    	return actions;
};

const InventoryStart = (): JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(reducer, initialState);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();

	const actions = useMemo(
		() => InventoryStartActions(dispatch, navigate, enqueueSnackbar),
		[enqueueSnackbar, navigate],
	);

	return (
		<InventoryStartPresentational
			{...state}
			{...actions}
		/>
	);
};

export default InventoryStart;

import '../../helpers/yupMethods';
import * as Yup from 'yup';
import { validationMessage } from '../../helpers/yupMessages';
import { BusinessPartnerType } from '../../enums/BusinessPartnerType';
import { ContractType } from '../../enums/ContractType';
import { CreditRiskType } from '../../enums/CredityRiskType';
import { LegalEntityType } from '../../enums/LegalEntityType';
import { LegalEntityTypeLabels } from '../../constants/legalEntityLabels';
import { BusinessPartnerStatusLabels, BusinessPartnerTypeLabels } from '../../constants/businessPartnerLabels';
import { ContractTypeLabels } from '../../constants/contractTypeLabes';
import { CreditRiskTypeLabels } from '../../constants/creditRiskTypeLabels';
import { BusinessPartnerSubtype } from '../../enums/BusinessPartnerSubtype';
import { BusinessPartnerSubtypeLabels } from '../../constants/businessPartnerSubtypeLabels';
import { ConversionType } from '../../enums/ConversionType';

export interface IContact {
	id?: string;
	name: string;
	email: string;
	phone: string;
	typeContactId: string;
	businessPartnerId?: string;
}

export interface IProductBusinessPartner {
	id?: string;
	code: string;
	description: string;
	productId?: string;
	businessPartnerId?: string;
	hasInvoiceItem?: string;
	origin?: string;
	originIndex?: number;
	originUnitIndex?: number;
	isTemporary?: boolean;
}

export interface ISalesman {
	id: string;
	name: string;
	email: string;
}

export interface ISalesmans {
	data: ISalesman[];
	count: number;
}

export interface IProductBusinessPartners {
	data: IProductBusinessPartner[];
	count: number;
}

export interface IBusinessPartner {
	id?: string;
	tempId?: string;
	unitIndex?: string;
	type: BusinessPartnerType;
	legalEntity?: LegalEntityType;
	name: string;
	marketName: string;
	taxId: string;
	country: string;
	state: string;
	city: string;
	zipCode: string;
	district: string;
	address: string;
	number: string;
	email: string;
	phone: string;
	approved: boolean;
	groupCredit: boolean;
	active: boolean;
	creditRisk: CreditRiskType;
	creditValue?: number;
	creditDate?: Date;
	subtype?: BusinessPartnerSubtype;
	stateTaxId?: string;
	cityTaxId?: string;
	addressOne?: string;
	website?: string;
	invoiceMail?: string;
	contractType?: ContractType;
	businessPartnerId?: string;
	userId?: string;
	contacts: IContact[];
	productBusinessPartners: IProductBusinessPartner[];
	units: IBusinessPartner[];
	salesmans?: any[];
}

export interface IBusinessPartners {
	data: IBusinessPartner[];
	count: number;
}

export const typeOptions = Object.entries(BusinessPartnerTypeLabels).map(
	([value, label]) => ({
		id: value,
		value,
		label,
	}),
);

export const bpStatusOptions = Object.entries(BusinessPartnerStatusLabels).map(
	([value, label]) => ({
		id: value,
		value,
		label,
	}),
);

export const subtypeOptions = Object.entries(BusinessPartnerSubtypeLabels).map(
	([value, label]) => ({
		id: value,
		value,
		label,
	}),
);

export const legalEntityOptions = Object.entries(LegalEntityTypeLabels).map(
	([value, label]) => ({
		id: value,
		value,
		label,
	}),
);

export const contractTypeOptions = Object.entries(ContractTypeLabels).map(
	([value, label]) => ({
		id: value,
		value,
		label,
	}),
);

export const creditRiskTypeOptions = Object.entries(CreditRiskTypeLabels).map(
	([value, label]) => ({
		id: value,
		value,
		label,
	}),
);

export type PartialBusinessPartner = Partial<IBusinessPartner>;

export const initialValues: IBusinessPartner = {
	type: BusinessPartnerType.CLIENT,
	legalEntity: LegalEntityType.PHYSICAL,
	creditRisk: CreditRiskType.NONE,
	name: '',
	marketName: '',
	taxId: '',
	country: '',
	state: '',
	city: '',
	zipCode: '',
	district: '',
	address: '',
	number: '',
	email: '',
	phone: '',
	active: true,
	approved: true,
	stateTaxId: '',
	cityTaxId: '',
	addressOne: '',
	website: '',
	invoiceMail: '',
	creditValue: 0,
	creditDate: new Date(),
	groupCredit: false,
	contacts: [],
	productBusinessPartners: [],
	units: [],
};

export interface IProductBusinessPartnerDetail {
	id: string;
	code: string;
	description: string;
	barCode: string;
	measureId?: string;
	typeId?: string;
	qualityControl: boolean;
	locateControl: boolean;
	apportionmentControl: boolean;
	erpCode?: string;
	erpDescription?: string;
	convertionType?: ConversionType;
	convertionIndex?: string;
	companyId?: string;
	branchId?: string;
	accountingAccountId?: string;
	image?: File;
	imageB64?: string;
}

export interface ITopSellingProduct {
	product: IProductBusinessPartnerDetail;
	totalQuantitySold: number;
}

export interface IProductBusinessPartnerDetails {
	data: IProductBusinessPartnerDetail[];
	count: number;
}

export const stepSchemas = [Yup.object().shape({
	type: Yup.number()
		.nullable()
		.required('É obrigatório escolher o tipo de parceiro de negócios!'),
	name: Yup.string().required(validationMessage.required),
	taxId: Yup.string()
		.when('legalEntity', {
			is: LegalEntityType.PHYSICAL,
			then: Yup.string().cpf().required(validationMessage.required),
			otherwise: Yup.string().when('legalEntity', {
				is: LegalEntityType.LEGAL,
				then: Yup.string().cnpj().required(validationMessage.required),
				otherwise: Yup.string().required(validationMessage.required),
			}),
		}),
	zipCode: Yup.string().required(validationMessage.required),
	district: Yup.string().required(validationMessage.required),
	number: Yup.string().required(validationMessage.required),
	address: Yup.string().required(validationMessage.required),
	country: Yup.string().required(validationMessage.required),
	state: Yup.string().required(validationMessage.required),
	city: Yup.string().required(validationMessage.required),
	email: Yup.string()
		.email(validationMessage.string.email)
		.required(validationMessage.required),
	phone: Yup.string()
		.required(validationMessage.required)
		.min(10, validationMessage.string.min(10)),
	businessPartnerId: Yup.string()
		.when('type', {
			is: BusinessPartnerType.CLIENT,
			then: Yup.string().required(validationMessage.required),
			otherwise: Yup.string().nullable(true),
		}),
	userId: Yup.string()
		.when('type', {
			is: BusinessPartnerType.SALESMAN,
			then: Yup.string().required(validationMessage.required),
			otherwise: Yup.string().nullable(true),
		}),
	invoiceEmail: Yup.string().email(validationMessage.string.email),
	webSite: Yup.string().url(validationMessage.string.url),
	creditRisk: Yup.string()
		.when('type', {
			is: BusinessPartnerType.CLIENT,
			then: Yup.string().required(validationMessage.required),
			otherwise: Yup.string().nullable(true),
		}),
}),
Yup.object().shape({
	units: Yup.array().of(
		Yup.object({
			subtype: Yup.string().required(validationMessage.required),
			legalEntity: Yup.string().required(validationMessage.required),
			name: Yup.string().required(validationMessage.required),
			taxId: Yup.string()
				.when('legalEntity', {
					is: LegalEntityType.PHYSICAL,
					then: Yup.string().cpf().required(validationMessage.required),
					otherwise: Yup.string().when('legalEntity', {
						is: LegalEntityType.LEGAL,
						then: Yup.string().cnpj().required(validationMessage.required),
						otherwise: Yup.string().required(validationMessage.required),
					}),
				}),
			zipCode: Yup.string().required(validationMessage.required),
			district: Yup.string().required(validationMessage.required),
			number: Yup.string().required(validationMessage.required),
			address: Yup.string().required(validationMessage.required),
			country: Yup.string().required(validationMessage.required),
			state: Yup.string().required(validationMessage.required),
			city: Yup.string().required(validationMessage.required),
			email: Yup.string()
				.email(validationMessage.string.email).required(validationMessage.required),
			phone: Yup.string()
				.required(validationMessage.required).min(10, validationMessage.string.min(10)),
		}),
	),
}),
Yup.object().shape({
	contacts: Yup.array().of(
		Yup.object({
			name: Yup.string().required(validationMessage.required),
			email: Yup.string()
				.email(validationMessage.string.email)
				.required(validationMessage.required),
			phone: Yup.string()
				.required(validationMessage.required)
				.min(10, validationMessage.string.min(10)),
			typeContactId: Yup.string().required(validationMessage.required),
		}),
	),
}),
Yup.object().shape({
	productBusinessPartners: Yup.array().of(
		Yup.object({
			productId: Yup.string().required(validationMessage.required),
			code: Yup.string().required(validationMessage.required),
			description: Yup.string().required(validationMessage.required),
		}),
	),
}),
];

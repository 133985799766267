import React, { InputHTMLAttributes, useCallback } from 'react';
import {
	FormControlLabel,
	FormControl,
	FormLabel,
	FormHelperText,
	Theme,
	Checkbox,
} from '@mui/material';
import { SxProps } from '@mui/system';
import { useField } from 'formik';

interface ICheckboxFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  options: {
    id: string;
    checked: boolean;
    label: string;
  }[];
  orientation?: 'column' | 'row';
  label?: string;
}

interface ISingleCheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
}

const column: SxProps<Theme> = {
	flexDirection: 'column',
};

const row: SxProps<Theme> = {
	flexDirection: 'row',
};

const CheckboxField = ({
	name,
	options,
	orientation,
	label,
}: ICheckboxFieldProps): JSX.Element => {
	const [field, meta, helpers] = useField({ name });

	return (
		<FormControl component="fieldset" sx={orientation === 'row' ? row : column}>
			{label && (
				<FormLabel component="legend" required>
					{label}
				</FormLabel>
			)}
			{options.map((option) => (
				<FormControlLabel
					key={option.id}
					control={(
						// eslint-disable-next-line react/jsx-props-no-spreading
						<Checkbox {...field} checked={option.checked} name={option.id} />
					)}
					label={option.label}
				/>
			))}
			<FormHelperText error={Boolean(meta.touched) && Boolean(meta.error)}>
				{Boolean(meta.error) && meta.error}
			</FormHelperText>
		</FormControl>
	);
};

const SingleCheckbox = ({
	name,
	label,
	onChangeValue,
// eslint-disable-next-line react/require-default-props
}: ISingleCheckboxProps & { onChangeValue?: (checked: boolean) => void }): JSX.Element => {
	const [field, meta, helpers] = useField({ name });

	const handleChange = useCallback((
		event: React.ChangeEvent<HTMLInputElement>,
	): void => {
		const { checked } = event.target;
		helpers.setValue(checked);
		if (onChangeValue) {
			onChangeValue(checked);
		}
	}, [helpers, onChangeValue]);

	return (
		<FormControl component="fieldset">
			<FormControlLabel
				control={(
					<Checkbox
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...field}
						checked={!!field.value}
						onChange={handleChange}
						indeterminate={field.value === '' || field.value === undefined}
					/>
				)}
				label={label}
			/>
			<FormHelperText error={Boolean(meta.touched) && Boolean(meta.error)}>
				{Boolean(meta.error) && meta.error}
			</FormHelperText>
		</FormControl>
	);
};

CheckboxField.defaultProps = {
	orientation: 'column',
	label: '',
};

SingleCheckbox.defaultProps = {
	label: '',
};

export default {
	CheckboxField: React.memo(CheckboxField),
	SingleCheckbox: React.memo(SingleCheckbox),
};

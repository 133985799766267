import React, { useCallback, useEffect, useState } from 'react';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Divider,
	Grid,
	IconButton,
	Typography,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { Add, ExpandMore, Remove } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import Input from '../../Common/Form/Input';
import { formatBarCode, removeMaskBarCode } from '../../../helpers/masks';
import { initialValues } from './Consumption';
import useConfirmationDialog from '../../../hooks/useConfirmationDialog';
import { SaveTaskType } from '../../../services/transfer';
import { ConsumptionProductionTask } from '../../../containers/Consumption/ConsumptionAssets';
import { ConsumptionType } from '../../../enums/ConsumptionType';
import Button from '../../Common/Button/Button';

interface ConsumptionItemsProps {
	consumptionTasks: ConsumptionProductionTask[];
	saveTempTask(data: SaveTaskType): void;
	handleBack(): void;
	type?: ConsumptionType;
}

const ConsumptionItems = ({
	consumptionTasks,
	saveTempTask,
	handleBack,
	type,
}: ConsumptionItemsProps): JSX.Element => {
	const [countVisibility, setCountVisibility] = useState<Record<string, boolean>>({});

	useEffect(() => {
		const initialVisibility = consumptionTasks.reduce((acc, task) => {
			acc[task.id] = false;
			return acc;
		}, {} as Record<string, boolean>);

		setCountVisibility(initialVisibility);
	}, [consumptionTasks]);

	const toggleVisibility = useCallback((taskId: string, visible: boolean) => {
		setCountVisibility((prev) => ({
			...prev,
			[taskId]: visible,
		}));
	}, [setCountVisibility]);

	const { values, setFieldValue } = useFormikContext<typeof initialValues>();
	const { enqueueSnackbar } = useSnackbar();
	const { requestConfirm, confirmationDialog } = useConfirmationDialog();

	const handleIncrement = useCallback((): void => {
		const currentValue = Number(values.productQuantity) || 0;
		setFieldValue('productQuantity', currentValue + 1);
	}, [setFieldValue, values]);

	const handleDecrement = useCallback((): void => {
		const currentValue = Number(values.productQuantity) || 0;
		if (currentValue > 0) {
			setFieldValue('productQuantity', currentValue - 1);
		}
	}, [setFieldValue, values]);

	const handleConfirmBarCode = useCallback((locationBarCode: string, taskId: string):void => {
		if (removeMaskBarCode(values.locationBarCode) === removeMaskBarCode(locationBarCode)) {
			toggleVisibility(taskId, true);
		} else {
			enqueueSnackbar('Código de barras inválido!', {
				variant: 'error',
			});
			setFieldValue('locationBarCode', '');
		}
	}, [
		enqueueSnackbar,
		setFieldValue,
		toggleVisibility,
		values.locationBarCode,
	]);

	const handleBlurOrEnter = useCallback((
		event: React.KeyboardEvent<HTMLDivElement>,
		consumptionTask: ConsumptionProductionTask,
		typeTask: 'location' | 'product',
	): void => {
		if ((event.key === 'Tab') || (event.key === 'Enter')) {
			event.preventDefault();
			if (typeTask === 'location') {
				if (type === ConsumptionType.REQUISITION) {
					handleConfirmBarCode(consumptionTask.locationOriginBarCode, consumptionTask.id);
				} else if (type === ConsumptionType.DEVOLUTION) {
					handleConfirmBarCode(consumptionTask.locationDestinyBarCode, consumptionTask.id);
				}
			} else if (typeTask === 'product') {
				setFieldValue('productBarCode', '');
				handleIncrement();
			}
		}
	}, [handleConfirmBarCode, handleIncrement, setFieldValue, type]);

	const resetItem = useCallback(() => {
		setFieldValue('productQuantity', '');
		setFieldValue('locationBarCode', '');
		setFieldValue('productBarCode', '');

		setCountVisibility((prev) => {
			const updated = { ...prev };
			Object.keys(updated).forEach((key) => {
				updated[key] = false;
			});
			return updated;
		});
	}, [setFieldValue]);

	return (
		<Box>
			<Typography mt={2} mb={2} variant="caption" textTransform="uppercase" marginBottom={2}>
				Itens do Consumo:
			</Typography>
			{consumptionTasks.map((consumptionTask) => (
				<Accordion key={consumptionTask.id} sx={{ mb: 1 }}>
					<AccordionSummary
						expandIcon={<ExpandMore />}
					>
						<Box>
							<Box display="flex" alignItems="center" gap={1}>
								{type === ConsumptionType.REQUISITION && (
									<Typography sx={{ fontWeight: !countVisibility[consumptionTask.id] ? 'bold' : undefined, mb: 1 }}>
										{consumptionTask?.locationOriginBarCode ? formatBarCode(consumptionTask.locationOriginBarCode) : 'Localização Indefinida'}
									</Typography>
								)}
								{type === ConsumptionType.DEVOLUTION && (
									<Typography sx={{ fontWeight: !countVisibility[consumptionTask.id] ? 'bold' : undefined, mb: 1 }}>
										{consumptionTask?.locationDestinyBarCode ? formatBarCode(consumptionTask.locationDestinyBarCode) : 'Localização Indefinida'}
									</Typography>
								)}
							</Box>
							<Typography sx={{ fontWeight: countVisibility[consumptionTask.id] ? 'bold' : undefined }}>
								{`${consumptionTask.productCode} - ${consumptionTask.productDescription}`}
							</Typography>
							<Typography sx={{ fontWeight: countVisibility[consumptionTask.id] ? 'bold' : undefined }}>
								{`${consumptionTask.quantity} ${consumptionTask.primaryMeasureDescription} (s)`}
							</Typography>
						</Box>
					</AccordionSummary>
					<Divider sx={{ mb: 1, mx: 2 }} />
					<AccordionDetails>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6}>
								{!countVisibility[consumptionTask.id] ? (
									<Grid item xs={12} sm={6}>
										<Typography sx={{ marginBottom: 2 }}>
											Leia o código de barras da localização:
										</Typography>
										<Input.InputField
											id="locationBarCode"
											name="locationBarCode"
											label="Código de Barras da Localização"
											autoComplete="off"
											format={(value) => formatBarCode(value)}
											onKeyDown={(
												event,
											) => handleBlurOrEnter(event, consumptionTask, 'location')}
											fullWidth
											sx={{ mb: 2 }}
											autoFocus
											required
										/>
										<Button
											variant="contained"
											size="small"
											color="primary"
											sx={{ width: '100%' }}
											onClick={() => {
												if (type === ConsumptionType.REQUISITION) {
													handleConfirmBarCode(
														consumptionTask.locationOriginBarCode,
														consumptionTask.id,
													);
												} else if (type === ConsumptionType.DEVOLUTION) {
													handleConfirmBarCode(
														consumptionTask.locationDestinyBarCode,
														consumptionTask.id,
													);
												}
											}}
										>
											Confirmar
										</Button>
									</Grid>
								) : (
									<Grid item xs={12} sm={6}>
										<Input.InputField
											label="Código de Barras"
											id="productBarCode"
											name="productBarCode"
											sx={{ mb: 2 }}
											onKeyDown={(
												event,
											) => handleBlurOrEnter(event, consumptionTask, 'product')}
											autoFocus
											fullWidth
										/>
										<Box display="flex" justifyContent="center" marginBottom={2} gap={2}>
											<IconButton size="large" onClick={handleDecrement}>
												<Remove />
											</IconButton>
											<Input.InputField
												id="productQuantity"
												name="productQuantity"
												style={{ width: 100, textAlign: 'center' }}
												type="number"
												inputProps={{ min: 0 }}
												required
											/>
											<IconButton size="large" onClick={handleIncrement}>
												<Add />
											</IconButton>
										</Box>
										{Number(values.productQuantity) === Number(consumptionTask.quantity) ? (
											<Button
												variant="contained"
												size="small"
												color="primary"
												sx={{ width: '100%' }}
												onClick={() => {
													saveTempTask({
														taskId: consumptionTask.id,
														quantity: Number(values.productQuantity),
													});
													resetItem();
												}}
												disabled={
													Number(values.productQuantity) === 0
												|| Number(values.productQuantity) > Number(consumptionTask.quantity)
												}
											>
												Confirmar
											</Button>
										) : (
											<Button
												variant="outlined"
												size="small"
												color="error"
												sx={{ width: '100%' }}
												disabled={Number(values.productQuantity) <= 0
													|| Number(values.productQuantity) > Number(consumptionTask.quantity)}
												onClick={() => requestConfirm({
													title: 'Confirmar Inconsistência',
													description: 'Existe uma diferença entre a quantidade informada e a esperada, deseja confirmar mesmo assim?',
													callback: () => {
														saveTempTask({
															taskId: consumptionTask.id,
															quantity: Number(values.productQuantity),
														});
														resetItem();
													},
												})}
											>
												Inconsistente
											</Button>
										)}
									</Grid>
								)}
							</Grid>
						</Grid>
					</AccordionDetails>
				</Accordion>
			))}
			{confirmationDialog}
			{handleBack && (
				<Button variant="contained" color="primary" sx={{ my: 2 }} onClick={handleBack}>
					Voltar
				</Button>
			)}
		</Box>
	);
};

ConsumptionItems.defaultProps = {
	type: undefined,
};

export default ConsumptionItems;

import React, { useCallback, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Link } from 'react-router-dom';
import { PageHeader } from '../Common/PageHeader/PageHeader';
import DrawerFilter from '../Common/DrawerFilter';
import LocationTable from './LocationTable';
import { PageHeaderButtonProps } from '../../interfaces/PageHeaderInterface';
import {
	EditProductFormProps,
	ILocationWithPagination,
	initialValueLocationFilter,
} from '../../containers/Location/LocationAssets';
import { ProductQueryParams } from '../../interfaces/ProductQueryParams';
import { IProductWithoutDetails } from '../../containers/Product/ProductAssets';
import LocationFilter from './LocationFilter';
import { LocationProductQueryParams } from '../../interfaces/LocationProduct';
import useDataGridManagement from '../../hooks/useDataGridManagement';
import Pagination from '../Common/Pagination';
import { Loading } from '../Common/Loading';

interface LocationProps {
  loading: boolean;
  loadingProducts: boolean;
  location: ILocationWithPagination;
  getLocations: (queryParams: LocationProductQueryParams) => void;
  getProductsWithoutDetails(queryParams: ProductQueryParams): void;
  products: IProductWithoutDetails[];
  updateLocationProduct: (
    productId: string,
    locationId: string,
    values: EditProductFormProps,
  ) => Promise<void>;
  saveLocationProduct: (
    productId: string,
    locationId: string,
    valuedQuantity: string
  ) => void;
  printLabel(barCode: string): void;
  deleteLocationProduct: (productId: string, locationId: string) => void;
  handleProductLocationActiveStatus:(
	locationId: string,
	productId: string,
	activeProduct: boolean,
  ) => void;
  handleActiveLocation: (locationId: string, active: boolean) => void;
}

const Location = ({
	loading,
	loadingProducts,
	location,
	products,
	getLocations,
	getProductsWithoutDetails,
	updateLocationProduct,
	saveLocationProduct,
	deleteLocationProduct,
	printLabel,
	handleProductLocationActiveStatus,
	handleActiveLocation,
}: LocationProps): JSX.Element => {
	const [isFilterDrawerOpen, setFilterDrawerOpen] = useState(false);

	const {
		filter, setFilter,
	} = useDataGridManagement<LocationProductQueryParams>({
		fetchData: getLocations,
	});

	const toggleFilterDrawer = useCallback(() => {
		setFilterDrawerOpen(!isFilterDrawerOpen);
	}, [isFilterDrawerOpen]);

	const sendFilter = useCallback(
		(values: Partial<LocationProductQueryParams>) => {
			setFilter(values);
			setFilterDrawerOpen(false);
		},
		[setFilter],
	);

	const headerButtonsProps = useMemo(
		(): PageHeaderButtonProps[] => [
			{
				variant: 'contained',
				color: 'primary',
				type: 'submit',
				fullWidth: true,
				component: Link,
				to: 'edit',
				text: 'Gerenciar Localizações',
			},
			{
				variant: 'contained',
				color: 'primary',
				onClick: toggleFilterDrawer,
				text: 'Filtro',
				startIcon: <FilterListIcon />,
			},
		],
		[toggleFilterDrawer],
	);

	const handlePaginationChange = useCallback(
		(pageNumber: number, rowsPerPage: number) => {
			setFilter({ ...filter, take: rowsPerPage, skip: pageNumber });
		},
		[setFilter, filter],
	);

	const onChangeItems = useCallback(
		(pageNumber: number, rowsPerPage: number) => {
			setFilter({ ...filter, take: rowsPerPage, skip: pageNumber - 1 });
		},
		[setFilter, filter],
	);

	const filterMemo = useMemo(
		() => (
			<DrawerFilter open={isFilterDrawerOpen} onClose={toggleFilterDrawer}>
				<LocationFilter
					filter={filter}
					sendFilter={sendFilter}
					products={products}
					loadingProducts={loadingProducts}
					getProducts={getProductsWithoutDetails}
					initialValues={{ ...initialValueLocationFilter, ...filter }}
				/>
			</DrawerFilter>
		),
		[
			isFilterDrawerOpen,
			toggleFilterDrawer,
			filter,
			sendFilter,
			products,
			loadingProducts,
			getProductsWithoutDetails,
		],
	);

	const paginationMemo = useMemo(() => (
		<Pagination
			loading={loading}
			count={Math.ceil(location.count / (filter.take as number))}
			page={filter.skip ?? 0}
			take={(filter.take) as number}
			onChangeItems={onChangeItems}
			onChange={handlePaginationChange}
		/>
	), [filter.skip,
		filter.take,
		handlePaginationChange,
		loading,
		location,
		onChangeItems]);

	if (loading) {
		return <Loading />;
	}

	return (
		<Box className="content" sx={{ overflowY: 'scroll' }}>
			<PageHeader title="Localização" buttons={headerButtonsProps} />
			{filterMemo}
			<LocationTable
				location={location}
				loading={loading}
				updateLocationProduct={updateLocationProduct}
				handleActiveLocation={handleActiveLocation}
				saveLocationProduct={saveLocationProduct}
				getProducts={getProductsWithoutDetails}
				products={products}
				deleteLocationProduct={deleteLocationProduct}
				printLabel={printLabel}
				handleProductLocationActiveStatus={handleProductLocationActiveStatus}
			/>
			{paginationMemo}
		</Box>
	);
};

export default Location;

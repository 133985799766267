import React, {
	useCallback,
	useMemo,
	useState,
} from 'react';
import {
	Grid,
	Typography,
} from '@mui/material';
import { useFormikContext } from 'formik';
import {
	cepMask,
	phoneMask,
	cpfMask,
	cnpjMask,
} from '../../../helpers/masks';
import RadioField from '../../Common/Form/Radio';
import SwitchField from '../../Common/Form/Switch';
import Input from '../../Common/Form/Input';
import Autocomplete from '../../Common/Form/Autocomplete';
import { ICountry } from '../../../services/country';
import { IRegionState } from '../../../services/state';
import { ICity } from '../../../services/city';
import { useConditionalSearch } from '../../../hooks/useConditionalSearchProps';
import {
	typeOptions,
	legalEntityOptions,
	contractTypeOptions,
	creditRiskTypeOptions,
	IBusinessPartner,
	ISalesman,
} from '../../../containers/BusinessPartner/BusinessPartnerAssets';
import { DatePicker } from '../../Common/Form/DatePicker';
import { BusinessPartnerType } from '../../../enums/BusinessPartnerType';
import { LegalEntityType } from '../../../enums/LegalEntityType';
import { ContractType } from '../../../enums/ContractType';
import Select from '../../Common/Form/Select';
import usePermission from '../../../hooks/usePermission';

interface BusinessPartnerFormProps {
	salesmans: ISalesman[];
	countries: ICountry[];
	states: IRegionState[];
	cities: ICity[];
	users: Array<{ id: string; name: string; login: string }>;
	getCountries(searchQuery?: string): void;
	getCities(searchQuery?: string): void;
	handleCepBlur(cep: string): void;
}

const BPForm = ({
	salesmans,
	countries,
	states,
	cities,
	users,
	getCities,
	getCountries,
	handleCepBlur,
}: BusinessPartnerFormProps): JSX.Element => {
	const { values, setFieldValue, handleBlur } = useFormikContext<IBusinessPartner>();
	const permissionSales = usePermission('BUSINESS_PARTNER');
	const permissionAdmin = usePermission('ACCESS_GROUP');
	const [countryInputValue, setCountryInputValue] = useState('');
	const [cityInputValue, setCityInputValue] = useState('');

	useConditionalSearch({
		inputValue: countryInputValue,
		loadedItems: countries,
		searchFunction: getCountries,
		minLength: 2,
		maxLength: 8,
		compareKey: 'name',
	});

	useConditionalSearch({
		inputValue: cityInputValue,
		loadedItems: cities,
		searchFunction: getCities,
		minLength: 3,
		maxLength: 8,
		compareKey: 'name',
	});

	const handlePartnerTypeChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		const partnerTypeValue = Number(event.target.value);

		if (partnerTypeValue !== BusinessPartnerType.SALESMAN) {
			setFieldValue('contractType', null);
		}
	}, [setFieldValue]);

	const handleContractTypeChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		const contractTypeValue = Number(event.target.value);

		if (contractTypeValue === ContractType.EMPLOYEE) {
			setFieldValue('legalEntity', 0);
		}
	}, [setFieldValue]);

	const typeOptionsFiltered = useMemo(() => {
		if (permissionAdmin && permissionAdmin.isAdmin) {
			return typeOptions;
		} if (permissionSales && permissionSales.isAdmin) {
			return typeOptions.filter((option) => option.value === BusinessPartnerType.CLIENT.toString()
				|| option.value === BusinessPartnerType.SALESMAN.toString());
		} if (permissionSales && !permissionSales.isAdmin) {
			return typeOptions.filter((option) => option.value === BusinessPartnerType.CLIENT.toString());
		}
		return typeOptions;
	}, [permissionSales, permissionAdmin]);

	const renderCommonFields = useMemo(() => (
		<>
			{values.type !== BusinessPartnerType.SALESMAN && (
				<>
					<Grid item xs={6}>
						<Input.InputField
							id="name"
							name="name"
							label="Razão Social"
							required
						/>
					</Grid>
					<Grid item xs={6}>
						<Input.InputField
							id="marketName"
							name="marketName"
							label="Nome de Mercado"
							required
						/>
					</Grid>
				</>
			)}
			{values.type === BusinessPartnerType.SALESMAN && (
				<Grid item xs={4}>
					<Input.InputField
						id="name"
						name="name"
						label="Nome Completo"
						required
					/>
				</Grid>
			)}
			{values.legalEntity === LegalEntityType.PHYSICAL && (
				<Grid item xs={2}>
					<Input.InputMaskField
						id="taxId"
						name="taxId"
						label="CPF"
						mask={cpfMask}
						onBlur={handleBlur}
						required
					/>
				</Grid>
			)}
			{values.legalEntity === LegalEntityType.LEGAL && (
				<Grid item xs={2}>
					<Input.InputMaskField
						id="taxId"
						name="taxId"
						label="CNPJ"
						mask={cnpjMask}
						onBlur={handleBlur}
						required
					/>
				</Grid>
			)}
			{values.legalEntity === LegalEntityType.FOREIGNER && (
				<Grid item xs={2}>
					<Input.InputField
						id="taxId"
						name="taxId"
						label="TAXID"
						required
					/>
				</Grid>
			)}
			<Grid item xs={2}>
				<Autocomplete
					name="country"
					label="País"
					options={countries}
					labelKey="id"
					valueKey="name"
					onInputChange={(event, newInputValue) => setCountryInputValue(newInputValue)}
					required
				/>
			</Grid>
			<Grid item xs={2}>
				<Input.InputMaskField
					id="zipCode"
					name="zipCode"
					label="CEP"
					mask={cepMask}
					onBlur={(e) => handleCepBlur(e.target.value)}
					required
				/>
			</Grid>
			<Grid item xs={2}>
				<Autocomplete
					name="state"
					label="Estado"
					options={states}
					labelKey="abbrev"
					valueKey="name"
					required
				/>
			</Grid>
			<Grid item xs={2}>
				<Autocomplete
					name="city"
					label="Cidade"
					options={cities}
					labelKey="id"
					valueKey="name"
					onInputChange={(event, newInputValue) => setCityInputValue(newInputValue)}
					required
				/>
			</Grid>
			<Grid item xs={2}>
				<Input.InputField
					id="district"
					name="district"
					label="Bairro"
					required
				/>
			</Grid>
			<Grid item xs={4}>
				<Input.InputField
					id="address"
					name="address"
					label="Logradouro"
					required
				/>
			</Grid>
			<Grid item xs={2}>
				<Input.InputField
					id="number"
					name="number"
					label="Número"
					required
				/>
			</Grid>
			<Grid item xs={2}>
				<Input.InputField
					id="addressOne"
					name="addressOne"
					label="Complemento"
				/>
			</Grid>
			<Grid item xs={2}>
				<Input.InputMaskField
					id="phone"
					name="phone"
					label="Telefone"
					mask={phoneMask}
					onBlur={handleBlur}
					required
				/>
			</Grid>
			<Grid item xs={2}>
				<Input.InputField
					id="email"
					name="email"
					label="E-mail"
					required
				/>
			</Grid>
			{values.contractType !== ContractType.EMPLOYEE
				&& values.legalEntity !== LegalEntityType.PHYSICAL && (
				<>
					<Grid item xs={2}>
						<Input.InputField
							id="invoiceMail"
							name="invoiceMail"
							label="E-mail para Nota Fiscal"
						/>
					</Grid>
					<Grid item xs={3}>
						<Input.InputField
							id="stateTaxId"
							name="stateTaxId"
							label="Inscrição Estadual"
						/>
					</Grid>
					<Grid item xs={3}>
						<Input.InputField
							id="cityTaxId"
							name="cityTaxId"
							label="Inscrição Municipal"
						/>
					</Grid>
				</>
			)}
		</>
	), [
		values.type,
		values.legalEntity,
		values.contractType,
		countries, states, cities,
		handleCepBlur,
		handleBlur,
	]);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Typography variant="caption" display="block" gutterBottom>
					Os campos com * são obrigatórios!
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<RadioField
					name="type"
					label="Tipo de parceiro"
					options={typeOptionsFiltered}
					orientation="row"
					required
					onChange={handlePartnerTypeChange}
				/>
			</Grid>
			{ values.type !== BusinessPartnerType.SALESMAN && (
				<Grid item xs={12}>
					<RadioField
						name="legalEntity"
						label="Entidade Legal"
						options={legalEntityOptions}
						orientation="row"
						required
					/>
				</Grid>
			)}
			{values.type === BusinessPartnerType.SALESMAN && (
				<>
					<Grid item xs={12}>
						<RadioField
							name="contractType"
							label="Tipo de Contrato"
							options={contractTypeOptions}
							orientation="row"
							onChange={handleContractTypeChange}
						/>
					</Grid>
					{values.contractType === ContractType.EMPLOYEE && renderCommonFields}
					{values.contractType === ContractType.SUPPLIER && (
						<>
							<Grid item xs={12}>
								<RadioField
									name="legalEntity"
									label="Entidade Legal"
									options={legalEntityOptions}
									orientation="row"
									required
								/>
							</Grid>
							{values.legalEntity !== null && renderCommonFields}
						</>
					)}
				</>
			)}
			{(values.type !== BusinessPartnerType.SALESMAN)
				&& values.legalEntity !== null && (
				<>
					{renderCommonFields}
					{values.type === BusinessPartnerType.CLIENT && (
						<>
							<Grid item xs={4}>
								<Select
									name="businessPartnerId"
									label="Vendedor"
									options={salesmans || []}
									labelKey="name"
									valueKey="id"
									required
								/>
							</Grid>
							<Grid item xs={2}>
								<Input.FinancialInputField
									id="creditValue"
									name="creditValue"
									label="Valor de Crédito"
								/>
							</Grid>
							<Grid item xs={2}>
								<DatePicker name="creditDate" label="Data de Crédito" />
							</Grid>
							<Grid item xs={12}>
								<SwitchField
									name="groupCredit"
									label="Crédito em Grupo"
								/>
							</Grid>
							<Grid item xs={12}>
								<RadioField
									name="creditRisk"
									label="Risco de Crédito"
									options={creditRiskTypeOptions}
									orientation="row"
								/>
							</Grid>
						</>
					)}
				</>
			)}
			{(values.type === BusinessPartnerType.SALESMAN) && values.contractType !== undefined && (
				<Grid item xs={8}>
					<Autocomplete<{ name: string; id: string; login: string }>
						label="Selecione o usuário do sistema"
						name="userId"
						options={users || []}
						labelKey="name"
						valueKey="id"
						valueLabel="login"
						required
					/>
				</Grid>
			)}
		</Grid>
	);
};

export default BPForm;

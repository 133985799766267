import React, {
	useCallback, useMemo, useState,
} from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SwapHorizontalCircleOutlinedIcon from '@mui/icons-material/SwapHorizontalCircleOutlined';
import FilterListIcon from '@mui/icons-material/FilterList';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import omit from 'lodash/omit';
import Box from '@mui/material/Box';
import useTheme from '@mui/material/styles/useTheme';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import { Loading } from '../Common/Loading';
import DrawerFilter from '../Common/DrawerFilter';
import { PageHeader } from '../Common/PageHeader/PageHeader';
import InventoryTaskInvoiceTable from './InventoryTaskInvoiceTable';
import TransferProductModal from './Modals/TransferProductModal';
import InventoryTaskInvoiceFilter from './InventoryTaskInvoiceFilter';
import DefaultDashboard from '../Common/Dashboard/DefaultDashboard';
import useDataGridManagement from '../../hooks/useDataGridManagement';
import { CardDefinition, SectionDefinition, useDashboard } from '../../hooks/useDashboard';
import { PageHeaderButtonProps } from '../../interfaces/PageHeaderInterface';
import { ProductQueryParams } from '../../interfaces/ProductQueryParams';
import {
	InventoryTaskInvoiceQueryParams,
	InventoryTaskTransferProductData,
	InventoryTaskTransferProductParams,
	ReleaseTasksParams,
	RemoveTasksParams,
	ReprocessTasksParams,
} from '../../interfaces/InventoryTaskInvoiceQueryParams';
import {
	ILocationProductWithoutProduct,
	LocationProductWithoutProductQueryParams,
} from '../../interfaces/LocationProduct';
import {
	IInventoryTaskInvoice,
	InventoryTask,
	InvoiceStatusCountResponse,
} from '../../containers/InventoryTaskInvoice/InventoryTaskInvoiceAssets';
import {
	ILocationProductData,
	IProductWithoutDetails,
} from '../../containers/Product/ProductAssets';
import { InventoryTaskStatus } from '../../enums/InventoryTaskStatus';
import { getStatusEnum } from '../../helpers/StatusCount';
import {
	getInventoryTaskDashboardSections,
	getInventoryTaskHeaderStatusCards,
} from '../../helpers/dashboardConfig';
import { InventoryTaskInvoiceType } from '../../enums/InventoryTaskInvoiceType';
import { InventoryTaskStatusHeader } from '../../enums/InventoryTaskStatusHeader';
import { getViewTitle } from '../../helpers/inventoryTaskInvoiceHelper';

interface InventoryTaskProps {
  loading: boolean;
  inventoryTaskInvoices: IInventoryTaskInvoice[];
  inventoryTaskInvoicesPages: number;
  inventoryTaskInvoicesPage: number;
  inventoryTaskInvoicesTake: number;
  products: IProductWithoutDetails[];
  productLocations: ILocationProductData[];
  locationWithoutProducts: ILocationProductWithoutProduct[];
  statusCount: InvoiceStatusCountResponse;
  getInventoryTaskInvoice: (queryParams: InventoryTaskInvoiceQueryParams) => void;
  getInventoryTaskInvoiceStatusCount: () => void;
  releaseTasks: (data: ReleaseTasksParams) => void;
  reprocessLocationTasks: (data: ReprocessTasksParams) => void;
  finishTask: (data: RemoveTasksParams) => void;
  getProducts: (queryParams: ProductQueryParams) => void;
  getProductLocationsData: (productId: string) => Promise<void>;
  getLocationProductWithoutProduct: (params: LocationProductWithoutProductQueryParams) => void;
  createTransferProductTask: (data: InventoryTaskTransferProductData) => void;
}

interface TaskGroup {
  tasksIds: Array<string>;
}

export interface SelectedTasks {
  [key: string]: TaskGroup;
}

const InventoryTaskInvoice = ({
	loading,
	inventoryTaskInvoices,
	inventoryTaskInvoicesPages,
	inventoryTaskInvoicesPage,
	inventoryTaskInvoicesTake,
	products,
	productLocations,
	locationWithoutProducts,
	statusCount,
	getInventoryTaskInvoice,
	getInventoryTaskInvoiceStatusCount,
	releaseTasks,
	reprocessLocationTasks,
	finishTask,
	getProducts,
	getProductLocationsData,
	getLocationProductWithoutProduct,
	createTransferProductTask,
}: InventoryTaskProps): JSX.Element => {
	const [selectedRows, setSelectedRows] = useState<SelectedTasks>({} as SelectedTasks);
	const [rowsIndex, setRowsIndex] = useState<Array<number>>([]);
	const [allSelected, setAllSelected] = useState(false);
	const [isFilterDrawerOpen, setFilterDrawerOpen] = useState(false);
	const [isTransferProductModalOpen, setIsTransferProductModalOpen] = useState(false);
	const [showTable, setShowTable] = useState(false);
	const [expandedSection, setExpandedSection] = useState<InventoryTaskInvoiceType | null>(
		InventoryTaskInvoiceType.SUPPLY,
	);
	const [currentView, setCurrentView] = useState<{
			type: InventoryTaskInvoiceType,
			status: InventoryTaskStatusHeader,
		} | null>(null);

	const theme = useTheme();

	const {
		filter,
		setFilter,
		onChangePage,
		onChangePageSize,
	} = useDataGridManagement<InventoryTaskInvoiceQueryParams>({
		initialFilterValues: {
			status: InventoryTaskStatusHeader.PENDING,
			skip: 0,
		},
		initialPageSize: inventoryTaskInvoicesTake,
		initialPage: inventoryTaskInvoicesPage,
		fetchData: (params) => {
			if (showTable) {
				getInventoryTaskInvoice(params);
			}
		},
	});

	const resetValueScreen = useCallback(() => {
		setAllSelected(false);
		setRowsIndex([]);
		setSelectedRows({} as SelectedTasks);
	}, []);

	const handlerTransferProductModal = useCallback((open: boolean) => {
		setIsTransferProductModalOpen(open);
	}, []);

	const getIdsInventoryTasksFiltered = useCallback(
		(
			allInventoryTask: InventoryTask[],
			statusProduct: InventoryTaskStatus,
		) => allInventoryTask
			.filter(
				(inventoryTask) => inventoryTask.status === statusProduct
          && Boolean(inventoryTask.locationDestinyBarCode)
          && Boolean(inventoryTask.locationOriginBarCode),
			)
			.map((taskData) => taskData.id),
		[],
	);

	const handlerReleaseTask = useCallback(() => {
		const allTasksIds: ReleaseTasksParams = {
			tasks: Object.values(selectedRows).flatMap(
				(selectedRow) => selectedRow.tasksIds,
			),
		};

		releaseTasks(allTasksIds);
		resetValueScreen();
	}, [selectedRows, releaseTasks, resetValueScreen]);

	const selectedAllPendingsRows = useCallback(() => {
		const indexs: Array<number> = [];
		const rowsSelected: SelectedTasks = {} as SelectedTasks;
		const selectedAll = !allSelected;

		if (selectedAll) {
			inventoryTaskInvoices.forEach((task, index) => {
				const eligibleTasks = task.inventoryTasks.filter(
					(inventoryTask) => inventoryTask.status === InventoryTaskStatus.PENDING
            && Boolean(inventoryTask.locationDestinyBarCode)
            && Boolean(inventoryTask.locationOriginBarCode),
				);

				if (eligibleTasks.length > 0) {
					indexs.push(index);

					rowsSelected[task.id] = {
						tasksIds: eligibleTasks.map((taskData) => taskData.id),
					};
				}
			});
		}

		setAllSelected(selectedAll);
		setRowsIndex(indexs);
		setSelectedRows(rowsSelected);
	}, [allSelected, inventoryTaskInvoices]);

	const handleSelectedRowsChange = useCallback(
		(rowIndex: number): void => {
			const task = inventoryTaskInvoices[rowIndex];
			const taskId = task?.id;

			if (taskId) {
				task.selected = !task.selected;

				setSelectedRows((prevSelectedTasks) => {
					const newSelectedTasks = { ...prevSelectedTasks };
					const eligibleTaskIds = getIdsInventoryTasksFiltered(
						task.inventoryTasks,
						InventoryTaskStatus.PENDING,
					);

					if (task.selected) {
						setRowsIndex((data) => [...data, rowIndex]);

						if (newSelectedTasks[taskId]) {
							newSelectedTasks[taskId] = {
								tasksIds: eligibleTaskIds,
							};
						} else {
							newSelectedTasks[taskId] = {
								tasksIds: eligibleTaskIds,
							};
						}
					} else {
						setRowsIndex((indexs) => indexs.filter((index) => index !== rowIndex));
						delete newSelectedTasks[taskId];
					}

					return newSelectedTasks;
				});
			}
		},
		[inventoryTaskInvoices, getIdsInventoryTasksFiltered],
	);

	const handlerChangeProduct = useCallback(
		(rowIndex: number, indexProduct: number, checked: boolean) => {
			const task = inventoryTaskInvoices[rowIndex];
			const inventoryTask = task.inventoryTasks[indexProduct];
			const taskId = task.id;
			const inventoryId = inventoryTask.id;

			if (taskId) {
				setSelectedRows((prevSelectedTasks) => {
					const newSelectedTasks = { ...prevSelectedTasks };
					const currentTasksIds = newSelectedTasks[taskId]?.tasksIds ?? [];

					if (checked) {
						newSelectedTasks[taskId] = {
							tasksIds: [...currentTasksIds, inventoryId],
						};

						const eligibleTasks = task.inventoryTasks.filter(
							(item) => item.status === InventoryTaskStatus.PENDING
                && Boolean(item.locationDestinyBarCode)
                && Boolean(item.locationOriginBarCode),
						).map((item) => item.id);

						if (newSelectedTasks[taskId]?.tasksIds.length === eligibleTasks.length) {
							// eslint-disable-next-line no-param-reassign
							task.selected = true;
							setRowsIndex((indexs) => [...indexs, rowIndex]);
						}
					} else {
						newSelectedTasks[taskId] = {
							tasksIds: currentTasksIds.filter((id) => id !== inventoryId),
						};

						task.selected = false;
						setRowsIndex((indexs) => indexs.filter((index) => index !== rowIndex));

						if (newSelectedTasks[taskId]?.tasksIds.length === 0) {
							delete newSelectedTasks[taskId];
						}
					}

					return newSelectedTasks;
				});
			}
		},
		[inventoryTaskInvoices],
	);

	const getTaskIdsByType = useCallback(
		(
			allInventoryTask: InventoryTask[],
			statusProduct: InventoryTaskStatus,
			hasLocation: boolean,
		) => allInventoryTask
			.filter((inventoryTask) => {
				if (inventoryTask.status !== statusProduct) return false;

				return Boolean(
					inventoryTask.locationDestinyBarCode
            && inventoryTask.locationOriginBarCode,
				) === hasLocation;
			})
			.map((taskData) => taskData.id),
		[],
	);

	const handlerReprocessLocation = useCallback(() => {
		const tasksIdsWithoutLocation = inventoryTaskInvoices
			.flatMap((task) => getTaskIdsByType(
				task.inventoryTasks,
				InventoryTaskStatus.PENDING,
				false,
			));

		reprocessLocationTasks({ tasks: tasksIdsWithoutLocation });
		resetValueScreen();
	}, [
		reprocessLocationTasks,
		resetValueScreen,
		inventoryTaskInvoices,
		getTaskIdsByType,
	]);

	const toggleFilterDrawer = useCallback(() => {
		setFilterDrawerOpen((value) => !value);
	}, []);

	const handlerValueFilter = useCallback(
		(filterData: Partial<InventoryTaskInvoiceQueryParams>) => {
			setFilter({
				...filterData,
			} as InventoryTaskInvoiceQueryParams);
			toggleFilterDrawer();
		},
		[setFilter, toggleFilterDrawer],
	);

	const onSubmitTransferProduct = useCallback(
		(trasferData: InventoryTaskTransferProductParams) => {
			const productQuantity = Number(trasferData.productQuantity);
			const dataTransferProduct = {
				...omit(trasferData, ['product']),
				productQuantity,
				invoiceValue: productQuantity,
				locationOriginId: trasferData.locationOriginId ?? '',
				locationDestinyId: trasferData.locationDestinyId ?? '',
				productCode: trasferData.productCode ?? '',
			};

			createTransferProductTask({ data: [dataTransferProduct] });
			setFilter({ ...filter });
			setIsTransferProductModalOpen(false);
			getInventoryTaskInvoice(filter);
		},
		[createTransferProductTask, setFilter, filter, getInventoryTaskInvoice],
	);

	const handlerFinishTask = useCallback((inventoryTasksIds: Array<string>) => {
		if (inventoryTasksIds?.length) {
			finishTask({ tasks: inventoryTasksIds });
			resetValueScreen();
		}
	}, [finishTask, resetValueScreen]);

	const handleBackToDashboard = useCallback(() => {
		setShowTable(false);
		setCurrentView(null);
		resetValueScreen();

		setFilter({
			status: InventoryTaskStatusHeader.PENDING,
			skip: 0,
		});

		getInventoryTaskInvoiceStatusCount();
	}, [getInventoryTaskInvoiceStatusCount, resetValueScreen, setFilter]);

	const handleStatusCardClick = useCallback((
		sectionId: InventoryTaskInvoiceType,
		statusKey: InventoryTaskStatusHeader,
	) => {
		const statusEnum = getStatusEnum(statusKey);

		setFilter({
			type: sectionId,
			status: statusEnum,
			skip: 0,
		});

		setCurrentView({
			type: sectionId,
			status: statusKey,
		});

		setExpandedSection(sectionId);
		setShowTable(true);
		resetValueScreen();
	}, [setFilter, resetValueScreen]);

	const statusCards: Record<number, CardDefinition<InventoryTaskStatusHeader>> = useMemo(
		() => getInventoryTaskHeaderStatusCards(theme) as Record<
		number,
		CardDefinition<InventoryTaskStatusHeader>>,
		[theme],
	);

	const dashboardSections: SectionDefinition<
	InventoryTaskInvoiceType,
	InvoiceStatusCountResponse,
	InventoryTaskStatusHeader>[] = useMemo(
		() => getInventoryTaskDashboardSections(theme) as SectionDefinition<
		InventoryTaskInvoiceType,
		InvoiceStatusCountResponse,
		InventoryTaskStatusHeader>[],
		[theme],
	);

	const dashboardConfig = useDashboard(
		statusCount,
		dashboardSections,
		statusCards,
		handleStatusCardClick,
		getInventoryTaskInvoiceStatusCount,
	);

	const inventoryTaskDashboardMemo = useMemo(() => (
		<DefaultDashboard
			sections={dashboardConfig}
			defaultExpanded={expandedSection || InventoryTaskInvoiceType.SUPPLY}
		/>
	), [expandedSection, dashboardConfig]);

	const buttonReprocessLocationEnabled = useMemo(() => inventoryTaskInvoices
		.some((task) => task.inventoryTasks
			.some((inventoryTask) => inventoryTask.status === InventoryTaskStatus.PENDING
        && (!inventoryTask.locationDestinyBarCode
			|| !inventoryTask.locationOriginBarCode))), [inventoryTaskInvoices]);

	const filterMemo = useMemo(
		() => (
			<DrawerFilter open={isFilterDrawerOpen} onClose={toggleFilterDrawer}>
				<InventoryTaskInvoiceFilter
					initialValues={filter}
					handlerValueFilter={handlerValueFilter}
					products={products}
					locationWithoutProducts={locationWithoutProducts}
					getProducts={getProducts}
					getLocationProductWithoutProduct={getLocationProductWithoutProduct}
				/>
			</DrawerFilter>
		),
		[
			isFilterDrawerOpen,
			toggleFilterDrawer,
			filter,
			handlerValueFilter,
			products,
			getProducts,
			locationWithoutProducts,
			getLocationProductWithoutProduct,
		],
	);

	const transferProductModalMemo = useMemo(
		() => (
			<TransferProductModal
				open={isTransferProductModalOpen}
				loading={loading}
				products={products}
				getProducts={getProducts}
				productLocations={productLocations}
				getProductLocationsData={getProductLocationsData}
				onClose={handlerTransferProductModal}
				onSubmit={onSubmitTransferProduct}
			/>
		),
		[
			getProducts,
			getProductLocationsData,
			handlerTransferProductModal,
			isTransferProductModalOpen,
			loading,
			onSubmitTransferProduct,
			productLocations,
			products,
		],
	);

	const tableHeaderButtons: PageHeaderButtonProps[] = useMemo(
		() => {
			const buttons: PageHeaderButtonProps[] = [
				{
					text: 'Filtros',
					variant: 'contained',
					onClick: toggleFilterDrawer,
					startIcon: <FilterListIcon />,
				},
				{
					show: Object.keys(selectedRows).length > 0,
					text: 'Liberar tarefas',
					variant: 'contained',
					onClick: handlerReleaseTask,
				},
				{
					show: inventoryTaskInvoices.some((task) => task.inventoryTasks
						.some((subTask) => subTask.status === InventoryTaskStatus.PENDING
						&& Boolean(subTask.locationDestinyBarCode)
						&& Boolean(subTask.locationOriginBarCode))),
					text: !allSelected ? 'Marcar Pendentes' : 'Desmarcar Pendentes',
					variant: 'contained',
					onClick: selectedAllPendingsRows,
					startIcon: !allSelected ? (
						<CheckBoxOutlineBlankIcon />
					) : (
						<CheckBoxIcon />
					),
				},
				{
					show: buttonReprocessLocationEnabled,
					text: 'Reprocessar Localização',
					variant: 'contained',
					color: 'warning',
					onClick: handlerReprocessLocation,
				},
			];
			if (currentView?.type === InventoryTaskInvoiceType.MOVE
				&& currentView?.status === InventoryTaskStatusHeader.PENDING) {
				buttons.push({
					text: 'Transferir',
					variant: 'contained',
					onClick: () => handlerTransferProductModal(true),
					startIcon: <SwapHorizontalCircleOutlinedIcon />,
				} as PageHeaderButtonProps);
			}

			return buttons;
		},
		[
			toggleFilterDrawer,
			selectedRows,
			handlerReleaseTask,
			inventoryTaskInvoices,
			allSelected,
			selectedAllPendingsRows,
			buttonReprocessLocationEnabled,
			handlerReprocessLocation,
			currentView,
			handlerTransferProductModal,
		],
	);

	const viewData = useMemo(
		() => getViewTitle(currentView, { color: 'primary', fontSize: 'large' }),
		[currentView],
	);

	return (
		<Box className="content" sx={{ overflowY: 'hidden' }}>
			<PageHeader
				title={viewData.title}
				icon={viewData.icon}
				buttons={
					showTable
						? [
							{
								text: 'Voltar',
								onClick: handleBackToDashboard,
								startIcon: <ArrowBackIcon />,
								variant: 'outlined',
							},
							...tableHeaderButtons,
						]
						: []
				}
			/>
			{loading && !isTransferProductModalOpen && <Loading />}
			{filterMemo}
			{transferProductModalMemo}
			<Fade in={!showTable} unmountOnExit>
				<Box>
					{inventoryTaskDashboardMemo}
				</Box>
			</Fade>
			<Fade in={showTable} unmountOnExit>
				<Paper sx={{ p: 2, boxShadow: () => theme.shadows[2] }}>
					<InventoryTaskInvoiceTable
						rows={inventoryTaskInvoices}
						filter={filter}
						loading={loading}
						tasksPages={inventoryTaskInvoicesPages}
						tasksPage={inventoryTaskInvoicesPage}
						tasksTake={inventoryTaskInvoicesTake}
						selectedRows={selectedRows}
						rowsIndex={rowsIndex}
						handlerFinishTask={handlerFinishTask}
						onChangePageSize={onChangePageSize}
						onChangePage={onChangePage}
						handleSelectedRowsChange={handleSelectedRowsChange}
						handlerChangeProduct={handlerChangeProduct}
					/>
				</Paper>
			</Fade>
		</Box>
	);
};

export default InventoryTaskInvoice;

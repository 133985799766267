import { SaveTaskType } from '../../InventoryTaskInvoice/InventoryTaskInvoiceAssets';

export enum StepsConsumption {
    START,
    CONSUMPTION_LIST,
	CONSUMPTION_ITEMS,
    SIGNATURE
}

export interface SaveConsumptionCountBody {
    signatureBase64?: string;
    tasks: SaveTaskType[]
}

import React, { useEffect, useState } from 'react';
import { ChevronRight, Search } from '@mui/icons-material';
import {
	Box,
	IconButton,
	Paper,
	Stack,
	Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { useFormikContext } from 'formik';
import Input from '../../Common/Form/Input';
import { ConsumptionForm } from './Consumption';
import { ConsumptionProductionTask, IConsumption } from '../../../containers/Consumption/ConsumptionAssets';
import { ConsumptionType } from '../../../enums/ConsumptionType';
import Button from '../../Common/Button/Button';

interface ConsumptionListProps {
	handleSelectedConsumption: (taskId: ConsumptionProductionTask[]) => void;
	consumptions: IConsumption[];
	handleBack(): void;
	type?: ConsumptionType;
}

const ConsumptionList = ({
	handleSelectedConsumption,
	consumptions,
	handleBack,
	type,
}: ConsumptionListProps): JSX.Element => {
	const [filteredConsumptions, setFilteredConsumptions] = useState<IConsumption[]>(consumptions);
	const { values } = useFormikContext<ConsumptionForm>();

	useEffect(() => {
		if (values.consumptionCode) {
			const searchTerm = values.consumptionCode.toString().toLowerCase();
			const filtered = consumptions.filter((
				consumption,
			) => consumption.code.toString().toLowerCase().includes(searchTerm));
			setFilteredConsumptions(filtered);
		} else {
			setFilteredConsumptions(consumptions);
		}
	}, [values.consumptionCode, consumptions]);

	return (
		<Box>
			<Typography variant="h6" align="center">Consumo</Typography>
			{consumptions.length > 0 && (
				<Typography align="center">
					{type === ConsumptionType.REQUISITION
						? 'Requisições Pendentes'
						: 'Devoluções Pendentes'}
				</Typography>
			)}
			{consumptions.length === 0 && (
				<Typography align="center">
					{`Não há ${type === ConsumptionType.REQUISITION
						? 'requisições'
						: 'devoluções'} pendentes`}
				</Typography>
			)}
			{consumptions.length > 0 && (
				<Paper sx={{ boxShadow: 'none', marginY: 2 }}>
					<Input.InputField
						id="consumptionCode"
						name="consumptionCode"
						label="Código do Consumo"
						autoComplete="off"
						InputProps={{
							endAdornment: <IconButton color="primary" aria-label="search location"><Search /></IconButton>,
							inputProps: {
								min: 0,
								step: 'any',
							},
						}}
						fullWidth
						autoFocus
						required
					/>
				</Paper>
			)}
			{filteredConsumptions.map((consumption) =>	(
				<Box
					key={consumption.id}
					sx={{
						padding: 2,
						border: 1,
						borderColor: '#bbb',
						bgcolor: 'white',
						borderRadius: 2,
						mb: 2,
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						cursor: 'pointer',
					}}
					onClick={() => handleSelectedConsumption(consumption.tasks)}
				>
					<Stack direction="column">
						<Typography fontWeight="bold">
							{`Código: ${consumption.code}`}
						</Typography>
						<Typography>
							Data:
							{' '}
							{format(new Date(consumption.createdAt), 'dd/MM/yyyy')}
						</Typography>
						<Typography>
							Solicitante:
							{' '}
							{consumption.operatorName}
						</Typography>
					</Stack>
					<ChevronRight color="primary" />
				</Box>
			))}
			<Button variant="contained" sx={{ mt: 4 }} onClick={handleBack}>
				Voltar
			</Button>
		</Box>
	);
};

ConsumptionList.defaultProps = {
	type: undefined,
};

export default ConsumptionList;

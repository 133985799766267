/* eslint-disable react/jsx-props-no-spreading */
import React, {
	useCallback,
	useState,
} from 'react';
import {
	Form, FormikContext, useFormik,
} from 'formik';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Input from '../Common/Form/Input';
import { InventoryTaskInvoiceQueryParams } from '../../interfaces/InventoryTaskInvoiceQueryParams';
import { filterValidationSchema } from '../../constants/inventoryTask';
import { DatePicker } from '../Common/Form/DatePicker';
import { filterObject } from '../../helpers/Utils';
import Autocomplete from '../Common/Form/Autocomplete';
import { IProductWithoutDetails } from '../../containers/Product/ProductAssets';
import { ProductQueryParams } from '../../interfaces/ProductQueryParams';
import { useConditionalSearch } from '../../hooks/useConditionalSearchProps';
import { ILocationProductWithoutProduct, LocationProductWithoutProductQueryParams } from '../../interfaces/LocationProduct';

interface FilterProps {
	handlerValueFilter: (values: Partial<InventoryTaskInvoiceQueryParams>) => void;
    initialValues: InventoryTaskInvoiceQueryParams
	products: IProductWithoutDetails[];
	locationWithoutProducts: ILocationProductWithoutProduct[];
	getProducts: (queryParams: ProductQueryParams) => void;
	getLocationProductWithoutProduct: (params: LocationProductWithoutProductQueryParams) => void;
}

const InventoryPendingTaskFilter = ({
	initialValues,
	products,
	locationWithoutProducts,
	handlerValueFilter,
	getProducts,
	getLocationProductWithoutProduct,
}: FilterProps): JSX.Element => {
	const [productInputValue, setProductInputValue] = useState('');
	const [locationProductInputValue, setLocationProductInputValue] = useState('');

	const onSubmit = useCallback((values: Partial<InventoryTaskInvoiceQueryParams>) => {
		handlerValueFilter(filterObject(values));
	}, [handlerValueFilter]);

	useConditionalSearch({
		inputValue: productInputValue,
		loadedItems: products,
		searchFunction: getProducts,
		minLength: 3,
		compareKey: 'description',
		compareKeySecondary: 'code',
		queryParam: 'description',
	});

	useConditionalSearch({
		inputValue: locationProductInputValue,
		loadedItems: locationWithoutProducts,
		searchFunction: getLocationProductWithoutProduct,
		minLength: 3,
		compareKey: 'locationBarCode',
		queryParam: 'locationBarCode',
	});

	const formik = useFormik({
		validationSchema: filterValidationSchema,
		validateOnChange: false,
		initialValues,
		onSubmit,
		validateOnMount: true,
	});

	const onReset = useCallback((resetForm) => {
		const preservedParams = {
			skip: 0,
			type: initialValues?.type,
			status: initialValues?.status,
		};
		handlerValueFilter(preservedParams);
		resetForm();
	}, [handlerValueFilter, initialValues?.status, initialValues?.type]);

	return (
		<FormikContext.Provider value={formik}>
			<Form>
				<Grid container spacing={2} sx={{ p: 2, marginTop: 6 }}>
					<Grid container spacing={2} item xs={12}>
						<Grid item xs={6}>
							<DatePicker
								name="startDate"
								label="Data início"
							/>
						</Grid>

						<Grid item xs={6}>
							<DatePicker
								name="endDate"
								label="Data Final"
							/>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Input.InputField
							id="businessPartnerName"
							name="businessPartnerName"
							label="Fornecedor/Cliente"
							autoComplete="off"
							fullWidth
						/>
					</Grid>
					<Grid item xs={12}>
						<Input.InputField
							id="saleOrder"
							name="saleOrder"
							label="Nº Pedido"
							autoComplete="off"
							fullWidth
						/>
					</Grid>
					<Grid item xs={12}>
						<Input.InputField
							id="invoiceNumber"
							name="invoiceNumber"
							label="Nº Documento"
							autoComplete="off"
							fullWidth
						/>
					</Grid>
					<Grid item xs={12}>
						<Autocomplete
							multiple
							limitTags={2}
							name="productIds"
							label="Produto"
							options={products}
							labelKey="description"
							valueKey="id"
							valueLabel="code"
							onInputChange={(event, newInputValue) => setProductInputValue(newInputValue)}
						/>
					</Grid>
					<Grid item xs={12}>
						<Autocomplete
							multiple
							limitTags={2}
							name="locationIds"
							label="Localização"
							options={locationWithoutProducts}
							labelKey="locationName"
							valueLabel="locationBarCode"
							valueKey="locationId"
							formatBarCode
							onInputChange={
								(event, newInputValue) => setLocationProductInputValue(newInputValue)
							}
						/>
					</Grid>
					<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
						<Button variant="outlined" onClick={() => onReset(() => formik.resetForm())}>
							Restaurar
						</Button>
						<Button variant="contained" type="submit">
							Filtrar
						</Button>
					</Grid>
				</Grid>
			</Form>
		</FormikContext.Provider>
	);
};

export default InventoryPendingTaskFilter;

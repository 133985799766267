import React from 'react';
import {
	Box,
	Typography,
	Stack,
} from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { Loading } from '../../Common/Loading';
import { ISaleOrderLocations } from '../../../containers/Mobile/Expedition/ExpeditionAssets';
import { GroupedTasks } from './Expedition';
import Button from '../../Common/Button/Button';

interface ExpeditionStartProps {
	handleLocationStep: (order: ISaleOrderLocations) => void;
	handleBackStart: () => void;
	loading: boolean;
	groupedTasks: GroupedTasks | null;
}

const ExpeditionStart = ({
	loading,
	handleBackStart,
	handleLocationStep,
	groupedTasks,
}: ExpeditionStartProps): JSX.Element => {
	if (loading) {
		return <Loading />;
	}

	if (!loading && groupedTasks && Object.values(groupedTasks).length === 0) {
		return (
			<Box sx={{ mt: 2 }}>
				<Typography textAlign="center">Não há pedidos pendentes.</Typography>
				<Button sx={{ mt: 4, width: '100%' }} size="large" variant="contained" onClick={handleBackStart}>
					Voltar para a tela inicial
				</Button>
			</Box>
		);
	}

	return (
		<Box padding={1}>
			<Typography variant="h6" align="center">Expedição</Typography>
			<Typography variant="subtitle1" align="center" marginBottom={2}>Selecione o Pedido de Venda</Typography>
			<Typography variant="overline">Pedidos de Venda:</Typography>
			{groupedTasks && Object.values(groupedTasks).map((order) => (
				<Box
					key={order.saleOrder}
					sx={{
						padding: 2,
						border: 1,
						borderColor: '#bbb',
						bgcolor: 'white',
						borderRadius: 2,
						mb: 2,
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
					onClick={() => handleLocationStep(order)}
				>
					<Stack direction="column">
						<Typography fontWeight="bold">{order.saleOrder}</Typography>
						<Typography>{`${order.locations ? Object.values(order.locations).reduce((acc, loc) => acc + loc.tasks.length, 0) : 0} Produto(s) para picking`}</Typography>
						<Typography>{`${Object.keys(order.locations).length} Localização(ões)`}</Typography>
					</Stack>
					<ChevronRight color="primary" />
				</Box>
			))}
		</Box>
	);
};

export default ExpeditionStart;

import React, {
	Dispatch,
	Reducer,
	useMemo,
	useReducer,
} from 'react';
import {
	OptionsObject,
	SnackbarKey,
	SnackbarMessage,
	useSnackbar,
} from 'notistack';
import { AxiosError } from 'axios';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import BusinessPartnerPresentational from '../../components/BusinessPartner/BusinessPartner';
import {
	getBusinessPartners,
	approveBusinessPartner,
	deleteBusinessPartner,
	handleBusinessPartnerActiveStatus,
	getBusinessPartnersAdmin,
	getBusinessPartnersSalesAdmin,
} from '../../services/businessPartner';
import { IBusinessPartner } from './BusinessPartnerAssets';
import { BusinessPartnerQueryParams } from '../../interfaces/BusinessPartnerQueryParams';
import { ICountry, getCountries } from '../../services/country';
import { IRegionState, getStates } from '../../services/state';
import { ICity, getCities } from '../../services/city';
import { removeDuplicates } from '../../helpers/Utils';
import usePermission from '../../hooks/usePermission';
import { IModule } from '../../interfaces/Module';

enum ActionType {
	LOADING,
	BUSINESSPARTNERS,
	UPDATE_BUSINESSPARTNER,
	COUNTRY,
	STATE,
	CITY,
}

interface IState {
	loading: boolean;
	count: number;
	businessPartners: IBusinessPartner[];
	countries: ICountry[];
	states: IRegionState[];
	cities: ICity[];
	businessPartnersPages: number;
	businessPartnersPage: number;
	businessPartnersTake: number;
}

type TAction =
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| {
		type: ActionType.BUSINESSPARTNERS; payload: {
			businessPartners: IBusinessPartner[],
			count: number,
			businessPartnersPages: number,
			businessPartnersPage: number,
			businessPartnersTake: number,
		}
	}
	| { type: ActionType.COUNTRY; payload: { countries: ICountry[] } }
	| { type: ActionType.STATE; payload: { states: IRegionState[] } }
	| { type: ActionType.CITY; payload: { cities: ICity[] } }
	| { type: ActionType.UPDATE_BUSINESSPARTNER; payload: { businessPartner: IBusinessPartner } };

interface IBusinessPartnerActions {
	setLoading(loading: boolean): void;
	getBusinessPartners(params: BusinessPartnerQueryParams): void;
	handleEdit(id: string): void;
	handleDeleteBusinessPartner(id: string): void;
	approveBusinessPartner(id: string): void;
	getCountries(): void;
	getStates(): void;
	getCities(): void;
	handleBusinessPartnerActiveStatus(id: string, active: boolean): void;
}

const initialState: IState = {
	loading: false,
	businessPartners: [],
	countries: [],
	states: [],
	cities: [],
	count: 0,
	businessPartnersPages: 0,
	businessPartnersPage: 0,
	businessPartnersTake: 10,
};

const reducer: React.Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return {
				...state,
				loading: action.payload.loading,
			};
		case ActionType.BUSINESSPARTNERS:
			return {
				...state,
				businessPartners: action.payload.businessPartners,
				count: action.payload.count,
				businessPartnersPages: action.payload.businessPartnersPages,
				businessPartnersPage: action.payload.businessPartnersPage,
				businessPartnersTake: action.payload.businessPartnersTake,
			};
		case ActionType.COUNTRY: {
			const newCountries = action.payload.countries;
			const updatedCountries = removeDuplicates([...state.countries, ...newCountries], 'id');
			return { ...state, countries: updatedCountries };
		}
		case ActionType.STATE:
			return { ...state, states: action.payload.states };
		case ActionType.CITY: {
			const newCities = action.payload.cities;
			const updatedCities = removeDuplicates([...state.cities, ...newCities], 'id');
			return { ...state, cities: updatedCities };
		}
		case ActionType.UPDATE_BUSINESSPARTNER:
			return {
				...state,
				businessPartners: state.businessPartners.map((
					businessPartner,
				) => (businessPartner.id === action.payload.businessPartner.id
					? action.payload.businessPartner : businessPartner)),
			};
		default:
			throw new Error();
	}
};

const BusinessPartnerActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
	navigate: NavigateFunction,
	permissionAdmin: IModule | undefined,
	permissionSales: IModule | undefined,
): IBusinessPartnerActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		getBusinessPartners(queryParams: BusinessPartnerQueryParams) {
			actions.setLoading(true);
			const take = queryParams.take ?? 10;
			const params = { ...queryParams, skip: queryParams.skip * take };

			let fetchBusinessPartners;

			const isAdmin = (permission?: IModule): boolean => permission?.isAdmin === true;

			switch (true) {
				case isAdmin(permissionAdmin):
					fetchBusinessPartners = getBusinessPartnersAdmin;
					break;
				case isAdmin(permissionSales):
					fetchBusinessPartners = getBusinessPartnersSalesAdmin;
					break;
				default:
					fetchBusinessPartners = getBusinessPartners;
					break;
			}
			fetchBusinessPartners(params)
				.then((response) => {
					dispatch({
						type: ActionType.BUSINESSPARTNERS,
						payload: {
							businessPartners: response.data.data,
							count: response.data.count,
							businessPartnersPages: response.data.count,
							businessPartnersPage: queryParams.skip,
							businessPartnersTake: take,
						},
					});
				})
				.catch(() => {
					enqueueSnackbar('Erro ao carregar a lista de parceiros de negócios.', {
						variant: 'error',
					});
				})
				.finally(() => {
					actions.setLoading(false);
				});
		},
		handleEdit(id: string) {
			navigate(`/edit/${id}`);
		},
		handleDeleteBusinessPartner(id: string) {
			actions.setLoading(true);
			deleteBusinessPartner(id)
				.then((response) => {
					enqueueSnackbar(response?.data.message, {
						variant: 'success',
					});
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
						variant: 'error',
					});
				})
				.finally(() => {
					actions.setLoading(false);
					actions.getBusinessPartners({ skip: 0 });
				});
		},
		approveBusinessPartner(id: string) {
			actions.setLoading(true);
			approveBusinessPartner(id).then(() => {
				actions.setLoading(false);
			});
		},
		getCountries(searchQuery?: string) {
			getCountries(searchQuery).then((response) => {
				dispatch({
					type: ActionType.COUNTRY,
					payload: {
						countries: response.data.data,
					},
				});
			});
		},
		getStates() {
			getStates().then((response) => {
				dispatch({
					type: ActionType.STATE,
					payload: {
						states: response.data.data,
					},
				});
			});
		},
		getCities(searchQuery?: string) {
			getCities(searchQuery).then((response) => {
				dispatch({
					type: ActionType.CITY,
					payload: {
						cities: response.data.data,
					},
				});
			});
		},
		handleBusinessPartnerActiveStatus(id: string, active: boolean): void {
			actions.setLoading(true);
			handleBusinessPartnerActiveStatus(id, active)
				.then((response) => {
					enqueueSnackbar(response.data.message, { variant: 'success' });
					actions.setLoading(false);
					dispatch({
						type: ActionType.UPDATE_BUSINESSPARTNER,
						payload: { businessPartner: response.data.data },
					});
				})
				.catch(() => {
					actions.setLoading(false);
					throw new Error('Ocorreu um erro ao atualizar o status do parceiro de negócios.');
				});
		},
	};

	return actions;
};

const BusinessPartner = (): JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(reducer, initialState);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const permissionAdmin = usePermission('ACCESS_GROUP');
	const permissionSales = usePermission('BUSINESS_PARTNER');

	const actions = useMemo(
		() => BusinessPartnerActions(
			dispatch,
			enqueueSnackbar,
			navigate,
			permissionAdmin,
			permissionSales,
		),
		[enqueueSnackbar, navigate, permissionAdmin, permissionSales],
	);

	// eslint-disable-next-line react/jsx-props-no-spreading
	return <BusinessPartnerPresentational {...state} {...actions} />;
};

export default React.memo(BusinessPartner);

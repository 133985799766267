import React from 'react';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from '@mui/material';
import {
	Form,
	FormikContext,
	useFormik,
} from 'formik';
import { LoadingButton } from '@mui/lab';
import { DatePicker } from '../../Common/Form/DatePicker';

interface ModalEditInventoryDateProps {
    openModalDateInventERP: boolean;
    onClose: () => void;
	inventoryId: string;
	dateInventERP: Date | string | null;
	handleChangeDateInventERP: (inventoryId: string, dateInventERP: Date | string | null) => void;
}

const ModalEditInventoryDate = ({
	openModalDateInventERP,
	onClose,
	dateInventERP,
	handleChangeDateInventERP,
	inventoryId,
}: ModalEditInventoryDateProps): JSX.Element => {
	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			dateInventERP,
		},
		onSubmit: (values) => {
			handleChangeDateInventERP(inventoryId, values.dateInventERP);
		},
	});

	return (
		<FormikContext.Provider value={formik}>
			<Dialog open={openModalDateInventERP}>
				<Form>
					<DialogTitle>
						Editar Data do Inventário ERP
					</DialogTitle>
					<DialogContent>
						<Box mt={2}>
							<DatePicker
								name="dateInventERP"
								label="Data Invent. ERP"
								required
							/>
						</Box>
					</DialogContent>
					<DialogActions sx={{ gap: 1 }}>
						<Button onClick={onClose}>Cancelar</Button>
						<LoadingButton
							type="submit"
							variant="contained"
							disabled={!formik.values.dateInventERP}
							onClick={onClose}
						>
							Confirmar
						</LoadingButton>
					</DialogActions>
				</Form>
			</Dialog>
		</FormikContext.Provider>
	);
};

export default ModalEditInventoryDate;

import React, {
	useMemo, useState, useCallback, useEffect,
} from 'react';
import {
	styled,
	useTheme,
	Theme,
	SxProps,
	CSSObject,
} from '@mui/material/styles';
import { Link, useLocation } from 'react-router-dom';
import MuiDrawer, { DrawerProps } from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import Tooltip from '@mui/material/Tooltip';
import StorageIcon from '@mui/icons-material/Storage';
import PieChartIcon from '@mui/icons-material/PieChart';
import SettingsIcon from '@mui/icons-material/Settings';
import AppsIcon from '@mui/icons-material/Apps';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DrawerHeader from './DrawerHeader';
import {
	drawerWidth,
	moduleGroups,
} from '../../constants/menu';
import { hasPermission } from '../../helpers/permission';
import { useNavigationContext } from '../../contexts/NavigationContext';
import useModuleManager from '../../hooks/useModuleManager';

interface DrawerMenuProps {
  open: boolean;
  toggleDrawer(): void;
}

interface DrawerOpenProps extends DrawerProps {
  open?: boolean;
}

const listItemTextStyle: SxProps<Theme> = {
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
};

const openedMixin = (theme: Theme): CSSObject => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up('sm')]: {
		width: `calc(${theme.spacing(8)} + 1px)`,
	},
});

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== 'open',
})<DrawerOpenProps>(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: 'nowrap',
	boxSizing: 'border-box',
	...(open && {
		...openedMixin(theme),
		'& .MuiDrawer-paper': openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		'& .MuiDrawer-paper': closedMixin(theme),
	}),
}));

const sxListItemButton: SxProps<Theme> = {
	minHeight: 48,
	px: 2.5,
	'& > *': {
		transition: '200ms ease all',
	},
	justifyContent: 'initial',
	'&:hover > *:not(.MuiTouchRipple-root)': {
		transform: 'translateX(10px)',
	},
};

const sxClosedListItemButton: SxProps<Theme> = {
	...sxListItemButton,
	justifyContent: 'center',
	'&:hover > *:not(.MuiTouchRipple-root)': {
		transform: 'translateX(2.5px)',
	},
};

const sxCategoryButton: SxProps<Theme> = {
	...sxListItemButton,
	'&:hover': {
		bgcolor: 'rgba(0, 0, 0, 0.08)',
	},
};

const sxClosedCategoryButton: SxProps<Theme> = {
	...sxClosedListItemButton,
	'&:hover': {
		bgcolor: 'rgba(0, 0, 0, 0.08)',
	},
};

const sxNestedItem: SxProps<Theme> = {
	pl: 4,
};

const DrawerMenu = ({ open, toggleDrawer }: DrawerMenuProps): JSX.Element => {
	const theme = useTheme();
	const location = useLocation();
	const [openApps, setOpenApps] = useState(false);
	const [openAdmin, setOpenAdmin] = useState(false);
	const { expandedSection, syncNavigationState } = useNavigationContext();
	const {
		accessFlags,
		getGroupModules,
		expandedGroups,
		toggleGroup,
		adminModulesList,
	} = useModuleManager();

	useEffect(() => {
		syncNavigationState(location.pathname);
	}, [location.pathname, syncNavigationState]);

	const handleAppsClick = useCallback((): void => {
		setOpenApps((prev) => {
			if (!open && !prev) {
				toggleDrawer();
			}
			return !prev;
		});
	}, [open, toggleDrawer]);

	const handleAdminClick = useCallback((): void => {
		setOpenAdmin((prev) => {
			if (!open && !prev) {
				toggleDrawer();
			}
			return !prev;
		});
	}, [open, toggleDrawer]);

	const createGroupMenuItems = useCallback((
		groupModules: string[],
	) => getGroupModules(groupModules).map((module) => (
		<ListItemButton
			key={module.name}
			sx={{
				...sxListItemButton,
				...(open && { pl: openApps ? 6 : 4 }),
			}}
			component={Link}
			to={module.link}
			selected={location.pathname.split('/')[1] === module.link}
		>
			<ListItemIcon
				sx={{
					minWidth: 0,
					mr: open ? 3 : 'auto',
					justifyContent: 'center',
				}}
			>
				<Icon>{module.icon}</Icon>
			</ListItemIcon>
			<ListItemText
				primary={module.name}
				sx={{
					opacity: open ? 1 : 0,
					...listItemTextStyle,
				}}
			/>
		</ListItemButton>
	)), [getGroupModules, location.pathname, open, openApps]);

	const adminMenuItems = useMemo(
		() => adminModulesList.map((module) => (
			<ListItemButton
				key={module.name}
				sx={{ ...sxListItemButton, ...(open && sxNestedItem) }}
				component={Link}
				to={module.link}
				selected={location.pathname.split('/')[1] === module.link}
			>
				<ListItemIcon
					sx={{
						minWidth: 0,
						mr: open ? 3 : 'auto',
						justifyContent: 'center',
					}}
				>
					<Icon>{module.icon}</Icon>
				</ListItemIcon>
				<ListItemText
					primary={module.name}
					sx={{
						opacity: open ? 1 : 0,
						...listItemTextStyle,
					}}
				/>
			</ListItemButton>
		)),
		[adminModulesList, location.pathname, open],
	);

	const activeSections = useMemo(() => ({
		isAdminSection: expandedSection === 'admin',
		isRegularSection: expandedSection === 'applications',
		isHomeSection: location.pathname === '/' && !expandedSection,
	}), [expandedSection, location.pathname]);

	return (
		<Drawer variant="permanent" open={open}>
			<DrawerHeader>
				<IconButton onClick={toggleDrawer}>
					{theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
				</IconButton>
			</DrawerHeader>
			<Divider />
			<List component="nav">
				<ListItemButton
					onClick={handleAppsClick}
					sx={open ? sxCategoryButton : sxClosedCategoryButton}
					selected={activeSections.isRegularSection}
				>
					<Tooltip
						title="SuItes"
						placement="right"
					>
						<ListItemIcon
							sx={{
								minWidth: 0,
								mr: open ? 3 : 'auto',
								justifyContent: 'center',
							}}
						>
							<AppsIcon color="primary" />
						</ListItemIcon>
					</Tooltip>
					<ListItemText
						primary="Suites"
						sx={{
							opacity: open ? 1 : 0,
							...listItemTextStyle,
						}}
					/>
					{open && (openApps ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
				</ListItemButton>
				<Collapse in={open && openApps} timeout="auto" unmountOnExit>
					<List component="div" disablePadding>
						{accessFlags.masterData && (
							<>
								<ListItemButton
									onClick={() => toggleGroup('masterData')}
									sx={{ ...sxListItemButton, ...(open && sxNestedItem) }}
								>
									<ListItemIcon
										sx={{
											minWidth: 0,
											mr: open ? 3 : 'auto',
											justifyContent: 'center',
										}}
									>
										<StorageIcon color="primary" />
									</ListItemIcon>
									<ListItemText
										primary="Cadastros"
										sx={{
											opacity: open ? 1 : 0,
											...listItemTextStyle,
										}}
									/>
									{open && (expandedGroups.masterData ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" />)}
								</ListItemButton>
								<Collapse in={open && expandedGroups.masterData} timeout="auto" unmountOnExit>
									<List component="div" disablePadding>
										{createGroupMenuItems(moduleGroups.masterData)}
									</List>
								</Collapse>
							</>
						)}
						{accessFlags.budget && (
							<>
								<ListItemButton
									onClick={() => toggleGroup('budget')}
									sx={{ ...sxListItemButton, ...(open && sxNestedItem) }}
								>
									<ListItemIcon
										sx={{
											minWidth: 0,
											mr: open ? 3 : 'auto',
											justifyContent: 'center',
										}}
									>
										<AccountBalanceWalletIcon color="primary" />
									</ListItemIcon>
									<ListItemText
										primary="Gestão Orçamento"
										sx={{
											opacity: open ? 1 : 0,
											...listItemTextStyle,
										}}
									/>
									{open && (expandedGroups.budget ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" />)}
								</ListItemButton>
								<Collapse in={open && expandedGroups.budget} timeout="auto" unmountOnExit>
									<List component="div" disablePadding>
										{createGroupMenuItems(moduleGroups.budget)}
										{(hasPermission({ module: 'BUDGET' }) || hasPermission({ module: 'ORDER' })) && (
											<ListItemButton
												sx={{ ...sxListItemButton, ...(open && { pl: 6 }) }}
												component={Link}
												to="/order/apportionment"
												selected={location.pathname.includes('/order/apportionment')}
											>
												<ListItemIcon
													sx={{
														minWidth: 0,
														mr: open ? 3 : 'auto',
														justifyContent: 'center',
													}}
												>
													<PieChartIcon />
												</ListItemIcon>
												<ListItemText
													primary="Gerenciar Rateios"
													sx={{
														opacity: open ? 1 : 0,
														...listItemTextStyle,
													}}
												/>
											</ListItemButton>
										)}
									</List>
								</Collapse>
							</>
						)}
						{accessFlags.warehouse && (
							<>
								<ListItemButton
									onClick={() => toggleGroup('warehouse')}
									sx={{ ...sxListItemButton, ...(open && sxNestedItem) }}
								>
									<ListItemIcon
										sx={{
											minWidth: 0,
											mr: open ? 3 : 'auto',
											justifyContent: 'center',
										}}
									>
										<WarehouseIcon color="primary" />
									</ListItemIcon>
									<ListItemText
										primary="Gestão de Armazém"
										sx={{
											opacity: open ? 1 : 0,
											...listItemTextStyle,
										}}
									/>
									{open && (expandedGroups.warehouse ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" />)}
								</ListItemButton>
								<Collapse in={open && expandedGroups.warehouse} timeout="auto" unmountOnExit>
									<List component="div" disablePadding>
										{createGroupMenuItems(moduleGroups.warehouse)}
									</List>
								</Collapse>
							</>
						)}
						{accessFlags.pricing && (
							<>
								<ListItemButton
									onClick={() => toggleGroup('pricing')}
									sx={{ ...sxListItemButton, ...(open && sxNestedItem) }}
								>
									<ListItemIcon
										sx={{
											minWidth: 0,
											mr: open ? 3 : 'auto',
											justifyContent: 'center',
										}}
									>
										<MonetizationOnIcon color="primary" />
									</ListItemIcon>
									<ListItemText
										primary="Gestão Preço e Rentabilidade"
										sx={{
											opacity: open ? 1 : 0,
											...listItemTextStyle,
										}}
									/>
									{open && (expandedGroups.pricing ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" />)}
								</ListItemButton>
								<Collapse in={open && expandedGroups.pricing} timeout="auto" unmountOnExit>
									<List component="div" disablePadding>
										{createGroupMenuItems(moduleGroups.pricing)}
									</List>
								</Collapse>
							</>
						)}
						{accessFlags.hours && (
							<>
								<ListItemButton
									onClick={() => toggleGroup('hours')}
									sx={{ ...sxListItemButton, ...(open && sxNestedItem) }}
								>
									<ListItemIcon
										sx={{
											minWidth: 0,
											mr: open ? 3 : 'auto',
											justifyContent: 'center',
										}}
									>
										<AccessTimeIcon color="primary" />
									</ListItemIcon>
									<ListItemText
										primary="Gestão de Horas"
										sx={{
											opacity: open ? 1 : 0,
											...listItemTextStyle,
										}}
									/>
									{open && (expandedGroups.hours ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" />)}
								</ListItemButton>
								<Collapse in={open && expandedGroups.hours} timeout="auto" unmountOnExit>
									<List component="div" disablePadding>
										{createGroupMenuItems(moduleGroups.hours)}
									</List>
								</Collapse>
							</>
						)}
					</List>
				</Collapse>
				{adminMenuItems.length > 0 && (
					<>
						<ListItemButton
							onClick={handleAdminClick}
							sx={open ? sxCategoryButton : sxClosedCategoryButton}
							selected={activeSections.isAdminSection}
						>
							<Tooltip
								title="Administração"
								placement="right"
							>
								<ListItemIcon
									sx={{
										minWidth: 0,
										mr: open ? 3 : 'auto',
										justifyContent: 'center',
									}}
								>
									<SettingsIcon color="primary" />
								</ListItemIcon>
							</Tooltip>
							<ListItemText
								primary="Administração"
								sx={{
									opacity: open ? 1 : 0,
									...listItemTextStyle,
								}}
							/>
							{open && (openAdmin ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
						</ListItemButton>
						<Collapse in={open && openAdmin} timeout="auto" unmountOnExit>
							<List component="div" disablePadding>
								{adminMenuItems}
							</List>
						</Collapse>
					</>
				)}
			</List>
		</Drawer>
	);
};

export default DrawerMenu;

import React, { useState, useEffect, useCallback } from 'react';
import { Typography } from '@mui/material';

export const AutoRefreshTimer = ({
	onRefresh,
}: {
    onRefresh: () => void
  }): JSX.Element => {
	const [timeUntilRefresh, setTimeUntilRefresh] = useState(60);

	useEffect(() => {
		const refreshInterval = setInterval(() => {
			onRefresh();
			setTimeUntilRefresh(60);
		}, 1000 * 60); // 1 minuto

		const countdownInterval = setInterval(() => {
			setTimeUntilRefresh((prev) => {
				if (prev <= 0) return 60;
				return prev - 1;
			});
		}, 1000);

		return () => {
			clearInterval(refreshInterval);
			clearInterval(countdownInterval);
		};
	}, [onRefresh]);

	const formatTime = useCallback((seconds: number): string => {
		const minutes = Math.floor(seconds / 60);
		const remainingSeconds = seconds % 60;
		return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
	}, []);

	return (
		<Typography>
			{`Atualização automática em: ${formatTime(timeUntilRefresh)}`}
		</Typography>
	);
};

import React, {
	Dispatch,
	Reducer,
	useMemo,
	useReducer,
} from 'react';
import {
	OptionsObject,
	SnackbarKey,
	SnackbarMessage,
	useSnackbar,
} from 'notistack';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import ConsumptionPresentational from '../../../components/Mobile/Consumption/Consumption';
import { saveTaskCountBatch } from '../../../services/inventoryTask';
import { SaveConsumptionCountBody, StepsConsumption } from './ConsumptionAssets';
import { InventoryTaskAction } from '../../../enums/InventoryTaskAction';
import { InventoryTaskStatus } from '../../../enums/InventoryTaskStatus';
import { ConsumptionProductionTaskQueryParams } from '../../../interfaces/ConsumptionProductionTaskQueryParams';
import { getConsumptionProduction } from '../../../services/consumption';
import { IConsumption, ConsumptionProductionTask } from '../../Consumption/ConsumptionAssets';
import { SaveTaskType } from '../../InventoryTaskInvoice/InventoryTaskInvoiceAssets';

enum ActionType {
	LOADING,
	CONSUMPTION_TASKS,
	SELECTED_TASKS,
	ACTIVE_STEP,
    SAVE_TASK_COUNT_BATCH,
	TASKS_COUNTED
}

interface IState {
  loading: boolean;
  consumptions: IConsumption[];
  selectedConsumptionTasks: ConsumptionProductionTask[];
  activeStep: StepsConsumption;
  tasksCounted: SaveTaskType[];
}

type TAction = {
	type: ActionType.LOADING; payload: { loading: boolean }
} | {
	type: ActionType.CONSUMPTION_TASKS; payload: { consumptions: IConsumption[] }
}| {
	type: ActionType.SELECTED_TASKS; payload: {
		selectedConsumptionTasks: ConsumptionProductionTask[] }
}| {
	type: ActionType.ACTIVE_STEP; payload: { activeStep: StepsConsumption }
}| {
	type: ActionType.SAVE_TASK_COUNT_BATCH; payload: { saveTaskCount: SaveTaskType }
}| {
	type: ActionType.TASKS_COUNTED; payload: { tasksCounted: SaveTaskType[] }
};

interface IConsumptionActions {
	setLoading(loading: boolean): void;
	getConsumptionProduction(queryParams: ConsumptionProductionTaskQueryParams): void;
	saveTempTask(data: SaveTaskType): void
	setSelectedConsumptionTasks(selectedConsumptionTasks: ConsumptionProductionTask[]): void;
	setActiveStep(step: StepsConsumption): void;
	handleBack(): void;
	saveTaskCountBatch(data: SaveConsumptionCountBody): void;
	setTasksCounted(tasksCounted: SaveTaskType[]): void;
}

const initialState: IState = {
	loading: false,
	consumptions: [],
	selectedConsumptionTasks: [],
	activeStep: StepsConsumption.START,
	tasksCounted: [],
};

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.ACTIVE_STEP:
			return { ...state, activeStep: action.payload.activeStep };
		case ActionType.SELECTED_TASKS:
			return { ...state, selectedConsumptionTasks: action.payload.selectedConsumptionTasks };
		case ActionType.CONSUMPTION_TASKS:
			return {
				...state,
				consumptions: action.payload.consumptions,
			};
		case ActionType.TASKS_COUNTED:
			return {
				...state,
				tasksCounted: action.payload.tasksCounted,
			};
		case ActionType.SAVE_TASK_COUNT_BATCH: {
			const updatedTasks = state.selectedConsumptionTasks.filter(
				(task) => task.id !== action.payload.saveTaskCount.taskId,
			);
			return {
				...state,
				selectedConsumptionTasks: updatedTasks,
				activeStep: updatedTasks.length === 0
					? StepsConsumption.SIGNATURE
					: state.activeStep,
				tasksCounted: [...state.tasksCounted, action.payload.saveTaskCount],
			};
		}
		default:
			throw new Error();
	}
};

const ConsumptionActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (
		message: SnackbarMessage,
		options?: OptionsObject | undefined
	) => SnackbarKey,
	navigate: NavigateFunction,
): IConsumptionActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		setTasksCounted(tasksCounted: SaveTaskType[]) {
			dispatch({ type: ActionType.TASKS_COUNTED, payload: { tasksCounted } });
		},
		setSelectedConsumptionTasks(selectedConsumptionTasks: ConsumptionProductionTask[]) {
			dispatch({ type: ActionType.SELECTED_TASKS, payload: { selectedConsumptionTasks } });
		},
		setActiveStep(activeStep: StepsConsumption) {
			dispatch({ type: ActionType.ACTIVE_STEP, payload: { activeStep } });
		},
		getConsumptionProduction(params: ConsumptionProductionTaskQueryParams) {
			actions.setLoading(true);
			const take = params.take ?? 10;

			getConsumptionProduction({
				skip: params.skip,
				take,
				action: InventoryTaskAction.CONSUMPTION,
				type: params.type,
			}, InventoryTaskStatus.WAITING)
				.then((response) => {
					dispatch({
						type: ActionType.CONSUMPTION_TASKS,
						payload: {
							consumptions: response.data.data,
						},
					});
				})
				.catch((error) => {
					enqueueSnackbar(
						error.response?.data.message || 'Erro ao obter informações',
						{
							variant: 'error',
						},
					);
				}).finally(() => actions.setLoading(false));
		},
		saveTempTask(data: SaveTaskType) {
			dispatch({
				type: ActionType.SAVE_TASK_COUNT_BATCH,
				payload: {
					saveTaskCount: data,
				},
			});
		},
		handleBack() {
			navigate('/app');
		},
		saveTaskCountBatch(data: SaveConsumptionCountBody) {
			actions.setLoading(true);
			saveTaskCountBatch(data)
				.then((response) => {
					if (response.data?.success_message) {
						enqueueSnackbar(response.data.success_message, {
							variant: 'success',
						});
					}
					if (response.data?.error_message) {
						enqueueSnackbar(response.data.error_message, {
							variant: 'error',
						});
					}
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
						variant: 'error',
					});
				})
				.finally(() => {
					actions.setLoading(false);
					actions.setActiveStep(StepsConsumption.START);
					actions.setSelectedConsumptionTasks([]);
					actions.setTasksCounted([]);
				});
		},
	};

	return actions;
};

const ConsumptionContainer = (): JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(
		reducer,
		initialState,
	);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();

	const actions = useMemo(
		() => ConsumptionActions(dispatch, enqueueSnackbar, navigate),
		[enqueueSnackbar, navigate],
	);

	return (
		<ConsumptionPresentational
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...state}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...actions}
		/>
	);
};

export default ConsumptionContainer;

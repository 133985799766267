import React, { useEffect } from 'react';
import { Box, Paper } from '@mui/material';
import { useFormik, Form, FormikContext } from 'formik';
import Autocomplete from '../../Common/Form/Autocomplete';
import { ICompany } from '../../../containers/Company/CompanyAssets';
import { IBranch } from '../../../containers/Branch/BranchAssets';
import { BRANCH_ID_KEY, ChangeBranchPreference, COMPANY_ID_KEY } from '../../../services/auth';
import { Loading } from '../../Common/Loading';
import Button from '../../Common/Button/Button';

interface CompanyBranchSelectionProps {
	companies: ICompany[];
	branches: IBranch[];
	getBranches: (companyId: string) => void;
	getUserCompanies: () => void;
	setBranch: (data: ChangeBranchPreference) => void;
}

const CompanyBranchSelection = ({
	companies,
	branches,
	getBranches,
	getUserCompanies,
	setBranch,
}: CompanyBranchSelectionProps): JSX.Element => {
	const initialValues = {
		branchCompanyId: localStorage.getItem(COMPANY_ID_KEY) || '',
		branchId: localStorage.getItem(BRANCH_ID_KEY) || '',
	};

	const formik = useFormik({
		initialValues,
		onSubmit: (values) => {
			setBranch({
				branchId: values.branchId,
			});
		},
	});

	useEffect(() => {
		getUserCompanies();
		if (formik.values.branchCompanyId) {
			getBranches(formik.values.branchCompanyId);
		}
	}, [formik.values.branchCompanyId, getBranches, getUserCompanies]);

	if (!companies && !branches) return <Loading />;

	return (
		<FormikContext.Provider value={formik}>
			<Form>
				<Box component={Paper} sx={{ p: 2, mt: 2, borderRadius: '8px' }}>
					<Autocomplete
						label="Empresa"
						options={companies}
						name="branchCompanyId"
						labelKey="name"
						valueKey="id"
						valueLabel="code"
						sx={{ mb: 2 }}
						closeOnSelect
						required
					/>

					<Autocomplete
						label="Filial"
						options={branches}
						name="branchId"
						labelKey="name"
						valueKey="id"
						valueLabel="code"
						sx={{ mb: 2 }}
						disabled={!formik.values.branchCompanyId}
						closeOnSelect
						required
					/>

					<Button
						variant="contained"
						fullWidth
						type="submit"
						disabled={!formik.values.branchCompanyId || !formik.values.branchId}
						sx={{
							mt: 2,
						}}
					>
						Salvar
					</Button>
				</Box>
			</Form>
		</FormikContext.Provider>
	);
};

export default CompanyBranchSelection;

import React, { useCallback, useState } from 'react';
import { Button, Grid } from '@mui/material';
import {
	Form, FormikContext, useFormik,
} from 'formik';
import Input from '../Common/Form/Input';
import Autocomplete from '../Common/Form/Autocomplete';
import { IProductWithoutDetails } from '../../containers/Product/ProductAssets';
import { LocationProductQueryParams } from '../../interfaces/LocationProduct';
import { ProductQueryParams } from '../../interfaces/ProductQueryParams';
import { formatBarCode, removeBarCodeMask } from '../../helpers/masks';
import { useConditionalSearch } from '../../hooks/useConditionalSearchProps';

interface InventoryStartFilterProps {
    sendFilter: (params: Partial<LocationProductQueryParams>) => void;
	products: IProductWithoutDetails[];
	loading: boolean;
	initialValues: Partial<LocationProductQueryParams>;
	getProducts: (queryParams: ProductQueryParams) => void;
}

const InventoryStartFilter = ({
	sendFilter,
	loading,
	initialValues,
	products,
	getProducts,
}: InventoryStartFilterProps): JSX.Element => {
	const [productInputValue, setProductInputValue] = useState('');

	useConditionalSearch({
		inputValue: productInputValue,
		loadedItems: products,
		searchFunction: getProducts,
		minLength: 2,
		compareKey: 'description',
		compareKeySecondary: 'code',
	});

	const onReset = useCallback((resetForm) => {
		sendFilter({});
		resetForm();
	}, [sendFilter]);

	const formik = useFormik({
		initialValues,
		onSubmit: (values) => {
			sendFilter({
				...values,
				locationValue: values.locationValue
					? removeBarCodeMask(values.locationValue)
					: undefined,
			});
		},
	});

	return (
		<FormikContext.Provider value={formik}>
			<Form>
				<Grid container spacing={2} sx={{ p: 2, marginTop: 6 }}>

					<Grid item xs={12}>
						<Input.InputField
							id="locationValue"
							name="locationValue"
							label="Localização"
							autoComplete="off"
							format={(value) => formatBarCode(value)}
							fullWidth
						/>
					</Grid>

					<Grid item xs={12}>
						<Autocomplete
							label="Produtos"
							name="productIds"
							valueKey="id"
							valueLabel="code"
							labelKey="description"
							options={products || []}
							loading={loading}
							onInputChange={(event, newInputValue) => setProductInputValue(newInputValue)}
							multiple
							fullWidth
						/>
					</Grid>

					<Grid
						item
						xs={12}
						sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}
					>
						<Button variant="outlined" onClick={() => onReset(formik.handleReset)}>
							Restaurar
						</Button>
						<Button variant="contained" type="submit">
							Filtrar
						</Button>
					</Grid>
				</Grid>
			</Form>
		</FormikContext.Provider>
	);
};

export default InventoryStartFilter;

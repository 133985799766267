import * as React from 'react';
import { DataGrid, GridSortDirection, GridSortModel } from '@mui/x-data-grid';
import { useCallback, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import FilterListIcon from '@mui/icons-material/FilterList';
import { PageHeader } from '../Common/PageHeader/PageHeader';
import { PageHeaderButtonProps } from '../../interfaces/PageHeaderInterface';
import DrawerFilter from '../Common/DrawerFilter';
import ProfitabilityAnalysisFilter from './ProfitabilityAnalysisFilter';
import { useManageColumns } from '../../hooks/useManageColumns';
import { ToolbarComponent } from '../Common/Datagrid/DataGridToolBar';
import { initialValuesFilter, ISaleOrderSimulation } from '../../containers/ProfitabilityAnalysis/ProfitabilityAnalysisAssets';
import { SaleOrderSimulationQueryParams } from '../../interfaces/SaleOrderSimulationQueryParams';
import useDataGridManagement from '../../hooks/useDataGridManagement';
import useConfirmationDialog from '../../hooks/useConfirmationDialog';
import { AlertTypes } from '../../enums/ConfirmationDialogType';
import { normalizeDataGridOrderBy } from '../../helpers/Utils';
import { ProfitabilityAnalysisStatus } from '../../enums/ProfitabilityAnalysisStatus';
import { profitabilityAnalysisColumns } from '../../constants/profitabilityAnalysisColumns';
import ActionJustificativeModal from '../Common/ActionJustificativeModal';
import useColumns from '../../hooks/useColumns';

const localStorageKey = 'ProfitabilityAnalysis';

const columnsVisibility = [
	'nrOrder',
	'revision',
	'emissionDate',
	'salesman',
	'client',
	'paymentTerm',
	'averagePaymentTerm',
	'locality',
	'deliveryType',
	'priceList',
	'status',
	'actions',
];

const optionalColumnsVisibility = [
	'saleForceOrder',
	'actionJustificative',
];

const initialSortModel: GridSortModel = [
	{ field: 'nrOrder', sort: 'asc' },
];

interface ProfitabilityAnalysisProps {
	loading: boolean;
	saleOrderSimulations: ISaleOrderSimulation[];
	saleOrderSimulationsPages: number;
	saleOrderSimulationsPage: number;
	saleOrderSimulationsTake: number;
	getSaleOrderSimulations(queryParams: SaleOrderSimulationQueryParams): void;
	handleApproveReject(
		id: string,
		status: ProfitabilityAnalysisStatus,
		actionJustificative?: string,
	): void;
	handleDeleteSaleOrderSimulation(id: string): void;
	handleEdit(id: string): void;
}

const ProfitabilityAnalysis = ({
	loading,
	saleOrderSimulations,
	saleOrderSimulationsPages,
	saleOrderSimulationsPage,
	saleOrderSimulationsTake,
	getSaleOrderSimulations,
	handleApproveReject,
	handleDeleteSaleOrderSimulation,
	handleEdit,
}: ProfitabilityAnalysisProps): JSX.Element => {
	const [isFilterDrawerOpen, setFilterDrawerOpen] = useState(false);
	const [
		filterValues,
		setFilterValues,
	] = useState<SaleOrderSimulationQueryParams>(initialValuesFilter);
	const [isJustificativeModalOpen, setJustificativeModalOpen] = useState(false);
	const [selectedId, setSelectedId] = useState<string | null>(null);
	const { requestConfirm, confirmationDialog } = useConfirmationDialog();

	const {
		setFilter,
		sortModel,
		onChangePage,
		onChangePageSize,
		onSortModelChange,
	} = useDataGridManagement<SaleOrderSimulationQueryParams>({
		initialSortModel,
		initialPageSize: saleOrderSimulationsTake,
		initialPage: saleOrderSimulationsPage,
		fetchData: getSaleOrderSimulations,
	});

	const sendFilter = useCallback((values: SaleOrderSimulationQueryParams) => {
		const pageFilter = {
			...values,
			skip: 0,
			orderBy: normalizeDataGridOrderBy(sortModel) as {
				code: GridSortDirection,
			},
		};
		setFilter(pageFilter);
	}, [setFilter, sortModel]);

	const handleApprove = useCallback((id: string) => {
		handleApproveReject(id, ProfitabilityAnalysisStatus.APPROVED);
	}, [handleApproveReject]);

	const handleReject = useCallback((id: string) => {
		setSelectedId(id);
		setJustificativeModalOpen(true);
	}, []);

	const handleJustificativeSubmit = useCallback((justificative: string) => {
		if (selectedId) {
			handleApproveReject(selectedId, ProfitabilityAnalysisStatus.REJECTED, justificative);
			setJustificativeModalOpen(false);
			setSelectedId(null);
		}
	}, [handleApproveReject, selectedId]);

	const handleDelete = useCallback((id: string) => {
		requestConfirm({
			description: 'Tem certeza que deseja excluir a Análise de Rentabilidade selecionada? A Ação não poderá ser desfeita!',
			alertType: AlertTypes.ERROR,
			callback: () => handleDeleteSaleOrderSimulation(id),
		});
	}, [requestConfirm, handleDeleteSaleOrderSimulation]);

	const toggleFilterDrawer = useCallback(() => {
		setFilterDrawerOpen(!isFilterDrawerOpen);
	}, [isFilterDrawerOpen]);

	const handleFilterSubmit = useCallback((
		newFilterValues: SaleOrderSimulationQueryParams,
	) => {
		setFilterValues(newFilterValues);
		setFilterDrawerOpen(false);
		sendFilter(newFilterValues);
	}, [sendFilter]);

	const countActiveFilters = useCallback((filters: Partial<SaleOrderSimulationQueryParams>): number => Object.values(filters).filter((value) => value && (typeof value !== 'string' || value.trim() !== '')).length, []);

	const shouldShowApproveReject = useCallback((rowData: any): boolean => {
		const status = typeof rowData.status === 'string' ? parseInt(rowData.status, 10) : rowData.status;
		return status === ProfitabilityAnalysisStatus.PENDING
		|| status === ProfitabilityAnalysisStatus.UNDER_ANALYSIS;
	}, []);

	const { columns, defaultVisibility } = useColumns({
		columnsVisibility,
		defaultColumnsOverride: profitabilityAnalysisColumns,
		optionalColumnsVisibility,
		onApproveClick: handleApprove,
		onRejectClick: handleReject,
		shouldShowApproveReject,
		onEditClick: handleEdit,
		onDeleteClick: handleDelete,
	});

	const {
		currentColumns,
		columnVisibilityModel,
		handleColumnVisibilityModelChange,
		onRestoreClick,
	} = useManageColumns({
		initialColumns: columns,
		initialVisibility: defaultVisibility,
		localStorageKey,
		isDraggable: true,
	});

	const headerButtonsProps = useMemo((): PageHeaderButtonProps[] => [
		{
			variant: 'contained',
			color: 'primary',
			onClick: toggleFilterDrawer,
			badgeContent: countActiveFilters(filterValues),
			text: 'Filtro',
			startIcon: <FilterListIcon />,
		},
	], [countActiveFilters, filterValues, toggleFilterDrawer]);

	const pageHeaderMemo = useMemo(() => <PageHeader title="Análise de Rentabilidade" buttons={headerButtonsProps} />, [headerButtonsProps]);

	const filterMemo = useMemo(() => (
		<DrawerFilter open={isFilterDrawerOpen} onClose={toggleFilterDrawer}>
			<ProfitabilityAnalysisFilter
				sendFilter={handleFilterSubmit}
				initialValues={filterValues}
			/>
		</DrawerFilter>
	), [filterValues, handleFilterSubmit, isFilterDrawerOpen, toggleFilterDrawer]);

	return (
		<Box className="content">
			{pageHeaderMemo}
			{filterMemo}
			<Box component={Paper} sx={{ width: '100%', mt: 2 }}>
				<DataGrid
					autoHeight
					rows={saleOrderSimulations}
					rowCount={saleOrderSimulationsPages}
					pagination
					paginationMode="server"
					sortingMode="server"
					columns={currentColumns}
					page={saleOrderSimulationsPage}
					pageSize={saleOrderSimulationsTake}
					rowsPerPageOptions={[10, 25, 50, 100]}
					sortModel={sortModel}
					onPageChange={onChangePage}
					onPageSizeChange={onChangePageSize}
					onSortModelChange={onSortModelChange}
					columnVisibilityModel={columnVisibilityModel}
					onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
					components={{ Toolbar: ToolbarComponent(onRestoreClick) }}
					disableSelectionOnClick
					loading={loading}
					experimentalFeatures={{ newEditingApi: true }}
				/>
				{confirmationDialog}
				<ActionJustificativeModal
					title="Justificativa para Reprovação"
					onConfirm={handleJustificativeSubmit}
					open={isJustificativeModalOpen}
					onClose={() => setJustificativeModalOpen(false)}
				/>
			</Box>
		</Box>
	);
};
export default ProfitabilityAnalysis;

import React, {
	createContext,
	useCallback,
	useContext,
	useMemo,
	useState,
} from 'react';
import { getMenu } from '../services/app';
import { adminModules } from '../constants/menu';
import { IMenu } from '../interfaces/Module';

interface NavigationContextType {
  expandedSection: string | null;
  syncNavigationState: (pathname: string) => void;
}

const NavigationContext = createContext<NavigationContextType | undefined>(undefined);

export const NavigationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const [expandedSection, setExpandedSection] = useState<string | null>(null);
	const menu = useMemo<IMenu[]>(() => getMenu(), []);

	const syncNavigationState = useCallback((pathname: string): void => {
		if (pathname === '/') {
			setExpandedSection(null);
			return;
		}

		const isAdmin = adminModules.some((moduleCode) => {
			const adminModule = menu.find((m) => m.code === moduleCode);
			return adminModule && pathname.startsWith(`/${adminModule.link}`);
		});

		if (isAdmin || pathname === 'admin') {
			setExpandedSection('admin');
			return;
		}

		const isRegular = menu.some(
			(module) => !adminModules.includes(module.code)
					&& module.link
					&& pathname.startsWith(`/${module.link}`),
		) || pathname.startsWith('/order/apportionment');

		if (isRegular || pathname === 'applications') {
			setExpandedSection('applications');
		}
	}, [menu]);

	const value = useMemo(() => ({
		expandedSection,
		syncNavigationState,
	}), [expandedSection, syncNavigationState]);

	return (
		<NavigationContext.Provider value={value}>
			{children}
		</NavigationContext.Provider>
	);
};

export const useNavigationContext = (): NavigationContextType => {
	const context = useContext(NavigationContext);
	if (context === undefined) {
		throw new Error('useNavigationContext deve ser usado com o NavigationProvider');
	}
	return context;
};

import React, {
	Dispatch, Reducer, useMemo, useReducer,
} from 'react';
import {
	OptionsObject,
	SnackbarKey,
	SnackbarMessage,
	useSnackbar,
} from 'notistack';
import { AxiosError } from 'axios';
import ConsumptionAndProductionTaskPresentational from '../../components/Consumption/ConsumptionTask';
import {
	ReleaseTasksParams,
	RemoveTasksParams,
	ReprocessTasksParams,
} from '../../interfaces/InventoryTaskInvoiceQueryParams';
import {
	releaseTasks,
	reprocessLocationTasks,
	finishTask,
} from '../../services/inventoryTask';
import { ProductQueryParams } from '../../interfaces/ProductQueryParams';
import {
	getProductsWithoutDetails,
} from '../../services/product';
import {
	ILocationProductData,
	IProductWithoutDetails,
} from '../Product/ProductAssets';
import { InventoryTaskAction } from '../../enums/InventoryTaskAction';
import {
	removeDuplicates,
} from '../../helpers/Utils';
import {
	getLocations,
} from '../../services/location';
import {
	ILocationProductWithoutProduct,
	LocationProductQueryParams,
} from '../../interfaces/LocationProduct';
import { ILocation } from '../Location/LocationAssets';
import {
	CreateConsumptionTasksParams, IConsumption, IProduction,
	IStatusCount,
} from './ConsumptionAssets';
import { createConsumptionTasks, getConsumptionCountByStatus, getConsumptionProduction } from '../../services/consumption';
import { ConsumptionProductionTaskQueryParams } from '../../interfaces/ConsumptionProductionTaskQueryParams';

enum ActionType {
  LOADING,
  FILTERS,
  PRODUCTS,
  PRODUCT_LOCATIONS,
  LOCATION_PRODUCT,
  LOCATION,
  CONSUMPTION,
  PRODUCTION,
  STATUS_COUNT,
}

interface IState {
  loading: boolean;
  tasksPages: number;
  tasksPage: number;
  tasksTake: number;
  products: IProductWithoutDetails[];
  productLocations: ILocationProductData[];
  locationWithoutProducts: ILocationProductWithoutProduct[];
  locations: ILocation[];
  consumptions: IConsumption[];
  productions: IProduction[];
  statusCount: IStatusCount;
}

type TAction =
  | { type: ActionType.LOADING; payload: { loading: boolean } }
  | { type: ActionType.PRODUCTS; payload: { products: IProductWithoutDetails[] } }
  | { type: ActionType.LOCATION; payload: { locations: ILocation[] } }
  | { type: ActionType.CONSUMPTION; payload: { consumptions: IConsumption[];
	tasksPages: number;
	tasksPage: number;
	tasksTake: number;
	} }
  | { type: ActionType.PRODUCTION; payload: { productions: IProduction[] } }
  | {
      type: ActionType.PRODUCT_LOCATIONS;
      payload: { productLocations: ILocationProductData[] };
    }
  | {
      type: ActionType.LOCATION_PRODUCT;
      payload: { locationWithoutProducts: ILocationProductWithoutProduct[] }
   }
  | {
	type: ActionType.STATUS_COUNT;
	payload: {
		statusCount: IStatusCount;
	}
 };

interface IConsumptionActions {
  setLoading(loading: boolean): void;
  releaseTasks: (data: ReleaseTasksParams) => void;
  reprocessLocationTasks: (data: ReprocessTasksParams) => void;
  finishTask: (data: RemoveTasksParams) => void;
  getProducts: (queryParams: ProductQueryParams) => void;
  getLocations: (queryParams?: LocationProductQueryParams) => void;
  createConsumptionTasks: (params: CreateConsumptionTasksParams) => void;
  getConsumptionProduction: (
	params: ConsumptionProductionTaskQueryParams,
	) => void;
	getConsumptionCountByStatus: () => void;
}

const initialState: IState = {
	loading: false,
	tasksPages: 0,
	tasksPage: 0,
	tasksTake: 10,
	products: [],
	productLocations: [],
	locationWithoutProducts: [],
	locations: [],
	consumptions: [],
	productions: [],
	statusCount: {
		requisition: {
			pending: 0,
			inProgress: 0,
			completed: 0,
			canceled: 0,
		},
		devolution: {
			pending: 0,
			inProgress: 0,
			completed: 0,
			canceled: 0,
		},
	},
};

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.PRODUCTS:
		{
			const newProducts = action.payload.products;
			const updatedProducts = removeDuplicates([...state.products, ...newProducts], 'id');
			return { ...state, products: updatedProducts };
		}
		case ActionType.LOCATION:
			return { ...state, locations: action.payload.locations };
		case ActionType.PRODUCT_LOCATIONS:
			return { ...state, productLocations: action.payload.productLocations };
		case ActionType.CONSUMPTION:
			return {
				...state,
				consumptions: action.payload.consumptions,
				tasksPages: action.payload.tasksPages,
				tasksPage: action.payload.tasksPage,
				tasksTake: action.payload.tasksTake,
			};
		case ActionType.LOCATION_PRODUCT:
		{
			const newLocations = action.payload.locationWithoutProducts;
			const updatedLocations = removeDuplicates([...state.locationWithoutProducts, ...newLocations], 'locationId');
			return { ...state, locationWithoutProducts: updatedLocations };
		}
		case ActionType.STATUS_COUNT:
			return { ...state, statusCount: action.payload.statusCount };
		default:
			throw new Error();
	}
};

let lastQueryParams: ConsumptionProductionTaskQueryParams;

const ConsumptionActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject | undefined
  ) => SnackbarKey,
): IConsumptionActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		getConsumptionProduction(
			params: ConsumptionProductionTaskQueryParams,
		) {
			const take = params.take ?? 10;
			const skip = params.skip ?? 0;
			actions.setLoading(true);

			const queryParams = {
				...(lastQueryParams || {}),
				skip: skip * take,
				take,
				action: params.action,
				operatorName: params.operatorName,
				code: params.code,
				startDate: params.startDate,
				endDate: params.endDate,
				type: params.type !== undefined ? params.type : lastQueryParams?.type,
				statusHeader: params.statusHeader !== undefined
					? params.statusHeader : lastQueryParams?.statusHeader,
			};

			getConsumptionProduction(
				queryParams,
			).then((response) => {
				if (params.action === InventoryTaskAction.CONSUMPTION) {
					dispatch({
						type: ActionType.CONSUMPTION,
						payload: {
							consumptions: response.data.data,
							tasksPages: Math.ceil(response.data.count / take),
							tasksPage: params.skip,
							tasksTake: take,
						},
					});
				}
				lastQueryParams = queryParams;
			})
				.catch((error) => {
					enqueueSnackbar(
						error.response?.data.message || 'Erro ao obter informações',
						{
							variant: 'error',
						},
					);
				}).finally(() => {
					actions.setLoading(false);
				});
		},
		releaseTasks(data: ReleaseTasksParams) {
			actions.setLoading(true);
			releaseTasks(data)
				.then((response) => {
					enqueueSnackbar(
						response.data.message || 'Tarefas liberadas com sucesso!',
						{ variant: 'success' },
					);
				})
				.catch((error) => {
					enqueueSnackbar(
						error.response?.data.message || 'Erro ao reprocessar localizações',
						{ variant: 'error' },
					);
				})
				.finally(() => {
					if (lastQueryParams) {
						actions.getConsumptionProduction({
							...lastQueryParams,
							skip: 0,
						});
					}
					actions.setLoading(false);
				});
		},
		reprocessLocationTasks(data: ReprocessTasksParams) {
			actions.setLoading(true);
			reprocessLocationTasks(data)
				.then((response) => {
					enqueueSnackbar(
						response.data.message || 'Tarefas reprocessadas com sucesso!',
						{ variant: 'success' },
					);
				})
				.catch((error) => {
					enqueueSnackbar(
						error.response?.data.message || 'Erro ao reprocessar localizações',
						{
							variant: 'error',
						},
					);
				})
				.finally(() => {
					if (lastQueryParams) {
						actions.getConsumptionProduction(lastQueryParams);
					}
					actions.setLoading(false);
				});
		},
		finishTask(data: RemoveTasksParams) {
			finishTask(data)
				.then((response) => {
					enqueueSnackbar(
						response.data.message || 'Tarefa Cancelada!',
						{ variant: 'success' },
					);
				})
				.catch((error) => {
					enqueueSnackbar(
						error.response?.data.message || 'Erro ao finalizar tarefa',
						{
							variant: 'error',
						},
					);
				})
				.finally(() => {
					if (lastQueryParams) {
						actions.getConsumptionProduction(lastQueryParams);
					}
				});
		},
		getProducts(queryParams: ProductQueryParams) {
			getProductsWithoutDetails(queryParams).then((response) => {
				dispatch({
					type: ActionType.PRODUCTS,
					payload: {
						products: response.data.data,
					},
				});
			}).catch((error: AxiosError) => {
				enqueueSnackbar(
					error.response?.data.message || 'Algum erro ocorreu ao obter as informações dos produtos, tente novamente ou contate um administrador.',
					{
						variant: 'error',
					},
				);
			});
		},
		getLocations(queryParams?: LocationProductQueryParams) {
			getLocations(queryParams)
				.then((response) => {
					dispatch({
						type: ActionType.LOCATION,
						payload: {
							locations: response.data.locations,
						},
					});
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(
						error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.',
						{ variant: 'error' },
					);
				});
		},
		createConsumptionTasks(data: CreateConsumptionTasksParams) {
			actions.setLoading(true);
			createConsumptionTasks(data)
				.then((response) => {
					enqueueSnackbar(
						response.data.message || 'Tarefas criadas com sucesso!',
						{ variant: 'success' },
					);
				})
				.catch((error) => {
					enqueueSnackbar(
						error.response?.data.message || 'Erro ao criar tarefas',
						{
							variant: 'error',
						},
					);
				})
				.finally(() => {
					actions.getConsumptionCountByStatus();
					actions.setLoading(false);
				});
		},
		getConsumptionCountByStatus() {
			getConsumptionCountByStatus()
				.then((response) => {
					const statusCount: IStatusCount = {
						requisition: {
							pending: response.data.requisition.pending,
							inProgress: response.data.requisition.inProgress,
							completed: response.data.requisition.completed,
							canceled: response.data.requisition.canceled,
						},
						devolution: {
							pending: response.data.devolution.pending,
							inProgress: response.data.devolution.inProgress,
							completed: response.data.devolution.completed,
							canceled: response.data.devolution.canceled,
						},
					};
					dispatch({
						type: ActionType.STATUS_COUNT,
						payload: {
							statusCount,
						},
					});
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(
						error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.',
						{ variant: 'error' },
					);
				});
		},
	};

	return actions;
};

const ConsumptionAndProductionTask = (): JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(
		reducer,
		initialState,
	);
	const { enqueueSnackbar } = useSnackbar();
	const actions = useMemo(
		() => ConsumptionActions(dispatch, enqueueSnackbar),
		[dispatch, enqueueSnackbar],
	);

	// eslint-disable-next-line react/jsx-props-no-spreading
	return <ConsumptionAndProductionTaskPresentational {...state} {...actions} />;
};

export default ConsumptionAndProductionTask;

import React, {
	useCallback,
	useMemo,
	useState,
} from 'react';
import {
	Card, CardContent, Typography, Box,
	Grid,
	TableContainer,
	Table,
	TableBody,
	Paper,
	TableCell,
	TableHead,
	TableRow,
	Alert,
} from '@mui/material';
import { DataGrid, GridRowsProp, GridSelectionModel } from '@mui/x-data-grid';
import { useFormik, FormikContext, Form } from 'formik';
import Decimal from 'decimal.js';
import {
	IIndirectCostSaleOrderSimulationItem,
	initialSaleOrderSimulation,
	ISaleOrderSimulation,
	SaleOrderSimulationRow,
} from '../../containers/ProfitabilityAnalysis/ProfitabilityAnalysisAssets';
import { profitabilityAnalysisFormColumns } from '../../constants/profitabilityAnalysisFormColumns';
import SimulationDrawer from './Drawers/SimulationDrawer';
import { ItemType } from '../../enums/ItemType';
import { formatDecimalValue, formatPercentage, getColorForValue } from '../../helpers/Utils';
import { DeliveryTypeLabels } from '../../constants/deliveryTypeLabels';
import {
	calculateNetProfit,
	getFinancialCost,
	getFinancialCostSimulation,
	calculateGrossProfit,
	calculateNetProfitMargin,
	calculateGrossMargin,
	calculateGrossProfitSaleOrder,
	calculateGrossProfitSimulation,
	calculateNetProfitSaleOrder,
	calculateNetProfitSimulation,
	calculateNetProfitMarginSimulation,
	calculateUnitValueAverageTerm,
	calculateUnitValueRangePriceCommission,
	calculateUnitValueIndirectCost,
	calculateUnitValueAverageTermSimulation,
	calculateTotalIndirectFinancialCommissionCost,
} from '../../helpers/ProfitabilityAnalysisCalculations';
import { useFormikValuesUpdater } from '../../hooks/useFormikValuesUpdater';

interface ProfitabilityAnalysisFormProps {
	saleOrderSimulation?: ISaleOrderSimulation;
	updateSelectedRangeSimulation(
		saleOrderSimulationItemId: string,
		rangeCommissionId: string,
	): void;
	updateSelectedAveragePaymentSimulation(
		saleOrderSimulationItemId: string,
		averagePaymentTermId: string,
	): void;
	upsertIndirectCostSimulation(data: IIndirectCostSaleOrderSimulationItem): void;
	updateSimulationPrice(
		saleOrderSimulationItemId: string,
		simulationPrice: number,
	): void;
}

const ProfitabilityAnalysisForm = ({
	saleOrderSimulation,
	updateSelectedRangeSimulation,
	updateSelectedAveragePaymentSimulation,
	upsertIndirectCostSimulation,
	updateSimulationPrice,
}: ProfitabilityAnalysisFormProps): JSX.Element => {
	const [triggerCalculation, setTriggerCalculation] = useState(0);
	const [selectedItemWithoutPriceFormation, setSelectedItemWithoutPriceFormation] = useState(false);
	const formik = useFormik<ISaleOrderSimulation>({
		initialValues: {
			...initialSaleOrderSimulation,
			...saleOrderSimulation,
			saleOrderSimulationItems: saleOrderSimulation?.saleOrderSimulationItems?.map((item) => ({
				...item,
				salePriceFormation: item.salePriceFormation
					? {
						...item.salePriceFormation,
						discountIndex: item.salePriceFormation.priceListStateSalePriceFormation?.discount
							? new Decimal(item.salePriceFormation.priceListStateSalePriceFormation.discount)
								.dividedBy(
									new Decimal(100).minus(
										item.salePriceFormation.priceListStateSalePriceFormation.discount,
									),
								)
								.times(100)
								.toDecimalPlaces(3)
								.toNumber()
							: 0,
						formulas: item.salePriceFormation.formulas?.length
							? item.salePriceFormation.formulas
							: [
								{ id: '0', description: '((FPV.pv - FPV.cd) / FPV.pv) * 100' }, // Margem Bruta
								{ id: '1', description: 'FPV.pv - FPV.cd' }, // Lucro Bruto
								{ id: '2', description: '(FPV.lb - (FPV.ci.V.1 + FPV.ci.V.2 + FPV.ci.V.3 + FPV.ci.V.4 + FPV.fc.V))' }, // Lucro Líquido
								{ id: '3', description: 'FPV.ll / FPV.pv * 100' }, // Margem Lucro Líquido
							],
					}
					: undefined,
			})) || [],
		},
		onSubmit: (values, { setSubmitting }) => {
			setSubmitting(false);
		},
	});

	const handleUpdateSelectedRangeSimulation = useCallback(
		(saleOrderSimulationItemId: string, rangeCommissionId: string) => {
			updateSelectedRangeSimulation(saleOrderSimulationItemId, rangeCommissionId);
			setTriggerCalculation((prev) => prev + 1);
		},
		[updateSelectedRangeSimulation],
	);

	const handleUpdateSelectedAveragePaymentSimulation = useCallback(
		(saleOrderSimulationItemId: string, averagePaymentTermId: string) => {
			updateSelectedAveragePaymentSimulation(saleOrderSimulationItemId, averagePaymentTermId);
			setTriggerCalculation((prev) => prev + 1);
		},
		[updateSelectedAveragePaymentSimulation],
	);

	const handleUpsertIndirectCostSimulation = useCallback(
		(data: IIndirectCostSaleOrderSimulationItem) => {
			upsertIndirectCostSimulation(data);
			setTriggerCalculation((prev) => prev + 1);
		},
		[upsertIndirectCostSimulation],
	);

	const handleUpdateSimulationPrice = useCallback(
		(saleOrderSimulationItemId: string, simulationPrice: number) => {
			updateSimulationPrice(saleOrderSimulationItemId, simulationPrice);
			setTriggerCalculation((prev) => prev + 1);
		},
		[updateSimulationPrice],
	);

	const calculatedValues = useMemo(() => {
		const newFieldValues: Record<string, any> = {};

		formik.values.saleOrderSimulationItems.forEach((item, itemIndex) => {
			if (item.type === ItemType.SALE && item.salePriceFormation) {
				const { salePriceFormation, indirectCostSimulations = [] } = item;

				const totalIndirectCostSimulation = indirectCostSimulations.reduce(
					(acc, cost) => new Decimal(acc).plus(cost.interestRateSimulation || 0).toNumber(),
					0,
				);

				const indexValues: { [key: string]: string } = {};
				const rangeCommissionSalePriceFormations = salePriceFormation
					.rangeCommissionSalePriceFormations?.map(
						(rangeCommission) => {
							const indexValue = new Decimal(1)
								.div(
									new Decimal(1).minus(
										new Decimal(rangeCommission.commissionRate)
											.div(100)
											.plus(new Decimal(salePriceFormation.totalIndirectCost || 0).div(100)),
									),
								)
								.toFixed(4)
								.replace('.', ',');

							indexValues[rangeCommission.commissionRate.toString()] = indexValue;

							const sppvIndex = new Decimal(1)
								.div(
									new Decimal(1).minus(
										new Decimal(rangeCommission.commissionRate)
											.div(100)
											.plus(new Decimal(totalIndirectCostSimulation).div(100)),
									),
								)
								.toNumber();

							const sppvUnitValueR = calculateUnitValueRangePriceCommission(
								salePriceFormation.totalDirectCost || 0,
								sppvIndex,
								rangeCommission.commissionRate,
								totalIndirectCostSimulation,
							);

							return {
								...rangeCommission,
								indexValue,
								sppvIndex,
								sppvUnitValueR,
							};
						},
					);

				const matchedRange = rangeCommissionSalePriceFormations?.find(
					(range) => range.rangeCommission.range === item.commission,
				);

				const indexValue = matchedRange ? parseFloat(matchedRange.indexValue.replace(',', '.')) : 1;

				const selectedRangeCommission = rangeCommissionSalePriceFormations?.find(
					(range) => range.rangeCommissionId === item.selectedRangeCommissionId,
				);

				const indexValueRangeCommissionSimulation = selectedRangeCommission
					? selectedRangeCommission.sppvIndex
					: (matchedRange?.sppvIndex || 0);

				const unitValueRangeCommission = calculateUnitValueRangePriceCommission(
					salePriceFormation.totalDirectCost || 0,
					indexValue,
					item.commission,
					salePriceFormation.totalIndirectCost || 0,
				);

				const unitValueRangeCommissionSimulation = calculateUnitValueRangePriceCommission(
					salePriceFormation.totalDirectCost || 0,
					indexValueRangeCommissionSimulation,
					selectedRangeCommission?.commissionRate || item.commission,
					totalIndirectCostSimulation,
				);

				const updatedIndirectCostSimulations = indirectCostSimulations.map((indirectCost) => {
					const unitValueI = calculateUnitValueIndirectCost(
						salePriceFormation.totalDirectCost || 0,
						indexValue,
						indirectCost.interestRate,
						salePriceFormation.totalIndirectCost || 0,
						item.commission,
					);

					const sppvUnitValueI = calculateUnitValueIndirectCost(
						salePriceFormation.totalDirectCost || 0,
						indexValueRangeCommissionSimulation,
						indirectCost.interestRateSimulation,
						totalIndirectCostSimulation,
						item.commission,
					);

					return {
						...indirectCost,
						unitValueI,
						sppvUnitValueI,
					};
				});

				const totalUnitValueIndirectCost = updatedIndirectCostSimulations.reduce(
					(acc, cost) => acc + (cost.unitValueI || 0),
					0,
				);

				const totalUnitValueIndirectCostSimulation = updatedIndirectCostSimulations.reduce(
					(acc, cost) => acc + (cost.sppvUnitValueI || 0),
					0,
				);

				const averagePaymentTermSalePriceFormations = salePriceFormation
					.averagePaymentTermSalePriceFormations?.map(
						(term) => {
							const unitValueAverageTerm = calculateUnitValueAverageTerm(
								{
									...item,
									totalUnitValueIndirectCost,
									salePriceFormation: {
										...salePriceFormation,
										unitValueRangeCommission,
									},
								},
								term,
							);

							const unitValueAverageTermSimulation = calculateUnitValueAverageTermSimulation(
								{
									...item,
									totalUnitValueIndirectCostSimulation,
									salePriceFormation: {
										...salePriceFormation,
										unitValueRangeCommissionSimulation,
									},
								},
								term,
							);

							return {
								...term,
								unitValueAverageTerm,
								unitValueAverageTermSimulation,
							};
						},
					);

				const defaultTerm = averagePaymentTermSalePriceFormations?.find(
					(term) => term.averagePaymentTerm.description === formik.values.averagePaymentTerm,
				);

				const selectedTerm = averagePaymentTermSalePriceFormations?.find(
					(term) => term.averagePaymentTermId === item.selectedAveragePaymentTermId,
				) || defaultTerm;

				const updatedSalePriceFormation = {
					...salePriceFormation,
					rangeCommissionSalePriceFormations,
					unitValueRangeCommission,
					unitValueRangeCommissionSimulation,
					averagePaymentTermSalePriceFormations,
					averageTermSimulationId: selectedTerm?.averagePaymentTermId,
					unitValueAverageTerm: defaultTerm?.unitValueAverageTerm ?? 0,
					unitValueAverageTermSimulation: selectedTerm?.unitValueAverageTermSimulation ?? 0,
					indexValues,
					indexValue: indexValue.toString(),
					indexValueRangeCommissionSimulation,
				};

				const updatedItem = {
					...item,
					salePriceFormation: updatedSalePriceFormation,
					totalUnitValueIndirectCost,
					totalUnitValueIndirectCostSimulation,
				};

				const grossMargin = calculateGrossMargin(updatedSalePriceFormation);
				const grossProfit = calculateGrossProfit(updatedItem);
				const grossProfitSaleOrder = calculateGrossProfitSaleOrder(updatedItem);
				const grossProfitSimulation = calculateGrossProfitSimulation(updatedItem);

				const netProfit = calculateNetProfit(grossProfit, totalUnitValueIndirectCost, updatedItem);
				const netProfitSaleOrder = calculateNetProfitSaleOrder(
					grossProfitSaleOrder,
					totalUnitValueIndirectCost,
					updatedItem,
				);
				const netProfitSimulation = calculateNetProfitSimulation(
					grossProfitSimulation,
					totalUnitValueIndirectCostSimulation,
					updatedItem,
				);

				const netProfitMargin = calculateNetProfitMargin(netProfit, updatedSalePriceFormation);
				const netProfitMarginSimulation = calculateNetProfitMarginSimulation(
					netProfitSimulation,
					updatedItem,
				);

				const financialCost = getFinancialCost(updatedItem);

				const financialCostSimulation = getFinancialCostSimulation(updatedItem);

				// eslint-disable-next-line operator-linebreak
				const totalSimulationIndirectFinancialCommissionCost =
					calculateTotalIndirectFinancialCommissionCost(
						financialCostSimulation,
						totalUnitValueIndirectCostSimulation,
						unitValueRangeCommissionSimulation,
						item.quantity,
					);

				const totalIndirectFinancialCommissionCost = calculateTotalIndirectFinancialCommissionCost(
					financialCost,
					totalUnitValueIndirectCost,
					unitValueRangeCommission || 0,
					item.quantity,
				);

				newFieldValues[`saleOrderSimulationItems[${itemIndex}].salePriceFormation`] = updatedSalePriceFormation;
				newFieldValues[`saleOrderSimulationItems[${itemIndex}].totalIndirectCostSimulation`] = totalIndirectCostSimulation;
				newFieldValues[`saleOrderSimulationItems[${itemIndex}].indirectCostSimulations`] = updatedIndirectCostSimulations;
				newFieldValues[`saleOrderSimulationItems[${itemIndex}].totalUnitValueIndirectCost`] = totalUnitValueIndirectCost;
				newFieldValues[`saleOrderSimulationItems[${itemIndex}].totalUnitValueIndirectCostSimulation`] = totalUnitValueIndirectCostSimulation;
				newFieldValues[`saleOrderSimulationItems[${itemIndex}].averageTermSimulation`] = selectedTerm?.interestRate ?? 0;
				newFieldValues[`saleOrderSimulationItems[${itemIndex}].grossMargin`] = grossMargin;
				newFieldValues[`saleOrderSimulationItems[${itemIndex}].grossProfit`] = grossProfit;
				newFieldValues[`saleOrderSimulationItems[${itemIndex}].grossProfitSaleOrder`] = grossProfitSaleOrder;
				newFieldValues[`saleOrderSimulationItems[${itemIndex}].grossProfitSimulation`] = grossProfitSimulation;
				newFieldValues[`saleOrderSimulationItems[${itemIndex}].netProfit`] = netProfit;
				newFieldValues[`saleOrderSimulationItems[${itemIndex}].netProfitSaleOrder`] = netProfitSaleOrder;
				newFieldValues[`saleOrderSimulationItems[${itemIndex}].netProfitSimulation`] = netProfitSimulation;
				newFieldValues[`saleOrderSimulationItems[${itemIndex}].netProfitMargin`] = netProfitMargin;
				newFieldValues[`saleOrderSimulationItems[${itemIndex}].netProfitMarginSimulation`] = netProfitMarginSimulation;
				newFieldValues[`saleOrderSimulationItems[${itemIndex}].financialCost`] = financialCost;
				newFieldValues[`saleOrderSimulationItems[${itemIndex}].financialCostSimulation`] = financialCostSimulation;
				newFieldValues[`saleOrderSimulationItems[${itemIndex}].totalSimulationIndirectFinancialCommissionCost`] = totalSimulationIndirectFinancialCommissionCost;
				newFieldValues[`saleOrderSimulationItems[${itemIndex}].totalIndirectFinancialCommissionCost`] = totalIndirectFinancialCommissionCost;
			}
		});

		return newFieldValues;
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [triggerCalculation]);

	useFormikValuesUpdater(calculatedValues, [calculatedValues], formik);

	const handleRowSelection = useCallback((selectionModel: GridSelectionModel) => {
		const selectedRow = formik.values.saleOrderSimulationItems.find(
			(item) => item.id === selectionModel[0],
		);

		if (selectedRow) {
			setSelectedItemWithoutPriceFormation(!selectedRow.salePriceFormation);
		} else {
			setSelectedItemWithoutPriceFormation(false);
		}

		const updatedItems = formik.values.saleOrderSimulationItems.map((item) => ({
			...item,
			selected: item.id === selectionModel[0],
		}));
		formik.setFieldValue('saleOrderSimulationItems', updatedItems);
	}, [formik]);

	const salesItems = useMemo(() => formik.values.saleOrderSimulationItems
		.filter((item) => item.type === ItemType.SALE), [formik.values.saleOrderSimulationItems]);

	const bonusItems = useMemo(() => formik.values.saleOrderSimulationItems
		.filter((item) => item.type === ItemType.BONUS), [formik.values.saleOrderSimulationItems]);

	const totalSalesRow = useMemo(() => ({
		id: 'totalSales',
		nrItem: null,
		codeDescription: 'Total Vendas',
		quantity: null,
		price: null,
		simulationPrice: salesItems.reduce(
			(sum, item) => sum + item.quantity * item.simulationPrice,
			0,
		),
		total: salesItems.reduce((sum, item) => sum + item.total, 0),
		discount: null,
		discountValue: salesItems.reduce((sum, item) => sum + item.discountValue, 0),
		totalWithDiscount: salesItems.reduce((sum, item) => sum
		+ (item.totalWithDiscount), 0),
		commission: salesItems.length ? (
			salesItems.reduce((sum, item) => sum + item.commission, 0) / salesItems.length
		) : 0,
		commissionValue: salesItems.reduce((sum, item) => sum + item.commissionValue, 0),
		totalDirectCost: salesItems.reduce((sum, item) => sum + (
			item.quantity * (item.salePriceFormation?.totalDirectCost || 0)), 0),
		grossProfitSaleOrder: salesItems.reduce((sum, item) => sum
			+ (((item.grossProfitSaleOrder || 0) * item.quantity) - item.discountValue), 0),
		grossProfitSimulation: salesItems.reduce((sum, item) => sum
		+ (((item.grossProfitSimulation || 0) * item.quantity) - item.discountValue), 0),
		netProfitSaleOrder: salesItems.reduce((sum, item) => sum
			+ (
				(item.totalWithDiscount || 0)
				- (item.salePriceFormation?.totalDirectCost || 0) * item.quantity
				- (item.commissionValue || 0)
				- (item.totalIndirectFinancialCommissionCost || 0)
			), 0),
		netProfitMarginSaleOrder: (salesItems.reduce((sum, item) => sum
			+ (
				(item.totalWithDiscount || 0)
				- (item.salePriceFormation?.totalDirectCost || 0) * item.quantity
				- (item.commissionValue || 0)
				- (item.totalIndirectFinancialCommissionCost || 0)
			), 0) / salesItems.reduce((sum, item) => sum
			+ (item.totalWithDiscount), 0)) * 100 || 0,
		netProfitSimulation: salesItems.reduce((sum, item) => sum
		+ (
			(((item.simulationPrice || 0) * item.quantity) - item.discountValue)
			- (item.salePriceFormation?.totalDirectCost || 0) * item.quantity
			- (item.commissionValue || 0)
			- (item.totalSimulationIndirectFinancialCommissionCost || 0)
		), 0),
		netProfitMarginSimulation: (
			salesItems.reduce((sum, item) => sum
				+ (
					(((item.simulationPrice || 0) * item.quantity) - item.discountValue)
					- (item.salePriceFormation?.totalDirectCost || 0) * item.quantity
					- (item.commissionValue || 0)
					- (item.totalSimulationIndirectFinancialCommissionCost || 0)
				), 0)
			/ salesItems.reduce((sum, item) => sum
				+ (((item.simulationPrice || 0) * item.quantity) - item.discountValue), 0)
		) * 100 || 0,
		financialCost: salesItems.reduce((sum, item) => sum + (item.financialCost || 0), 0),
		financialCostSimulation: salesItems.reduce((sum, item) => sum
		+ (item.financialCostSimulation || 0), 0),
		totalUnitValueIndirectCost: salesItems.reduce((sum, item) => sum
		+ (item.totalUnitValueIndirectCost || 0), 0),
		totalUnitValueIndirectCostSimulation: salesItems.reduce((sum, item) => sum
		+ (item.totalUnitValueIndirectCostSimulation || 0), 0),
		totalIndirectFinancialCommissionCost:
		salesItems.reduce((sum, item) => sum + (item.totalIndirectFinancialCommissionCost || 0), 0),
		totalSimulationIndirectFinancialCommissionCost:
		salesItems.reduce((sum, item) => sum
		+ (item.totalSimulationIndirectFinancialCommissionCost || 0), 0),
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}), [salesItems, formik.values.saleOrderSimulationItems]);

	const totalBonusRow = useMemo(() => ({
		id: 'totalBonus',
		nrItem: null,
		codeDescription: 'Total Bonificações',
		quantity: null,
		price: null,
		total: bonusItems.reduce((sum, item) => sum + item.total, 0),
		discount: bonusItems.reduce((sum, item) => sum + item.discount, 0),
		discountValue: bonusItems.reduce((sum, item) => sum + item.discountValue, 0),
		totalWithDiscount: bonusItems.reduce((sum, item) => sum + item.totalWithDiscount, 0),
		commission: null,
		commissionValue: bonusItems.reduce((sum, item) => sum + item.commissionValue, 0),
		totalDirectCost: bonusItems.reduce((sum, item) => sum + (
			item.quantity * (item.salePriceFormation?.totalDirectCost || 0)), 0),
	}), [bonusItems]);

	const updatedBonusItems = useMemo(() => bonusItems.map((item) => ({
		...item,
		totalDirectCost: item.quantity * (item.salePriceFormation?.totalDirectCost || 0),
		totalWithDiscount: item.total - item.discountValue,
		grossProfitSaleOrder: item.grossProfitSaleOrder,
	})), [bonusItems]);

	const updatedSalesItems = useMemo(() => salesItems.map((item) => ({
		...item,
		totalDirectCost: item.quantity * (item.salePriceFormation?.totalDirectCost || 0),
		grossProfitSaleOrder: item.quantity * (item.grossProfitSaleOrder || 0),
		grossProfitSimulation: item.quantity * (item.grossProfitSimulation || 0),
		netProfitSaleOrder: (item.quantity * (item.netProfitSaleOrder || 0)),
		netProfitSimulation: (item.quantity * (item.netProfitSimulation || 0)),
	})), [salesItems]);

	const rows: GridRowsProp = useMemo(() => {
		const baseRows: SaleOrderSimulationRow[] = [...updatedSalesItems];
		baseRows.push(totalSalesRow);
		if (bonusItems.length > 0) {
			baseRows.push(...updatedBonusItems, totalBonusRow);
		}

		return baseRows;
	}, [updatedSalesItems, totalSalesRow, bonusItems.length, updatedBonusItems, totalBonusRow]);

	const simulationDrawerMemo = useMemo(() => {
		const hasSelectedItem = formik.values.saleOrderSimulationItems
			.some((item) => item.selected
			&& item.salePriceFormation !== undefined
			&& item.type === ItemType.SALE);
		if (hasSelectedItem) {
			return (
				<SimulationDrawer
					updateSelectedRangeSimulation={handleUpdateSelectedRangeSimulation}
					updateSelectedAveragePaymentSimulation={handleUpdateSelectedAveragePaymentSimulation}
					upsertIndirectCostSimulation={handleUpsertIndirectCostSimulation}
					updateSimulationPrice={handleUpdateSimulationPrice}
				/>
			);
		}
		return null;
	}, [
		formik.values.saleOrderSimulationItems,
		handleUpdateSelectedRangeSimulation,
		handleUpdateSelectedAveragePaymentSimulation,
		handleUpsertIndirectCostSimulation,
		handleUpdateSimulationPrice,
	]);

	return (
		<FormikContext.Provider value={formik}>
			<Form onSubmit={formik.handleSubmit}>
				<Box sx={{ position: 'relative', height: '100%' }}>
					<Card>
						<CardContent>
							<Grid container spacing={2}>
								<Grid item xs={5}>
									<Typography variant="h6" gutterBottom>
										Dados do Pedido de Venda
									</Typography>
									<TableContainer component={Paper}>
										<Table
											size="small"
											stickyHeader
											sx={{
												'& .MuiTableCell-root': {
													fontSize: '14px',
													padding: '4px',
												},
											}}
										>
											<TableBody>
												<TableRow sx={{ backgroundColor: '#f9f9f9', height: '82px' }}>
													<TableCell sx={{ fontWeight: 'bold', width: '25%' }}>
														Pedido Venda / Rev:
													</TableCell>
													<TableCell align="left" sx={{ width: '50%' }}>
														{formik.values.nrOrder}
														{' '}
														/
														{formik.values.revision}
													</TableCell>
													<TableCell sx={{ fontWeight: 'bold', width: '25%' }}>
														Tabela:
													</TableCell>
													<TableCell align="left" sx={{ width: '50%' }}>
														{formik.values.priceList}
													</TableCell>
												</TableRow>
												<TableRow sx={{ backgroundColor: '#ffffff', height: '48px' }}>
													<TableCell sx={{ fontWeight: 'bold', width: '25%' }}>
														Emissão:
													</TableCell>
													<TableCell align="left" sx={{ width: '50%' }}>
														{new Date(formik.values.emissionDate).toLocaleDateString()}
													</TableCell>
													<TableCell sx={{ fontWeight: 'bold', width: '25%' }}>
														Condição Pagto:
													</TableCell>
													<TableCell align="left" sx={{ width: '50%' }}>
														{formik.values.paymentTerm}
													</TableCell>
												</TableRow>
												<TableRow sx={{ backgroundColor: '#f9f9f9', height: '48px' }}>
													<TableCell sx={{ fontWeight: 'bold', width: '25%' }}>
														Vendedor:
													</TableCell>
													<TableCell align="left" sx={{ width: '50%' }}>
														{formik.values.salesman}
													</TableCell>
													<TableCell sx={{ fontWeight: 'bold', width: '25%' }}>
														Prazo Médio:
													</TableCell>
													<TableCell align="left" sx={{ width: '50%' }}>
														{formik.values.averagePaymentTerm}
													</TableCell>
												</TableRow>
												<TableRow sx={{ backgroundColor: '#ffffff', height: '48px' }}>
													<TableCell sx={{ fontWeight: 'bold', width: '25%' }}>
														Cliente:
													</TableCell>
													<TableCell align="left" sx={{ width: '50%' }}>
														{formik.values.client}
													</TableCell>
													<TableCell sx={{ fontWeight: 'bold', width: '25%' }}>
														Pedido Força Venda:
													</TableCell>
													<TableCell align="left" sx={{ width: '50%' }}>
														{formik.values.saleForceOrder}
													</TableCell>
												</TableRow>
												<TableRow sx={{ backgroundColor: '#f9f9f9', height: '48px' }}>
													<TableCell sx={{ fontWeight: 'bold', width: '25%' }}>
														Localidade:
													</TableCell>
													<TableCell align="left" sx={{ width: '50%' }}>
														{formik.values.locality}
													</TableCell>
													<TableCell sx={{ fontWeight: 'bold', width: '25%' }}>
														Frete:
													</TableCell>
													<TableCell align="left" sx={{ width: '50%' }}>
														{DeliveryTypeLabels[formik.values.deliveryType]
													|| 'Desconhecido'}
													</TableCell>
												</TableRow>
											</TableBody>
										</Table>
									</TableContainer>
								</Grid>
								<Grid item xs={5}>
									<Typography variant="h6" gutterBottom>
										DRE do Pedido
									</Typography>
									<TableContainer component={Paper}>
										<Table
											size="small"
											stickyHeader
											sx={{
												'& .MuiTypography-root': {
													fontSize: '14px',
												},
											}}
										>
											<TableHead>
												<TableRow>
													<TableCell><strong>Operação</strong></TableCell>
													<TableCell><strong>Venda</strong></TableCell>
													<TableCell><strong>(%)</strong></TableCell>
													<TableCell><strong>Bonificação</strong></TableCell>
													<TableCell><strong>Rentabilidade</strong></TableCell>
													<TableCell><strong>(%)</strong></TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												<TableRow>
													<TableCell>Receita</TableCell>
													<TableCell align="right">
														<Box display="flex" justifyContent="space-between" alignItems="center">
															<Typography>R$</Typography>
															<Typography>
																{formatDecimalValue(totalSalesRow
																	.totalWithDiscount)}
															</Typography>
														</Box>
													</TableCell>
													<TableCell>-</TableCell>
													<TableCell align="right">
														<Box display="flex" justifyContent="space-between" alignItems="center">
															<Typography>R$</Typography>
															<Typography>
																-
															</Typography>
														</Box>
													</TableCell>
													<TableCell align="right">
														<Box display="flex" justifyContent="space-between" alignItems="center">
															<Typography>R$</Typography>
															<Typography>
																{formatDecimalValue(totalSalesRow.totalWithDiscount)}
															</Typography>
														</Box>
													</TableCell>
													<TableCell>-</TableCell>
												</TableRow>
												<TableRow>
													<TableCell>(-) Descontos</TableCell>
													<TableCell align="right">
														<Box display="flex" justifyContent="space-between" alignItems="center">
															<Typography>R$</Typography>
															<Typography>
																{formatDecimalValue(totalSalesRow
																	.discountValue)}
															</Typography>
														</Box>
													</TableCell>
													<TableCell>-</TableCell>
													<TableCell align="right">
														<Box display="flex" justifyContent="space-between" alignItems="center">
															<Typography>R$</Typography>
															<Typography>
																-
															</Typography>
														</Box>
													</TableCell>
													<TableCell align="right">
														<Box display="flex" justifyContent="space-between" alignItems="center">
															<Typography>R$</Typography>
															<Typography>
																{formatDecimalValue(totalSalesRow.discountValue
																		+ totalBonusRow.discountValue)}
															</Typography>
														</Box>
													</TableCell>
													<TableCell>-</TableCell>
												</TableRow>
												<TableRow>
													<TableCell>Custos Diretos (CPV)</TableCell>
													<TableCell align="right">
														<Box display="flex" justifyContent="space-between" alignItems="center">
															<Typography>R$</Typography>
															<Typography>
																{formatDecimalValue(totalSalesRow
																	.totalDirectCost)}
															</Typography>
														</Box>
													</TableCell>
													<TableCell>-</TableCell>
													<TableCell align="right">
														<Box display="flex" justifyContent="space-between" alignItems="center">
															<Typography>R$</Typography>
															<Typography>
																{formatDecimalValue(totalBonusRow
																	.totalDirectCost)}
															</Typography>
														</Box>
													</TableCell>
													<TableCell align="right">
														<Box display="flex" justifyContent="space-between" alignItems="center">
															<Typography>R$</Typography>
															<Typography>
																{formatDecimalValue(totalSalesRow.totalDirectCost
																		+ totalBonusRow.totalDirectCost)}
															</Typography>
														</Box>
													</TableCell>
													<TableCell>-</TableCell>
												</TableRow>
												<TableRow>
													<TableCell>Lucro/Prejuízo Bruto</TableCell>
													<TableCell align="right">
														<Box display="flex" justifyContent="space-between" alignItems="center">
															<Typography>R$</Typography>
															<Typography
																sx={{
																	color: getColorForValue(totalSalesRow.grossProfitSaleOrder),
																	fontWeight: 'bold',
																}}
															>
																{formatDecimalValue(totalSalesRow.grossProfitSaleOrder)}
															</Typography>
														</Box>
													</TableCell>
													<TableCell align="right">
														<Box display="flex" justifyContent="space-between" alignItems="center">
															<Typography
																sx={{
																	color: getColorForValue(
																		(((totalSalesRow.total
																			- totalSalesRow.totalDirectCost
																			- totalSalesRow.commissionValue)
																			/ totalSalesRow.totalWithDiscount) * 100),
																	),
																	fontWeight: 'bold',
																}}
															>
																{formatPercentage(((totalSalesRow.total
																	- totalSalesRow.totalDirectCost
																	- totalSalesRow.commissionValue)
																		/ totalSalesRow.totalWithDiscount) * 100)}
															</Typography>
														</Box>
													</TableCell>
													<TableCell align="right">
														<Box display="flex" justifyContent="space-between" alignItems="center">
															<Typography>R$</Typography>
															<Typography>
																-
															</Typography>
														</Box>
													</TableCell>
													<TableCell align="right">
														<Box display="flex" justifyContent="space-between" alignItems="center">
															<Typography>R$</Typography>
															<Typography
																sx={{
																	color: getColorForValue(
																		((totalSalesRow
																			.totalWithDiscount)
																				- (totalSalesRow.discountValue)
																				- (totalSalesRow.totalDirectCost
																				+ totalBonusRow.totalDirectCost)),
																	),
																	fontWeight: 'bold',
																}}
															>
																{formatDecimalValue((totalSalesRow
																	.totalWithDiscount)
																			- (totalSalesRow.discountValue)
																			- (totalSalesRow.totalDirectCost
																			+ totalBonusRow.totalDirectCost))}
															</Typography>
														</Box>
													</TableCell>
													<TableCell align="right">
														<Box display="flex" justifyContent="space-between" alignItems="center">
															<Typography
																sx={{
																	color: getColorForValue(
																		((((totalSalesRow
																			.totalWithDiscount)
																				- (totalSalesRow.discountValue)
																				- (totalSalesRow.totalDirectCost
																				+ totalBonusRow.totalDirectCost))
																				/ (totalSalesRow.totalWithDiscount)) * 100),
																	),
																	fontWeight: 'bold',
																}}
															>
																{formatPercentage((((totalSalesRow
																	.totalWithDiscount)
																		- (totalSalesRow.discountValue)
																		- (totalSalesRow.totalDirectCost
																		+ totalBonusRow.totalDirectCost))
																		/ (totalSalesRow.totalWithDiscount)) * 100)}
															</Typography>
														</Box>
													</TableCell>
												</TableRow>
												<TableRow>
													<TableCell>(-) Comissão</TableCell>
													<TableCell align="right">
														<Box display="flex" justifyContent="space-between" alignItems="center">
															<Typography>R$</Typography>
															<Typography>
																{formatDecimalValue(totalSalesRow
																	.commissionValue)}
															</Typography>
														</Box>
													</TableCell>
													<TableCell>-</TableCell>
													<TableCell align="right">
														<Box display="flex" justifyContent="space-between" alignItems="center">
															<Typography>R$</Typography>
															<Typography>
																-
															</Typography>
														</Box>
													</TableCell>
													<TableCell align="right">
														<Box display="flex" justifyContent="space-between" alignItems="center">
															<Typography>R$</Typography>
															<Typography>
																{formatDecimalValue(totalSalesRow.commissionValue
																		+ totalBonusRow.commissionValue)}
															</Typography>
														</Box>
													</TableCell>
													<TableCell>-</TableCell>
												</TableRow>
												<TableRow>
													<TableCell>(-) Custos Ind + Fin</TableCell>
													<TableCell align="right">
														<Box display="flex" justifyContent="space-between" alignItems="center">
															<Typography>R$</Typography>
															<Typography>
																{formatDecimalValue(
																	totalSalesRow.totalIndirectFinancialCommissionCost,
																)}
															</Typography>
														</Box>
													</TableCell>
													<TableCell align="right">
														<Box display="flex" justifyContent="space-between" alignItems="center">
															<Typography>
																-
															</Typography>
														</Box>
													</TableCell>
													<TableCell align="right">
														<Box display="flex" justifyContent="space-between" alignItems="center">
															<Typography>R$</Typography>
															<Typography>
																-
															</Typography>
														</Box>
													</TableCell>
													<TableCell align="right">
														<Box display="flex" justifyContent="space-between" alignItems="center">
															<Typography>R$</Typography>
															<Typography>
																{formatDecimalValue(
																	totalSalesRow.totalIndirectFinancialCommissionCost,
																)}
															</Typography>
														</Box>
													</TableCell>
													<TableCell align="right">
														<Box display="flex" justifyContent="space-between" alignItems="center">
															<Typography>
																-
															</Typography>
														</Box>
													</TableCell>
												</TableRow>
												<TableRow>
													<TableCell>Lucro/Prejuízo Líquido</TableCell>
													<TableCell align="right">
														<Box display="flex" justifyContent="space-between" alignItems="center">
															<Typography>R$</Typography>
															<Typography
																sx={{
																	color: getColorForValue(
																		((totalSalesRow.total
																			- totalSalesRow.discountValue
																			- totalSalesRow.totalDirectCost)
																				- totalSalesRow.commissionValue
																				- totalSalesRow.totalIndirectFinancialCommissionCost
																		),
																	),
																	fontWeight: 'bold',
																}}
															>
																{formatDecimalValue((totalSalesRow.total
																	- totalSalesRow.discountValue
																	- totalSalesRow.totalDirectCost)
																		- totalSalesRow.commissionValue
																		- totalSalesRow.totalIndirectFinancialCommissionCost)}
															</Typography>
														</Box>
													</TableCell>
													<TableCell align="right">
														<Box display="flex" justifyContent="space-between" alignItems="center">
															<Typography
																sx={{
																	color: getColorForValue(
																		(((totalSalesRow.total
																			- totalSalesRow.discountValue
																			- totalSalesRow.totalDirectCost
																			- totalSalesRow.commissionValue
																			- totalSalesRow.totalIndirectFinancialCommissionCost)
																			/ totalSalesRow.totalWithDiscount) * 100),
																	),
																	fontWeight: 'bold',
																}}
															>
																{formatPercentage(
																	(((totalSalesRow.total
																		- totalSalesRow.discountValue
																		- totalSalesRow.totalDirectCost
																		- totalSalesRow.commissionValue
																		- totalSalesRow.totalIndirectFinancialCommissionCost)
																		/ totalSalesRow.totalWithDiscount) * 100),
																)}
															</Typography>
														</Box>
													</TableCell>
													<TableCell align="right">
														<Box display="flex" justifyContent="space-between" alignItems="center">
															<Typography>R$</Typography>
															<Typography>
																-
															</Typography>
														</Box>
													</TableCell>
													<TableCell align="right">
														<Box display="flex" justifyContent="space-between" alignItems="center">
															<Typography>R$</Typography>
															<Typography
																sx={{
																	color: getColorForValue(
																		(((totalSalesRow
																			.totalWithDiscount)
																				- (totalSalesRow.discountValue)
																				- (totalSalesRow.totalDirectCost
																				+ totalBonusRow.totalDirectCost))
																				- (totalSalesRow.commissionValue)
																				- totalSalesRow.totalIndirectFinancialCommissionCost
																		),
																	),
																	fontWeight: 'bold',
																}}
															>
																{formatDecimalValue(((totalSalesRow
																	.totalWithDiscount)
																		- (totalSalesRow.discountValue)
																		- (totalSalesRow.totalDirectCost
																		+ totalBonusRow.totalDirectCost))
																		- (totalSalesRow.commissionValue)
																		- totalSalesRow.totalIndirectFinancialCommissionCost)}
															</Typography>
														</Box>
													</TableCell>
													<TableCell align="right">
														<Box display="flex" justifyContent="space-between" alignItems="center">
															<Typography
																sx={{
																	color: getColorForValue(
																		(((((totalSalesRow.totalWithDiscount)
																			- (totalSalesRow.discountValue)
																			- (totalSalesRow.totalDirectCost
																			+ totalBonusRow.totalDirectCost))
																			- (totalSalesRow.commissionValue)
																		- totalSalesRow.totalIndirectFinancialCommissionCost)
																		/ totalSalesRow.totalWithDiscount) * 100),
																	),
																	fontWeight: 'bold',
																}}
															>
																{formatPercentage(((((totalSalesRow
																	.totalWithDiscount)
																		- (totalSalesRow.discountValue)
																		- (totalSalesRow.totalDirectCost
																		+ totalBonusRow.totalDirectCost))
																		- (totalSalesRow.commissionValue)
																	- totalSalesRow.totalIndirectFinancialCommissionCost)
																	/ totalSalesRow.totalWithDiscount) * 100)}
															</Typography>
														</Box>
													</TableCell>
												</TableRow>
											</TableBody>
										</Table>
									</TableContainer>
								</Grid>
								<Grid item xs={2}>
									<Typography variant="h6" gutterBottom>
										Simulação
									</Typography>
									<TableContainer component={Paper}>
										<Table
											size="small"
											stickyHeader
											sx={{
												'& .MuiTypography-root': {
													fontSize: '14px',
												},
											}}
										>
											<TableHead>
												<TableRow>
													<TableCell align="left">
														<strong>Total</strong>
													</TableCell>
													<TableCell align="left">
														<strong>(%)</strong>
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												<TableRow>
													<TableCell align="left">
														<Box display="flex" justifyContent="space-between" alignItems="center">
															<Typography>R$</Typography>
															<Typography>
																{formatDecimalValue(totalSalesRow.simulationPrice)}
															</Typography>
														</Box>
													</TableCell>
													<TableCell align="left">
														-
													</TableCell>
												</TableRow>
												<TableRow>
													<TableCell align="left">
														<Box display="flex" justifyContent="space-between" alignItems="center">
															<Typography>R$</Typography>
															<Typography>
																{formatDecimalValue(totalSalesRow
																	.discountValue)}
															</Typography>
														</Box>
													</TableCell>
													<TableCell align="left">
														-
													</TableCell>
												</TableRow>
												<TableRow>
													<TableCell align="left">
														<Box display="flex" justifyContent="space-between" alignItems="center">
															<Typography>R$</Typography>
															<Typography>
																{formatDecimalValue(totalSalesRow
																	.totalDirectCost + totalBonusRow.totalDirectCost)}
															</Typography>
														</Box>
													</TableCell>
													<TableCell align="left">
														-
													</TableCell>
												</TableRow>
												<TableRow>
													<TableCell align="left">
														<Box display="flex" justifyContent="space-between" alignItems="center">
															<Typography>R$</Typography>
															<Typography
																sx={{
																	color: getColorForValue(
																		(totalSalesRow.simulationPrice
																			- totalSalesRow.discountValue
																			- (totalSalesRow.totalDirectCost
																			+ totalBonusRow.totalDirectCost)),
																	),
																	fontWeight: 'bold',
																}}
															>
																{formatDecimalValue(totalSalesRow.simulationPrice
																	- totalSalesRow.discountValue
																	- (totalSalesRow.totalDirectCost
																	+ totalBonusRow.totalDirectCost))}
															</Typography>
														</Box>
													</TableCell>
													<TableCell align="left">
														<Box display="flex" justifyContent="space-between" alignItems="center">
															<Typography
																sx={{
																	color: getColorForValue(
																		(((totalSalesRow.simulationPrice
																			- totalSalesRow.discountValue
																			- (totalSalesRow.totalDirectCost
																			+ totalBonusRow.totalDirectCost))
																			/ totalSalesRow.simulationPrice) * 100),
																	),
																	fontWeight: 'bold',
																}}
															>
																{formatPercentage(((totalSalesRow.simulationPrice
																	- totalSalesRow.discountValue
																	- (totalSalesRow.totalDirectCost
																	+ totalBonusRow.totalDirectCost))
																	/ totalSalesRow.simulationPrice) * 100)}
															</Typography>
														</Box>
													</TableCell>
												</TableRow>
												<TableRow>
													<TableCell align="left">
														<Box display="flex" justifyContent="space-between" alignItems="center">
															<Typography>R$</Typography>
															<Typography>
																{formatDecimalValue((totalSalesRow.commission / 100)
																	* totalSalesRow.simulationPrice)}
															</Typography>
														</Box>
													</TableCell>
													<TableCell align="left">
														-
													</TableCell>
												</TableRow>
												<TableRow>
													<TableCell align="left">
														<Box display="flex" justifyContent="space-between" alignItems="center">
															<Typography>R$</Typography>
															<Typography>
																{formatDecimalValue(
																	totalSalesRow.totalSimulationIndirectFinancialCommissionCost,
																)}
															</Typography>
														</Box>
													</TableCell>
													<TableCell align="left">
														-
													</TableCell>
												</TableRow>
												<TableRow>
													<TableCell align="left">
														<Box display="flex" justifyContent="space-between" alignItems="center">
															<Typography>R$</Typography>
															<Typography
																sx={{
																	color: getColorForValue(
																		(((totalSalesRow
																			.simulationPrice)
																				- (totalSalesRow.discountValue)
																				- (totalSalesRow.totalDirectCost
																				+ totalBonusRow.totalDirectCost))
																				- ((totalSalesRow.commission / 100)
																				* totalSalesRow.simulationPrice)
																			- totalSalesRow
																				.totalSimulationIndirectFinancialCommissionCost),
																	),
																	fontWeight: 'bold',
																}}
															>
																{formatDecimalValue(((totalSalesRow
																	.simulationPrice)
																		- (totalSalesRow.discountValue)
																		- (totalSalesRow.totalDirectCost
																		+ totalBonusRow.totalDirectCost))
																		- ((totalSalesRow.commission / 100)
																		* totalSalesRow.simulationPrice)
																		- totalSalesRow.totalSimulationIndirectFinancialCommissionCost)}
															</Typography>
														</Box>
													</TableCell>
													<TableCell align="left">
														<Box display="flex" justifyContent="space-between" alignItems="center">
															<Typography
																sx={{
																	color: getColorForValue(
																		((((((totalSalesRow
																			.simulationPrice)
																				- (totalSalesRow.discountValue)
																				- (totalSalesRow.totalDirectCost
																				+ totalBonusRow.totalDirectCost))
																				- ((totalSalesRow.commission / 100)
																				* totalSalesRow.simulationPrice))
																			- totalSalesRow
																				.totalSimulationIndirectFinancialCommissionCost)
																			/ totalSalesRow
																				.simulationPrice) * 100),
																	),
																	fontWeight: 'bold',
																}}
															>
																{formatPercentage((((((totalSalesRow
																	.simulationPrice)
																		- (totalSalesRow.discountValue)
																		- (totalSalesRow.totalDirectCost
																		+ totalBonusRow.totalDirectCost))
																		- ((totalSalesRow.commission / 100)
																		* totalSalesRow.simulationPrice))
																	- totalSalesRow.totalSimulationIndirectFinancialCommissionCost)
																	/ totalSalesRow
																		.simulationPrice) * 100)}
															</Typography>
														</Box>
													</TableCell>
												</TableRow>
											</TableBody>
										</Table>
									</TableContainer>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
					<Box style={{ height: 400, width: '100%', marginTop: '20px' }}>
						{selectedItemWithoutPriceFormation && (
							<Box sx={{ width: '100%', marginBottom: '8px' }}>
								<Alert severity="warning">
									O item selecionado não possui Formação de Preço.
								</Alert>
							</Box>
						)}
						<DataGrid
							rows={rows}
							columns={profitabilityAnalysisFormColumns}
							onSelectionModelChange={handleRowSelection}
							getRowClassName={(params) => {
								const { row } = params;
								if (params.id === 'totalSales' || params.id === 'totalBonus') {
									return 'bold-row';
								}
								if (!row.salePriceFormation) {
									return 'row-error';
								}
								return '';
							}}
							sx={{
								'& .bold-row': {
									fontWeight: 'bold',
								},
								'& .row-error': {
									backgroundColor: 'rgba(255, 0, 0, 0.1)',
									'&:hover': {
										backgroundColor: 'rgba(255, 0, 0, 0.15)',
									},
									'&.Mui-selected': {
										backgroundColor: 'rgba(255, 0, 0, 0.2) !important',
									},
								},
							}}
						/>
					</Box>
					{simulationDrawerMemo}
				</Box>
			</Form>
		</FormikContext.Provider>
	);
};

ProfitabilityAnalysisForm.defaultProps = {
	saleOrderSimulation: undefined,
};

export default ProfitabilityAnalysisForm;

import React from 'react';
import {
	Card as MuiCard, CardContent, CardProps, Stack, Typography,
} from '@mui/material';

interface DashboardCardProps extends CardProps {
	children: JSX.Element;
	title?: string;
}

const Card = (props: DashboardCardProps): JSX.Element => {
	const { title, children } = props;

	return (
		<MuiCard sx={{ width: '100%' }}>
			<CardContent component={Stack} sx={{ height: '100%', width: '100%' }}>
				<Typography sx={{ fontSize: 14, mb: 3 }} color="text.secondary" gutterBottom>
					{title?.toUpperCase()}
				</Typography>
				{children}
			</CardContent>
		</MuiCard>
	);
};

Card.defaultProps = {
	title: '',
};

export default Card;

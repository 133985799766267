import { useContext, useEffect, useMemo } from 'react';
import { DashboardSection } from '../components/Common/Dashboard/DefaultDashboard';
import { BranchContext } from '../contexts/BranchContext';

export interface CardDefinition<StatusKey extends string | number = string | number> {
  key: StatusKey;
  title: string;
  icon: React.ReactNode;
  color: string;
}

export interface SectionDefinition<
  SectionKey extends string | number = string | number,
  DataType = any,
  StatusKey extends string | number = string | number
> {
  id: SectionKey;
  title: string;
  icon: React.ReactNode;
  iconColor: string;
  accordionColor: string;
  totalCountFn: (data: DataType) => number;
  totalTooltip: string;
  getStatusCount: (data: DataType, statusKey: StatusKey) => number;
}

export function useDashboard<
  SectionKey extends string | number = string | number,
  StatusKey extends string | number = string | number,
  DataType = any
>(
	data: DataType,
	sections: Array<SectionDefinition<SectionKey, DataType, StatusKey>>,
	statusCards: Record<string | number, CardDefinition<StatusKey>>,
	onStatusClick: (sectionId: SectionKey, statusKey: StatusKey) => void,
	fetchStatusData?: () => void,
): DashboardSection[] {
	const { refreshTriggerBranch } = useContext(BranchContext);

	useEffect(() => {
		if (fetchStatusData) {
			fetchStatusData();
		}
	}, [fetchStatusData, refreshTriggerBranch.branchId]);

	return useMemo(() => sections.map((section) => {
		const cards = Object.entries(statusCards).map(([keyString, cardDef]) => {
			const statusKey = Number.isNaN(Number(keyString))
				? keyString as unknown as StatusKey
				: Number(keyString) as unknown as StatusKey;

			return {
				id: typeof statusKey === 'string' ? 0 : Number(statusKey),
				title: cardDef.title,
				icon: cardDef.icon,
				color: cardDef.color,
				count: section.getStatusCount(data, statusKey),
				onClick: () => onStatusClick(section.id, statusKey),
			};
		});

		return {
			id: typeof section.id === 'string' ? 0 : Number(section.id),
			title: section.title,
			icon: section.icon,
			iconColor: section.iconColor,
			accordionColor: section.accordionColor,
			totalCount: section.totalCountFn(data),
			totalTooltip: section.totalTooltip,
			cards,
		};
	}), [data, sections, statusCards, onStatusClick]);
}

import { IProductWithoutDetails } from '../containers/Product/ProductAssets';
import { ILinkProductBusinessPartner } from '../interfaces/FiscalDocumentParams';

interface FilterProductsParams {
    selectedProducts: IProductWithoutDetails[];
    availableProducts: IProductWithoutDetails[];
    postData: ILinkProductBusinessPartner[];
    currentProductBusinessPartnerId: string;
  }

export const removeDuplicateProducts = (
	products: IProductWithoutDetails[],
): IProductWithoutDetails[] => products.filter(
	(product, index, self) => index === self.findIndex((p) => p.id === product.id),
);

export const getFilteredProducts = ({
	selectedProducts,
	availableProducts,
	postData,
	currentProductBusinessPartnerId,
}: FilterProductsParams): IProductWithoutDetails[] => {
	const currentMapping = postData.find(
		(postItem) => postItem.productBusinessPartnerId === currentProductBusinessPartnerId,
	);

	const filteredProducts = availableProducts.filter((product) => {
		const isCurrentProduct = currentMapping && currentMapping.productId === product.id;
		const isAlreadySelected = postData.some((postItem) => postItem.productId === product.id);

		return !isAlreadySelected || isCurrentProduct;
	});

	return removeDuplicateProducts([...selectedProducts, ...filteredProducts]);
};

export const getProductValue = (
	products: IProductWithoutDetails[],
	selectedProducts: IProductWithoutDetails[],
	formikValue: string | null,
): IProductWithoutDetails | null => {
	if (!formikValue) return null;

	const allProducts = [...selectedProducts, ...products];
	return allProducts.find((product) => product.id === formikValue) || null;
};

import React, {
	useCallback,
	useMemo,
	useState,
} from 'react';
import {
	Form,
	FieldArray,
	useFormik,
	FormikContext,
} from 'formik';
import difference from 'lodash/difference';
import jwtDecode from 'jwt-decode';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Icon, Tooltip } from '@mui/material';
import Input from '../Common/Form/Input';
import {
	IAccessGroup,
	EditAccessGroup,
	initialValues,
	validationSchema,
	Module,
} from '../../containers/AccessGroup/AccessGroupAssets';
import Autocomplete from '../Common/Form/Autocomplete';
import {
	ILicense,
	ILicenseDetails,
} from '../../containers/License/LicenseAssets';
import Select from '../Common/Form/Select';
import Checkbox from '../Common/Form/Checkbox';

interface AccessGroupFormProps {
	accessGroup?: IAccessGroup;
	users: Array<{ id: string; name: string; login: string }>;
	licenses: ILicense[];
	onSubmit: (values: EditAccessGroup) => void;
	loading: boolean;
}

const AccessGroupForm: React.FC<AccessGroupFormProps> = ({
	accessGroup,
	users,
	licenses,
	onSubmit,
	loading,
}) => {
	const [allModules, setAllModules] = useState<boolean>(false);

	const initialValuesLoad = useMemo(
		() => (accessGroup ? {
			name: accessGroup.name,
			licenseId: accessGroup.licenseId,
			usersId: accessGroup.users?.map((user) => user.id) || [],
			modules: accessGroup.modules || [],
		} : initialValues),
		[accessGroup],
	);

	const formik = useFormik<EditAccessGroup>({
		initialValues: initialValuesLoad,
		validationSchema,
		onSubmit: (values) => onSubmit({
			usersId: values.usersId,
			name: values.name,
			modules: values.modules,
			licenseId: values.licenseId,
		}),
		validateOnBlur: false,
		validateOnChange: false,
	});

	const license = licenses.find((currentLicense) => currentLicense.id === formik.values.licenseId);
	// eslint-disable-next-line no-underscore-dangle
	const inUse = license?.accessGroups?.reduce((acc, curr) => acc + curr._count.users, 0) || 0;
	const details = license ? { ...jwtDecode(license.license), inUse } as ILicenseDetails : null;
	const removedDiff = difference(initialValuesLoad.usersId, formik.values.usersId).length;
	const addedDiff = difference(formik.values.usersId, initialValuesLoad.usersId).length;
	const availableLicenses = (details
		&& details.nof ? details.nof - inUse + removedDiff - addedDiff : 0);

	const handleAllCanEditChange = useCallback((checked: boolean) => {
		const modules = Array.isArray(formik.values.modules) ? formik.values.modules : [];
		formik.setFieldValue('', true);
		modules.forEach((_, index) => {
			formik.setFieldValue(`modules.${index}.canEdit`, checked);
		});
	}, [formik]);

	const handleAllIsAdminChange = useCallback((checked: boolean) => {
		const modules = Array.isArray(formik.values.modules) ? formik.values.modules : [];
		modules.forEach((_, index) => {
			formik.setFieldValue(`modules.${index}.isAdmin`, checked);
		});
	}, [formik]);

	return (
		<FormikContext.Provider value={formik}>
			<Form noValidate>
				<Paper sx={{ mt: 3, p: 3 }} elevation={3}>
					<Stack spacing="4rem">
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Input.InputField
									id="name"
									name="name"
									label="Nome"
									required
									fullWidth
								/>
							</Grid>
							<Grid item xs={12}>
								<Select
									name="licenseId"
									label="Licença"
									options={licenses}
									labelKey="identifier"
									valueKey="id"
									required
								/>
							</Grid>
							{formik.values.licenseId && (
								<>
									<Grid item xs={4}>
										<Typography variant="subtitle2">Número de Licenças</Typography>
										<Typography>{details?.nof}</Typography>
									</Grid>
									<Grid item xs={4}>
										<Typography variant="subtitle2">Licenças em uso</Typography>
										<Typography>{details?.inUse}</Typography>
									</Grid>
									<Grid item xs={4}>
										<Typography variant="subtitle2">Licenças disponíveis</Typography>
										<Typography>{availableLicenses}</Typography>
									</Grid>
								</>
							)}
							{ formik.values.modules && details && (
								<Grid item xs={12}>
									<Typography variant="h5">Módulos</Typography>
									<TableContainer component={Paper}>
										<Table size="small" aria-label="simple table">
											<TableHead>
												<TableRow sx={{ '& th': { fontSize: '1rem', fontWeight: 500 } }}>
													<TableCell>
														<Stack direction="row" alignItems="center">
															<Tooltip title="Habilitar todos os módulos">
																<Switch
																	checked={allModules}
																	onChange={() => {
																		setAllModules((prevState) => {
																			const newState = !prevState;
																			const currentModules = formik.values.modules as Module[];

																			if (newState) {
																				const modulesToAdd = details.mod.filter((
																					module,
																				) => !currentModules.some(
																					(m) => m.module.code === module.code,
																				));

																				const newModules = [
																					...currentModules,
																					...modulesToAdd.map((module) => ({
																						module,
																						canEdit: false,
																						isAdmin: false,
																					})),
																				];

																				formik.setFieldValue('modules', newModules);
																			} else {
																				formik.setFieldValue('modules', []);
																			}

																			return newState;
																		});
																	}}
																/>
															</Tooltip>
														</Stack>

													</TableCell>
													<TableCell>Ícone</TableCell>
													<TableCell>Módulo</TableCell>
													<TableCell>
														<Stack direction="row" alignItems="center">
															<Checkbox.SingleCheckbox
																name="allCanEdit"
																onChangeValue={handleAllCanEditChange}
															/>
															<Typography
																fontWeight={500}
																sx={{ marginLeft: -2 }}
															>
																Pode Editar
															</Typography>
														</Stack>
													</TableCell>
													<TableCell>
														<Stack direction="row" alignItems="center">
															<Checkbox.SingleCheckbox
																name="allIsAdmin"
																onChangeValue={handleAllIsAdminChange}
															/>
															<Typography
																fontWeight={500}
																sx={{ marginLeft: -2 }}
															>
																Admin
															</Typography>
														</Stack>
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												<FieldArray name="modules">
													{({ push, remove }) => (
														details.mod.map((module) => {
															const currentModule = (formik.values.modules as Module[])
																.findIndex((mod) => mod.module.code === module.code);

															return (
																<TableRow key={module.code}>
																	<TableCell>
																		<Switch
																			checked={currentModule > -1}
																			onChange={(
																				ev,
																				checked,
																			) => (checked ? push({ module }) : remove(currentModule))}
																		/>
																	</TableCell>
																	<TableCell>
																		<Icon>{module.icon}</Icon>
																	</TableCell>
																	<TableCell>
																		{module.name}
																	</TableCell>
																	<TableCell>
																		{currentModule > -1 && (
																			<Checkbox.SingleCheckbox
																				name={`modules.${currentModule}.canEdit`}
																				onChangeValue={() => formik.setFieldValue('allCanEdit', undefined)}
																			/>
																		)}
																	</TableCell>
																	<TableCell>
																		{currentModule > -1 && (
																			<Checkbox.SingleCheckbox
																				name={`modules.${currentModule}.isAdmin`}
																				onChangeValue={() => formik.setFieldValue('allIsAdmin', undefined)}
																			/>
																		)}
																	</TableCell>
																</TableRow>
															);
														})
													)}
												</FieldArray>
											</TableBody>
										</Table>
									</TableContainer>
								</Grid>
							)}
							<Grid item xs={12}>
								<Autocomplete<{ name: string; id: string; login: string }>
									multiple
									limitTags={details?.nof || 2}
									label="Usuários"
									name="usersId"
									options={users || []}
									labelKey="name"
									valueKey="id"
									valueLabel="login"
									disableAllOptions={availableLicenses <= 0}
								/>
							</Grid>
						</Grid>
						<Box>
							<LoadingButton
								loading={loading}
								variant="contained"
								color="primary"
								type="submit"
							>
								Salvar
							</LoadingButton>
						</Box>
					</Stack>
				</Paper>
			</Form>
		</FormikContext.Provider>
	);
};

AccessGroupForm.defaultProps = {
	accessGroup: undefined,
};

export default AccessGroupForm;

import { useEffect } from 'react';

interface UseConditionalSearchProps<TData, TQuery> {
	inputValue: string;
	loadedItems: TData[];
	searchFunction: (queryParams: TQuery) => void;
	minLength: number;
	compareKey: keyof TData;
	compareKeySecondary?: keyof TData;
	queryParam?: keyof TQuery;
	maxLength?: number;
  }

export function useConditionalSearch<TData, TQuery>({
	inputValue,
	loadedItems,
	searchFunction,
	minLength,
	compareKey,
	compareKeySecondary,
	queryParam,
	maxLength,
}: UseConditionalSearchProps<TData, TQuery>): void {
	useEffect(() => {
		if (inputValue.length < minLength) return;

		if (maxLength !== undefined && inputValue.length > maxLength) return;

		const hasItemsLoaded = loadedItems.some(
			(item) => {
				const primaryMatch = String(item[compareKey])
					.toLowerCase()
					.includes(inputValue.toLowerCase());

				if (compareKeySecondary) {
					const secondaryMatch = String(item[compareKeySecondary])
						.toLowerCase()
						.includes(inputValue.toLowerCase());
					return primaryMatch || secondaryMatch;
				}

				return primaryMatch;
			},
		);

		if (!hasItemsLoaded) {
			if (queryParam) {
				searchFunction({ [queryParam]: inputValue } as TQuery);
			} else {
				searchFunction(inputValue as unknown as TQuery);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputValue]);
}

import React, { useCallback, useEffect, useState } from 'react';
import {
	Drawer,
	Box,
	Typography,
	Grid,
	TableContainer,
	Paper,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	IconButton,
	FormControl,
} from '@mui/material';
import { Form, FormikContext, useFormik } from 'formik';
import { Check, Delete } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import Autocomplete from '../../Common/Form/Autocomplete';
import { LocationProductQueryParams } from '../../../interfaces/LocationProduct';
import { ILocation } from '../../../containers/Location/LocationAssets';
import Input from '../../Common/Form/Input';
import { IProductWithoutDetails } from '../../../containers/Product/ProductAssets';
import { ProductQueryParams } from '../../../interfaces/ProductQueryParams';
import Select from '../../Common/Form/Select';
import { useConditionalSearch } from '../../../hooks/useConditionalSearchProps';
import { CreateConsumptionTasksParams } from '../../../containers/Consumption/ConsumptionAssets';
import { ConsumptionType } from '../../../enums/ConsumptionType';

interface SelectedProduct {
	id: string
	fullDescription: string
	quantity: number
}

export interface ConsumptionDrawerProps {
	open: boolean
	onClose: () => void;
	getLocations: (queryParams?: LocationProductQueryParams) => void;
	locations: ILocation[];
	products: IProductWithoutDetails[];
	getProducts: (queryParams: ProductQueryParams) => void;
	createConsumptionTasks: (params: CreateConsumptionTasksParams) => void;
}

const ConsumptionDrawer = ({
	open,
	onClose,
	getLocations,
	locations,
	products,
	getProducts,
	createConsumptionTasks,
}: ConsumptionDrawerProps): JSX.Element | null => {
	const [selectedProducts, setSelectedProducts] = useState<SelectedProduct[]>([]);
	const [productInputValue, setProductInputValue] = useState('');

	const formik = useFormik({
		initialValues: {
			locationId: '',
			quantity: 0,
			operatorName: '',
			productId: '',
			type: undefined,
		},
		onSubmit: (values) => {
			if (values.type !== undefined && values.locationId && selectedProducts.length > 0) {
				const tasks = selectedProducts.map((product) => ({
					productId: product.id,
					quantity: Number(product.quantity),
				}));
				createConsumptionTasks({
					type: values.type,
					locationId: values.locationId,
					operatorName: values.operatorName,
					tasks,
				});
				onClose();
			}
			formik.resetForm();
			setSelectedProducts([]);
		},
	});

	useEffect(() => {
		if (open) {
			getLocations({ skip: 0, name: 'Balcão' });
		}
	}, [getLocations, open]);

	useConditionalSearch({
		inputValue: productInputValue,
		loadedItems: products,
		searchFunction: getProducts,
		minLength: 2,
		compareKey: 'code',
		compareKeySecondary: 'description',
	});

	const handleAddProduct = useCallback(():void => {
		const { productId } = formik.values;
		const { quantity } = formik.values;
		const productSelected = products.find((product) => product.id === productId);
		if (productSelected && quantity) {
			setSelectedProducts([...selectedProducts, {
				id: productSelected.id,
				fullDescription: `${productSelected.code} - ${productSelected.description}`,
				quantity,
			}]);
			formik.setFieldValue('productId', '');
			formik.setFieldValue('quantity', '');
		}
	}, [formik, products, selectedProducts]);

	return (
		<FormikContext.Provider value={formik}>
			<Drawer
				variant="temporary"
				anchor="right"
				open={open}
				onClose={onClose}
				sx={{
					'& .MuiDrawer-paper': {
						width: '650px',
					},
				}}
			>
				<Form noValidate>
					<Box
						sx={{
							marginTop: '60px',
							width: '100%',
							padding: 4,
							height: '100%',
							boxSizing: 'border-box',
						}}
						role="presentation"
					>
						<Typography variant="h5" color="primary" sx={{ mb: 2 }}>Consumo</Typography>
						<Select
							label="Tipo de consumo"
							name="type"
							labelKey="name"
							valueKey="id"
							sx={{ mb: 2 }}
							options={[
								{
									id: ConsumptionType.REQUISITION,
									name: 'Requisição',
								},
								{
									id: ConsumptionType.DEVOLUTION,
									name: 'Devolução',
								},
							]}
							required
						/>
						<Autocomplete
							label={formik.values.type === ConsumptionType.DEVOLUTION ? 'Localização de origem' : 'Localização de destino'}
							name="locationId"
							valueKey="id"
							valueLabel="barCode"
							formatBarCode
							labelKey="name"
							options={locations}
							sx={{ mb: 2 }}
							closeOnSelect
							fullWidth
							required
						/>
						<Input.InputField
							name="operatorName"
							label="Solicitante"
							sx={{ mb: 2 }}
							fullWidth
							required
						/>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TableContainer component={Paper} sx={{ padding: 1 }}>
									<Table stickyHeader>
										<TableHead>
											<TableRow>
												<TableCell sx={{ width: '65%' }}>Descrição</TableCell>
												<TableCell align="right" sx={{ width: '30%' }}>Quantidade</TableCell>
												<TableCell align="right" sx={{ width: '5%' }}>Ações</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{selectedProducts.map((product, index) => (
												<TableRow key={product.id}>
													<TableCell sx={{ padding: '6px 16px' }}>{product.fullDescription}</TableCell>
													<TableCell align="right">{product.quantity}</TableCell>
													<TableCell align="right">
														<IconButton
															onClick={() => {
																setSelectedProducts(selectedProducts.filter((_, i) => i !== index));
															}}
															color="error"
														>
															<Delete />
														</IconButton>
													</TableCell>
												</TableRow>
											))}
											<TableRow>
												<TableCell>
													<FormControl fullWidth margin="dense">
														<Autocomplete
															name="productId"
															label="Produto"
															options={products}
															labelKey="description"
															valueKey="id"
															valueLabel="code"
															closeOnSelect
															onInputChange={(
																_event,
																newInputValue,
															) => setProductInputValue(newInputValue)}
														/>
													</FormControl>
												</TableCell>
												<TableCell align="right">
													<Input.InputField
														label="Quantidade"
														name="quantity"
														variant="outlined"
														margin="dense"
														type="number"
														inputProps={{ min: 0 }}
														fullWidth
													/>
												</TableCell>
												<TableCell align="right">
													<IconButton
														onClick={handleAddProduct}
														color="primary"
														disabled={!formik.values.productId
															|| (!formik.values.quantity || formik.values.quantity <= 0)}
													>
														<Check />
													</IconButton>
												</TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</TableContainer>
							</Grid>
							<Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
								<LoadingButton
									variant="contained"
									type="submit"
									color="primary"
									disabled={
										selectedProducts.length === 0
									|| formik.values.type === undefined
									|| !formik.values.locationId
									|| !formik.values.operatorName
									}
								>
									Criar
								</LoadingButton>
							</Grid>
						</Grid>
					</Box>
				</Form>
			</Drawer>
		</FormikContext.Provider>
	);
};

export default ConsumptionDrawer;

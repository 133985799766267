import React, { useMemo, useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box } from '@mui/material';
import { PageHeaderMenuProps } from '../../../interfaces/PageHeaderInterface';

const menuPaperProps = {
	elevation: 3,
	sx: {
		overflow: 'visible',
		filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.15))',
		borderRadius: '8px',
		mt: 1.5,
		'& .MuiList-root': {
			padding: '4px 0',
		},
		'& .MuiMenuItem-root': {
			padding: '10px 16px',
			transition: 'background-color 0.2s ease',
			textTransform: 'uppercase',
			fontWeight: 500,
			'&:hover': {
				backgroundColor: 'rgba(0,0,0,0.04)',
			},
		},
	},
};

const iconButtonStyle = {
	borderRadius: '8px',
	padding: '8px',
	transition: 'all 0.2s ease',
	'&:hover': {
		backgroundColor: 'rgba(0,0,0,0.04)',
		transform: 'rotate(90deg)',
	},
};

export const PageHeaderMenu = ({
	options,
}: PageHeaderMenuProps): JSX.Element => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [open, setOpen] = useState(false);

	const handleClick = (event: any): void => {
		setAnchorEl(event.currentTarget);
		setOpen(true);
	};

	const handleClose = (): void => {
		setAnchorEl(null);
		setOpen(false);
	};

	const optionsMemo = useMemo(
		() => options.map((option) => {
			const { onClick, disabled } = option;
			const { sx } = option;

			return (
				<MenuItem
					key={option.text}
					onClick={(e) => {
						handleClose();
						if (onClick) {
							onClick(e as unknown as React.MouseEvent<HTMLButtonElement, MouseEvent>);
						}
					}}
					disabled={disabled}
					sx={{
						...(option.startIcon && { pl: 1 }),
						...(sx || {}),
					}}
				>
					{option.startIcon && (
						<Box component="span" sx={{ mr: 1.5, display: 'flex' }}>
							{option.startIcon}
						</Box>
					)}
					{option.text}
				</MenuItem>
			);
		}),
		[options],
	);

	return (
		<>
			<IconButton onClick={handleClick} sx={iconButtonStyle}>
				<MoreVertIcon />
			</IconButton>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
				PaperProps={menuPaperProps}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				{optionsMemo}
			</Menu>
		</>
	);
};

import React, { useCallback, useEffect, useRef } from 'react';
import {
	Card,
	CardActions,
	CardContent,
	Typography,
} from '@mui/material';
import {
	Form, FormikContext, useFormik,
} from 'formik';
import { useNavigate } from 'react-router-dom';
import Input from '../../../../../Common/Form/Input';
import { formatBarCode } from '../../../../../../helpers/masks';
import Button from '../../../../../Common/Button/Button';

interface InventoryStartProps {
	confirmLocation: (locationBarCode: string) => void;
}

const StepLocation = ({
	confirmLocation,
}: InventoryStartProps):JSX.Element => {
	const inputRef = useRef<HTMLInputElement>(null);
	const navigate = useNavigate();

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	}, []);

	const formik = useFormik({
		initialValues: {
			locationBarCode: '',
		},
		onSubmit: (values) => {
			confirmLocation(values.locationBarCode);
		},
	});

	const handleBlurOrEnter = useCallback((event: React.KeyboardEvent<HTMLInputElement>): void => {
		if ((event.key === 'Tab') || (event.key === 'Enter')) {
			event.preventDefault();
			confirmLocation(formik.values.locationBarCode);
		}
	}, [confirmLocation, formik.values.locationBarCode]);

	return (
		<FormikContext.Provider value={formik}>
			<Form>
				<Card>
					<CardContent>
						<Typography textAlign="center" variant="h6" gutterBottom>
							Localização
						</Typography>
						<Typography
							textAlign="center"
							variant="subtitle1"
							gutterBottom
							sx={{ marginBottom: 2 }}
						>
							Leia o código de barras da localização
						</Typography>

						<Input.InputField
							id="locationBarCode"
							name="locationBarCode"
							label="Código de Barras da Localização"
							format={(value) => formatBarCode(value)}
							autoComplete="off"
							inputRef={inputRef}
							onKeyDown={handleBlurOrEnter}
							fullWidth
							required
						/>
					</CardContent>
					<CardActions>
						<Button
							variant="contained"
							size="large"
							type="submit"
							fullWidth
						>
							Confirmar
						</Button>
					</CardActions>
				</Card>
				<Button variant="text" sx={{ my: 2 }} onClick={() => navigate(-1)}>
					Voltar
				</Button>
			</Form>
		</FormikContext.Provider>
	);
};

export default StepLocation;

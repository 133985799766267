import React, { useMemo } from 'react';
import {
	Alert, Box, FormGroup, Theme, InputAdornment, Zoom,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { SxProps } from '@mui/system';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import LoginIcon from '@mui/icons-material/Login';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import InfoIcon from '@mui/icons-material/Info';
import Input from '../Common/Form/Input';
import { LoginData } from '../../services/auth';

interface LoginFormProps {
  loading: boolean;
  errorMessage: string;
  alreadyLogged: boolean;
  handleSubmit: (loginData: LoginData) => void;
}

const form: SxProps<Theme> = (theme) => ({
	width: '100%',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	gap: theme.spacing(3),
});

const loginButton: SxProps<Theme> = (theme) => ({
	marginTop: theme.spacing(2),
	height: '48px',
	width: '100%',
	fontWeight: 500,
	letterSpacing: '0.5px',
	textTransform: 'none',
	fontSize: '1rem',
	borderRadius: '8px',
	gap: '8px',
	boxShadow: theme.shadows[2],
	transition: theme.transitions.create(['background-color', 'box-shadow', 'transform'], {
		duration: '0.2s',
	}),
	'&:hover': {
		boxShadow: theme.shadows[4],
		transform: 'translateY(-2px)',
		backgroundColor: theme.palette.primary.dark,
	},
	'&:active': {
		boxShadow: theme.shadows[1],
		transform: 'translateY(0)',
	},
	'& .MuiLoadingButton-loadingIndicator': {
		color: 'white',
	},
	'& .MuiSvgIcon-root': {
		fontSize: '1.25rem',
	},
});

const alert: SxProps<Theme> = {
	width: '100%',
	boxSizing: 'border-box',
	borderRadius: 1.5,
	boxShadow: '0 2px 8px rgba(0, 0, 0, 0.08)',
	'& .MuiAlert-icon': {
		opacity: 0.8,
	},
	display: 'flex',
	alignItems: 'center',
};

const inputField: SxProps<Theme> = (theme) => ({
	'& .MuiOutlinedInput-root': {
		borderRadius: theme.shape.borderRadius * 0.5,
		transition: theme.transitions.create(['box-shadow', 'border-color']),
		'&:hover': {
			boxShadow: '0 2px 8px rgba(0, 0, 0, 0.05)',
		},
		'&.Mui-focused': {
			boxShadow: '0 4px 12px rgba(0, 0, 0, 0.08)',
		},
		'& .MuiOutlinedInput-input': {
			padding: theme.spacing(1.5, 2),
		},
		'& .MuiInputAdornment-root': {
			marginRight: theme.spacing(1),
		},
	},
});

const validationSchema = Yup.object().shape({
	email: Yup.string()
		.required('Login é obrigatório'),
	password: Yup.string()
		.required('Senha é obrigatória'),
});

const LoginForm = ({
	loading,
	errorMessage,
	alreadyLogged,
	handleSubmit,
}: LoginFormProps): JSX.Element => {
	const invalidLoginError = useMemo(() => {
		if (errorMessage) {
			return (
				<Zoom in>
					<Alert
						sx={alert}
						severity="error"
						icon={<WarningAmberIcon />}
					>
						{errorMessage}
					</Alert>
				</Zoom>
			);
		}

		return null;
	}, [errorMessage]);

	const alreadyLoggedInfo = useMemo(() => {
		if (alreadyLogged) {
			return (
				<Zoom in>
					<Alert
						sx={alert}
						severity="info"
						icon={<InfoIcon />}
					>
						Já existe uma sessão aberta para o seu usuário. Caso deseje entrar aqui,
						a outra sessão será encerrada e você perderá qualquer trabalho não salvo.
					</Alert>
				</Zoom>
			);
		}

		return null;
	}, [alreadyLogged]);

	return (
		<Formik
			initialValues={{
				email: '',
				password: '',
			}}
			validationSchema={validationSchema}
			onSubmit={(values: { email: string; password: string }) => {
				handleSubmit({ username: values.email, password: values.password });
			}}
		>
			<Box
				component={Form}
				sx={form}
				noValidate
			>
				{invalidLoginError}
				<FormGroup sx={{ width: '100%', display: 'flex', rowGap: '1.5rem' }}>
					<Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '1.5rem' }}>
						<Input.InputField
							id="email"
							name="email"
							type="text"
							label="Login/E-mail"
							placeholder="Digite seu login ou e-mail"
							fullWidth
							sx={inputField}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<PersonOutlineIcon color="action" />
									</InputAdornment>
								),
							}}
						/>
						<Input.PasswordInputField
							id="password"
							name="password"
							label="Senha"
							placeholder="Digite sua senha"
							fullWidth
							sx={inputField}
						/>
						{alreadyLoggedInfo}
					</Box>
					<LoadingButton
						loading={loading}
						variant="contained"
						color="primary"
						type="submit"
						size="large"
						startIcon={!loading && <LoginIcon />}
						sx={loginButton}
					>
						{alreadyLogged ? 'Entrar mesmo assim' : 'Entrar'}
					</LoadingButton>
				</FormGroup>
			</Box>
		</Formik>
	);
};

export default LoginForm;

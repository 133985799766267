import { BusinessPartnerStatus } from '../enums/Approved';
import { BusinessPartnerType } from '../enums/BusinessPartnerType';

export const BusinessPartnerTypeLabels = {
	[BusinessPartnerType.CLIENT]: 'Cliente',
	[BusinessPartnerType.SUPPLIER]: 'Fornecedor',
	[BusinessPartnerType.SALESMAN]: 'Vendedor/Representante',
	[BusinessPartnerType.DELIVERY]: 'Transportador',
};

export const BusinessPartnerStatusLabels = {
	[BusinessPartnerStatus.APPROVED]: 'Sim',
	[BusinessPartnerStatus.NOT_APPROVED]: 'Não',
};

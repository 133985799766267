import React, {
	Dispatch, Reducer, useReducer, useMemo,
} from 'react';
import { AxiosError } from 'axios';
import {
	OptionsObject, SnackbarMessage, SnackbarKey, useSnackbar,
} from 'notistack';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import FiscalDocumentEntryEditPresentational from '../../components/FiscalDocumentEntry/FiscalDocumentEntryEdit';
import { IInvoiceHeader, IInvoiceItem } from './FiscalDocumentEntryAssets';
import {
	ICreateTask, ICreateTaskFromInvoice, ILinkProductBusinessPartner, ProductsInvoiceQueryParams,
} from '../../interfaces/FiscalDocumentParams';
import { linkProductBusinessPartner, getProductsInvoice } from '../../services/invoice';
import { getProductsWithoutDetails } from '../../services/product';
import { ProductQueryParams } from '../../interfaces/ProductQueryParams';
import { IProductWithoutDetails } from '../Product/ProductAssets';
import { InventoryTaskAction } from '../../enums/InventoryTaskAction';
import { getLocations } from '../../services/location';
import { ILocation } from '../Location/LocationAssets';
import { LocationProductQueryParams } from '../../interfaces/LocationProduct';
import { createTaskFromInvoice, createTasksInvoice } from '../../services/inventoryTask';

enum ActionType {
	LOADING,
	INVOICE_ITEM,
	PRODUCT,
	LOCATION
}

interface IState {
	loading: boolean;
	invoiceItems: IInvoiceItem[];
	invoice?: IInvoiceHeader;
	products: IProductWithoutDetails[];
	locations: ILocation[];
}

type TAction =
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| { type: ActionType.PRODUCT; payload: { products: IProductWithoutDetails[] } }
	| { type: ActionType.LOCATION; payload: { locations: ILocation[] } }
	| { type: ActionType.INVOICE_ITEM; payload: {
		invoiceItems: IInvoiceItem[];
		invoice: IInvoiceHeader;
	} };

interface IFiscalDocumentEntryEditActions {
	setLoading(loading: boolean): void;
	getProductsInvoice(
		invoiceId: string,
		params?: ProductsInvoiceQueryParams,
		setLoading?: boolean
	): void;
	getProductsWithoutDetails(queryParams: ProductQueryParams): void;
	linkProductBusinessPartner(data: ILinkProductBusinessPartner[]): void;
	createTasksInvoice(action: InventoryTaskAction, data: ICreateTask): void;
	getLocations: (queryParams?: LocationProductQueryParams) => void;
	createTaskFromInvoice: (
		inventoryTaskInvoiceId: string,
		data: ICreateTaskFromInvoice,
	) => void;
	handleEdit: (businessPartnerId: string) => void;
}

const initialState: IState = {
	loading: false,
	invoiceItems: [],
	invoice: undefined,
	products: [],
	locations: [],
};

let lastinvoiceIdParam: string;

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.INVOICE_ITEM:
			return {
				...state,
				invoiceItems: action.payload.invoiceItems,
				invoice: action.payload.invoice,
			};
		case ActionType.LOCATION:
			return { ...state, locations: action.payload.locations };
		case ActionType.PRODUCT:
			return {
				...state,
				products: action.payload.products,
			};
		default:
			throw new Error();
	}
};

const FiscalDocumentEntryEditActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (
		message: SnackbarMessage,
		options?: OptionsObject | undefined
	) => SnackbarKey,
	navigate: NavigateFunction,
): IFiscalDocumentEntryEditActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		getProductsInvoice(
			invoiceId: string,
			params?: ProductsInvoiceQueryParams,
			setLoading?: boolean,
		) {
			actions.setLoading(setLoading !== false);
			getProductsInvoice(invoiceId, params)
				.then((response) => {
					dispatch({
						type: ActionType.INVOICE_ITEM,
						payload: {
							invoice: response.data.invoice,
							invoiceItems: response.data.items,
						},
					});
					lastinvoiceIdParam = invoiceId;
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(
						error.response?.data.message
			|| 'Algum erro ocorreu ao obter os produtos da NF. Tente novamente ou contate um administrador.',
						{ variant: 'error' },
					);
				})
				.finally(() => {
					actions.setLoading(false);
				});
		},
		getProductsWithoutDetails(queryParams: ProductQueryParams) {
			getProductsWithoutDetails(queryParams).then((response) => {
				dispatch({
					type: ActionType.PRODUCT,
					payload: {
						products: response.data.data,
					},
				});
			});
		},
		linkProductBusinessPartner(data: ILinkProductBusinessPartner[]) {
			linkProductBusinessPartner(data)
				.then((response) => {
					enqueueSnackbar(response.data.message, { variant: 'success' });
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
						variant: 'error',
					});
				});
		},
		createTasksInvoice(action: InventoryTaskAction, data: ICreateTask) {
			createTasksInvoice(action, data).then((response) => {
				enqueueSnackbar(response?.data?.message || 'Tarefas criadas com sucesso', { variant: 'success' });
				navigate('/fiscal-document-entry');
			})
				.catch((error: AxiosError) => {
					enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
						variant: 'error',
					});
					actions.setLoading(false);
				});
		},
		createTaskFromInvoice(inventoryTaskInvoiceId: string, data: ICreateTaskFromInvoice) {
			createTaskFromInvoice(inventoryTaskInvoiceId, data).then((response) => {
				enqueueSnackbar(response.data.message, { variant: 'success' });
				actions.getProductsInvoice(lastinvoiceIdParam);
			})
				.catch((error: AxiosError) => {
					enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
						variant: 'error',
					});
					actions.setLoading(false);
				});
		},
		getLocations(queryParams?: LocationProductQueryParams) {
			actions.setLoading(true);

			getLocations(queryParams)
				.then((response) => {
					dispatch({
						type: ActionType.LOCATION,
						payload: {
							locations: response.data.locations,
						},
					});
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(
						error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.',
						{ variant: 'error' },
					);
				})
				.finally(() => {
					actions.setLoading(false);
				});
		},
		handleEdit(businessPartnerId: string) {
			navigate(`/business-partner/edit/${businessPartnerId}`);
		},
	};

	return actions;
};

const FiscalDocumentEntryEditContainer = (): JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(reducer, initialState);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const actions = useMemo(
		() => FiscalDocumentEntryEditActions(dispatch, enqueueSnackbar, navigate),
		[enqueueSnackbar, navigate],
	);
	return (
		/* eslint-disable react/jsx-props-no-spreading */
		<FiscalDocumentEntryEditPresentational {...state} {...actions} />
	);
};

export default FiscalDocumentEntryEditContainer;

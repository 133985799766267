import React from 'react';
import { ChevronRight } from '@mui/icons-material';
import {
	Box,
	Stack,
	Typography,
} from '@mui/material';
import { ConsumptionType } from '../../../enums/ConsumptionType';
import Button from '../../Common/Button/Button';

interface ConsumptionStartProps {
    handleType: (type: ConsumptionType) => void;
	handleBack: () => void;
}

const ConsumptionStart = ({
	handleType,
	handleBack,
}:ConsumptionStartProps): JSX.Element => (
	<Box>
		<Typography variant="h6" align="center">Consumo</Typography>
		<Typography align="center">
			Selecione o tipo de consumo
		</Typography>

		<Box
			sx={{
				padding: 2,
				border: 1,
				borderColor: '#bbb',
				bgcolor: 'white',
				borderRadius: 2,
				mb: 2,
				mt: 2,
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				cursor: 'pointer',
			}}
			onClick={() => handleType(ConsumptionType.REQUISITION)}
		>
			<Stack direction="column">
				<Typography fontWeight="500">
					Requisições
				</Typography>
			</Stack>
			<ChevronRight color="primary" />
		</Box>

		<Box
			sx={{
				padding: 2,
				border: 1,
				borderColor: '#bbb',
				bgcolor: 'white',
				borderRadius: 2,
				mb: 2,
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				cursor: 'pointer',
			}}
			onClick={() => handleType(ConsumptionType.DEVOLUTION)}
		>
			<Stack direction="column">
				<Typography fontWeight="500">
					Devoluções
				</Typography>
			</Stack>
			<ChevronRight color="primary" />
		</Box>

		<Button variant="contained" sx={{ mt: 4 }} onClick={handleBack}>
			Voltar
		</Button>
	</Box>
);

export default ConsumptionStart;

import React, {
	useCallback,
} from 'react';
import {
	Box,
} from '@mui/material';
import {
	Form,
	FormikContext,
	useFormik,
} from 'formik';
import ConsumptionItems from './ConsumptionItems';
import { Loading } from '../../Common/Loading';
import { InventoryTaskAction } from '../../../enums/InventoryTaskAction';
import {
	SaveConsumptionCountBody,
	StepsConsumption,
} from '../../../containers/Mobile/Consumption/ConsumptionAssets';
import Signature from '../../Common/Mobile/Signature';
import ConsumptionStart from './ConsumptionStart';
import ConsumptionList from './ConsumptionList';
import { formatBarCode } from '../../../helpers/masks';
import { ConsumptionProductionTask, IConsumption } from '../../../containers/Consumption/ConsumptionAssets';
import { ConsumptionProductionTaskQueryParams } from '../../../interfaces/ConsumptionProductionTaskQueryParams';
import { ConsumptionType } from '../../../enums/ConsumptionType';
import { SaveTaskType } from '../../../containers/InventoryTaskInvoice/InventoryTaskInvoiceAssets';

export interface ConsumptionForm {
	consumptionCode: string;
    locationBarCode: string;
    productBarCode: string;
    taskId: string;
    productQuantity: number;
	type?: ConsumptionType;
	signatureBase64: string;
	locationSignature: string;
}

export const initialValues: ConsumptionForm = {
	consumptionCode: '',
	locationBarCode: '',
	productBarCode: '',
	taskId: '',
	productQuantity: 0,
	signatureBase64: '',
	locationSignature: '',
};

interface ConsumptionProps {
	loading: boolean;
	consumptions: IConsumption[];
	getConsumptionProduction(params: ConsumptionProductionTaskQueryParams): void;
	selectedConsumptionTasks: ConsumptionProductionTask[];
	setSelectedConsumptionTasks(selectedConsumptionTasks: ConsumptionProductionTask[]): void;
	activeStep: StepsConsumption;
	setActiveStep(step: StepsConsumption): void;
	saveTempTask(data: SaveTaskType): void;
	saveTaskCountBatch(data: SaveConsumptionCountBody): void;
	handleBack(): void;
	tasksCounted: SaveTaskType[];
}

const Consumption = ({
	loading,
	consumptions,
	getConsumptionProduction,
	saveTempTask,
	selectedConsumptionTasks,
	setSelectedConsumptionTasks,
	activeStep,
	setActiveStep,
	handleBack,
	saveTaskCountBatch,
	tasksCounted,
}: ConsumptionProps): JSX.Element => {
	const formik = useFormik({
		initialValues,
		onSubmit: (values, { resetForm }) => {
			saveTaskCountBatch({
				tasks: tasksCounted,
				signatureBase64: values.signatureBase64,
			});
			resetForm();
		},
	});

	const setStep = useCallback((step: StepsConsumption): void => {
		setActiveStep(step);
	}, [setActiveStep]);

	const handleSelectedConsumption = useCallback((tasks: ConsumptionProductionTask[]): void => {
		setStep(StepsConsumption.CONSUMPTION_ITEMS);
		setSelectedConsumptionTasks(tasks);
		if (formik.values.type === ConsumptionType.REQUISITION) {
			formik.setFieldValue('locationSignature', formatBarCode(tasks[0].locationDestinyBarCode));
		}
	}, [formik, setSelectedConsumptionTasks, setStep]);

	const handleType = useCallback((type: ConsumptionType): void => {
		getConsumptionProduction({
			skip: 0,
			type,
			action: InventoryTaskAction.CONSUMPTION,
		});
		formik.setFieldValue('type', type);
		setActiveStep(StepsConsumption.CONSUMPTION_LIST);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getConsumptionProduction, setActiveStep]);

	if (loading) {
		return (
			<Box padding={1}>
				<Loading />
			</Box>
		);
	}

	return (
		<FormikContext.Provider value={formik}>
			<Form>
				<Box padding={1}>
					{activeStep === StepsConsumption.START && (
						<ConsumptionStart
							handleType={handleType}
							handleBack={() => {
								formik.resetForm();
								handleBack();
							}}
						/>
					)}

					{activeStep === StepsConsumption.CONSUMPTION_LIST && (
						<ConsumptionList
							consumptions={consumptions}
							handleSelectedConsumption={handleSelectedConsumption}
							handleBack={() => {
								formik.resetForm();
								setActiveStep(StepsConsumption.START);
							}}
							type={formik.values.type}
						/>
					)}

					{activeStep === StepsConsumption.CONSUMPTION_ITEMS && (
						<ConsumptionItems
							consumptionTasks={selectedConsumptionTasks}
							saveTempTask={saveTempTask}
							handleBack={() => {
								formik.resetForm();
								setActiveStep(StepsConsumption.CONSUMPTION_LIST);
							}}
							type={formik.values.type}
						/>
					)}

					{activeStep === StepsConsumption.SIGNATURE && (
						<Signature
							onSave={(signatureBase64: string) => {
								formik.setFieldValue('signatureBase64', signatureBase64);
								formik.submitForm();
							}}
							subtitle={formik.values.type === ConsumptionType.DEVOLUTION ? 'Recolha a assinatura do solicitante.'
								: `Dirija-se até a localização ${formik.values.locationSignature} e recolha a assinatura do solicitante.`}
						/>
					)}
				</Box>
			</Form>
		</FormikContext.Provider>
	);
};

export default Consumption;

import React, { useState, useCallback, useMemo } from 'react';
import {
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	Stack,
	Box,
	Typography,
	useTheme,
	Collapse,
} from '@mui/material';
import {
	IInventoryTaskInvoice,
	InventoryTask,
} from '../../containers/InventoryTaskInvoice/InventoryTaskInvoiceAssets';
import { InventoryTaskStatus } from '../../enums/InventoryTaskStatus';
import { SelectedTasks } from './InventoryTaskInvoice';
import { InventoryTaskInvoiceQueryParams } from '../../interfaces/InventoryTaskInvoiceQueryParams';
import { generatePDF } from './InventoryTaskInvoicePDF';
import { Loading } from '../Common/Loading';
import Pagination from '../Common/Pagination';
import { ExpandableRow, ExpandedTaskDetails } from './ExpandedTaskDetails';
import { InventoryTaskInvoiceType } from '../../enums/InventoryTaskInvoiceType';
import { InventoryTaskStatusHeader } from '../../enums/InventoryTaskStatusHeader';
import { dateTitle } from '../../helpers/Utils';
import {
	getAdjustedColWidths,
	getLabelColumnQuantityCounted,
	getLabelStatusTask,
	getTotalVisibleColumns,
	getVisibleColumns,
} from '../../helpers/inventoryTaskInvoiceHelper';
import {
	collapseContainerStyle,
	expandabledRowTableCellStyle,
	stickyHeaderStyle,
	tableBodyStyle,
	tableContainerStyle,
	tableRowStyle,
} from '../../helpers/tableStylesHelper';

interface InventoryTaskInvoiceTableProps {
  rows: IInventoryTaskInvoice[];
  filter: InventoryTaskInvoiceQueryParams;
  tasksPages: number;
  tasksPage: number;
  tasksTake: number;
  selectedRows: SelectedTasks;
  rowsIndex: Array<number>;
  handlerFinishTask: (inventoryTasksIds: Array<string>) => void;
  onChangePage(page: number): void;
  onChangePageSize(pageSize: number): void;
  handleSelectedRowsChange: (rowIndex: number) => void;
  handlerChangeProduct: (
	rowIndex: number,
	indexProduct: number,
	checked: boolean,
  ) => void;
  loading: boolean;
}

const InventoryTaskInvoiceTable = ({
	rows,
	filter,
	tasksPages,
	tasksPage,
	tasksTake,
	selectedRows,
	rowsIndex,
	onChangePage,
	handlerFinishTask,
	onChangePageSize,
	handleSelectedRowsChange,
	handlerChangeProduct,
	loading,
}: InventoryTaskInvoiceTableProps): JSX.Element => {
	const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({});
	const theme = useTheme();

	const handleRowExpand = useCallback((id: string) => {
		setExpandedRows((prev) => ({
			...prev,
			[id]: !prev[id],
		}));
	}, []);

	const handlePrint = useCallback(
		(index: number, logoUrl: string) => {
			const row = rows[index];
			const subgridItems = row.inventoryTasks;

			generatePDF(row, subgridItems, logoUrl);
		},
		[rows],
	);

	const isRowEnabled = useCallback(
		(id: string) => {
			const inventoryTaskInvoice = rows.find((row) => row.id === id);
			if (!inventoryTaskInvoice) return false;

			return inventoryTaskInvoice.inventoryTasks
				.some((task) => task.status === InventoryTaskStatus.PENDING
				&& Boolean(task.locationOriginBarCode)
				&& Boolean(task.locationDestinyBarCode));
		},
		[rows],
	);

	const isCheckedProductEnabled = useCallback(
		(
			status: InventoryTaskStatus,
			locationDestinyBarCode?: string,
			locationOriginBarCode?: string,
		) => {
			if (status !== InventoryTaskStatus.PENDING) {
				return false;
			}

			return Boolean(locationDestinyBarCode && locationOriginBarCode);
		},
		[],
	);

	const isChecked = useCallback(
		(inventoryTaskInvoice: IInventoryTaskInvoice, task: InventoryTask) => {
			const tasksData = selectedRows[inventoryTaskInvoice.id]?.tasksIds ?? [];

			return tasksData.includes(task.id);
		},
		[selectedRows],
	);

	const buildLabelStatusTask = useCallback(
		(status: InventoryTaskStatus) => getLabelStatusTask(status, filter),
		[filter],
	);

	const handleMenuItemClick = useCallback(
		(indexItemSelected: number, inventoryTask: InventoryTask) => {
			const isFinishTask = indexItemSelected === 0;

			if (isFinishTask) {
				const { id } = inventoryTask;
				handlerFinishTask([id]);
			}
		},
		[handlerFinishTask],
	);

	const handleRowSelect = useCallback(
		(rowIndex: number) => {
			handleSelectedRowsChange(rowIndex);
		},
		[handleSelectedRowsChange],
	);

	const handlePaginationChange = useCallback(
		(page: number, take: number) => {
			onChangePage(page - 1);
			onChangePageSize(take);
		},
		[onChangePage, onChangePageSize],
	);

	const buildLabelColumnQuantityCounted = useMemo(() => getLabelColumnQuantityCounted(
		filter,
	), [filter]);

	const selectedItems = useMemo(
		() => rowsIndex.map((index) => rows[index]?.id || '').filter((id) => id),
		[rowsIndex, rows],
	);

	const visibleColumns = useMemo(() => getVisibleColumns(filter), [filter]);

	const adjustedColWidths = useMemo(() => getAdjustedColWidths(
		filter,
	), [filter]);

	const totalVisibleColumns = useMemo(() => getTotalVisibleColumns(
		visibleColumns,
	), [visibleColumns]);

	return (
		<>
			<TableContainer sx={tableContainerStyle(theme)}>
				<Table stickyHeader aria-label="collapsible table" sx={stickyHeaderStyle(theme)}>
					<TableHead>
						<TableRow sx={tableRowStyle(theme)}>
							<TableCell padding="checkbox" style={{ width: adjustedColWidths[0], minWidth: adjustedColWidths[0] }} />
							<TableCell style={{
								width: adjustedColWidths[1],
								minWidth: adjustedColWidths[1],
							}}
							>
								Documento
							</TableCell>

							{visibleColumns.documentType && (
								<TableCell style={{
									width: adjustedColWidths[2],
									minWidth: adjustedColWidths[2],
								}}
								>
									Tipo Doc
								</TableCell>
							)}

							{visibleColumns.businessPartner && (
								<TableCell style={{
									width: adjustedColWidths[3],
									minWidth: adjustedColWidths[3],
								}}
								>
									Cliente/Fornecedor
								</TableCell>
							)}

							{visibleColumns.totalValue && (
								<TableCell style={{
									width: adjustedColWidths[4],
									minWidth: adjustedColWidths[4],
									textAlign: 'right',
								}}
								>
									<Box sx={{ marginRight: '60%' }}>
										{' '}
										Total Documento
									</Box>
								</TableCell>
							)}
							{visibleColumns.saleOrder && (
								<TableCell style={{
									width: adjustedColWidths[5],
									minWidth: adjustedColWidths[5],
								}}
								>
									Pedido de Compra
								</TableCell>
							)}
							{visibleColumns.emissionDate
								&& (
									<TableCell style={{ width: adjustedColWidths[5] }}>
										{dateTitle(filter.type ?? InventoryTaskInvoiceType.SUPPLY)}
									</TableCell>
								)}
							{filter.type === InventoryTaskInvoiceType.DISPATCH
							&& (filter.status === InventoryTaskStatusHeader.PENDING
							|| filter.status === InventoryTaskStatusHeader.IN_PROGRESS) && (
								<TableCell style={{
									width: adjustedColWidths[6],
									minWidth: adjustedColWidths[6],
								}}
								>
									Imprimir
								</TableCell>
							)}
							<TableCell style={{ width: adjustedColWidths[7], minWidth: adjustedColWidths[7] }} />
						</TableRow>
					</TableHead>
					<TableBody sx={tableBodyStyle(theme)}>
						{loading && (
							<TableRow>
								<TableCell colSpan={totalVisibleColumns} align="center">
									<Stack direction="row" justifyContent="center" p={2}>
										<Loading />
									</Stack>
								</TableCell>
							</TableRow>
						)}
						{!loading && rows.length > 0 && rows.map((invoice, index) => (
							<React.Fragment key={invoice.id}>
								<ExpandableRow
									inventoryTaskInvoice={invoice}
									isSelected={selectedItems.includes(invoice.id)}
									isRowEnabled={isRowEnabled(invoice.id)}
									onSelect={() => handleRowSelect(index)}
									onExpand={() => handleRowExpand(invoice.id)}
									isExpanded={expandedRows[invoice.id] || false}
									index={index}
									onPrint={handlePrint}
									operationType={filter.type}
									visibleColumns={visibleColumns}
									adjustedColWidths={adjustedColWidths}
								/>
								<TableRow>
									<TableCell
										style={expandabledRowTableCellStyle()}
										colSpan={totalVisibleColumns}
									>
										<Collapse in={expandedRows[invoice.id] || false} timeout="auto" unmountOnExit>
											<Box sx={collapseContainerStyle(theme)}>
												{expandedRows[invoice.id] && (
													<ExpandedTaskDetails
														inventoryTaskInvoice={invoice}
														inventoryTaskInvoices={rows}
														isChecked={isChecked}
														isCheckedProductEnabled={isCheckedProductEnabled}
														handlerChangeProduct={handlerChangeProduct}
														buildLabelStatusTask={buildLabelStatusTask}
														buildLabelColumnQuantityCounted={buildLabelColumnQuantityCounted}
														handleMenuItemClick={handleMenuItemClick}
													/>
												)}
											</Box>
										</Collapse>
									</TableCell>
								</TableRow>
							</React.Fragment>
						))}
						{!loading && rows.length === 0 && (
							<TableRow>
								<TableCell colSpan={totalVisibleColumns} align="center">
									<Typography p={2}>Nenhum registro encontrado</Typography>
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<Box sx={{ mt: 2, mb: 1 }}>
				<Pagination
					loading={loading}
					count={tasksPages}
					page={tasksPage + 1}
					take={tasksTake}
					onChange={handlePaginationChange}
				/>
			</Box>
		</>
	);
};

export default InventoryTaskInvoiceTable;

import React from 'react';
import { LoadingButton as MuiLoadingButton, LoadingButtonProps as MuiLoadingButtonProps } from '@mui/lab';
import { SxProps, Theme } from '@mui/material';

type LoadingButtonProps = MuiLoadingButtonProps & {
	sx?: SxProps<Theme>;
};

export const buttonStyle: SxProps<Theme> = {
	borderRadius: '8px',
	padding: '8px 16px',
	transition: 'all 0.2s ease-in-out',
	fontWeight: 600,
	textTransform: 'uppercase',
	boxShadow: '0 1px 3px rgba(0,0,0,0.05)',
	'&:hover': {
		transform: 'translateY(-2px)',
		boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
	},
	'&:active': {
		transform: 'translateY(0px)',
		boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
	},
	'&.MuiButton-contained': {
		backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255,0.08), rgba(0,0,0,0.12))',
	},
	'&.MuiButton-outlined': {
		borderWidth: '1.5px',
	},
};

const LoadingButton: React.FC<LoadingButtonProps> = ({
	sx,
	...props
// eslint-disable-next-line react/jsx-props-no-spreading
}) => <MuiLoadingButton sx={{ ...buttonStyle, ...sx }} {...props} />;

LoadingButton.defaultProps = {
	sx: undefined,
};

export default LoadingButton;

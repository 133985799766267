import { getTokenDecoded } from '../services/app';

export const getUserData = (): any => {
	const data = getTokenDecoded();

	if (!data) {
		return null;
	}

	return {
		...data,
		firstName: data.name.split(' ')[0],
	};
};

export const getUserInitials = (name: string): string => {
	const nameParts = name.trim().split(' ').filter(Boolean);

	if (nameParts.length === 1) return nameParts[0].charAt(0).toUpperCase();

	const firstName = nameParts[0].charAt(0);
	const lastName = nameParts[nameParts.length - 1].charAt(0);

	return (firstName + lastName).toUpperCase();
};

import { AxiosResponse } from 'axios';
import api from './api';
import {
	InventoryTaskInvoiceQueryParams,
	InventoryTaskTransferProductData,
	InventoryTaskTransferParams,
	ReleaseTasksParams,
	RemoveTasksParams,
	ReprocessTasksParams,
} from '../interfaces/InventoryTaskInvoiceQueryParams';
import {
	FinishTask,
	InventoryTaskInvoiceResponse,
	InvoiceStatusCountResponse,
	IProductAndPlace,
	LocationOriginOperationgResponse,
	LocationProductsResponse,
	MessageSaveTaskCountBatch,
	ReleaseTasksReturn,
	ReprocessTasks,
	SaveTaskType,
	TransferProductTask,
	TransferUserTask,
} from '../containers/InventoryTaskInvoice/InventoryTaskInvoiceAssets';
import { ErrorResponse } from '../interfaces/ErrorResponse';
import { LocationOriginQueryParams, ProductsByLocationQueryParams } from '../containers/Mobile/Storage/StorageAssets';
import { InventoryTaskStatus } from '../enums/InventoryTaskStatus';
import { InventoryTaskAction } from '../enums/InventoryTaskAction';
import { IMessageResponse } from '../interfaces/MessageResponse';
import { ICreateTask, ICreateTaskFromInvoice } from '../interfaces/FiscalDocumentParams';
import { SaveConsumptionCountBody } from '../containers/Mobile/Consumption/ConsumptionAssets';

export const getByProductAndPlace = (
	product: string,
	place: string,
): Promise<AxiosResponse<IProductAndPlace>> => api.get<IProductAndPlace, AxiosResponse<any>>(
	`inventory/productAndPlace/${product}/${place}`,
);

export const getFirstRecord = (
	countNumber?: number,
): Promise<AxiosResponse<any>> => api.get<{ countNumber?: number }, AxiosResponse<any>>(
	`inventory/firstRecord/${countNumber}`,
);

export const getInventoryTaskInvoice = (
	params: InventoryTaskInvoiceQueryParams,
): Promise<AxiosResponse<InventoryTaskInvoiceResponse>> => api.get<InventoryTaskInvoiceResponse>('/inventory-task/invoice', { params });

export const releaseTasks = (
	data: ReleaseTasksParams,
): Promise<AxiosResponse<ReleaseTasksReturn>> => api.post<ReprocessTasks>('/inventory-task/release', data);

export const reprocessLocationTasks = (
	data: ReprocessTasksParams,
): Promise<AxiosResponse<ReprocessTasks>> => api.post<ReprocessTasks>('/inventory-task/findLocation', data);

export const finishTask = (
	data: RemoveTasksParams,
): Promise<AxiosResponse<FinishTask>> => api.patch<FinishTask>('/inventory-task/remove', data);

export const transferUserTask = (
	userId: string,
	data: InventoryTaskTransferParams,
): Promise<AxiosResponse<TransferUserTask>> => api.post<TransferUserTask>(`/inventory-task/transferTask/${userId}`, data);

export const createTransferProductTask = (
	data: InventoryTaskTransferProductData,
): Promise<AxiosResponse<TransferProductTask, ErrorResponse>> => api.post<
	TransferProductTask,
	AxiosResponse<TransferProductTask, ErrorResponse>,
	InventoryTaskTransferProductData
>('/inventory-task/move', data);

export const getLocationOriginOperating = (params: LocationOriginQueryParams): Promise<AxiosResponse<LocationOriginOperationgResponse>> => api.get<LocationOriginOperationgResponse>(`/inventory-task/location-origin/${InventoryTaskStatus.OPERATING}`, { params });

export const getProductsByLocationBarCode = (locationBarCode: string, status: InventoryTaskStatus, params: ProductsByLocationQueryParams): Promise<AxiosResponse<LocationProductsResponse>> => api.get<LocationProductsResponse>(`/inventory-task/location-products/${status}/${locationBarCode}`, { params });

export const getInventoryTaskInvoiceStatusCount = (): Promise<AxiosResponse<InvoiceStatusCountResponse>> => api.get<InvoiceStatusCountResponse>('/inventory-task/invoice-status-count');

export const createTasksInvoice = (
	action: InventoryTaskAction,
	data: ICreateTask,
): Promise<AxiosResponse<IMessageResponse>> => api.post<ICreateTask, AxiosResponse<IMessageResponse>>(`/inventory-task/create-task/${action}`, data);

export const createTaskFromInvoice = (
	inventoryTaskInvoiceId: string,
	data: ICreateTaskFromInvoice,
): Promise<AxiosResponse<IMessageResponse>> => api.post<ICreateTaskFromInvoice, AxiosResponse<IMessageResponse>>(`/inventory-task/create-task-from-invoice/${inventoryTaskInvoiceId}`, data);

export const saveTaskCountBatch = (
	data: SaveConsumptionCountBody,
): Promise<
	AxiosResponse<MessageSaveTaskCountBatch
	>> => api.post<SaveTaskType, AxiosResponse<MessageSaveTaskCountBatch>>(
		'/inventory-task/taskCount/batch',
		data,
	);

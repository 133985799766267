import React from 'react';
import * as Yup from 'yup';
import { InventoryTaskStatus } from '../enums/InventoryTaskStatus';
import {
	InventoryTaskInvoiceQueryParams,
	InventoryTaskTransferProductInitialValue,
} from '../interfaces/InventoryTaskInvoiceQueryParams';
import {
	CountDiffTaskMap,
	StatusTaskMap,
	StatusTaskMapParams,
} from '../containers/InventoryTaskInvoice/InventoryTaskInvoiceAssets';
import { InventoryTaskDiff } from '../enums/InventoryTaskDiff';
import { InventoryTaskAction } from '../enums/InventoryTaskAction';
import { validationMessage } from '../helpers/yupMessages';
import { TransferProductModalProps } from '../components/InventoryTaskInvoice/Modals/TransferProductModal';
import { ILocationProductData } from '../containers/Product/ProductAssets';
import { formatNumberForLocale } from '../helpers/NumberUtils';
import { InventoryTaskStatusHeader } from '../enums/InventoryTaskStatusHeader';

export const inventoryTaskStatus = [
	'Pendente',
	'Em fila',
	'Em andamento',
	'Concluído',
	'Cancelado',
];

export const createStatusTaskMap = ({ operatingLabel }: StatusTaskMapParams): StatusTaskMap => ({
	[InventoryTaskStatus.PENDING]: { label: 'Pendente' },
	[InventoryTaskStatus.WAITING]: { label: 'Em fila' },
	[InventoryTaskStatus.OPERATING]: { label: operatingLabel },
	[InventoryTaskStatus.FINISHED]: { label: 'Concluído' },
	[InventoryTaskStatus.CANCELED]: { label: 'Cancelado' },
});

export const statusTask: StatusTaskMap = {
	[InventoryTaskStatus.PENDING]: { label: 'Pendente' },
	[InventoryTaskStatus.OPERATING]: { label: 'Em andamento' },
	[InventoryTaskStatus.FINISHED]: { label: 'Concluído' },
};

export const countDiffTask: CountDiffTaskMap = {
	[InventoryTaskDiff.MINUS]: { label: 'A menor' },
	[InventoryTaskDiff.MORE]: { label: 'A maior' },
};

export const inventoryTaskStatusFilter = [
	{
		id: '0',
		label: 'Pendente',
		value: String(InventoryTaskStatus.PENDING),
	},
	{
		id: '1',
		label: 'Em andamento',
		value: String(InventoryTaskStatus.OPERATING),
	},
	{
		id: '2',
		label: 'Concluído',
		value: String(InventoryTaskStatus.FINISHED),
	},
];

export const filterValidationSchema = Yup.object().shape({
	client: Yup.string().nullable(),
	companyId: Yup.string().nullable(),
	startDate: Yup.date().nullable(),
	endDate: Yup.date()
		.nullable()
		.test(
			'date-min',
			'A data final não pode ser menor que a data inicial',
			function checkEndDate(endDate) {
				const { startDate } = this.parent;
				if (startDate && endDate) {
					return new Date(endDate) >= new Date(startDate);
				}
				return true;
			},
		),
	saleOrder: Yup.string().nullable(),
	status: Yup.number().nullable(),
});

export const validationSchemaTrasnferTask = (
	props: Partial<TransferProductModalProps>,
): Yup.AnyObjectSchema => Yup.object().shape({
	productId: Yup.string().required(validationMessage.string.required),
	productQuantity: Yup.number()
		.required(validationMessage.number.required)
		.min(1, validationMessage.number.min(1))
		.test(
			'check-quantity-origin',
			'A quantidade informada excede a quantidade disponível na localização de origem.',
			(value, context) => {
				const productQuantityValue = value || 0;
				const { productLocations = [] } = props;
				const { productId, locationOriginId } = context.parent;

				if (!productId || !locationOriginId) {
					return false;
				}

				const {
					product: { currentQuantity },
				} = productLocations.find((productLocation) => productLocation.id === locationOriginId
					&& productLocation.product.id === productId) || ({} as ILocationProductData);

				if (productQuantityValue > Number(currentQuantity)) {
					return context.createError({
						message: `A quantidade informada excede a quantidade de ${formatNumberForLocale(
							currentQuantity,
							2,
						)} disponíveis na localização de origem`,
					});
				}

				return true;
			},
		)
		.test(
			'check-quantity-destiny',
			'A quantidade informada excede a capacidade da localização de destino.',
			(value, context) => {
				const productQuantityValue = value || 0;
				const { productLocations = [] } = props;
				const { productId, locationDestinyId } = context.parent;

				if (!productId || !locationDestinyId) {
					return false;
				}

				const {
					product: { currentQuantity, valuedQuantity },
				} = productLocations.find((productLocation) => productLocation.id === locationDestinyId
					&& productLocation.product.id === productId) || ({} as ILocationProductData);

				const remainingCapacity = Number(valuedQuantity) - Number(currentQuantity);

				if (productQuantityValue > remainingCapacity) {
					return context.createError({
						message: `A quantidade informada excede a capacidade disponível de ${formatNumberForLocale(
							remainingCapacity,
							2,
						)} itens na localização de destino.`,
					});
				}

				return !(productQuantityValue > remainingCapacity);
			},
		),
	locationOriginId: Yup.string()
		.nullable()
		.required(validationMessage.string.required),
	locationDestinyId: Yup.string()
		.nullable()
		.required(validationMessage.string.required),
});

const inventoryTypeString = localStorage.getItem('inventoryType');
export const defaultFilterContextScreen = inventoryTypeString ? Number(
	inventoryTypeString,
) : InventoryTaskAction.SUPPLY;

export const defaultInventoryTaskQueryParams: InventoryTaskInvoiceQueryParams = {
	skip: 0,
	take: 10,
	status: InventoryTaskStatusHeader.PENDING,
	type: defaultFilterContextScreen,
	client: '',
	shipping: '',
	startDate: '',
	endDate: '',
	companyId: '',
	branchId: '',
	saleOrder: '',
};

export const defaultTrasferProductParams: InventoryTaskTransferProductInitialValue = {
	productId: '',
	invoiceValue: 0,
	locationOriginId: '',
	locationDestinyId: '',
	productCode: '',
	productQuantity: 0,
};

import React, { useCallback, useEffect, useRef } from 'react';
import {
	Box,
	Card, CardActions, CardContent, Divider, Typography,
} from '@mui/material';
import { Form, FormikContext, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import Input from '../../../../../Common/Form/Input';
import { formatBarCode, removeBarCodeMask } from '../../../../../../helpers/masks';
import { IInventoryTask } from '../../../../../../containers/Inventory/InventoryAssets';
import Button from '../../../../../Common/Button/Button';

interface StepLocationProps {
    inventoryTask: IInventoryTask | null;
	confirmLocation: (locationBarCode: string) => void;
}

const StepLocation = ({
	inventoryTask,
	confirmLocation,
}: StepLocationProps): JSX.Element => {
	const inputRef = useRef<HTMLInputElement>(null);
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	}, []);

	const formik = useFormik({
		initialValues: {
			locationBarCode: '',
			productBarCode: '',
			productQuantity: 0,
		},
		onSubmit: () => {
			if (inventoryTask
				&& removeBarCodeMask(
					formik.values.locationBarCode,
				) === removeBarCodeMask(
					inventoryTask.locationOrigin.barCode,
				)) {
				confirmLocation(formik.values.locationBarCode);
			} else {
				enqueueSnackbar('Código de barras inválido', {
					variant: 'error',
				});
			}
			formik.setFieldValue('locationBarCode', '');
		},
	});

	const handleBlurOrEnter = useCallback((event: React.KeyboardEvent<HTMLInputElement>): void => {
		const target = event.target as HTMLInputElement;
		if ((event.key === 'Tab') || (event.key === 'Enter')) {
			event.preventDefault();
			if (inventoryTask
				&& removeBarCodeMask(
					target.value,
				) === removeBarCodeMask(inventoryTask.locationOrigin.barCode)) {
				confirmLocation(target.value);
			} else {
				enqueueSnackbar('Código de barras inválido', {
					variant: 'error',
				});
			}
			formik.setFieldValue('locationBarCode', '');
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<FormikContext.Provider value={formik}>
			<Form>
				<Box>

					<Card>
						<CardContent>
							<Typography
								variant="h6"
								textAlign="center"
							>
								{formatBarCode(inventoryTask?.locationOrigin.barCode || '')}
							</Typography>
							<Typography
								textAlign="center"
							>
								{`${inventoryTask?.product.barCode}
							 - ${inventoryTask?.product.description}`}
							</Typography>
							<Divider sx={{ marginY: 2 }} />
							<Typography sx={{ marginBottom: 2 }}>
								Leia o código de barras da localização:
							</Typography>
							<Input.InputField
								id="locationBarCode"
								name="locationBarCode"
								label="Código de Barras da Localização"
								format={(value) => formatBarCode(value)}
								autoComplete="off"
								inputRef={inputRef}
								onKeyDown={handleBlurOrEnter}
								fullWidth
								required
							/>
						</CardContent>
						<CardActions>
							<Button
								variant="contained"
								size="large"
								type="submit"
								fullWidth
							>
								Confirmar
							</Button>
						</CardActions>
					</Card>
				</Box>
			</Form>
		</FormikContext.Provider>
	);
};

export default StepLocation;

import React from 'react';
import {
	DataGrid,
	GridColDef,
	GridSortModel,
} from '@mui/x-data-grid';
import { Box, Paper } from '@mui/material';
import { ILocationProduct } from '../../interfaces/LocationProduct';

interface InventoryStartTableProps {
	rows: ILocationProduct[],
	columns: GridColDef[],
	loading: boolean,
	locationsProductsCount: number,
	locationsProductsTake: number,
	locationsProductsPage: number,
	sortModel: GridSortModel
	onChangePage(productsTake: number): void
	onChangePageSize(pageSize: number): void
	onSortModelChange(sortModel: GridSortModel): void
}

const InventoryStartTable = ({
	rows,
	loading,
	columns,
	locationsProductsCount,
	locationsProductsTake,
	locationsProductsPage,
	sortModel,
	onChangePage,
	onChangePageSize,
	onSortModelChange,
}: InventoryStartTableProps):JSX.Element => (
	<Box component={Paper} p={2}>
		<DataGrid
			autoHeight
			rows={rows}
			columns={columns}
			pagination
			rowsPerPageOptions={[10, 25, 50, 100]}
			loading={loading}
			rowCount={locationsProductsCount}
			paginationMode="server"
			sortingMode="server"
			pageSize={locationsProductsTake}
			page={locationsProductsPage}
			onPageChange={onChangePage}
			onPageSizeChange={onChangePageSize}
			onSortModelChange={onSortModelChange}
			sortModel={sortModel}
		/>
	</Box>
);

export default InventoryStartTable;

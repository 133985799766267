import React, { useCallback, useEffect, useRef } from 'react';
import {
	Box,
	Typography,
	IconButton,
	Grid,
	Stack,
} from '@mui/material';
import {
	Add,
	Remove,
} from '@mui/icons-material';
import { useFormikContext } from 'formik';
import { useSnackbar } from 'notistack';
import Input from '../../Common/Form/Input';
import { Loading } from '../../Common/Loading';
import { ITask } from '../../../containers/Mobile/TaskAssets';
import { formatBarCode } from '../../../helpers/masks';
import { SaveTaskType } from '../../../containers/InventoryTaskInvoice/InventoryTaskInvoiceAssets';
import useConfirmationDialog from '../../../hooks/useConfirmationDialog';
import Button from '../../Common/Button/Button';

interface ExpeditionProductDetailsProps {
	saleOrder: string;
	loading: boolean;
	location: string;
	handleConfirmQuantity: (task: SaveTaskType) => void;
	isBarCode: boolean;
}

const ExpeditionProductDetails = ({
	loading,
	location,
	handleConfirmQuantity,
	saleOrder,
	isBarCode,
}:ExpeditionProductDetailsProps): JSX.Element => {
	const { values, setFieldValue } = useFormikContext<{
		productQuantity: number;
		productSelected: ITask;
		locationSelected: string;
	}>();

	const { enqueueSnackbar } = useSnackbar();
	const inputRef = useRef<HTMLInputElement>(null);
	const { requestConfirm, confirmationDialog } = useConfirmationDialog();

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus();
		}

		if (isBarCode) {
			setFieldValue('productQuantity', 1);
		} else {
			setFieldValue('productQuantity', 0);
		}
	}, [saleOrder, isBarCode, setFieldValue]);

	if (loading) {
		<Loading />;
	}

	const handleIncrement = useCallback(():void => {
		setFieldValue('productQuantity', (Number(values.productQuantity) ?? 0) + 1);
	}, [setFieldValue, values.productQuantity]);

	const handleDecrement = useCallback(():void => {
		setFieldValue('productQuantity', (Number(values.productQuantity) ?? 0) - 1);
	}, [setFieldValue, values.productQuantity]);

	const handleBlurOrEnter = useCallback((event: React.KeyboardEvent<HTMLInputElement>): void => {
		const target = event.target as HTMLInputElement;
		if ((event.key === 'Tab') || (event.key === 'Enter')) {
			event.preventDefault();
			if (values.productSelected.product.code === target.value) {
				handleIncrement();
			} else {
				enqueueSnackbar('Código de barras inválido!', {
					variant: 'error',
				});
			}
			setFieldValue('barcodeProduct', '');
		}
	}, [enqueueSnackbar, handleIncrement, setFieldValue, values.productSelected.product.code]);

	return (
		<Box padding={1}>
			<Typography variant="subtitle1" align="center">
				{`Localização: ${formatBarCode(location)}`}
			</Typography>
			<Typography variant="subtitle1" align="center" sx={{ marginBottom: 2 }}>
				{`Pedido de Venda: ${saleOrder}`}
			</Typography>
			<Box
				sx={{
					padding: 2, border: 1, borderColor: '#bbb', bgcolor: 'white', borderRadius: 2, mb: 2, display: 'flex', justifyContent: 'space-between',
				}}
			>
				<Stack direction="column">
					<Typography variant="subtitle1" align="center">
						{`${values.productSelected.product.barCode} - ${values.productSelected.product.description}`}
					</Typography>
					<Typography
						variant="subtitle1"
						align="center"
						sx={{ marginBottom: 2, fontWeight: 'bold' }}
					>
						{`${values.productSelected.quantity}
						${values.productSelected.product.measures[0].description}(s)`}
					</Typography>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6}>
							<Input.InputField
								label="Código de Barras"
								id="barcodeProduct"
								name="barcodeProduct"
								onKeyDown={(
									event: React.KeyboardEvent<HTMLInputElement>,
								) => handleBlurOrEnter(event)}
								inputRef={inputRef}
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Box
								sx={{
									width: '100%',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								<IconButton disabled={values.productQuantity === 0} size="large" onClick={handleDecrement}>
									<Remove />
								</IconButton>

								<Input.InputField
									id="productQuantity"
									name="productQuantity"
									style={{ width: 100 }}
									textAlign="center"
									type="number"
									inputProps={{ min: 0 }}
									required
								/>
								<IconButton size="large" onClick={handleIncrement}>
									<Add />
								</IconButton>
							</Box>
						</Grid>
						<Grid item xs={12} sm={6} display="flex" justifyContent="space-between" marginTop={2} gap={2}>
							{Number(values.productQuantity) === Number(values.productSelected.quantity)
								? (
									<Button
										variant="contained"
										size="small"
										color="primary"
										sx={{ width: '100%' }}
										disabled={
											Number(values.productQuantity) > Number(values.productSelected.quantity)
											|| Number(values.productQuantity) <= 0
										}
										onClick={() => {
											handleConfirmQuantity({
												quantity: Number(values.productQuantity),
												taskId: values.productSelected.id,
											});
										}}
									>
										Confirmar
									</Button>
								) : (
									<Button
										variant="outlined"
										size="small"
										color="error"
										sx={{ width: '100%' }}
										disabled={
											Number(values.productQuantity) > Number(values.productSelected.quantity)
											|| Number(values.productQuantity) <= 0
										}
										onClick={() => {
											requestConfirm({
												title: 'Confirmar Inconsistência',
												description: 'Existe uma diferença entre a quantidade informada e a esperada, deseja confirmar mesmo assim?',
												callback: () => {
													handleConfirmQuantity({
														quantity: Number(values.productQuantity),
														taskId: values.productSelected.id,
													});
												},
											});
										}}
									>
										Inconsistente
									</Button>
								)}
						</Grid>
					</Grid>
				</Stack>
			</Box>
			{confirmationDialog}
		</Box>
	);
};

export default ExpeditionProductDetails;

import { alpha, SxProps, Theme } from '@mui/material';

/**
 * Estilos para o contêiner da tabela com rolagem personalizada
 */
export const tableContainerStyle = (theme: Theme): SxProps => ({
	overflow: 'scroll',
	height: 'calc(100vh - 300px)',
	'&::-webkit-scrollbar': {
		width: '6px',
		height: '6px',
	},
	'&::-webkit-scrollbar-track': {
		backgroundColor: 'transparent',
	},
	'&::-webkit-scrollbar-thumb': {
		backgroundColor: alpha(theme.palette.primary.main, 0.2),
		borderRadius: '3px',
		'&:hover': {
			backgroundColor: alpha(theme.palette.primary.main, 0.3),
		},
	},
});

/**
 * Estilos para o cabeçalho de tabela com gradiente azul
 */
export const tableRowStyle = (theme: Theme): SxProps => ({
	background: () => `linear-gradient(to bottom, ${alpha(theme.palette.primary.main, 0.03)}, ${alpha(theme.palette.primary.main, 0.06)})`,
	'& .MuiTableCell-root': {
		background: 'transparent',
		borderBottom: () => `2px solid ${alpha(theme.palette.primary.main, 0.15)}`,
		fontWeight: 600,
		fontSize: '0.85rem',
		letterSpacing: '0.03em',
		color: () => theme.palette.primary.dark,
		padding: '12px 8px',
		whiteSpace: 'nowrap',
		textShadow: '0 1px 1px rgba(255,255,255,0.7)',
	},
});

/**
 * Estilos para cabeçalho fixo (sticky) durante rolagem
 */
export const stickyHeaderStyle = (theme: Theme): SxProps => ({
	tableLayout: 'fixed',
	'& .MuiTableCell-stickyHeader': {
		background: () => `linear-gradient(to bottom, ${alpha(theme.palette.primary.main, 0.04)}, ${alpha(theme.palette.primary.main, 0.08)}), white !important`,
		borderRight: 'none',
		borderLeft: 'none',
	},
});

/**
 * Estilos para o corpo da tabela com efeito zebrado
 */
export const tableBodyStyle = (theme: Theme): SxProps => ({
	'& tr:nth-of-type(odd)': {
		backgroundColor: () => (theme.palette.mode === 'light'
			? alpha(theme.palette.primary.main, 0.015)
			: alpha(theme.palette.primary.main, 0.03)),
	},
	'& tr:nth-of-type(even)': {
		backgroundColor: () => (theme.palette.mode === 'light'
			? alpha(theme.palette.primary.main, 0.005)
			: alpha(theme.palette.primary.main, 0.015)),
	},
});

/**
 * Função para criar estilos da linha expandida
 */

// Agora refatorando o createExpandableRowStyle para usar essas funções
export const createExpandableRowStyle = (
	theme: Theme,
	isExpanded: boolean,
	index: number,
): SxProps => ({
	cursor: 'pointer',
	height: '56px',
	// Aplica cores com MAIOR CONTRASTE baseadas no índice (efeito zebrado)
	background: () => {
		if (isExpanded) {
			return 'linear-gradient(to bottom, #eef5fc, #e4edf9)';
		}
		if (theme.palette.mode === 'light') {
			return index % 2 === 0
				? 'linear-gradient(to bottom, #f9fbfe, #f5f9fd)' // Azul claro para linhas pares
				: 'linear-gradient(to bottom, #ffffff, #fcfdfe)'; // Praticamente branco para linhas ímpares
		}
		return index % 2 === 0
			? 'linear-gradient(to bottom, #becce4, #b3c3dd)'
			: 'linear-gradient(to bottom, #f2f5fb, #ebf1fa)';
	},
	boxShadow: isExpanded
		? 'none'
		: () => (index % 2 === 0
			? '0 2px 4px rgba(0,0,0,0.04), inset 0 1px 0 rgba(255,255,255,0.7)'
			: 'none'), // Sem sombra para linhas brancas para aumentar contraste
	borderTop: isExpanded
		? 'none'
		: () => (index % 2 === 0
			? '1px solid rgba(255,255,255,0.8)'
			: '1px solid rgba(240,245,255,0.9)'), // Borda mais sutil para linhas brancas
	borderBottom: isExpanded
		? 'none'
		: () => (index % 2 === 0
			? '1px solid rgba(0,0,0,0.03)'
			: '1px solid rgba(230,240,250,0.7)'), // Borda para linhas brancas
	'&:hover': {
		background: isExpanded
			? () => alpha(theme.palette.primary.main, 0.12)
			: () => (theme.palette.mode === 'light'
				? 'linear-gradient(to bottom, #ebf1fb, #dfe8f6)' // Hover mais azulado
				: 'linear-gradient(to bottom, #becce4, #b3c3dd)'),
		'& .MuiTableCell-root': {
			color: isExpanded ? () => theme.palette.primary.dark : '#1d3557',
			fontWeight: '600',
			transform: 'scale(1.01)',
			transition: 'all 0.2s ease',
		},
	},
	'& .MuiTableCell-root': {
		padding: '0px 8px',
		height: '56px',
		fontWeight: isExpanded ? '600' : '500', // Fonte mais forte quando expandido
		letterSpacing: isExpanded ? '0' : '0.02em',
		color: isExpanded ? () => theme.palette.primary.dark : '#1d3557',
		textShadow: isExpanded ? 'none' : () => (index % 2 === 0
			? '0 1px 1px rgba(255,255,255,0.7)'
			: 'none'), // Sem sombra para linhas brancas
		transition: 'all 0.2s ease',
	},
});

/**
 * Estilos para área de detalhes expandida
 */
export const expandedDetailsBoxStyle = (): SxProps => ({
	width: '100%',
	padding: 0,
	margin: 0,
	backgroundColor: 'rgba(249, 249, 254, 0.8)',
	borderRadius: '0 0 6px 6px',
	overflow: 'hidden',
	transition: 'all 0.3s ease',
});

/**
 * Estilos para o cabeçalho da tabela de detalhes
 */
export const detailsTableContainerStyle = (theme: Theme): SxProps => ({
	width: '100%',
	padding: 0,
	margin: 0,
	'&::-webkit-scrollbar': {
		width: '6px',
		height: '6px',
	},
	'&::-webkit-scrollbar-track': {
		backgroundColor: 'transparent',
	},
	'&::-webkit-scrollbar-thumb': {
		backgroundColor: () => alpha(theme.palette.primary.main, 0.2),
		borderRadius: '3px',
		'&:hover': {
			backgroundColor: () => alpha(theme.palette.primary.main, 0.3),
		},
	},
});

export const detailsTableStyle = (theme: Theme): SxProps => ({
	tableLayout: 'auto',
	width: '100%',
	'& tbody tr': {
		transition: 'all 0.2s ease',
	},
	'& tbody tr:nth-of-type(odd)': {
		backgroundColor: 'rgba(240, 245, 255, 0.4)', // Azul mais claro para combinar
	},
	'& tbody tr:nth-of-type(even)': {
		backgroundColor: 'rgba(255, 255, 255, 0.95)', // Quase branco
	},
	'& tbody tr:hover': {
		backgroundColor: 'rgba(235, 241, 251, 0.7)', // Azul claro ao passar o mouse
		transform: 'translateY(-1px)',
		boxShadow: '0 2px 4px rgba(0,0,0,0.03)',
		'& .MuiTableCell-root': {
			color: () => theme.palette.primary.dark,
			fontWeight: '500',
		},
	},
	'& .MuiTableCell-root': {
		transition: 'all 0.2s ease',
	},
});

export const detailsTableRowStyle = (theme: Theme): SxProps => ({
	background: () => `linear-gradient(to bottom, ${alpha(theme.palette.primary.main, 0.06)}, ${alpha(theme.palette.primary.main, 0.03)})`,
	borderBottom: () => `2px solid ${alpha(theme.palette.primary.main, 0.15)}`,
	height: '42px',
	boxShadow: '0 2px 4px rgba(0,0,0,0.04)',
	position: 'relative',
	'&:after': {
		position: 'absolute',
		bottom: 0,
		left: 0,
		right: 0,
		height: '1px',
		backgroundColor: 'rgba(255,255,255,0.7)',
	},
	'& .MuiTableCell-root': {
		fontWeight: 600,
		fontSize: '0.825rem',
		letterSpacing: '0.03em',
		color: () => theme.palette.primary.dark,
		padding: '8px 8px',
		position: 'relative',
		textShadow: '0 1px 1px rgba(255,255,255,0.7)',
		'&:after': {
			position: 'absolute',
			bottom: 0,
			left: '4px',
			right: '4px',
			height: '2px',
			backgroundColor: 'transparent',
			transition: 'all 0.2s ease',
		},
		'&:hover:after': {
			backgroundColor: () => alpha(theme.palette.primary.main, 0.2),
		},
	},
});
/*
 * Estilos para área de colapso
 */
export const collapseContainerStyle = (theme: Theme): SxProps => ({
	width: '100%',
	margin: '0 0 8px 0',
	padding: 0,
	boxShadow: '0 4px 12px rgba(0,0,0,0.08)',
	border: '1px solid rgba(0,0,0,0.09)',
	borderRadius: '6px',
	overflow: 'hidden',
	position: 'relative',
	'&:before': {
		content: '""',
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		height: '4px',
		backgroundColor: alpha(theme.palette.primary.main, 0.15),
		zIndex: 1,
	},
});

export const checkBoxStyle = (theme: Theme): SxProps => ({
	color: '#3b5998',
	'&.Mui-checked': {
		color: () => theme.palette.primary.main,
	},
	'&:hover': {
		backgroundColor: () => alpha(theme.palette.primary.main, 0.04),
	},
});

export const tableRowItemStyle = (
	index: number,
	container: any,
	arrayProp: string,
): SxProps => ({
	'&:last-child td, &:last-child th': { borderBottom: 0 },
	height: '40px',
	'& .MuiTableCell-root': {
		color: '#1d3557',
		borderBottom: index === ((container[arrayProp] && container[arrayProp].length - 1) || 0)
			? 'none'
			: '1px solid rgba(224, 224, 224, 0.5)',
	},
});

export const iconButtonStyle = (): SxProps => ({
	padding: '3px',
	'&:hover': {
		backgroundColor: 'rgba(211, 47, 47, 0.02)',
		transform: 'scale(1.05)',
		transition: 'all 0.2s ease',
	},
	'&.Mui-disabled': {
		opacity: 0.5,
	},
});

export const expandabledRowCheckBoxStyle = (isExpanded: boolean): SxProps => ({
	color: isExpanded ? 'inherit' : '#3b5998',
	'&.Mui-checked': {
		color: isExpanded ? 'primary.main' : '#3b5998',
	},
});

export const expandabledRowTableCellStyle = (): React.CSSProperties => ({
	paddingBottom: 0,
	paddingTop: 0,
	padding: 0,
	border: 0,
	backgroundColor: 'transparent',
});

export const detailsTableCellBoxStyle = (theme: Theme): SxProps => ({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	'& .product-code': {
		color: () => theme.palette.primary.dark,
	},
});

import React, {
	useCallback,
	useMemo,
	useState,
} from 'react';
import {
	Button,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useFormikContext } from 'formik';
import { IBusinessPartner, IProductBusinessPartner } from '../../../containers/BusinessPartner/BusinessPartnerAssets';
import { IProductWithoutDetails } from '../../../containers/Product/ProductAssets';
import Select from '../../Common/Form/Select';
import Input from '../../Common/Form/Input';
import Pagination from '../../Common/Pagination';
import Autocomplete from '../../Common/Form/Autocomplete';
import { ProductQueryParams } from '../../../interfaces/ProductQueryParams';
import { useConditionalSearch } from '../../../hooks/useConditionalSearchProps';

interface ProductBPFormProps {
	products: IProductWithoutDetails[];
	getProducts(queryParams: ProductQueryParams): void;
	handleDeleteProductBusinessPartner(id: string): Promise<void>;
}

const ProductBPForm = ({
	products,
	getProducts,
	handleDeleteProductBusinessPartner,
}: ProductBPFormProps): JSX.Element => {
	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const { values, setFieldValue } = useFormikContext<IBusinessPartner>();
	const [productInputValue, setProductInputValue] = useState('');

	useConditionalSearch({
		inputValue: productInputValue,
		loadedItems: products,
		searchFunction: getProducts,
		minLength: 4,
		maxLength: 8,
		compareKey: 'description',
		compareKeySecondary: 'code',
		queryParam: 'description',
	});

	const getUnitOptions = useCallback(() => [
		{ value: values.id || values.name, label: 'Matriz', key: values.id },
		...values.units.map((unit, unitIndex) => (
			{
				value: unit.id || unit.tempId,
				label: `Unidade ${unitIndex + 1}
	- ${unit.marketName || ''}`,
				key: unit.id || unit.tempId,
			}
		)),
	], [values.id, values.name, values.units]);

	const allBPItems = useMemo(() => {
		const matrixProductBusinessPartners = values.productBusinessPartners.map((bp, index) => ({
			...bp,
			origin: 'matrix',
			originIndex: index,
		}));
		const unitProductBusinessPartners = values.units
			.flatMap((unit, unitIndex) => unit.productBusinessPartners.map((bp, index) => ({
				...bp,
				origin: 'unit',
				originUnitIndex: unitIndex,
				originIndex: index,
			})));
		return [...matrixProductBusinessPartners, ...unitProductBusinessPartners].sort((a, b) => {
			if (a.isTemporary && !b.isTemporary) return 1;
			if (!a.isTemporary && b.isTemporary) return -1;
			return 0;
		});
	}, [values.productBusinessPartners, values.units]);

	const paginationData = useMemo(() => {
		const totalItems = allBPItems.length;
		const totalPageCount = Math.ceil(totalItems / rowsPerPage) || 1;
		const startIdx = (page - 1) * rowsPerPage;
		const itemsForPage = allBPItems.slice(startIdx, startIdx + rowsPerPage);

		return {
			total: totalItems,
			totalPages: totalPageCount,
			startIndex: startIdx,
			pageItems: itemsForPage,
		};
	}, [allBPItems, page, rowsPerPage]);

	const { total, totalPages, pageItems } = paginationData;

	const handleAddProduct = useCallback(() => {
		const newProductBP: IProductBusinessPartner = {
			code: '',
			description: '',
			productId: '',
			businessPartnerId: values.id,
			isTemporary: true,
		};

		const newArray = [...values.productBusinessPartners, newProductBP];
		setFieldValue('productBusinessPartners', newArray);

		const newTotal = total + 1;
		const lastPage = Math.ceil(newTotal / rowsPerPage);
		setPage(lastPage);
	}, [values.id, values.productBusinessPartners, rowsPerPage, total, setFieldValue]);

	const handleRemoveProductBP = useCallback(
		(productBusinessPartner: IProductBusinessPartner) => {
			const updatePaginationAfterRemove = (): void => {
				const newTotal = total - 1;
				const newTotalPages = Math.ceil(newTotal / rowsPerPage);

				if (page > newTotalPages) {
					setPage(newTotalPages);
				}
			};
			if (productBusinessPartner.id) {
				handleDeleteProductBusinessPartner(productBusinessPartner.id)
					.then(() => {
						if (productBusinessPartner.origin === 'matrix' && productBusinessPartner.originIndex) {
							const updated = [...values.productBusinessPartners];
							updated.splice(productBusinessPartner.originIndex, 1);
							setFieldValue('productBusinessPartners', updated);
							updatePaginationAfterRemove();
						} else if (
							productBusinessPartner.origin === 'unit'
							&&	productBusinessPartner.originUnitIndex !== undefined
							&& productBusinessPartner.originIndex !== undefined
						) {
							const updated = [...values.units[productBusinessPartner.originUnitIndex]
								.productBusinessPartners];
							updated.splice(productBusinessPartner.originIndex, 1);
							setFieldValue(
								`units.${productBusinessPartner.originUnitIndex}.productBusinessPartners`,
								updated,
							);
							updatePaginationAfterRemove();
						}
					});
			} else if (productBusinessPartner.origin === 'matrix' && productBusinessPartner.originIndex) {
				const updated = [...values.productBusinessPartners];
				updated.splice(productBusinessPartner.originIndex, 1);
				setFieldValue('productBusinessPartners', updated);
				updatePaginationAfterRemove();
			} else if (
				productBusinessPartner.origin === 'unit'
				&& productBusinessPartner.originUnitIndex !== undefined
				&& productBusinessPartner.originIndex !== undefined
			) {
				const updated = [...values.units[productBusinessPartner.originUnitIndex]
					.productBusinessPartners];
				updated.splice(productBusinessPartner.originIndex, 1);
				setFieldValue(
					`units.${productBusinessPartner.originUnitIndex}.productBusinessPartners`,
					updated,
				);
				updatePaginationAfterRemove();
			}
		},
		[
			handleDeleteProductBusinessPartner,
			setFieldValue,
			values.productBusinessPartners,
			values.units,
			page,
			rowsPerPage,
			total,
		],
	);

	const renderRow = useCallback((productBusinessPartner, index) => {
		const productBpIndex = productBusinessPartner.origin === 'matrix'
			? `productBusinessPartners.${productBusinessPartner.originIndex}`
			: `units.${productBusinessPartner.originUnitIndex}.productBusinessPartners.${productBusinessPartner.originIndex}`;

		return (
			<TableRow key={productBusinessPartner.id || `new-${index}`}>
				<TableCell>
					<Autocomplete
						name={`${productBpIndex}.productId`}
						label="Digite o código/descrição"
						options={products}
						labelKey="description"
						valueKey="id"
						valueLabel="code"
						onInputChange={(event, newInputValue) => setProductInputValue(newInputValue)}
						readOnly={productBusinessPartner.hasTask}
					/>
				</TableCell>
				<TableCell>
					<Input.InputField
						name={`${productBpIndex}.code`}
						value={productBusinessPartner.code}
						label=""
						fullWidth
						required
						InputProps={{ readOnly: productBusinessPartner.hasTask }}
					/>
				</TableCell>
				<TableCell>
					<Input.InputField
						name={`${productBpIndex}.description`}
						value={productBusinessPartner.description}
						label=""
						fullWidth
						required
						InputProps={{ readOnly: productBusinessPartner.hasTask }}
					/>
				</TableCell>
				<TableCell>
					<Select
						name={`${productBpIndex}.businessPartnerId`}
						label=""
						options={getUnitOptions()}
						labelKey="label"
						valueKey="value"
						readOnly={productBusinessPartner.hasTask}
					/>
				</TableCell>
				<TableCell>
					<IconButton
						onClick={() => handleRemoveProductBP(productBusinessPartner)}
						color="error"
						disabled={productBusinessPartner.hasTask}
					>
						<DeleteIcon />
					</IconButton>
				</TableCell>
			</TableRow>
		);
	}, [getUnitOptions, handleRemoveProductBP, products]);

	const handlePaginationChange = useCallback((newPage: number, newRowsPerPage: number) => {
		setPage(newPage);
		setRowsPerPage(newRowsPerPage);
	}, []);

	return (
		<>
			<Typography variant="caption">Todos os campos são obrigatórios!</Typography>
			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Produto</TableCell>
							<TableCell>Código BP</TableCell>
							<TableCell>Descrição BP</TableCell>
							<TableCell>Unidade</TableCell>
							<TableCell>Ações</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{pageItems.map((item, index) => renderRow(item, index))}
						<TableRow>
							<TableCell colSpan={5}>
								<Button onClick={handleAddProduct} color="primary">
									Adicionar Produto
								</Button>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
				<Pagination
					loading={false}
					count={totalPages}
					page={page}
					take={rowsPerPage}
					onChange={handlePaginationChange}
				/>
			</TableContainer>
		</>
	);
};

export default ProductBPForm;

/* eslint-disable react/jsx-props-no-spreading */
import React, {
	useCallback, useEffect, useState,
} from 'react';
import {
	Form, FormikContext, useFormik,
} from 'formik';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Close from '@mui/icons-material/Close';
import pick from 'lodash/pick';
import { IconButton, InputAdornment } from '@mui/material';
import { LocationProductQueryParams } from '../../interfaces/LocationProduct';
import Autocomplete from '../Common/Form/Autocomplete';
import { IProductWithoutDetails } from '../../containers/Product/ProductAssets';
import { ProductQueryParams } from '../../interfaces/ProductQueryParams';
import Input from '../Common/Form/Input';
import Select from '../Common/Form/Select';
import { availableFilterData } from '../../constants/location';
import { formatBarCode } from '../../helpers/masks';
import Switch from '../Common/Form/Switch';

interface IFilterProps {
  loadingProducts: boolean;
  initialValues: LocationProductQueryParams;
  products: IProductWithoutDetails[];
  filter: LocationProductQueryParams;
  getProducts: (queryParams: ProductQueryParams) => void;
  sendFilter: (values: Partial<LocationProductQueryParams>) => void;
}

const LocationFilter = ({
	initialValues,
	loadingProducts,
	products,
	filter,
	sendFilter,
	getProducts,
}: IFilterProps): JSX.Element => {
	const [selectedProducts, setSelectedProducts] = useState<IProductWithoutDetails[]>([]);
	const [productOptions, setProductOptions] = useState<IProductWithoutDetails[]>(products);

	const onReset = useCallback(
		(handleReset) => {
			const newDataFilter = pick(filter, ['skip', 'take']);
			sendFilter(newDataFilter);
			handleReset();
		},
		[sendFilter, filter],
	);

	const formik = useFormik({
		initialValues,
		onSubmit: (values: LocationProductQueryParams) => {
			const newValueFilter: LocationProductQueryParams = {
				...values,
				locationValue: values.locationValue
					? values.locationValue.replace(/[^a-zA-Z0-9]/g, '')
					: undefined,
				skip: 0,
			};

			sendFilter(newValueFilter);
		},
	});

	useEffect(() => {
		const updatedOptions = [...selectedProducts,
			...products.filter((p) => !selectedProducts.some((sp) => sp.id === p.id))];
		setProductOptions(updatedOptions);
	}, [products, selectedProducts]);

	const handleChangeProducts = useCallback((_event, newValue, reason) => {
		if (reason === 'selectOption' && newValue !== null) {
			const productToAdd = products.find((product) => newValue.includes(product.id));
			if (productToAdd) {
				setSelectedProducts((prevState) => [...prevState, productToAdd]);
			}
			formik.setFieldValue('productIds', newValue);
		} else if (reason === 'input') {
			getProducts({ description: newValue, skip: 0, take: 10 });
		} else if (reason === 'removeOption' || reason === 'clear') {
			formik.setFieldValue('productIds', newValue);
		}
	}, [getProducts, products, formik]);

	return (
		<FormikContext.Provider value={formik}>
			<Form>
				<Grid container spacing={2} sx={{ p: 2, marginTop: 6 }}>
					<Grid item xs={12}>
						<Input.InputField
							id="locationValue"
							name="locationValue"
							label="Localização"
							autoComplete="off"
							format={(value) => formatBarCode(value)}
							fullWidth
						/>
					</Grid>

					<Grid item xs={12}>
						<Autocomplete
							label="Produtos"
							name="productIds"
							valueKey="id"
							valueLabel="code"
							labelKey="description"
							options={productOptions || []}
							loading={loadingProducts}
							onInputChange={(
								event,
								newValue,
								reason,
							) => {
								if (newValue.length > 2) {
									handleChangeProducts(event, newValue, reason);
								}
							}}
							onChange={(event, newValue, reason) => handleChangeProducts(event, newValue, reason)}
							multiple
							fullWidth
						/>
					</Grid>

					<Grid item xs={12}>
						<Select
							name="available"
							label="Disponível"
							options={availableFilterData}
							valueKey="value"
							labelKey="label"
							required={false}
							size="medium"
							endAdornment={
								(formik.values.available !== '' as any) ? (
									<InputAdornment sx={{ marginRight: '10px' }} position="end">
										<IconButton
											onClick={() => {
												formik.setFieldValue('available', '');
											}}
										>
											<Close />
										</IconButton>
									</InputAdornment>
								) : null
							}
						/>
					</Grid>

					<Grid item xs={12}>
						<Switch
							name="active"
							label="Inativo/Ativo"
						/>
					</Grid>

					<Grid
						item
						xs={12}
						sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}
					>
						<Button variant="outlined" onClick={() => onReset(formik.handleReset)}>
							Restaurar
						</Button>
						<Button variant="contained" type="submit">
							Filtrar
						</Button>
					</Grid>
				</Grid>
			</Form>
		</FormikContext.Provider>
	);
};

export default LocationFilter;

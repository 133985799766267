import React, { useCallback } from 'react';
import { Form, Formik } from 'formik';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Input from '../Common/Form/Input';
import SwitchField from '../Common/Form/Switch';
import { filterObject } from '../../helpers/Utils';
import { IProduct } from '../../containers/Product/ProductAssets';

interface IFilterProps {
    sendFilter: (values: Partial<IProduct>) => void;
}

interface IForm {
    description?: string;
    familyDescription?: string;
	active?: boolean;
}

const initialValues = {
	description: '',
	familyDescription: '',
	active: true,
};

const ProductFilter = ({
	sendFilter,
}: IFilterProps): JSX.Element => {
	const onSubmit = useCallback((values: IForm) => {
		sendFilter(filterObject(values));
	}, [sendFilter]);

	const onReset = useCallback((handleReset) => {
		sendFilter({});
		handleReset();
	}, [sendFilter]);

	return (
		<Paper sx={{ p: 2, width: '100%', mb: 2 }}>
			<Formik
				initialValues={initialValues}
				onSubmit={onSubmit}
			>
				{({ handleReset }) => (
					<Form>
						<Grid container spacing={2} sx={{ p: 2, marginTop: 6 }}>
							<Grid item xs={12}>
								<Input.InputField
									id="description"
									name="description"
									label="Código/Descrição Produto"
									autoComplete="off"
									fullWidth
								/>
							</Grid>
							<Grid item xs={12}>
								<Input.InputField
									id="familyDescription"
									name="familyDescription"
									label="Código/Descrição Família"
									autoComplete="off"
									fullWidth
								/>
							</Grid>
							<Grid item xs={12}>
								<SwitchField
									name="active"
									label="Inativo/Ativo"
								/>
							</Grid>
							<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
								<Button variant="outlined" onClick={() => onReset(handleReset)}>
									Restaurar
								</Button>
								<Button variant="contained" type="submit">
									Filtrar
								</Button>
							</Grid>
						</Grid>
					</Form>
				)}
			</Formik>
		</Paper>
	);
};

export default ProductFilter;

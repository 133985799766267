import { useState, useEffect } from 'react';
import { getUserData, getUserInitials } from '../helpers/getUserData';
import { UserData } from '../interfaces/UserData';

interface CurrentUserData {
	userData: UserData;
	userName: string;
	userInitials: string;
}

const useCurrentUser = (): CurrentUserData => {
	const [userData, setUserData] = useState(getUserData());
	const [userName, setUserName] = useState(userData?.name || 'Usuário');
	const [userInitials, setUserInitials] = useState(getUserInitials(userName));

	useEffect(() => {
		const currentUserData = getUserData();
		setUserData(currentUserData);

		const name = currentUserData?.name || 'Usuário';
		setUserName(name);

		setUserInitials(getUserInitials(name));
	}, []);

	return {
		userData,
		userName,
		userInitials,
	};
};

export default useCurrentUser;

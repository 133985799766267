import React, {
	useCallback,
	useMemo,
	useState,
} from 'react';
import { Link } from 'react-router-dom';
import { DataGrid, GridSortDirection, GridSortModel } from '@mui/x-data-grid';
import FilterListIcon from '@mui/icons-material/FilterList';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { IProductWithoutDetails } from '../../containers/Product/ProductAssets';
import { PageHeader } from '../Common/PageHeader/PageHeader';
import { PageHeaderButtonProps } from '../../interfaces/PageHeaderInterface';
import ProductFilter from './ProductFilter';
import { Loading } from '../Common/Loading';
import { ProductQueryParams } from '../../interfaces/ProductQueryParams';
import useDataGridManagement from '../../hooks/useDataGridManagement';
import { normalizeDataGridOrderBy } from '../../helpers/Utils';
import DrawerFilter from '../Common/DrawerFilter';
import useColumns from '../../hooks/useColumns';
import { productColumns } from '../../constants/productColumns';
import { useManageColumns } from '../../hooks/useManageColumns';
import { ToolbarComponent } from '../Common/Datagrid/DataGridToolBar';
import useConfirmationDialog from '../../hooks/useConfirmationDialog';
import { AlertTypes } from '../../enums/ConfirmationDialogType';

const localStorageKey = 'product';

const columnsVisibility = [
	'image',
	'code',
	'description',
	'familyCode',
	'familyDescription',
	'primaryMeasureId',
	'typeId',
	'actions',
];

const optionalColumnsVisibility = [
	'erpCode',
	'erpDescription',
	'barCode',
	'secondaryMeasureId',
	'qualityControl',
	'locateControl',
	'apportionmentControl',
	'createdAt',
	'updatedAt',
	'costInv',
	'costInvDate',
	'costSPF',
	'costSPFDate',
	'history',
];

interface ProductProps {
	loading: boolean;
	products: IProductWithoutDetails[];
	productsPages: number;
	productsPage: number;
	productsTake: number;
	getProducts(queryParams: ProductQueryParams): void;
	handleDeleteProduct(id: string): void;
	handleProductActiveStatus(id: string, active: boolean): void;
	handleEdit(id: string): void;
	printLabel(barCode: string): void;
}

const initialSortModel: GridSortModel = [
	{ field: 'code', sort: 'asc' },
];

const Product = ({
	loading,
	products,
	productsPages,
	productsPage,
	productsTake,
	getProducts,
	handleDeleteProduct,
	handleProductActiveStatus,
	handleEdit,
	printLabel,
}: ProductProps): JSX.Element => {
	const [isFilterDrawerOpen, setFilterDrawerOpen] = useState(false);
	const { requestConfirm, confirmationDialog } = useConfirmationDialog();

	const handleDelete = useCallback((id: string) => {
		requestConfirm({
			description: 'Tem certeza que deseja excluir o produto selecionado?',
			alertType: AlertTypes.ERROR,
			callback: () => handleDeleteProduct(id),
		});
	}, [requestConfirm, handleDeleteProduct]);

	const handleChangeActiveStatus = useCallback((id: string, active: boolean) => {
		if (!active) {
			requestConfirm({
				title: 'Desativar Produto',
				description: 'Tem certeza que deseja desativar o produto selecionado? Ele será desativado também nas localizações que contém este produto.',
				alertType: AlertTypes.ERROR,
				callback: () => handleProductActiveStatus(id, active),
			});
		} else {
			handleProductActiveStatus(id, active);
		}
	}, [requestConfirm, handleProductActiveStatus]);

	const {
		setFilter,
		sortModel,
		onChangePage,
		onChangePageSize,
		onSortModelChange,
	} = useDataGridManagement<ProductQueryParams>({
		initialSortModel,
		initialPageSize: productsTake,
		initialPage: productsPage,
		fetchData: getProducts,
	});

	const { columns, defaultVisibility } = useColumns({
		columnsVisibility,
		defaultColumnsOverride: productColumns,
		optionalColumnsVisibility,
		onEditClick: handleEdit,
		onDeleteClick: handleDelete,
		onChangeActiveStatus: handleChangeActiveStatus,
		onPrintLabel: printLabel,
	});

	const {
		currentColumns,
		columnVisibilityModel,
		handleColumnVisibilityModelChange,
		onRestoreClick,
	} = useManageColumns({
		initialColumns: columns,
		initialVisibility: defaultVisibility,
		localStorageKey,
		isDraggable: true,
	});

	const sendFilter = useCallback((values: Partial<ProductQueryParams>) => {
		const pageFilter = {
			...values,
			skip: 0,
			orderBy: normalizeDataGridOrderBy(sortModel) as {
				name: GridSortDirection,
				code: GridSortDirection,
			},
		};
		setFilter(pageFilter);
	}, [setFilter, sortModel]);

	const toggleFilterDrawer = useCallback(() => {
		setFilterDrawerOpen(!isFilterDrawerOpen);
	}, [isFilterDrawerOpen]);

	const headerButtonsProps = useMemo((): PageHeaderButtonProps[] => [
		{
			variant: 'contained',
			color: 'primary',
			type: 'submit',
			fullWidth: true,
			component: Link,
			to: 'edit',
			text: 'Novo',
		},
		{
			variant: 'contained',
			color: 'primary',
			onClick: toggleFilterDrawer,
			text: 'Filtro',
			startIcon: <FilterListIcon />,
		},
	], [toggleFilterDrawer]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const pageHeaderMemo = useMemo(() => <PageHeader title="Produto" buttons={headerButtonsProps} />, []);

	const filterMemo = useMemo(() => (
		<DrawerFilter open={isFilterDrawerOpen} onClose={toggleFilterDrawer}>
			<ProductFilter
				sendFilter={sendFilter}
			/>
		</DrawerFilter>
	), [isFilterDrawerOpen, sendFilter, toggleFilterDrawer]);

	return (
		<>
			{pageHeaderMemo}
			{filterMemo}
			{loading && <Loading />}
			{!loading && (
				<Box component={Paper} sx={{ width: '100%' }}>
					<DataGrid
						autoHeight
						rows={products}
						rowCount={productsPages}
						pagination
						paginationMode="server"
						sortingMode="server"
						columns={currentColumns}
						page={productsPage}
						pageSize={productsTake}
						rowsPerPageOptions={[10, 25, 50, 100]}
						loading={loading}
						sortModel={sortModel}
						columnVisibilityModel={columnVisibilityModel}
						onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
						components={{ Toolbar: ToolbarComponent(onRestoreClick) }}
						onPageChange={onChangePage}
						onPageSizeChange={onChangePageSize}
						onSortModelChange={onSortModelChange}
						disableSelectionOnClick
						disableColumnMenu
					/>
					{confirmationDialog}
				</Box>
			)}
		</>
	);
};

export default Product;

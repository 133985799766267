import React, {
	memo,
	useState,
	useMemo,
	useCallback,
} from 'react';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	IconButton,
	Stack,
	Tooltip,
	Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
	Print, ToggleOff, ToggleOn, Visibility, VisibilityOff,
} from '@mui/icons-material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { isEqual } from 'lodash';
import { ILocation } from '../../containers/Location/LocationAssets';
import { ToolbarAddComponent } from '../Common/Datagrid/DataGridAddToolBar';
import { formatProductsByLocationTable } from '../../helpers/formatProductsByLocationTable';

interface LocationAccordionProps {
    locationData: ILocation;
    handleOpenModalAddProduct: (locationData: ILocation) => void;
    printLabel: (barCode: string) => void;
    columns: GridColDef[];
    loading: boolean;
	handleActiveLocation: (locationId: string, active: boolean) => void;
}

const LocationAccordion = memo(({
	locationData,
	handleOpenModalAddProduct,
	printLabel,
	columns,
	loading,
	handleActiveLocation,
}: LocationAccordionProps) => {
	const [isExpanded, setIsExpanded] = useState(false);
	const [showInactiveProducts, setShowInactiveProducts] = useState(false);

	const formattedLocations = useMemo(
		() => formatProductsByLocationTable(locationData),
		[locationData],
	);

	const filteredLocations = useMemo(() => {
		if (showInactiveProducts) {
			return formattedLocations;
		}
		return formattedLocations.filter((product) => product.locationProduct.activeProduct);
	}, [formattedLocations, showInactiveProducts]);

	const toggleInactiveProductsButton = useMemo(() => (
		<Tooltip title={showInactiveProducts ? 'Ocultar Produtos Desativados' : 'Exibir Produtos Desativados'}>
			<IconButton
				color="primary"
				onClick={() => setShowInactiveProducts((prev) => !prev)}
			>
				{showInactiveProducts ? <VisibilityOff /> : <Visibility />}
			</IconButton>
		</Tooltip>
	), [showInactiveProducts]);

	const handleAccordionChange = useCallback(() => {
		setIsExpanded((prev) => !prev);
	}, []);

	return (
		<Accordion
			key={locationData.id}
			expanded={isExpanded}
			onChange={handleAccordionChange}
		>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
			>
				<Box sx={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					gap: 2,
					justifyContent: 'space-between',
					width: '100%',
				}}
				>
					<Typography>
						{ `${locationData.barCodeFormatted} - ${locationData.locationName}` }
					</Typography>
					<Box>
						<Tooltip title="Ativar/Desativar">
							<IconButton
								onClick={(e) => {
									e.stopPropagation();
									handleActiveLocation(locationData.id, !locationData.active);
								}}
							>
								{locationData.active ? <ToggleOn color="primary" /> : <ToggleOff />}
							</IconButton>
						</Tooltip>
						<Tooltip title="Imprimir etiqueta">
							<IconButton onClick={() => printLabel(locationData.barCode)}>
								<Print />
							</IconButton>
						</Tooltip>
					</Box>
				</Box>
			</AccordionSummary>
			<AccordionDetails>
				<DataGrid
					autoHeight
					rows={filteredLocations}
					columns={columns}
					loading={loading}
					hideFooter
					components={locationData.active ? {
						Toolbar: ToolbarAddComponent(
							() => handleOpenModalAddProduct(locationData),
							toggleInactiveProductsButton,
						),
					} : {
						Toolbar: ToolbarAddComponent(
							undefined,
							toggleInactiveProductsButton,
						),
					}}
				/>
			</AccordionDetails>
		</Accordion>
	);
}, isEqual);

export default LocationAccordion;

import React, { useEffect, useRef, useCallback } from 'react';
import {
	Box,
	Button,
	Paper,
	Typography,
} from '@mui/material';
import { useFormikContext } from 'formik';
import Input from '../../Common/Form/Input';
import { initialValues } from './CheckoutCarrier';

interface CheckoutBarCodeProps {
	handleConfirmBarCode: (barCode: string) => void
}

const CheckoutBarCode = ({ handleConfirmBarCode } : CheckoutBarCodeProps): JSX.Element => {
	const { values } = useFormikContext<typeof initialValues>();
	const barcodeInputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (barcodeInputRef.current) {
			barcodeInputRef.current.focus();
		}
	}, []);

	const handleBlurOrEnter = useCallback((event: React.KeyboardEvent<HTMLInputElement>): void => {
		if ((event.key === 'Tab') || (event.key === 'Enter')) {
			event.preventDefault();
			handleConfirmBarCode(values.danfeBarCode);
		}
	}, [handleConfirmBarCode, values.danfeBarCode]);

	return (
		<Box component={Paper} sx={{ p: 2, mt: 2 }}>
			<Typography textAlign="center" variant="h6" gutterBottom>
				Checkout Transportadora
			</Typography>
			<Typography
				textAlign="center"
				variant="subtitle1"
				gutterBottom
				sx={{ marginBottom: 2 }}
			>
				Leia o código de barras da DANFE
			</Typography>
			<Input.InputField
				id="danfeBarCode"
				name="danfeBarCode"
				label="Código de Barras da DANFE"
				autoComplete="off"
				autoFocus
				fullWidth
				required
				inputRef={barcodeInputRef}
				onKeyDown={handleBlurOrEnter}
			/>
			<Button
				variant="contained"
				type="submit"
				sx={{ mt: 2 }}
				fullWidth
				onClick={() => handleConfirmBarCode(values.danfeBarCode)}
				disabled={!values.danfeBarCode}
			>
				Confirmar
			</Button>
		</Box>
	);
};

export default CheckoutBarCode;

import React, { useMemo } from 'react';
import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Checkbox,
	Chip,
	IconButton,
	Tooltip,
	useTheme,
} from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import PrintIcon from '@mui/icons-material/Print';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { format } from 'date-fns';
import {
	IInventoryTaskInvoice,
	InventoryTask,
} from '../../containers/InventoryTaskInvoice/InventoryTaskInvoiceAssets';
import { InventoryTaskStatus } from '../../enums/InventoryTaskStatus';
import { formatNumberForLocale } from '../../helpers/NumberUtils';
import { countDiffTask } from '../../constants/inventoryTask';
import useConfirmationDialog from '../../hooks/useConfirmationDialog';
import { AlertTypes } from '../../enums/ConfirmationDialogType';
import { getStatusColor } from '../../helpers/StatusCount';
import { currencyBRLMask } from '../../helpers/intl';
import { getSecondaryMeasureQuantity } from '../../helpers/Utils';
import { InventoryTaskInvoiceType } from '../../enums/InventoryTaskInvoiceType';
import { formatBarCode } from '../../helpers/masks';
import {
	checkBoxStyle,
	createExpandableRowStyle,
	detailsTableCellBoxStyle,
	detailsTableContainerStyle,
	detailsTableRowStyle,
	detailsTableStyle,
	expandabledRowCheckBoxStyle,
	expandedDetailsBoxStyle,
	iconButtonStyle,
	tableRowItemStyle,
} from '../../helpers/tableStylesHelper';

interface VisibleColumns {
  checkbox: boolean;
  document: boolean;
  documentType: boolean;
  businessPartner: boolean;
  totalValue: boolean;
  emissionDate: boolean;
  printAction: boolean;
  expandAction: boolean;
  saleOrder: boolean;
}

interface ExpandedTaskDetailsProps {
  inventoryTaskInvoice: IInventoryTaskInvoice;
  inventoryTaskInvoices: IInventoryTaskInvoice[];
  isChecked: (inventoryTaskInvoice: IInventoryTaskInvoice, task: InventoryTask) => boolean;
  isCheckedProductEnabled: (
    status: InventoryTaskStatus,
    locationDestinyBarCode?: string,
    locationOriginBarCode?: string,
  ) => boolean;
  handlerChangeProduct: (rowIndex: number, indexProduct: number, checked: boolean) => void;
  buildLabelStatusTask: (status: InventoryTaskStatus) => string;
  buildLabelColumnQuantityCounted: string;
  handleMenuItemClick: (itemIndex: number, inventoryTask: InventoryTask) => void;
}

interface ExpandableRowProps {
  inventoryTaskInvoice: IInventoryTaskInvoice;
  isSelected: boolean;
  isRowEnabled: boolean;
  onSelect: () => void;
  onExpand: () => void;
  isExpanded: boolean;
  index: number;
  onPrint?: (index: number, logoUrl: string) => void;
  operationType?: InventoryTaskInvoiceType;
  visibleColumns?: VisibleColumns;
  adjustedColWidths?: string[];
}

export const ExpandedTaskDetails = ({
	inventoryTaskInvoice,
	inventoryTaskInvoices,
	isChecked,
	isCheckedProductEnabled,
	handlerChangeProduct,
	buildLabelStatusTask,
	buildLabelColumnQuantityCounted,
	handleMenuItemClick,
}: ExpandedTaskDetailsProps): JSX.Element => {
	const theme = useTheme();
	const { requestConfirm, confirmationDialog } = useConfirmationDialog();

	const handleCancelTaskClick = (inventoryTask: InventoryTask): void => {
		requestConfirm({
			callback: () => handleMenuItemClick(0, inventoryTask),
			title: 'Confirmar cancelamento',
			description: `Deseja realmente cancelar a tarefa para o produto ${inventoryTask.product.code}?`,
			alertType: AlertTypes.ERROR,
		});
	};

	const rowIndex = useMemo(
		() => inventoryTaskInvoices.findIndex((inv) => inv.id === inventoryTaskInvoice.id),
		[inventoryTaskInvoices, inventoryTaskInvoice.id],
	);

	return (
		<Box sx={expandedDetailsBoxStyle()}>
			{confirmationDialog}
			<TableContainer sx={detailsTableContainerStyle(theme)}>
				<Table
					size="small"
					aria-label="itens de tarefa"
					sx={detailsTableStyle(theme)}
				>
					<TableHead>
						<TableRow sx={detailsTableRowStyle(theme)}>
							<TableCell />
							<TableCell sx={{ width: '20%' }}>Produto</TableCell>
							<TableCell align="center" sx={{ width: '5%' }}>Status</TableCell>
							<TableCell align="right" sx={{ width: '7.5%' }}>Quantidade</TableCell>
							<TableCell sx={{ width: '5%' }}>1ª UM</TableCell>
							<TableCell align="right" sx={{ width: '7.5%' }}>Quantidade</TableCell>
							<TableCell sx={{ width: '5%' }}>2ª UM</TableCell>
							<TableCell sx={{ width: '15%' }}>Localização origem</TableCell>
							<TableCell sx={{ width: '15%' }}>Localização destino</TableCell>
							<TableCell sx={{ width: '5%' }}>Contagem</TableCell>
							<TableCell sx={{ width: '10%' }}>{buildLabelColumnQuantityCounted}</TableCell>
							<TableCell sx={{ width: '5%' }}>Ações</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{inventoryTaskInvoice.inventoryTasks.map(
							(inventoryTask, index) => (
								<TableRow
									key={inventoryTask.id}
									hover
									sx={tableRowItemStyle(index, inventoryTaskInvoice, 'inventoryTasks')}
								>
									<TableCell
										padding="checkbox"
										sx={{
											maxWidth: '40px',
										}}
									>
										<Checkbox
											checked={isChecked(
												inventoryTaskInvoice,
												inventoryTask,
											)}
											value={isChecked(
												inventoryTaskInvoice,
												inventoryTask,
											)}
											onChange={(
												event,
												checked,
											) => handlerChangeProduct(
												rowIndex,
												index,
												checked,
											)}
											disabled={
												!isCheckedProductEnabled(
													inventoryTask.status,
													inventoryTask.locationDestinyBarCode,
													inventoryTask.locationOriginBarCode,
												)
											}
											size="small"
											sx={checkBoxStyle(theme)}
										/>
									</TableCell>
									<TableCell>
										<Box sx={detailsTableCellBoxStyle(theme)}>
											{`${inventoryTask.product.code} - ${inventoryTask.product.description}`}
										</Box>
									</TableCell>
									<TableCell>
										<Chip
											label={buildLabelStatusTask(inventoryTask.status)}
											color={getStatusColor(inventoryTask.status)}
											size="small"
											variant="outlined"
											sx={{ height: '22px', width: '130px' }}
										/>
									</TableCell>
									<TableCell align="right">
										{formatNumberForLocale(inventoryTask.quantity, 2)}
									</TableCell>
									<Tooltip title={inventoryTask.product.primaryMeasureDescription || ''}>
										<TableCell>{inventoryTask.product.primaryMeasureId || '-'}</TableCell>
									</Tooltip>
									<TableCell align="right">
										{getSecondaryMeasureQuantity(
											inventoryTask.quantity,
											inventoryTask.product.conversionIndex,
											inventoryTask.product.conversionType,
										)}
									</TableCell>
									<Tooltip title={inventoryTask.product.secondaryMeasureDescription || ''}>
										<TableCell>{inventoryTask.product.secondaryMeasureId || '-'}</TableCell>
									</Tooltip>
									<TableCell>{inventoryTask.locationOriginBarCode ? formatBarCode(inventoryTask.locationOriginBarCode) : '-'}</TableCell>
									<TableCell>{inventoryTask.locationDestinyBarCode ? formatBarCode(inventoryTask.locationDestinyBarCode) : '-'}</TableCell>
									<TableCell>
										{inventoryTask.diff !== null
										&& countDiffTask[inventoryTask.diff]?.label
											? countDiffTask[inventoryTask.diff]?.label
											: '-'}
									</TableCell>
									<TableCell align="right">
										{formatNumberForLocale(inventoryTask.quantityCounted, 2) || '-'}
									</TableCell>
									<TableCell>
										<Tooltip title={(inventoryTask.status === InventoryTaskStatus.FINISHED
										|| inventoryTask.status === InventoryTaskStatus.CANCELED) ? 'Tarefa finalizada' : 'Cancelar tarefa'}
										>
											<Box>
												<IconButton
													size="small"
													color="error"
													onClick={() => handleCancelTaskClick(inventoryTask)}
													disabled={inventoryTask.status === InventoryTaskStatus.FINISHED
													|| inventoryTask.status === InventoryTaskStatus.CANCELED}
													aria-label="cancelar tarefa"
													sx={iconButtonStyle()}
												>
													<CancelOutlinedIcon fontSize="small" />
												</IconButton>
											</Box>
										</Tooltip>
									</TableCell>
								</TableRow>
							),
						)}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	);
};

export const ExpandableRow = ({
	inventoryTaskInvoice,
	isSelected,
	isRowEnabled,
	onSelect,
	onExpand,
	isExpanded,
	index,
	onPrint,
	operationType = InventoryTaskInvoiceType.SUPPLY,
	visibleColumns = {
		checkbox: true,
		document: true,
		documentType: true,
		businessPartner: true,
		totalValue: true,
		emissionDate: true,
		printAction: false,
		expandAction: true,
		saleOrder: false,
	},
	adjustedColWidths = ['5%', '10%', '10%', '20%', '10%', '10%', '10%', '10%'],
}: ExpandableRowProps): JSX.Element => {
	const theme = useTheme();
	const hasPendingTasks = useMemo(() => inventoryTaskInvoice.inventoryTasks.some(
		(task) => task.status === InventoryTaskStatus.PENDING
		|| task.status === InventoryTaskStatus.WAITING
		|| task.status === InventoryTaskStatus.OPERATING,
	), [inventoryTaskInvoice.inventoryTasks]);

	const showPrintButton = useMemo(
		() => visibleColumns.printAction
		&& operationType === InventoryTaskInvoiceType.DISPATCH
		&& hasPendingTasks,
		[visibleColumns.printAction, operationType, hasPendingTasks],
	);

	return (
		<TableRow
			hover
			onClick={onExpand}
			selected={isSelected}
			sx={createExpandableRowStyle(theme, isExpanded, index)}
		>
			<TableCell
				padding="checkbox"
				style={{ width: adjustedColWidths[0], minWidth: adjustedColWidths[0] }}
				onClick={(e) => {
					e.stopPropagation();
				}}
			>
				<Checkbox
					color="primary"
					checked={isSelected}
					onChange={(e) => {
						e.stopPropagation();
						onSelect();
					}}
					onClick={(e) => {
						e.stopPropagation();
					}}
					disabled={!isRowEnabled}
					size="small"
					sx={expandabledRowCheckBoxStyle(isExpanded)}
				/>
			</TableCell>
			<TableCell style={{ width: adjustedColWidths[1], minWidth: adjustedColWidths[1] }}>
				{inventoryTaskInvoice.invoiceNumber}
				/
				{inventoryTaskInvoice.invoiceSerie}
			</TableCell>
			{visibleColumns.documentType && (
				<TableCell style={{ width: adjustedColWidths[2], minWidth: adjustedColWidths[2] }}>
					{inventoryTaskInvoice.invoiceType}
				</TableCell>
			)}
			{visibleColumns.businessPartner && (
				<TableCell style={{ width: adjustedColWidths[3], minWidth: adjustedColWidths[3] }}>
					{inventoryTaskInvoice.businessPartnerName || '-'}
				</TableCell>
			)}
			{visibleColumns.totalValue && (
				<TableCell style={{
					width: adjustedColWidths[4],
					minWidth: adjustedColWidths[4],
					textAlign: 'right',
				}}
				>
					<Box sx={{ marginRight: '60%' }}>
						{currencyBRLMask(inventoryTaskInvoice.invoiceValue)}
					</Box>
				</TableCell>
			)}
			{visibleColumns.saleOrder && (
				<TableCell style={{ width: adjustedColWidths[5], minWidth: adjustedColWidths[5] }}>
					{inventoryTaskInvoice.saleOrder || '-'}
				</TableCell>
			)}
			<TableCell style={{ width: adjustedColWidths[6], minWidth: adjustedColWidths[6] }}>
				{inventoryTaskInvoice.fiscalEntry
					? format(new Date(inventoryTaskInvoice.fiscalEntry), 'dd/MM/yyyy') : ''}
			</TableCell>
			{showPrintButton && (
				<TableCell style={{ width: adjustedColWidths[6], minWidth: adjustedColWidths[6] }}>
					<Box display="flex" alignItems="center" justifyContent="space-between">
						<Tooltip title="Imprimir">
							<IconButton
								size="small"
								onClick={(e) => {
									e.stopPropagation();
									if (onPrint) {
										const logoUrl = `${window.location.origin}/api/configuration/logo`;
										onPrint(index, logoUrl);
									}
								}}
							>
								<PrintIcon fontSize="small" color="primary" />
							</IconButton>
						</Tooltip>
					</Box>
				</TableCell>
			)}
			<TableCell style={{ width: adjustedColWidths[7], minWidth: adjustedColWidths[7] }}>
				<IconButton
					aria-label="expand row"
					size="small"
					sx={{ padding: '2px' }}
					onClick={(e) => {
						e.stopPropagation();
						onExpand();
					}}
				>
					{isExpanded ? <KeyboardArrowUpIcon fontSize="small" /> : <KeyboardArrowDownIcon fontSize="small" />}
				</IconButton>
			</TableCell>
		</TableRow>
	);
};

ExpandableRow.defaultProps = {
	onPrint: undefined,
	operationType: InventoryTaskInvoiceType.SUPPLY,
	visibleColumns: undefined,
	adjustedColWidths: undefined,
};

export default ExpandedTaskDetails;

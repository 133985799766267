import {
	useState, useEffect, useRef, useCallback,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { GridSortDirection, GridSortModel } from '@mui/x-data-grid';
import useDataGridManagement from './useDataGridManagement';
import { FiscalDocumentQueryParams } from '../interfaces/FiscalDocumentParams';
import { StatusInvoice } from '../containers/FiscalDocumentEntry/FiscalDocumentEntryAssets';
import { normalizeDataGridOrderBy } from '../helpers/Utils';

interface FiscalDocumentStateOptions {
  fiscalDocumentTake: number;
  fiscalDocumentPage: number;
  getInvoiceStatusCount: () => void;
  getFiscalDocuments: (params: FiscalDocumentQueryParams) => void;
}

interface FiscalDocumentStateReturn {
	filter: FiscalDocumentQueryParams;
	showTable: boolean;
	currentView: { status: StatusInvoice } | null;
	isFilterDrawerOpen: boolean;
	isModalOpen: boolean;
	sortModel: GridSortModel;
	onChangePage: (page: number) => void;
	onChangePageSize: (pageSize: number) => void;
	onSortModelChange: (model: GridSortModel) => void;
	setFilter: React.Dispatch<React.SetStateAction<FiscalDocumentQueryParams>>;
	handleStatusCardClick: (sectionId: number, statusKey: StatusInvoice) => void;
	navigateBackToDashboard: () => void;
	toggleFilterDrawer: () => void;
	handleOpenModal: () => void;
	handleFilterSubmit: (newFilterValues: Partial<FiscalDocumentQueryParams>) => void;
	countActiveFilters: () => number;
  }

const useFiscalDocumentState = ({
	fiscalDocumentTake,
	fiscalDocumentPage,
	getInvoiceStatusCount,
	getFiscalDocuments,
}: FiscalDocumentStateOptions): FiscalDocumentStateReturn => {
	const [isFilterDrawerOpen, setFilterDrawerOpen] = useState(false);
	const [isModalOpen, setModalOpen] = useState(false);
	const [showTable, setShowTable] = useState(false);
	const [currentView, setCurrentView] = useState<{ status: StatusInvoice } | null>(null);
	const [isInitialized, setIsInitialized] = useState(false);
	const lastParamsRef = useRef<FiscalDocumentQueryParams | null>(null);
	const restoredRef = useRef(false);
	const prevShowTable = useRef<boolean>();
	const navigate = useNavigate();

	const {
		filter,
		setFilter,
		sortModel,
		onChangePage,
		onChangePageSize,
		onSortModelChange,
	} = useDataGridManagement<FiscalDocumentQueryParams>({
		initialPageSize: fiscalDocumentTake,
		initialPage: fiscalDocumentPage,
		fetchData: (params) => {
			if (showTable) {
				if (JSON.stringify(params) !== JSON.stringify(lastParamsRef.current)) {
					lastParamsRef.current = params;
					getFiscalDocuments(params);
				}
			}
		},
	});

	useEffect(
		() => () => {
			const currentPath = window.location.pathname;
			if (!currentPath.includes('/fiscal-document-entry')) {
				sessionStorage.removeItem('fiscalDocumentState');
			}
		},
		[],
	);

	useEffect(() => {
		if (!restoredRef.current) {
			restoredRef.current = true;
			const saved = sessionStorage.getItem('fiscalDocumentState');

			if (saved) {
				const parsed = JSON.parse(saved);

				if (parsed.filters) {
					setFilter((old) => {
						if (JSON.stringify(old) !== JSON.stringify(parsed.filters)) {
							return parsed.filters;
						}
						return old;
					});
				}

				if (parsed.currentView) {
					setCurrentView(parsed.currentView);
				}

				if (parsed.showTable) {
					setShowTable(true);
				}
			}

			setIsInitialized(true);
		}
	}, [setFilter]);

	useEffect(() => {
		sessionStorage.setItem('fiscalDocumentState', JSON.stringify({
			filters: filter,
			currentView,
			showTable,
		}));
	}, [filter, currentView, showTable]);

	useEffect(() => {
		if (!restoredRef.current || !isInitialized) return;

		const isFirstLoadOnDashboard = (prevShowTable.current === undefined && showTable === false);
		const isReturningToDashboard = (prevShowTable.current === true && showTable === false);

		if (isFirstLoadOnDashboard || isReturningToDashboard) {
			getInvoiceStatusCount();
		}

		prevShowTable.current = showTable;
	}, [showTable, getInvoiceStatusCount, isInitialized]);

	const handleStatusCardClick = useCallback((
		_sectionId: number,
		statusKey: StatusInvoice,
	) => {
		const newFilter = { status: statusKey, skip: 0 };
		setShowTable(true);
		setFilter(newFilter);
		setCurrentView({ status: statusKey });
	}, [setFilter]);

	const navigateBackToDashboard = useCallback(() => {
		setShowTable(false);
		setCurrentView(null);
		setFilter({ skip: 0 });
		sessionStorage.removeItem('fiscalDocumentState');
		navigate('/fiscal-document-entry', { replace: true });
	}, [navigate, setFilter]);

	const toggleFilterDrawer = useCallback(() => {
		setFilterDrawerOpen((prev) => !prev);
	}, []);

	const handleOpenModal = useCallback(() => {
		setModalOpen((prevState) => !prevState);
	}, []);

	const sendFilter = useCallback((values: Partial<FiscalDocumentQueryParams>) => {
		const pageFilter = {
			...values,
			skip: 0,
			take: fiscalDocumentTake,
			orderBy: normalizeDataGridOrderBy(sortModel) as {
        name: GridSortDirection,
        value: GridSortDirection,
      },
		};
		setFilter(pageFilter);
	}, [fiscalDocumentTake, setFilter, sortModel]);

	const handleFilterSubmit = useCallback((newFilterValues: Partial<FiscalDocumentQueryParams>) => {
		setFilterDrawerOpen(false);
		sendFilter(newFilterValues);
	}, [sendFilter]);

	const countActiveFilters = useCallback((): number => Object.entries(filter)
		.filter(([key, value]) => {
			const excludedKeys = ['status', 'skip', 'take', 'orderBy'];
			if (excludedKeys.includes(key)) {
				return false;
			}
			return value !== '' && value !== undefined;
		})
		.length, [filter]);

	return {
		filter,
		showTable,
		currentView,
		isFilterDrawerOpen,
		isModalOpen,
		sortModel,
		onChangePage,
		onChangePageSize,
		onSortModelChange,
		setFilter,
		handleStatusCardClick,
		navigateBackToDashboard,
		toggleFilterDrawer,
		handleOpenModal,
		handleFilterSubmit,
		countActiveFilters,
	};
};
export default useFiscalDocumentState;

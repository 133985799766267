import React, { useCallback, useMemo } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import {
	Link,
	Theme,
	useTheme,
	CardHeader,
	Paper,
	useMediaQuery,
	Fade,
	keyframes,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LoginForm from './LoginForm';
import { LoginData } from '../../services/auth';
import ChangePasswordLogin from '../ChangePassword/ChangePasswordLogin';
import {
	DEFAULT_NAME, VERSION, PRIMARY_COLOR,
} from '../../constants/general';

interface LoginProps {
  loading: boolean;
  isChangingPassword: boolean;
  errorMessage: string;
  dropSession: boolean;
  handleChangePassword: (password: string) => void;
  login: (loginData: LoginData, dropSession: boolean) => void;
}

const gradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const animatedBackground: SxProps<Theme> = (theme) => ({
	position: 'absolute',
	top: 0,
	left: 0,
	right: 0,
	bottom: 0,
	background: theme.palette.mode === 'light'
		? `linear-gradient(-45deg, 
        rgba(220, 230, 245, 0.9) 0%, 
        rgba(240, 247, 253, 0.9) 15%, 
        rgba(202, 208, 230, 0.87) 30%, 
        rgba(250, 252, 255, 0.95) 45%, 
        rgba(220, 230, 245, 0.85) 60%, 
        rgba(250, 252, 255, 0.95) 75%, 
        rgba(202, 208, 230, 0.9) 90%, 
        rgba(220, 230, 245, 0.9) 100%)`
		: `linear-gradient(-45deg, 
        rgba(20, 40, 70, 0.9) 0%, 
        rgba(35, 55, 90, 0.9) 20%, 
        rgba(25, 45, 75, 0.9) 40%, 
        rgba(30, 50, 85, 0.9) 60%, 
        rgba(18, 42, 66, 0.9) 80%, 
        rgba(20, 40, 70, 0.9) 100%)`,
	backgroundSize: '400% 400%',
	animation: `${gradientAnimation} 15s ease infinite`,
	zIndex: -2,
});

const backgroundPattern: SxProps<Theme> = {
	position: 'absolute',
	top: 0,
	left: 0,
	right: 0,
	bottom: 0,
	opacity: 0.03,
	pointerEvents: 'none',
	backgroundImage: 'url("data:image/svg+xml,%3Csvg width="100" height="100" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z" fill="%23000000" fill-opacity="0.4" fill-rule="evenodd"/%3E%3C/svg%3E")',
	zIndex: -1,
};

const container: SxProps<Theme> = (theme) => ({
	width: '100%',
	minHeight: '100vh',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	position: 'relative',
	padding: theme.spacing(2, 0),
	boxSizing: 'border-box',
	overflow: 'hidden',
});

const floatingParticles: SxProps<Theme> = () => ({
	position: 'absolute',
	top: 0,
	left: 0,
	right: 0,
	bottom: 0,
	opacity: 0.04,
	pointerEvents: 'none',
	background: 'radial-gradient(circle, transparent 20%, transparent 20%) 0 0, radial-gradient(circle, white 20%, transparent 25%) 25px 25px',
	backgroundSize: '50px 50px',
	zIndex: -1,
});

const card: SxProps<Theme> = (theme) => ({
	width: '100%',
	maxWidth: 480,
	margin: theme.spacing(2),
	borderRadius: theme.shape.borderRadius * 0.8,
	boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1), 0 1px 8px rgba(0, 0, 0, 0.07)',
	overflow: 'hidden',
	transition: theme.transitions.create(['box-shadow', 'border-color'], {
		duration: '0.3s',
	}),
	border: '2px solid transparent',
	'&:hover': {
		boxShadow: '0 20px 40px rgba(0, 0, 0, 0.15), 0 5px 15px rgba(0, 0, 0, 0.08)',
		borderColor: theme.palette.mode === 'light'
			? 'rgba(220, 230, 240, 1)'
			: 'rgba(30, 60, 90, 0.5)',
	},
	[theme.breakpoints.down('sm')]: {
		width: '90%',
		margin: theme.spacing(1),
	},
	backgroundColor: 'rgba(255, 255, 255, 0.95)',
	backdropFilter: 'blur(10px)',
});

const cardHeaderStyle: SxProps<Theme> = (theme) => ({
	background: `linear-gradient(135deg, ${PRIMARY_COLOR} 40%, rgba(202, 208, 230, 0.94) 90%)`,
	color: '#fff',
	padding: theme.spacing(3),
	'& .MuiCardHeader-title': {
		fontSize: '1.5rem',
		fontWeight: 500,
		letterSpacing: '0.5px',
	},
	'& .MuiCardHeader-avatar': {
		backgroundColor: 'rgba(255, 255, 255, 0.25)',
		padding: theme.spacing(1),
		borderRadius: '50%',
		boxShadow: '0 2px 10px rgba(0,0,0,0.2)',
	},
});

const content: SxProps<Theme> = (theme) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	padding: theme.spacing(4, 3, 3),
	rowGap: theme.spacing(3),
});

const logoContainer: SxProps<Theme> = (theme) => ({
	display: 'flex',
	justifyContent: 'center',
	width: '100%',
	marginBottom: theme.spacing(1),
});

const footer: SxProps<Theme> = (theme) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	marginTop: theme.spacing(3),
	padding: theme.spacing(1.5),
	borderRadius: theme.shape.borderRadius * 0.8,
	backgroundColor: 'rgba(255, 255, 255, 0.95)',
	backdropFilter: 'blur(10px)',
	boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
	width: 'fit-content',
	transition: theme.transitions.create(['background-color', 'box-shadow']),
	'&:hover': {
		backgroundColor: 'rgba(255, 255, 255, 0.85)',
		boxShadow: '0 4px 12px rgba(0,0,0,0.08)',
	},
});

const Login = ({
	isChangingPassword, loading, errorMessage, dropSession, handleChangePassword, login,
}: LoginProps): JSX.Element => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const loginProxy = useCallback((loginData: LoginData) => {
		login(loginData, dropSession);
	}, [dropSession, login]);

	const cardContentComponent = useMemo(() => {
		if (isChangingPassword) {
			return (
				<ChangePasswordLogin
					loading={loading}
					handleChangePassword={handleChangePassword}
				/>
			);
		}

		return (
			<LoginForm
				loading={loading}
				errorMessage={errorMessage}
				alreadyLogged={dropSession}
				handleSubmit={loginProxy}
			/>
		);
	}, [isChangingPassword, loading, errorMessage, dropSession, loginProxy, handleChangePassword]);

	const welcomeMessage = useMemo(() => {
		if (isChangingPassword) {
			return 'Por favor, defina sua nova senha';
		}
		return 'Bem-vindo ao Portal!';
	}, [isChangingPassword]);

	return (
		<Fade in timeout={800}>
			<Box sx={container(theme)}>
				<Box sx={animatedBackground(theme)} />
				<Box sx={backgroundPattern} />
				<Box sx={floatingParticles(theme)} />
				<Card sx={card(theme)}>
					<CardHeader
						sx={cardHeaderStyle(theme)}
						avatar={<LockOutlinedIcon />}
						title={isChangingPassword ? 'Alteração de Senha' : 'Acesso ao Sistema'}
					/>
					<CardContent sx={content(theme)}>
						<Box sx={logoContainer(theme)}>
							<Box
								component="img"
								src="/api/configuration/loginLogo"
								alt="Logo Login"
								sx={{
									maxWidth: isMobile ? '12rem' : '15rem',
									width: '100%',
									height: 'auto',
									transition: 'transform 0.3s ease-in-out',
									'&:hover': {
										transform: 'scale(1.05)',
									},
								}}
							/>
						</Box>
						<Typography
							variant="h6"
							component="h2"
							align="center"
							color="textSecondary"
							sx={{ mb: 2, fontWeight: 400 }}
						>
							{welcomeMessage}
						</Typography>
						{cardContentComponent}
					</CardContent>
				</Card>
				<Paper elevation={0} sx={footer(theme)}>
					<Typography
						variant="body2"
						color="text.secondary"
						align="center"
					>
						{`Copyright © ${new Date().getFullYear()} `}
						<Link
							color="primary"
							href="https://datasensetecnologia.com.br/"
							sx={{ fontWeight: 500 }}
						>
							{DEFAULT_NAME}
						</Link>
					</Typography>
					<Typography variant="caption" color="text.secondary">
						{`Versão ${VERSION}`}
					</Typography>
				</Paper>
			</Box>
		</Fade>
	);
};

export default Login;

import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import Avatar from '@mui/material/Avatar';
import CardHeader from '@mui/material/CardHeader';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import Button from '@mui/material/Button';
import TimerIcon from '@mui/icons-material/Timer';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Link } from 'react-router-dom';
import format from 'date-fns/format';
import Paper from '@mui/material/Paper';
import { PageHeader } from '../../Common/PageHeader/PageHeader';
import { IForm, UserHourProjectInconsistence, initialValues } from '../../../containers/HourProject/HourProjectInconsistenciesAssets';
import { UserHourProjectInconsistenceQueryParams } from '../../../interfaces/UserHourProjectInconsistenceQueryParams';
import DrawerFilter from '../../Common/DrawerFilter';
import { PageHeaderButtonProps } from '../../../interfaces/PageHeaderInterface';
import HourProjectInconsistenciesFilter from './HourProjectInconsistenciesFilter';
import NoDataPage from '../../Common/NoDataPage';
import { Loading } from '../../Common/Loading';
import { UserClock } from '../../../containers/HourProject/ClockHoursAssets';
import { formatHoursMinutes } from '../../../helpers/Utils';
import DayCarousel from '../../Common/DayCarousel';
import { BranchContext } from '../../../contexts/BranchContext';

const container: SxProps<Theme> = {
	width: '100%',
	display: 'flex',
	justifyContent: 'space-around',
	flexWrap: 'wrap',
	gap: '1rem 1rem',
};

interface HourProjectInconsistenciesProps {
	loading: boolean;
	users: UserHourProjectInconsistence[];
	usersFilter: UserClock[];
	getUsersInconsistencies(queryParams: UserHourProjectInconsistenceQueryParams): void;
	getUsersWithTaskHours(): void;
}

const HourProjectInconsistencies = (props: HourProjectInconsistenciesProps): JSX.Element => {
	const {
		loading,
		users,
		usersFilter,
		getUsersInconsistencies,
		getUsersWithTaskHours,
	} = props;

	const [isFilterDrawerOpen, setFilterDrawerOpen] = useState(false);
	const [filter, setFilter] = useState<IForm>(initialValues);
	const [currentDate, setCurrentDate] = useState(new Date());
	const { refreshTriggerBranch } = useContext(BranchContext);

	useEffect(() => {
		if (Object.keys(filter).length > 0) {
			const updatedFilter = {
				...filter,
				date: currentDate,
			};
			getUsersInconsistencies(updatedFilter);
		}
		getUsersWithTaskHours();
	}, [
		currentDate,
		filter,
		getUsersInconsistencies,
		getUsersWithTaskHours,
		refreshTriggerBranch.branchId,
	]);

	const sendFilter = useCallback((values: any) => {
		setFilterDrawerOpen(false);
		setFilter(values);
	}, []);

	const toggleFilterDrawer = useCallback(() => {
		setFilterDrawerOpen(!isFilterDrawerOpen);
	}, [isFilterDrawerOpen]);

	const appointmentFilterMemo = useMemo(() => (
		<DrawerFilter open={isFilterDrawerOpen} onClose={toggleFilterDrawer}>
			<HourProjectInconsistenciesFilter
				sendFilter={sendFilter}
				initialValuesLoad={filter}
				usersFilter={usersFilter}
			/>
		</DrawerFilter>
	), [filter, isFilterDrawerOpen, sendFilter, toggleFilterDrawer, usersFilter]);

	const headerButtonsProps = useMemo((): PageHeaderButtonProps[] => [
		{
			variant: 'contained',
			color: 'primary',
			onClick: toggleFilterDrawer,
			text: 'Filtro',
			startIcon: <FilterListIcon />,
		},
	], [toggleFilterDrawer]);

	const pageHeaderMemo = useMemo(
		() => (
			<PageHeader
				title="Gestão de apontamentos"
				buttons={headerButtonsProps}
			/>
		),
		[headerButtonsProps],
	);

	const dayCarouselMemo = useMemo(() => (
		<Paper sx={{
			padding: 3,
			marginBlock: 3,
		}}
		>
			<DayCarousel
				onDateChange={setCurrentDate}
			/>
		</Paper>
	), []);

	const userCards = useMemo(() => users.map((user) => {
		const date = user.tasksClockInOut[0]?.dateTime
			? new Date(user.tasksClockInOut[0].dateTime) : new Date();
		const formattedDate = format(date, 'dd/MM/yyyy');

		return (
			<Card key={user.id} sx={{ minWidth: 300, width: 400 }}>
				<CardHeader
					avatar={(
						<Avatar aria-label="user">
							{user.name.charAt(0)}
						</Avatar>
					)}
					title={user.name}
					subheader={formattedDate}
				/>
				<CardContent sx={{ backgroundColor: user.hasInconsistencies ? '#FCE4D6' : '#E2EFDA' }}>
					<Stack spacing="1rem">
						<Typography
							gutterBottom
							variant="body1"
						>
							Projetos Registrados:&nbsp;
							{user.numberOfProjects}
						</Typography>
						<Typography
							gutterBottom
							variant="body1"
						>
							Tarefas Registradas:&nbsp;
							{user.numberOfTasks}
						</Typography>
						<Typography
							gutterBottom
							variant="body1"
						>
							Total horas:&nbsp;
							{formatHoursMinutes(user.totalRegisteredHours)}
						</Typography>
						<Typography
							gutterBottom
							variant="body1"
						>
							Status:&nbsp;
							{user.hasInconsistencies ? 'Inconsistente' : 'Consistente'}
						</Typography>
						<Typography
							gutterBottom
							variant="body1"
						>
							Integrado:&nbsp;
							{user.integrated ? 'Sim' : 'Não'}
						</Typography>
					</Stack>
				</CardContent>
				<CardActions disableSpacing sx={{ display: 'flex', justifyContent: 'flex-end' }}>
					<Link to={`edit/inconsistence?date=${format(date, 'yyyy-MM-dd')}&userId=${user.id}`}>
						<Button size="small" LinkComponent={Link} endIcon={<TimerIcon />}>Ver horas</Button>
					</Link>
				</CardActions>
			</Card>
		);
	}), [users]);

	return (
		<Box>
			{pageHeaderMemo}
			{dayCarouselMemo}
			{appointmentFilterMemo}
			<Stack spacing="4rem" sx={{ mt: 2 }}>
				<Box sx={container}>
					{loading && <Loading absolute />}
					{users.length > 0 ? userCards : <NoDataPage />}
				</Box>
			</Stack>
		</Box>
	);
};

export default HourProjectInconsistencies;

import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { InventoryStatus } from '../enums/inventory';
import { InventoryQueryParams, InventoryType } from '../containers/Inventory/InventoryAssets';

interface CurrentView {
	type: InventoryType;
	status: InventoryStatus;
}

interface InventoryNavigationContext {
	filters?: InventoryQueryParams;
	type?: InventoryType;
	status?: InventoryStatus;
	originalExpandedSection?: InventoryType | null;
	previousView?: string;
}
interface UseInventoryNavigationOptions {
  setShowTable?: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentView?: React.Dispatch<React.SetStateAction<CurrentView | null>>;
  setFilter?: (filter: InventoryQueryParams) => void;
  setExpandedSection?: React.Dispatch<React.SetStateAction<InventoryType | null>>;
  getInventoryStatusCount?: () => void;
}

interface UseInventoryNavigationReturn {
  previousView: string | undefined;
  filters: InventoryQueryParams;
  type: InventoryType;
  status: InventoryStatus;
  originalExpandedSection: InventoryType | null;
  restoreFilter: boolean | undefined;
  navigateToInventoryTasks: (inventoryId: string, context?: InventoryNavigationContext) => void;
  navigateToReport: (
	reportPath: string,
	inventoryId: string,
	context?: InventoryNavigationContext
  ) => void;
  navigateBackToTable: () => void;
  navigateBackToDashboard: () => void;
}

const useInventoryNavigation = ({
	setShowTable,
	setCurrentView,
	setFilter,
	setExpandedSection,
	getInventoryStatusCount,
}: UseInventoryNavigationOptions = {}): UseInventoryNavigationReturn => {
	const navigate = useNavigate();
	const location = useLocation();

	const {
		previousView,
		filters,
		type,
		status,
		originalExpandedSection,
		restoreFilter,
	} = location.state || {};

	const navigateToInventoryTasks = useCallback((
		inventoryId: string,
		context: InventoryNavigationContext = {},
	) => {
		navigate(`edit/${inventoryId}`, {
			state: {
				previousView: 'InventoryTable',
				filters: context.filters || filters,
				type: context.type || type,
				status: context.status || status,
				originalExpandedSection: context.originalExpandedSection || originalExpandedSection,
				...context,
			},
		});
	}, [navigate, filters, type, status, originalExpandedSection]);

	const navigateToReport = useCallback((
		reportPath: string,
		inventoryId: string,
		context = {},
	) => {
		navigate(`${reportPath}/${inventoryId}`, {
			state: {
				previousView: 'InventoryTable',
				filters: context.filters || filters,
				type: context.type || type,
				status: context.status || status,
				originalExpandedSection: context.originalExpandedSection || originalExpandedSection,
				...context,
			},
		});
	}, [navigate, filters, type, status, originalExpandedSection]);

	const navigateBackToTable = useCallback(() => {
		navigate('/inventory', {
			state: {
				restoreFilter: true,
				filters,
				type,
				status,
				originalExpandedSection,
			},
		});
	}, [navigate, filters, type, status, originalExpandedSection]);

	const navigateBackToDashboard = useCallback(() => {
		if (setShowTable) {
			setShowTable(false);
		}

		if (setCurrentView) {
			setCurrentView(null);
		}

		if (setFilter) {
			setFilter({
				skip: 0,
			});
		}

		if (setExpandedSection && originalExpandedSection) {
			setExpandedSection(originalExpandedSection);
		}

		if (getInventoryStatusCount) {
			getInventoryStatusCount();
		}

		navigate('.', {
			replace: true,
			state: originalExpandedSection ? { originalExpandedSection } : undefined,
		});
	}, [
		navigate,
		originalExpandedSection,
		setShowTable,
		setCurrentView,
		setFilter,
		setExpandedSection,
		getInventoryStatusCount,
	]);

	return {
		previousView,
		filters,
		type,
		status,
		originalExpandedSection,
		restoreFilter,
		navigateToInventoryTasks,
		navigateToReport,
		navigateBackToTable,
		navigateBackToDashboard,
	};
};

export default useInventoryNavigation;

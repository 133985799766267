/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/require-default-props */
import React, {
	useCallback, useEffect, useState, useMemo,
} from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { LoadingButton } from '@mui/lab';
import {
	Form,
	useFormikContext,
} from 'formik';
import { Grid } from '@mui/material';
import {
	ILocation,
	ILocationType,
	OperationType,
} from '../../../containers/Location/LocationAssets';
import Input from '../../Common/Form/Input';
import Autocomplete from '../../Common/Form/Autocomplete';
import { formatBarCode } from '../../../helpers/masks';
import { ICompanyWithoutDetails } from '../../../containers/Company/CompanyAssets';
import { IBranch } from '../../../containers/Branch/BranchAssets';
import { BranchParams } from '../../../containers/Inventory/InventoryAssets';
import { initialValues } from '../LocationEdit';

interface LocationModalProps {
  open: boolean;
  loading: boolean;
  locationTypes: ILocationType[];
  operationType: OperationType | null;
  onClose: () => void;
  companies: ICompanyWithoutDetails[];
  branches: IBranch[];
  getCompanies: () => void;
  getBranches: (params: BranchParams) => void;
  selectedLocation: ILocation | null;
}

const LocationModal = ({
	open,
	loading,
	locationTypes,
	operationType,
	onClose,
	companies,
	getCompanies,
	branches,
	selectedLocation,
	getBranches,
}: LocationModalProps): JSX.Element => {
	const {
		values,
		setFieldValue,
	} = useFormikContext<typeof initialValues>();

	const handleLocationTypeChange = useCallback((locationTypeId: string) => {
		const locationTypeSelected = locationTypes.find(
			(locationType) => locationType.id === locationTypeId,
		);
		setFieldValue('prefix', locationTypeSelected?.prefix || '');
	}, [locationTypes, setFieldValue]);

	useEffect(() => {
		if (values.locationTypeId) {
			handleLocationTypeChange(values.locationTypeId);
		}
	}, [values.locationTypeId, handleLocationTypeChange]);

	useEffect(() => {
		getCompanies();
		if (operationType === OperationType.STORAGE) {
			setFieldValue('barCodeInitial', '-');
		} else {
			setFieldValue('barCodeInitial', selectedLocation?.barCode || '');
		}
	}, [getCompanies, operationType, selectedLocation?.barCode, setFieldValue]);

	useEffect(() => {
		if (values.companyId) {
			getBranches({
				companyId: values.companyId,
			});
		}
	}, [values.companyId, getBranches]);

	const formattedBarCode = useMemo(
		() => formatBarCode(values.barCodeInitial + values.prefix),
		[values.barCodeInitial, values.prefix],
	);

	return (
		<Dialog open={open} onClose={onClose} fullWidth>
			<Form>
				<DialogTitle>
					{operationType === OperationType.STORAGE ? 'Criar Armazém' : 'Criar Localização'}
				</DialogTitle>
				<DialogContent dividers>
					<Typography variant="body2" color="textSecondary" mb={2}>
						*Obrigatório
					</Typography>
					<Grid container spacing={2}>
						<Grid item xs={12} md={12} sm={12}>
							<Autocomplete
								label="Empresa"
								labelKey="name"
								valueKey="id"
								valueLabel="code"
								name="companyId"
								options={companies}
								fullWidth
								readOnly
								closeOnSelect
								required
							/>
						</Grid>
						<Grid item xs={12} md={12} sm={12}>
							<Autocomplete
								label="Filial"
								labelKey="name"
								valueKey="id"
								valueLabel="code"
								disabled={!values.companyId}
								name="branchId"
								options={branches}
								fullWidth
								readOnly
								closeOnSelect
								required
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={12}>
							<Input.InputField
								label="Nome da Localização"
								name="name"
								fullWidth
								required
							/>
						</Grid>
						<Grid item xs={12} md={12} sm={12}>
							<Autocomplete
								label="Tipo de Localização"
								valueLabel="level"
								valueKey="id"
								labelKey="name"
								name="locationTypeId"
								options={locationTypes}
								fullWidth
								closeOnSelect
								required
							/>
						</Grid>
						<>
							<Grid item xs={12} sm={12} md={6}>
								<Typography variant="subtitle1" color="#00000099">
									Código de Barras
								</Typography>
								<Typography variant="subtitle1">
									{formattedBarCode}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={12} md={6}>
								<Input.InputField
									label="Composição do Código de Barras"
									name="barCodeNumber"
									fullWidth
									required
								/>
							</Grid>
						</>
					</Grid>
				</DialogContent>
				<DialogActions sx={{ gap: 1 }}>
					<Button onClick={onClose}>
						Cancelar
					</Button>
					<LoadingButton
						variant="contained"
						loading={loading}
						type="submit"
					>
						Salvar
					</LoadingButton>
				</DialogActions>
			</Form>
		</Dialog>
	);
};

export default LocationModal;

import React, {
	useRef, useCallback,
} from 'react';
import {
	Box,
	Button,
	Typography,
} from '@mui/material';
import SignatureCanvas from 'react-signature-canvas';
import { useSnackbar } from 'notistack';

interface SignatureProps {
	onSave: (signature: string) => void;
	subtitle?: string;
}

const Signature = ({
	onSave,
	subtitle,
}: SignatureProps): JSX.Element => {
	const sigCanvas = useRef<SignatureCanvas>(null);
	const containerRef = useRef<HTMLDivElement>(null);
	const { enqueueSnackbar } = useSnackbar();

	const clearSignature = useCallback((): void => {
		sigCanvas.current?.clear();
	}, []);

	const saveSignature = useCallback((): void => {
		if (sigCanvas.current) {
			if (sigCanvas.current.isEmpty()) {
				enqueueSnackbar('A assinatura está vazia. Por favor, assine antes de salvar.', {
					variant: 'error',
				});
				return;
			}

			const dataUrl = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
			onSave(dataUrl);
		}
	}, [enqueueSnackbar, onSave]);

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				height: 200,
				padding: 2,
			}}
		>
			<Typography variant="h4" gutterBottom>
				Assinatura
			</Typography>
			{subtitle && (
				<Typography variant="subtitle1" textAlign="center" mb={2} gutterBottom>
					{subtitle}
				</Typography>
			)}
			<Box
				ref={containerRef}
				sx={{
					border: 1,
					borderColor: '#bbb',
					bgcolor: 'white',
					borderRadius: 2,
					width: 300,
					height: 200,
					marginBottom: 2,
				}}

			>
				<SignatureCanvas
					ref={sigCanvas}
					canvasProps={{
						width: 300,
						height: 200,
						className: 'sigCanvas',
					}}
				/>
			</Box>

			<Box sx={{
				display: 'flex',
				justifyContent: 'space-between',
				width: 300,
				gap: 2,
			}}
			>
				<Button variant="contained" color="primary" onClick={saveSignature}>
					Concluir
				</Button>
				<Box sx={{ backgroundColor: 'white' }}>
					<Button variant="outlined" color="primary" onClick={clearSignature}>
						Limpar
					</Button>
				</Box>
			</Box>
		</Box>
	);
};

Signature.defaultProps = {
	subtitle: '',
};

export default Signature;

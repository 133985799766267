import React, {
	useMemo,
} from 'react';
import {
	Box,
	Fade,
	useTheme,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FilterListIcon from '@mui/icons-material/FilterList';
import { PageHeader } from '../Common/PageHeader/PageHeader';
import FiscalDocumentEntryTable from './FiscalDocumentEntryTable';
import { PageHeaderButtonProps } from '../../interfaces/PageHeaderInterface';
import DrawerFilter from '../Common/DrawerFilter';
import FiscalDocumentEntryFilter from './FiscalDocumentEntryFilter';
import {
	IFileStatus,
	IFiscalDocument,
	FiscalDocumentoEntryStatusCountResponse,
	StatusInvoice,
} from '../../containers/FiscalDocumentEntry/FiscalDocumentEntryAssets';
import { FiscalDocumentQueryParams } from '../../interfaces/FiscalDocumentParams';
import FiscalDocumentEntryXMLModal from './FiscalDocumentEntryXMLModal';
import { CardDefinition, SectionDefinition, useDashboard } from '../../hooks/useDashboard';
import DefaultDashboard from '../Common/Dashboard/DefaultDashboard';
import { getInvoiceDashboardSections, getInvoiceStatusCards } from '../../helpers/dashboardConfig';
import { getInvoiceViewTitle } from '../../helpers/invoiceHelper';
import useFiscalDocumentState from '../../hooks/useFiscalDocumentState';

interface FiscalDocumentEntryProps {
	loading: boolean;
	loadingUpload: boolean;
	fiscalDocuments: IFiscalDocument[];
	getFiscalDocuments(params: FiscalDocumentQueryParams): void;
	fiscalDocumentTake: number;
	fiscalDocumentPage: number;
	fiscalDocumentPages: number;
	handleDeleteInvoice(id: string): void;
	handleEdit(id: string): void;
	uploadXml(data: FormData): void;
	filesStatus: IFileStatus[];
	clearFiles(): void;
	getInvoiceStatusCount(): void;
	statusCount: FiscalDocumentoEntryStatusCountResponse;
}

const FiscalDocumentEntry = ({
	loading,
	fiscalDocuments,
	getFiscalDocuments,
	fiscalDocumentTake,
	fiscalDocumentPage,
	fiscalDocumentPages,
	handleDeleteInvoice,
	handleEdit,
	uploadXml,
	loadingUpload,
	filesStatus,
	clearFiles,
	getInvoiceStatusCount,
	statusCount,
}: FiscalDocumentEntryProps): JSX.Element => {
	const theme = useTheme();

	const {
		filter,
		showTable,
		currentView,
		isFilterDrawerOpen,
		isModalOpen,
		sortModel,
		onChangePage,
		onChangePageSize,
		onSortModelChange,
		handleStatusCardClick,
		navigateBackToDashboard,
		toggleFilterDrawer,
		handleOpenModal,
		handleFilterSubmit,
		countActiveFilters,
	} = useFiscalDocumentState({
		fiscalDocumentTake,
		fiscalDocumentPage,
		getInvoiceStatusCount,
		getFiscalDocuments,
	});

	const dashboardHeaderButtons = useMemo((): PageHeaderButtonProps[] => [
		{
			variant: 'contained',
			color: 'primary',
			type: 'submit',
			fullWidth: true,
			text: 'Upload XML',
			onClick: handleOpenModal,
		},
	], [handleOpenModal]);

	const tableHeaderButtons = useMemo(
		(): PageHeaderButtonProps[] => [
			{
				variant: 'contained',
				color: 'primary',
				onClick: toggleFilterDrawer,
				text: 'Filtro',
				badgeContent: countActiveFilters(),
				badgeTitle: 'Filtros ativos',
				startIcon: <FilterListIcon />,
			},
		],
		[toggleFilterDrawer, countActiveFilters],
	);

	const fiscalDocumentEntryFilterMemo = useMemo(() => (
		<DrawerFilter open={isFilterDrawerOpen} onClose={toggleFilterDrawer}>
			<FiscalDocumentEntryFilter
				sendFilter={handleFilterSubmit}
				initialValues={filter}
			/>
		</DrawerFilter>
	), [filter, handleFilterSubmit, isFilterDrawerOpen, toggleFilterDrawer]);

	const fiscalDocumentEntryXMLModalMemo = useMemo(() => (
		<FiscalDocumentEntryXMLModal
			handleClose={() => {
				handleOpenModal();
				clearFiles();
			}}
			open={isModalOpen}
			uploadXml={uploadXml}
			loadingUpload={loadingUpload}
			filesStatus={filesStatus}
			clearFiles={clearFiles}
		/>
	), [handleOpenModal, isModalOpen, uploadXml, loadingUpload, filesStatus, clearFiles]);

	const statusCards: Record<number, CardDefinition<StatusInvoice>> = useMemo(
		() => getInvoiceStatusCards(theme) as Record<
      number,
      CardDefinition<StatusInvoice>>,
		[theme],
	);

	const dashboardSections: SectionDefinition<
	number,
    FiscalDocumentoEntryStatusCountResponse,
    StatusInvoice>[] = useMemo(() => getInvoiceDashboardSections(theme) as SectionDefinition<
	number,
    FiscalDocumentoEntryStatusCountResponse,
    StatusInvoice>[], [theme]);

	const dashboardConfig = useDashboard(
		statusCount,
		dashboardSections,
		statusCards,
		handleStatusCardClick,
	);

	const invoiceDashboardMemo = useMemo(() => (
		<DefaultDashboard
			sections={dashboardConfig}
			defaultExpanded={0}
		/>
	), [dashboardConfig]);
	const viewData = useMemo(() => getInvoiceViewTitle(
		currentView,
		{ color: 'primary', fontSize: 'large' },
	), [currentView]);

	return (
		<Box className="content">
			{fiscalDocumentEntryFilterMemo}
			{fiscalDocumentEntryXMLModalMemo}
			<PageHeader
				title={viewData.title}
				icon={viewData.icon}
				buttons={
					showTable
						? [
							{
								text: 'Voltar',
								onClick: navigateBackToDashboard,
								startIcon: <ArrowBackIcon />,
								variant: 'outlined',
							},
							...tableHeaderButtons,
						]
						: dashboardHeaderButtons
				}
			/>
			<Fade in={!showTable} unmountOnExit>
				<Box>
					{invoiceDashboardMemo}
				</Box>
			</Fade>
			{showTable && (
				<FiscalDocumentEntryTable
					rows={fiscalDocuments}
					loading={loading}
					sortModel={sortModel}
					onChangePageSize={onChangePageSize}
					onChangePage={onChangePage}
					onSortModelChange={onSortModelChange}
					fiscalDocumentTake={fiscalDocumentTake}
					fiscalDocumentPage={fiscalDocumentPage}
					fiscalDocumentPages={fiscalDocumentPages}
					handleDeleteInvoice={handleDeleteInvoice}
					handleEdit={handleEdit}
				/>
			)}
		</Box>
	);
};

export default FiscalDocumentEntry;

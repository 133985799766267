import React from 'react';
import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Checkbox,
	Chip,
	IconButton,
	Tooltip,
	Collapse,
	useTheme,
} from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { format } from 'date-fns';
import { formatBarCode } from '../../helpers/masks';
import { ConsumptionProductionTask, IConsumption } from '../../containers/Consumption/ConsumptionAssets';
import { InventoryTaskStatus } from '../../enums/InventoryTaskStatus';
import { colWidths } from './ConsumptionTable';
import { getSecondaryMeasureQuantity } from '../../helpers/Utils';
import useConfirmationDialog from '../../hooks/useConfirmationDialog';
import { AlertTypes } from '../../enums/ConfirmationDialogType';
import { getStatusColor } from '../../helpers/StatusCount';
import {
	checkBoxStyle,
	collapseContainerStyle,
	createExpandableRowStyle,
	detailsTableCellBoxStyle,
	detailsTableContainerStyle,
	detailsTableRowStyle,
	detailsTableStyle,
	expandabledRowCheckBoxStyle,
	expandabledRowTableCellStyle,
	expandedDetailsBoxStyle,
	iconButtonStyle,
	tableRowItemStyle,
} from '../../helpers/tableStylesHelper';

interface ExpandedConsumptionDetailsProps {
  consumption: IConsumption;
  consumptions: IConsumption[];
  isChecked: (consumption: IConsumption, task: ConsumptionProductionTask) => boolean;
  isCheckedProductEnabled: (
    status: InventoryTaskStatus,
    locationDestinyBarCode?: string,
    locationOriginBarCode?: string,
  ) => boolean;
  handlerChangeProduct: (rowIndex: number, indexProduct: number, checked: boolean) => void;
  buildLabelStatusTask: (status: InventoryTaskStatus) => string;
  handleMenuItemClick: (itemIndex: number, task: ConsumptionProductionTask) => void;
}

interface ExpandableRowProps {
	consumption: IConsumption;
	isSelected: boolean;
	isRowEnabled: boolean;
	onSelect: () => void;
	onExpand: () => void;
	isExpanded: boolean;
	consumptions: IConsumption[];
	isChecked: (consumption: IConsumption, task: ConsumptionProductionTask) => boolean;
	isCheckedProductEnabled: (
	status: InventoryTaskStatus,
	locationDestinyBarCode?: string,
	locationOriginBarCode?: string,
	) => boolean;
	handlerChangeProduct: (rowIndex: number, indexProduct: number, checked: boolean) => void;
	buildLabelStatusTask: (status: InventoryTaskStatus) => string;
	handleMenuItemClick: (itemIndex: number, task: ConsumptionProductionTask) => void;
	index: number;
}

const ExpandedConsumptionDetails = ({
	consumption,
	consumptions,
	isChecked,
	isCheckedProductEnabled,
	handlerChangeProduct,
	buildLabelStatusTask,
	handleMenuItemClick,
}: ExpandedConsumptionDetailsProps): JSX.Element => {
	const rowIndex = consumptions.findIndex((c) => c.id === consumption.id);
	const { requestConfirm, confirmationDialog } = useConfirmationDialog();
	const theme = useTheme();

	const handleCancelTaskClick = (task: ConsumptionProductionTask): void => {
		requestConfirm({
			callback: () => handleMenuItemClick(0, task),
			title: 'Confirmar cancelamento',
			description: `Deseja realmente cancelar a tarefa para o produto ${task.productCode}?`,
			alertType: AlertTypes.ERROR,
		});
	};

	return (
		<Box sx={expandedDetailsBoxStyle()}>
			{confirmationDialog}
			<TableContainer sx={detailsTableContainerStyle(theme)}>
				<Table
					size="small"
					aria-label="itens consumidos"
					sx={detailsTableStyle(theme)}
				>
					<TableHead>
						<TableRow sx={detailsTableRowStyle(theme)}>
							<TableCell />
							<TableCell sx={{ width: '20%' }}>Produto</TableCell>
							<TableCell sx={{ width: '5%' }}>Status</TableCell>
							<TableCell align="right" sx={{ width: '10%' }}>Quantidade</TableCell>
							<TableCell sx={{ width: '5%' }}>1ª UM</TableCell>
							<TableCell align="right" sx={{ width: '10%' }}>Quantidade</TableCell>
							<TableCell sx={{ width: '5%' }}>2ª UM</TableCell>
							<TableCell align="right" sx={{ width: '10%' }}>Qtd. Contada</TableCell>
							<TableCell sx={{ width: '15%' }}>Localização origem</TableCell>
							<TableCell sx={{ width: '15%' }}>Localização destino</TableCell>
							<TableCell sx={{ width: '5%' }}>Ações</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{consumption.tasks.map((task, index) => (
							<TableRow
								key={task.id}
								hover
								sx={tableRowItemStyle(index, consumption, 'tasks')}
							>
								<TableCell
									padding="checkbox"
									sx={{
										maxWidth: '40px',
									}}
								>
									<Checkbox
										checked={isChecked(consumption, task)}
										value={isChecked(consumption, task)}
										onChange={(event, checked) => handlerChangeProduct(rowIndex, index, checked)}
										disabled={
											!isCheckedProductEnabled(
												task.status,
												task.locationDestinyBarCode,
												task.locationOriginBarCode,
											)
										}
										size="small"
										sx={checkBoxStyle(theme)}
									/>
								</TableCell>
								<TableCell>
									<Box sx={detailsTableCellBoxStyle(theme)}>
										{task.productCode}
										{' '}
										-
										{task.productDescription}
									</Box>
								</TableCell>
								<TableCell>
									<Chip
										label={buildLabelStatusTask(task.status)}
										color={getStatusColor(task.status)}
										size="small"
										variant="outlined"
										sx={{
											height: '22px',
											width: '90px',
										}}
									/>
								</TableCell>
								<TableCell align="right">{task.quantity.toFixed(2).replace('.', ',')}</TableCell>
								<Tooltip title={task.primaryMeasureDescription || ''}>
									<TableCell>{task.primaryMeasureId || '-'}</TableCell>
								</Tooltip>
								<TableCell align="right">
									{getSecondaryMeasureQuantity(
										task.quantity,
										task.productConversionIndex,
										task.productConversionType,
									)}
								</TableCell>
								<Tooltip title={task.secundaryMeasureDescription || ''}>
									<TableCell>{task.secundaryMeasureId || '-'}</TableCell>
								</Tooltip>
								<TableCell align="right">{task.quantityCounted ? task.quantityCounted.toFixed(2).replace('.', ',') : '-'}</TableCell>
								<TableCell>{task.locationOriginBarCode ? formatBarCode(task.locationOriginBarCode) : '-'}</TableCell>
								<TableCell>{task.locationDestinyBarCode ? formatBarCode(task.locationDestinyBarCode) : '-'}</TableCell>
								<TableCell>
									<Tooltip title={(task.status === InventoryTaskStatus.FINISHED
                                        || task.status === InventoryTaskStatus.CANCELED) ? 'Tarefa finalizada' : 'Cancelar tarefa'}
									>
										<Box>
											<IconButton
												size="small"
												color="error"
												onClick={() => handleCancelTaskClick(task)}
												disabled={task.status === InventoryTaskStatus.FINISHED
                                                    || task.status === InventoryTaskStatus.CANCELED}
												aria-label="cancelar tarefa"
												sx={iconButtonStyle()}
											>
												<CancelOutlinedIcon fontSize="small" />
											</IconButton>
										</Box>
									</Tooltip>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	);
};

export const ExpandableRow = ({
	consumption,
	isSelected,
	isRowEnabled,
	onSelect,
	onExpand,
	isExpanded,
	consumptions,
	isChecked,
	isCheckedProductEnabled,
	handlerChangeProduct,
	buildLabelStatusTask,
	handleMenuItemClick,
	index,
}: ExpandableRowProps): JSX.Element => {
	const theme = useTheme();

	return (
		<>
			<TableRow
				hover
				onClick={onExpand}
				selected={isSelected}
				sx={createExpandableRowStyle(theme, isExpanded, index)}
			>
				<TableCell
					padding="checkbox"
					style={{ width: colWidths[0] }}
					onClick={(e) => {
						e.stopPropagation();
					}}
				>
					<Checkbox
						color="primary"
						checked={isSelected}
						onChange={(e) => {
							e.stopPropagation();
							onSelect();
						}}
						onClick={(e) => {
							e.stopPropagation();
						}}
						disabled={!isRowEnabled}
						size="small"
						sx={expandabledRowCheckBoxStyle(isExpanded)}
					/>
				</TableCell>
				<TableCell style={{
					width: colWidths[1],
					minWidth: colWidths[1],
				}}
				>
					{consumption.operatorName}
				</TableCell>
				<TableCell style={{
					width: colWidths[2],
					minWidth: colWidths[2],
				}}
				>
					{consumption.code}
				</TableCell>
				<TableCell style={{ width: colWidths[3], minWidth: colWidths[3] }}>{consumption.createdAt ? format(new Date(consumption.createdAt), 'dd/MM/yyyy') : ''}</TableCell>
				<TableCell style={{ width: colWidths[4] }}>
					<IconButton
						aria-label="expand row"
						size="small"
						onClick={(e) => {
							e.stopPropagation();
							onExpand();
						}}
					>
						{isExpanded ? <KeyboardArrowUpIcon fontSize="small" /> : <KeyboardArrowDownIcon fontSize="small" />}
					</IconButton>
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell
					style={expandabledRowTableCellStyle()}
					colSpan={6}
				>
					<Collapse in={isExpanded} timeout="auto" unmountOnExit>
						<Box sx={collapseContainerStyle(theme)}>
							<ExpandedConsumptionDetails
								consumption={consumption}
								consumptions={consumptions}
								isChecked={isChecked}
								isCheckedProductEnabled={isCheckedProductEnabled}
								handlerChangeProduct={handlerChangeProduct}
								buildLabelStatusTask={buildLabelStatusTask}
								handleMenuItemClick={handleMenuItemClick}
							/>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
};

export default ExpandedConsumptionDetails;

import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Grid from '@mui/material/Grid';
import { alpha } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CountUp from 'react-countup';
import { StatusCard } from '../StatusCard';

export interface DashboardCardConfig {
  id: number;
  title: string;
  icon: React.ReactNode;
  color: string;
  count: number;
  onClick: () => void;
}

export interface DashboardSection {
  id: number;
  title: string;
  icon: React.ReactNode;
  iconColor: string;
  accordionColor: string
  totalCount: number;
  totalTooltip: string;
  cards: DashboardCardConfig[];
}

interface DefaultDashboardProps {
  sections: DashboardSection[];
  defaultExpanded?: number;
}

const DefaultDashboard: React.FC<DefaultDashboardProps> = ({
	sections,
	defaultExpanded,
}) => {
	const [expanded, setExpanded] = useState<number | false>(
		defaultExpanded !== undefined ? defaultExpanded : false,
	);

	const handleChange = (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
		setExpanded(isExpanded ? panel : false);
	};

	return (
		<Box sx={{ mb: 4 }}>
			{sections.map((section, index) => (
				<Box mb={index < sections.length - 1 ? 2 : 0} key={section.id}>
					<Accordion
						expanded={expanded === section.id}
						onChange={handleChange(section.id)}
						sx={{
							boxShadow: 2,
							'&:before': { display: 'none' },
							borderRadius: '10px',
							overflow: 'hidden',
						}}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							sx={{
								bgcolor: alpha(section.accordionColor, 0.05),
								'&:hover': { bgcolor: alpha(section.accordionColor, 0.1) },
							}}
						>
							<Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
								<Box display="flex" alignItems="center">
									<Box sx={{ mr: 1.5, color: section.iconColor, fontSize: '1.8rem' }}>
										{section.icon}
									</Box>
									<Typography variant="h6" sx={{ fontWeight: 600 }}>
										{section.title}
									</Typography>
								</Box>
								<Tooltip title={section.totalTooltip} arrow>
									<Box
										sx={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											borderRadius: '30px',
											px: 2,
											py: 0.5,
											fontSize: '0.875rem',
											fontWeight: 'bold',
											ml: 'auto',
											bgcolor: alpha(section.iconColor, 0.15),
											color: section.iconColor,
										}}
									>
										<CountUp end={section.totalCount} duration={1} />
									</Box>
								</Tooltip>
							</Box>
						</AccordionSummary>
						<AccordionDetails>
							<Grid container spacing={3}>
								{section.cards.map((card) => (
									<Grid item xs={12} sm={6} md={3} key={card.id}>
										<StatusCard
											title={card.title}
											count={card.count}
											icon={card.icon}
											color={card.color}
											onClick={card.onClick}
										/>
									</Grid>
								))}
							</Grid>
						</AccordionDetails>
					</Accordion>
				</Box>
			))}
		</Box>
	);
};

DefaultDashboard.defaultProps = {
	defaultExpanded: undefined,
};

export default DefaultDashboard;

import { useMemo, useState, useCallback } from 'react';
import { getMenu } from '../services/app';
import { hasPermission } from '../helpers/permission';
import {
	adminModules, moduleGroups, GroupKey,
} from '../constants/menu';
import { IMenu } from '../interfaces/Module';

interface ModuleManagerReturn {
  menu: IMenu[];
  hasAdminAccess: boolean;
  hasGroupAccess: (groupModules: string[]) => boolean;
  accessFlags: Record<GroupKey, boolean>;
  hasRegularModuleAccess: boolean;
  adminModulesList: IMenu[];
  getGroupModules: (groupModules: string[]) => IMenu[];
  expandedGroups: Record<GroupKey, boolean>;
  toggleGroup: (group: GroupKey) => void;
}

const useModuleManager = (): ModuleManagerReturn => {
	const [expandedGroups, setExpandedGroups] = useState<Record<GroupKey, boolean>>({
		masterData: false,
		budget: false,
		warehouse: false,
		pricing: false,
		hours: false,
	});

	const menu = useMemo<IMenu[]>(() => getMenu(), []);

	const hasAdminAccess = useMemo<boolean>(
		() => menu.some((module) => adminModules.includes(module.code)),
		[menu],
	);

	const hasGroupAccess = useMemo<(
		groupModules: string[]) => boolean>(
		() => (groupModules: string[]): boolean => menu
			.some((module) => groupModules.includes(module.code)) || (
			groupModules === moduleGroups.budget
        && (hasPermission({ module: 'BUDGET' }) || hasPermission({ module: 'ORDER' }))
		),
		[menu],
		);

	const accessFlags = useMemo<Record<GroupKey, boolean>>(() => ({
		masterData: hasGroupAccess(moduleGroups.masterData),
		budget: hasGroupAccess(moduleGroups.budget),
		warehouse: hasGroupAccess(moduleGroups.warehouse),
		pricing: hasGroupAccess(moduleGroups.pricing),
		hours: hasGroupAccess(moduleGroups.hours),
	}), [hasGroupAccess]);

	const hasRegularModuleAccess = useMemo<boolean>(
		() => Object.values(accessFlags).some((flag) => flag),
		[accessFlags],
	);

	const adminModulesList = useMemo<IMenu[]>(
		() => menu.filter((module) => adminModules.includes(module.code)),
		[menu],
	);

	const getGroupModules = useCallback(
		(groupModules: string[]): IMenu[] => menu
			.filter((module) => groupModules.includes(module.code)),
		[menu],
	);

	const toggleGroup = useCallback((group: GroupKey): void => {
		setExpandedGroups((prev) => {
			if (prev[group]) {
				return {
					...prev,
					[group]: false,
				};
			}

			return {
				masterData: false,
				budget: false,
				warehouse: false,
				pricing: false,
				hours: false,
				[group]: true,
			};
		});
	}, []);

	return {
		menu,
		hasAdminAccess,
		hasGroupAccess,
		accessFlags,
		hasRegularModuleAccess,
		adminModulesList,
		getGroupModules,
		expandedGroups,
		toggleGroup,
	};
};

export default useModuleManager;

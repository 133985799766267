import React, { useCallback, useMemo, useState } from 'react';
import { Form, Formik } from 'formik';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Input from '../Common/Form/Input';
import { filterObject } from '../../helpers/Utils';
import {
	bpStatusOptions,
	typeOptions,
} from '../../containers/BusinessPartner/BusinessPartnerAssets';
import Autocomplete from '../Common/Form/Autocomplete';
import SwitchField from '../Common/Form/Switch';
import { ICountry } from '../../services/country';
import { IRegionState } from '../../services/state';
import { ICity } from '../../services/city';
import { useConditionalSearch } from '../../hooks/useConditionalSearchProps';
import { BusinessPartnerQueryParams } from '../../interfaces/BusinessPartnerQueryParams';
import { BusinessPartnerType } from '../../enums/BusinessPartnerType';
import usePermission from '../../hooks/usePermission';
import RadioField from '../Common/Form/Radio';
import { BusinessPartnerStatus } from '../../enums/Approved';

const getApprovedBP = (approved?: boolean): BusinessPartnerStatus | undefined => {
	if (approved === true) {
		return BusinessPartnerStatus.APPROVED;
	} if (approved === false) {
		return BusinessPartnerStatus.NOT_APPROVED;
	}
	return undefined;
};

interface IFilterProps {
	sendFilter: (values: Partial<BusinessPartnerQueryParams>) => void;
	getCountries(search: string): void;
	getCities(search: string): void;
	countries: ICountry[];
	states: IRegionState[];
	cities: ICity[];
}

interface IForm {
	active?: boolean;
	name?: string;
	type?: BusinessPartnerType;
	country?: string[];
	state?: string[];
	city?: string[];
	taxId?: string;
	approved?: BusinessPartnerStatus;
}

const BusinessPartnerFilter = ({
	initialValues,
	sendFilter,
	getCountries,
	getCities,
	countries,
	states,
	cities,
}: IFilterProps & { initialValues: Partial<BusinessPartnerQueryParams> }): JSX.Element => {
	const [countryInputValue, setCountryInputValue] = useState('');
	const [cityInputValue, setCityInputValue] = useState('');
	const permissionSales = usePermission('BUSINESS_PARTNER');
	const permissionAdmin = usePermission('ACCESS_GROUP');

	useConditionalSearch({
		inputValue: countryInputValue,
		loadedItems: countries,
		searchFunction: getCountries,
		minLength: 2,
		compareKey: 'name',
	});

	useConditionalSearch({
		inputValue: cityInputValue,
		loadedItems: cities,
		searchFunction: getCities,
		minLength: 3,
		compareKey: 'name',
	});

	const onSubmit = useCallback((values: IForm) => {
		const params = filterObject(values);
		let approvedValue;
		if (values?.approved === BusinessPartnerStatus.APPROVED) {
			approvedValue = true;
		} else if (values?.approved === BusinessPartnerStatus.NOT_APPROVED) {
			approvedValue = false;
		} else {
			approvedValue = undefined;
		}
		sendFilter({
			...params,
			approved: approvedValue,
		});
	}, [sendFilter]);

	const onReset = useCallback((handleReset) => {
		sendFilter({});
		handleReset();
	}, [sendFilter]);

	const typeOptionsFiltered = useMemo(() => {
		if (permissionAdmin && permissionAdmin.isAdmin) {
			return typeOptions;
		} if (permissionSales) {
			return typeOptions.filter((option) => option.value === BusinessPartnerType.CLIENT.toString()
				|| option.value === BusinessPartnerType.SALESMAN.toString());
		}
		return typeOptions;
	}, [permissionSales, permissionAdmin]);

	return (
		<Formik
			initialValues={{
				...initialValues,
				active: initialValues.active ?? true,
				approved: getApprovedBP(initialValues.approved),
			}}
			onSubmit={onSubmit}
		>
			{({ handleReset }) => (
				<Form>
					<Grid container spacing={2} sx={{ p: 2, marginTop: 6 }}>
						<Grid item xs={12}>
							<Input.InputField
								id="name"
								name="name"
								label="Nome"
								autoComplete="off"
								fullWidth
							/>
						</Grid>
						<Grid item xs={12}>
							<Autocomplete
								multiple
								limitTags={2}
								name="country"
								label="País"
								options={countries}
								labelKey="id"
								valueKey="name"
								onInputChange={(event, newInputValue) => setCountryInputValue(newInputValue)}
							/>
						</Grid>
						<Grid item xs={12}>
							<Autocomplete
								multiple
								limitTags={2}
								name="state"
								label="Estado"
								options={states}
								labelKey="id"
								valueKey="name"
							/>
						</Grid>
						<Grid item xs={12}>
							<Autocomplete
								multiple
								limitTags={2}
								name="city"
								label="Cidade"
								options={cities}
								labelKey="id"
								valueKey="name"
								onInputChange={(event, newInputValue) => setCityInputValue(newInputValue)}
							/>
						</Grid>
						<Grid item xs={12}>
							<Input.InputField
								id="taxId"
								name="taxId"
								label="CPF/CNPJ"
								autoComplete="off"
								fullWidth
							/>
						</Grid>
						{permissionSales?.isAdmin && (
							<Grid item xs={12}>
								<RadioField
									name="type"
									label="Tipo de parceiro"
									options={typeOptionsFiltered}
									orientation="row"
								/>
							</Grid>
						)}
						<Grid item xs={12}>
							<SwitchField
								name="active"
								label="Inativo/Ativo"
							/>
						</Grid>
						<Grid item xs={12}>
							<RadioField
								name="approved"
								label="Aprovado"
								options={bpStatusOptions}
								orientation="column"
							/>
						</Grid>
						<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
							<Button variant="outlined" onClick={() => onReset(handleReset)}>
								Restaurar
							</Button>
							<Button variant="contained" type="submit">
								Filtrar
							</Button>
						</Grid>
					</Grid>
				</Form>
			)}
		</Formik>
	);
};

export default BusinessPartnerFilter;

import React from 'react';
import {
	Box,
	Stack,
	Typography,
	Grid,
} from '@mui/material';
import {
	Inventory2,
	Warehouse,
	LocalShipping,
	ShoppingCartCheckout,
	Archive,
	ChevronRight,
	LibraryAddCheck,
	SwapHorizontalCircle,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import usePermission from '../../hooks/usePermission';

const HomeMobile = (): JSX.Element => {
	const navigate = useNavigate();
	const consumptionPermission = usePermission('CONSUMPTION');

	const cardStyle = {
		padding: 2,
		border: 1,
		borderColor: '#bbb',
		bgcolor: 'white',
		borderRadius: 2,
		mb: 2,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		cursor: 'pointer',
	};

	return (
		<Grid container>
			<Grid item xs={12}>
				<Box onClick={() => navigate('storage-check')} sx={cardStyle}>
					<Stack direction="row" spacing={2} alignItems="center" sx={{ flex: 1 }}>
						<LibraryAddCheck fontSize="large" color="primary" />
						<Typography>Conferências Pendentes</Typography>
					</Stack>
					<ChevronRight color="primary" />
				</Box>
			</Grid>

			<Grid item xs={12}>
				<Box onClick={() => navigate('storage')} sx={cardStyle}>
					<Stack direction="row" spacing={2} alignItems="center" sx={{ flex: 1 }}>
						<Warehouse fontSize="large" color="primary" />
						<Typography>Armazenagens Pendentes</Typography>
					</Stack>
					<ChevronRight color="primary" />
				</Box>
			</Grid>

			<Grid item xs={12}>
				<Box onClick={() => navigate('transfer')} sx={cardStyle}>
					<Stack direction="row" spacing={2} alignItems="center" sx={{ flex: 1 }}>
						<SwapHorizontalCircle fontSize="large" color="primary" />
						<Typography>Transferências Pendentes</Typography>
					</Stack>
					<ChevronRight color="primary" />
				</Box>
			</Grid>

			<Grid item xs={12}>
				<Box onClick={() => navigate('expedition')} sx={cardStyle}>
					<Stack direction="row" spacing={2} alignItems="center" sx={{ flex: 1 }}>
						<LocalShipping fontSize="large" color="primary" />
						<Typography>Expedições Pendentes</Typography>
					</Stack>
					<ChevronRight color="primary" />
				</Box>
			</Grid>

			<Grid item xs={12}>
				<Box onClick={() => navigate('checkout-carrier')} sx={cardStyle}>
					<Stack direction="row" spacing={2} alignItems="center" sx={{ flex: 1 }}>
						<ShoppingCartCheckout fontSize="large" color="primary" />
						<Typography>Checkout Transportadora</Typography>
					</Stack>
					<ChevronRight color="primary" />
				</Box>
			</Grid>

			<Grid item xs={12}>
				<Box onClick={() => navigate('inventory')} sx={cardStyle}>
					<Stack direction="row" spacing={2} alignItems="center" sx={{ flex: 1 }}>
						<Inventory2 fontSize="large" color="primary" />
						<Typography>Inventário</Typography>
					</Stack>
					<ChevronRight color="primary" />
				</Box>
			</Grid>

			{consumptionPermission && (
				<Grid item xs={12}>
					<Box onClick={() => navigate('consumption')} sx={cardStyle}>
						<Stack direction="row" spacing={2} alignItems="center" sx={{ flex: 1 }}>
							<Archive fontSize="large" color="primary" />
							<Typography>Consumos Pendentes</Typography>
						</Stack>
						<ChevronRight color="primary" />
					</Box>
				</Grid>
			)}
		</Grid>
	);
};

export default HomeMobile;

import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import styled from '@mui/system/styled';
import FormHelperText from '@mui/material/FormHelperText';
import { parseJustificative } from '../../helpers/JustificativeUtils';

const StyledDialog = styled(Dialog)`
.MuiDialog-paper {
    width: 400px;
    height: 370px;
  }
`;

const justificativeMaxLength = 100;

interface ActionJustificativeModalProps {
	id?: string;
	title: string;
	onConfirm?: (justificative: string, id?: string) => void;
	open: boolean;
	onClose: () => void;
	readonly?: boolean;
	initialJustificative?: string;
}

const ActionJustificativeModal: React.FC<ActionJustificativeModalProps> = ({
	id, title, onConfirm, open, onClose, readonly = false, initialJustificative = '',
}) => {
	const [justificative, setJustificative] = useState(initialJustificative);

	const handlejustificativeChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		if (!readonly) {
			setJustificative(event.target.value);
		}
	};

	const handleSubmit = (): void => {
		onConfirm?.(justificative, id);
		onClose();
		setJustificative('');
	};

	return (
		<StyledDialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
			<DialogTitle id="form-dialog-title">{title}</DialogTitle>
			<DialogContent>
				<TextField
					autoFocus={!readonly}
					margin="dense"
					id="justificative"
					label="Justificativa"
					placeholder={readonly ? '' : 'Justificativa...'}
					type="text"
					multiline
					rows={6}
					fullWidth
					value={readonly ? parseJustificative(initialJustificative) : justificative}
					onChange={handlejustificativeChange}
					inputProps={{
						maxLength: justificativeMaxLength,
						style: { fontSize: '18px' },
						readOnly: readonly,
					}}
					InputLabelProps={{
						style: { fontSize: '17px' },
					}}
				/>
				{!readonly && (
					<FormHelperText>
						{`${justificativeMaxLength - justificative.length} caracteres restantes`}
					</FormHelperText>
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} color="primary" variant="outlined">
					{readonly ? 'Fechar' : 'Cancelar'}
				</Button>
				{!readonly && (
					<Button onClick={handleSubmit} color="primary" variant="contained" disabled={!justificative.trim()}>
						Enviar
					</Button>
				)}
			</DialogActions>
		</StyledDialog>
	);
};

ActionJustificativeModal.defaultProps = {
	id: '',
	onConfirm: undefined,
	readonly: false,
	initialJustificative: '',
};

export default ActionJustificativeModal;

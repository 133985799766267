import React, { useCallback } from 'react';
import { Button, Grid } from '@mui/material';
import {
	Form, FormikContext, useFormik,
} from 'formik';
import Input from '../Common/Form/Input';
import { InventoryQueryParams } from '../../containers/Inventory/InventoryAssets';
import { DatePicker } from '../Common/Form/DatePicker';
import { filterObject } from '../../helpers/Utils';

interface InventoryFilterProps {
	handlerValueFilter: (values: Partial<InventoryQueryParams>) => void;
	initialValues: InventoryQueryParams
}

const InventoryFilter = (
	{
		handlerValueFilter,
		initialValues,
	}: InventoryFilterProps,
): JSX.Element => {
	const onReset = useCallback((resetForm) => {
		const preservedParams = {
			type: initialValues?.type,
			status: initialValues?.status,
			skip: 0,
		};
		handlerValueFilter(preservedParams);
		resetForm();
	}, [handlerValueFilter, initialValues]);

	const formik = useFormik({
		initialValues,
		onSubmit: (values) => {
			handlerValueFilter(filterObject(values));
		},
	});

	return (
		<FormikContext.Provider value={formik}>
			<Form>
				<Grid container spacing={2} sx={{ p: 2, marginTop: 6 }}>
					<Grid item xs={6}>
						<DatePicker
							name="startDate"
							label="Data início"
						/>
					</Grid>
					<Grid item xs={6}>
						<DatePicker
							name="endDate"
							label="Data Final"
						/>
					</Grid>
					<Grid item xs={12}>
						<Input.InputField
							id="document"
							name="document"
							label="Documento"
							autoComplete="off"
							fullWidth
						/>
					</Grid>
					<Grid
						item
						xs={12}
						sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}
					>
						<Button variant="outlined" onClick={() => onReset(formik.handleReset)}>
							Restaurar
						</Button>
						<Button variant="contained" type="submit">
							Filtrar
						</Button>
					</Grid>
				</Grid>
			</Form>
		</FormikContext.Provider>
	);
};

export default InventoryFilter;

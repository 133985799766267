import React, {
	useCallback, useEffect, useRef, useState,
} from 'react';
import {
	Box,
	Typography,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Grid,
	IconButton,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
	Add, Remove,
} from '@mui/icons-material';
import { Form, useFormikContext } from 'formik';
import { useSnackbar } from 'notistack';
import Input from '../../Common/Form/Input';
import useConfirmationDialog from '../../../hooks/useConfirmationDialog';
import { SaveTaskType } from '../../../services/storage';
import { LocationOriginQueryParams, TaskStorageFormik } from '../../../containers/Mobile/Storage/StorageAssets';
import { ITask } from '../../../containers/Mobile/TaskAssets';
import { InventoryTaskStatus } from '../../../enums/InventoryTaskStatus';
import { Loading } from '../../Common/Loading';
import StepperPagination from '../../Common/StepperPagination';
import Button from '../../Common/Button/Button';

interface StorageConfirmationProductsProps {
  getLocationOrigin: (
	barCode: string,
	status: InventoryTaskStatus,
	params: LocationOriginQueryParams
) => void;
  saveTakeTask: (data: SaveTaskType) => void;
  handleConfirmStorage: () => void;
  handleBack: () => void;
  tasks: ITask[];
  loading: boolean;
  taskPages: number;
  taskPage: number;
  taskTake: number;
}

const StorageCheckConfirmation = ({
	getLocationOrigin,
	tasks,
	saveTakeTask,
	handleConfirmStorage,
	handleBack,
	loading,
	taskPages,
	taskPage,
	taskTake,
}: StorageConfirmationProductsProps): JSX.Element => {
	const [expanded, setExpanded] = useState<null | string>(null);
	const { enqueueSnackbar } = useSnackbar();
	const { requestConfirm, confirmationDialog } = useConfirmationDialog();
	const { setFieldValue, values } = useFormikContext<TaskStorageFormik>();
	const barcodeInputRefs = useRef<{ [key: string]: HTMLInputElement | null }>({});

	const handlePageChange = useCallback((newPage: number): void => {
		setExpanded(null);
		getLocationOrigin(values.barCodeOrigin, InventoryTaskStatus.WAITING, {
			skip: (newPage - 1) * taskTake,
			take: taskTake,
		});
	}, [getLocationOrigin, taskTake, values]);

	useEffect(() => {
		if (tasks.length > 0) {
			setExpanded(tasks[0].id);
		}
	}, [tasks]);

	useEffect(() => {
		if (expanded && barcodeInputRefs.current[expanded]) {
			const barcodeInput = barcodeInputRefs.current[expanded];
			if (barcodeInput) {
				barcodeInput.focus();
			}
		}
	}, [expanded]);

	const handleIncrement = useCallback((taskId: string): void => {
		if (!values[`quantity-${taskId}`]) {
			setFieldValue(`quantity-${taskId}`, 1);
			return;
		}
		setFieldValue(`quantity-${taskId}`, (Number(values[`quantity-${taskId}`]) ?? 0) + 1);
	}, [setFieldValue, values]);

	const handleDecrement = useCallback((taskId: string): void => {
		if (!Number(values[`quantity-${taskId}`])) {
			return;
		}
		setFieldValue(`quantity-${taskId}`, (Number(values[`quantity-${taskId}`]) ?? 0) - 1);
	}, [setFieldValue, values]);

	const confirmQuantity = useCallback((task: ITask): void => {
		const taskId = task.id;
		const quantity = Number(values[`quantity-${task.id}`]);
		saveTakeTask({
			taskId,
			quantity,
		});
		setFieldValue('confirmedTasks', [...values.confirmedTasks, task]);
		setExpanded(taskId);
	}, [saveTakeTask, setFieldValue, values]);

	const handleBlurOrEnter = useCallback((
		event: React.KeyboardEvent<HTMLInputElement>,
		task: ITask,
	): void => {
		const target = event.target as HTMLInputElement;
		if ((event.key === 'Tab') || (event.key === 'Enter')) {
			event.preventDefault();
			if (task.product.code === target.value) {
				handleIncrement(task.id);
			} else {
				enqueueSnackbar('Código de barras inválido', {
					variant: 'error',
				});
			}
			setFieldValue(`barcode-${task.id}`, '');
		}
	}, [enqueueSnackbar, handleIncrement, setFieldValue]);

	const handleExpanded = (taskId: string): void => {
		setExpanded((prevState) => (prevState === taskId ? null : taskId));
	};

	if (!loading && tasks.length === 0) {
		return (
			<Box sx={{ mt: 2 }}>
				<Typography textAlign="center">Não há produtos nesta localização.</Typography>
				<Button
					sx={{ mt: 4, width: '100%' }}
					size="large"
					variant="contained"
					onClick={handleBack}
				>
					Voltar para a tela inicial
				</Button>
			</Box>
		);
	}

	if (loading) {
		return <Loading />;
	}

	return (
		<>
			<StepperPagination
				count={taskPages}
				page={taskPage + 1}
				itemsPerPage={taskTake}
				onChange={handlePageChange}
			>
				<Box>
					<Typography mt={2} variant="h6" align="center" marginBottom={2}>
						Confirme os itens para armazenagem
					</Typography>
					{tasks.map((task: ITask) => (
						<Accordion
							key={task.id}
							sx={{ marginBottom: 1 }}
							expanded={expanded === task.id}
						>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls={`panel${task.id}-content`}
								id={`panel${task.id}-header`}
								onClick={() => handleExpanded(task.id)}
							>
								<Box sx={{ display: 'flex', flexDirection: 'column' }}>
									<Box display="flex" alignItems="center" gap={1}>
										<Typography>{`${task.product.code} - ${task.product.description}`}</Typography>
									</Box>
									<Typography sx={{ fontWeight: 'bold', mb: 1 }}>
										{`${task.quantity} ${task.product.measures[0].description}(s)`}
									</Typography>
								</Box>
							</AccordionSummary>
							<AccordionDetails>
								<Form>
									<Grid container spacing={2}>
										<Grid item xs={12} sm={6}>
											<Input.InputField
												label="Código de Barras"
												id={`barcode-${task.id}`}
												name={`barcode-${task.id}`}
												// eslint-disable-next-line no-return-assign
												inputRef={(el) => barcodeInputRefs.current[task.id] = el}
												fullWidth
												onKeyDown={(
													event: React.KeyboardEvent<HTMLInputElement>,
												) => handleBlurOrEnter(event, task)}
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<Box
												sx={{
													width: '100%',
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
												}}
											>
												<IconButton disabled={Number(values[`quantity-${task.id}`]) === 0} size="large" onClick={() => handleDecrement(task.id)}>
													<Remove />
												</IconButton>

												<Input.InputField
													id={`quantity-${task.id}`}
													name={`quantity-${task.id}`}
													style={{ width: 100, textAlign: 'center' }}
													type="number"
													inputProps={{ min: 0 }}
													required
												/>
												<IconButton size="large" onClick={() => handleIncrement(task.id)}>
													<Add />
												</IconButton>
											</Box>
										</Grid>
										<Grid item xs={12} sm={6} display="flex" justifyContent="space-between" marginTop={2} gap={2}>
											{Number(values[`quantity-${task.id}`]) === Number(task.quantity) ? (
												<Button
													variant="contained"
													size="small"
													color="primary"
													sx={{ width: '100%' }}
													onClick={() => confirmQuantity(task)}
													disabled={Number(values[`quantity-${task.id}`]) < 0}
												>
													Confirmar
												</Button>
											) : (
												<Button
													variant="outlined"
													size="small"
													color="error"
													sx={{ width: '100%' }}
													disabled={Number(values[`quantity-${task.id}`]) < 0}
													onClick={() => requestConfirm({
														title: 'Confirmar Inconsistência',
														description: 'Existe uma diferença entre a quantidade informada e a esperada, deseja confirmar mesmo assim?',
														callback: () => confirmQuantity(task),
													})}
												>
													Inconsistente
												</Button>
											)}

										</Grid>
									</Grid>
								</Form>
							</AccordionDetails>
						</Accordion>
					))}

					{confirmationDialog}
				</Box>
			</StepperPagination>
			<Button
				sx={{ width: '100%', mt: 4 }}
				size="large"
				variant="contained"
				onClick={handleConfirmStorage}
			>
				Voltar para a tela inicial
			</Button>
		</>
	);
};

export default StorageCheckConfirmation;

import React, { useCallback, useState } from 'react';
import {
	Box,
	Paper,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { ChangePasswordForm } from '../../ChangePassword/ChangePasswordForm';
import { changePassword } from '../../../services/auth';

const ChangePassword = (): JSX.Element => {
	const [loading, setLoading] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();

	const handleChangePassword = useCallback((password: string) => {
		setLoading(true);

		changePassword({ password }).then((response) => {
			setLoading(false);

			enqueueSnackbar(response.data.message, {
				variant: 'success',
			});

			navigate('/app');
		}).catch(() => {
			setLoading(false);

			enqueueSnackbar('Algum erro ocorreu, tente novamente ou contate um administrador.', {
				variant: 'error',
			});
		});
	}, [enqueueSnackbar, navigate]);

	return (
		<Box component={Paper} sx={{ p: 2, mt: 2, borderRadius: '8px' }}>
			<ChangePasswordForm loading={loading} handleChangePassword={handleChangePassword} />
		</Box>
	);
};

export default ChangePassword;

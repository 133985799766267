/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { DatePicker as MuiDatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { useField, useFormikContext } from 'formik';

interface DatePickerInputProps extends Omit<DatePickerProps<Date, Date>, 'renderInput'> {
	required?: boolean;
}

interface IDatePickerProps {
	name: string;
	label: string;
	minDate?: Date;
	maxDate?: Date;
	required?: boolean;
}

interface IYearPickerProps {
	name: string;
	label: string;
	minYear?: Date;
	maxYear?: Date;
}

interface IMonthPickerProps {
	name: string;
	label: string;
}

const textFieldCommonProps = {
	helperText: null,
};

export const DatePickerInput = (props: DatePickerInputProps): JSX.Element => {
	const { required } = props;
	return (
		<MuiDatePicker
			{...props}
			renderInput={
				(params: TextFieldProps) => (
					<TextField
						{...params}
						{...textFieldCommonProps}
						required={required}
						fullWidth
						error={Boolean(params.value && params.error)}
					/>
				)
			}
			OpenPickerButtonProps={{
				tabIndex: -1,
			}}
		/>
	);
};

DatePickerInput.defaultProps = {
	required: false,
};

export const DatePicker = ({
	minDate,
	maxDate,
	name,
	label,
	required,
}: IDatePickerProps): JSX.Element => {
	const [field, meta, helpers] = useField({ name });

	const handleChange = (value: any): void => {
		if (value) {
			const year = value.getFullYear();

			if (year < 100) {
				value.setFullYear(2000 + year);
			}
		}
		helpers.setValue(value);
	};

	return (
		<DatePickerInput
			label={label}
			minDate={minDate}
			maxDate={maxDate}
			value={field.value || null}
			onChange={handleChange}
			required={required}
		/>
	);
};

DatePicker.defaultProps = {
	minDate: null,
	maxDate: null,
	required: false,
};

export const YearPicker = ({
	minYear, maxYear, name, label,
}: IYearPickerProps): JSX.Element => {
	const [field, meta, helpers] = useField({ name });

	const yearDate = useMemo(() => {
		const date = new Date();
		date.setFullYear(field.value);
		return date;
	}, [field.value]);

	const handleChange = (value: any): void => {
		helpers.setValue(value.getFullYear());
	};

	return (
		<DatePickerInput
			inputFormat="yyyy"
			views={['year']}
			label={label}
			minDate={minYear}
			maxDate={maxYear}
			value={yearDate}
			onChange={handleChange}
		/>
	);
};

YearPicker.defaultProps = {
	minYear: null,
	maxYear: null,
};

export const MonthPicker = ({ name, label }: IMonthPickerProps): JSX.Element => {
	const [field, meta, helpers] = useField({ name });
	const { values }: any = useFormikContext();

	const monthDate = useMemo(() => {
		const date = new Date(values.year, field.value, 1);
		return date;
	}, [field.value, values.year]);

	const handleChange = (value: any): void => {
		helpers.setValue(value.getMonth());
	};

	return (
		<DatePickerInput
			value={monthDate}
			onChange={handleChange}
			inputFormat="MMMM"
			mask=""
			views={['month']}
			label={label}
		/>
	);
};

import React, { memo } from 'react';
import { Form, Formik } from 'formik';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SxProps, Theme } from '@mui/material';
import { defaultPasswordRequirements } from '../../helpers/passwordRequirements';
import Input from '../Common/Form/Input';
import { validate } from '../../helpers/ChangePassword';
import LoadingButton from '../Common/LoadingButton/LoadingButton';

interface IChangePasswordProps {
	loading: boolean;
	handleChangePassword: (newPassword: string) => void;
}

interface IForm {
	newPassword: string;
	confirmPassword: string;
}

const sxRequirementsList: SxProps<Theme> = {
	margin: '.75rem 0 .5rem 0',
	borderRadius: '.5rem',
	paddingTop: '1rem',
	paddingBottom: '1rem',
	color: '#000000de',
	backgroundColor: 'rgba(0, 0, 0, 0.02)',
	transition: 'all .3s ease',
};

const initialValues: IForm = {
	newPassword: '',
	confirmPassword: '',
};

const PasswordRequirements = memo(() => (
	<Box sx={{ mb: 1, width: '100%' }}>
		<Typography>
			Sua nova senha deve conter:
		</Typography>
		<Box component="ul" sx={sxRequirementsList}>
			{defaultPasswordRequirements.map(
				(requirement) => (
					<li key={requirement.code}>
						{requirement.text}
						{' '}
					</li>
				),
			)}
		</Box>
	</Box>
));

export const ChangePasswordForm = ({
	loading, handleChangePassword,
}: IChangePasswordProps): JSX.Element => (
	<Box>
		<PasswordRequirements />
		<Formik
			initialValues={initialValues}
			validate={validate}
			validateOnBlur={false}
			onSubmit={(values) => handleChangePassword(values.newPassword)}
		>
			<Box component={Form} noValidate>
				<Input.PasswordInputField name="newPassword" label="Nova senha" />
				<Input.PasswordInputField name="confirmPassword" label="Confirmar nova senha" />

				<LoadingButton
					loading={loading}
					variant="contained"
					color="primary"
					type="submit"
					sx={
						{
							width: '100%',
							margin: 0,
							mt: 2,
						}
					}
				>
					Confirmar
				</LoadingButton>
			</Box>
		</Formik>
	</Box>
);

import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import { Fade, useTheme } from '@mui/material';
import { PageHeader } from '../Common/PageHeader/PageHeader';
import DrawerFilter from '../Common/DrawerFilter';
import { PageHeaderButtonProps } from '../../interfaces/PageHeaderInterface';
import InventoryTable from './InventoryTable';
import StartInventoryModal from './Modals/StartInventoryModal';
import InventoryFilter from './InventoryFilter';
import {
	IInventory,
	InventoryQueryParams,
	ParamsStartInventory,
	InventoryStatusCountResponse,
	InventoryType,
} from '../../containers/Inventory/InventoryAssets';
import useDataGridManagement from '../../hooks/useDataGridManagement';
import { getInventoryDashboardSections, getInventoryTaskHeaderStatusCards } from '../../helpers/dashboardConfig';
import { CardDefinition, SectionDefinition, useDashboard } from '../../hooks/useDashboard';
import DefaultDashboard from '../Common/Dashboard/DefaultDashboard';
import { InventoryStatus } from '../../enums/inventory';
import { getInventoryViewTitle } from '../../helpers/inventoryHelper';
import { Loading } from '../Common/Loading';
import useInventoryNavigation from '../../hooks/useInventoryNavigation';

const loadingSx = {
	position: 'absolute',
	top: 0,
	left: 0,
	right: 0,
	bottom: 0,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	backgroundColor: 'rgba(255, 255, 255, 0.7)',
	zIndex: 10,
};

interface InventaryProps {
  startInventory: (params: ParamsStartInventory) => void;
  getInventories: (values: Partial<InventoryQueryParams>) => void;
  getInventoryStatusCount: () => void;
  inventories: IInventory[];
  loading: boolean;
  inventoryPage: number;
  inventoryCount: number;
  inventoryTake: number;
  statusCount: InventoryStatusCountResponse;
  handleChangeDateInventERP: (inventoryId: string, dateInventERP: Date | string | null) => void;
  deleteInventory: (inventoryId: string) => void;
  cancelInventory: (inventoryId: string, justification: string) => void;
}

const Inventory = ({
	startInventory,
	getInventories,
	getInventoryStatusCount,
	inventoryPage,
	inventoryCount,
	inventoryTake,
	inventories,
	loading,
	statusCount,
	handleChangeDateInventERP,
	deleteInventory,
	cancelInventory,
}: InventaryProps): JSX.Element => {
	const [startModal, setStartModal] = useState(false);
	const [isFilterDrawerOpen, setFilterDrawerOpen] = useState(false);
	const [showTable, setShowTable] = useState(false);
	const [expandedSection, setExpandedSection] = useState<InventoryType | null>(
		InventoryType.PARTIAL,
	);
	const [currentView, setCurrentView] = useState<{
    type: InventoryType;
    status: InventoryStatus;
  } | null>(null);
	const theme = useTheme();

	const skipStatusCountRef = useRef(false);

	const {
		setFilter,
		filter,
		sortModel,
		onChangePage,
		onChangePageSize,
		onSortModelChange,
	} = useDataGridManagement<InventoryQueryParams>({
		initialPageSize: inventoryTake,
		initialPage: inventoryPage,
		fetchData: (params) => {
			if (showTable) {
				getInventories(params);
			}
		},
	});

	const {
		navigateBackToDashboard,
		restoreFilter,
		filters,
		type,
		status,
		originalExpandedSection,
	} = useInventoryNavigation({
		setShowTable,
		setCurrentView,
		setFilter,
		setExpandedSection,
		getInventoryStatusCount,
	});

	useEffect(() => {
		if (restoreFilter && filters) {
			skipStatusCountRef.current = true;
			setFilter(filters);
			setCurrentView({ type, status });
			setShowTable(true);

			if (originalExpandedSection) {
				setExpandedSection(originalExpandedSection);
			}
		}
	}, [restoreFilter, filters, setFilter, type, status, originalExpandedSection]);

	const handleStatusCardClick = useCallback((
		sectionId: InventoryType,
		statusKey: InventoryStatus,
	) => {
		setFilter({
			type: sectionId,
			status: statusKey,
			skip: 0,
		});
		setCurrentView({
			type: sectionId,
			status: statusKey,
		});

		setExpandedSection(sectionId);
		setShowTable(true);
	}, [setFilter]);

	const toggleFilterDrawer = useCallback(() => {
		setFilterDrawerOpen((prevState) => !prevState);
	}, []);

	const sendFilter = useCallback((values: Partial<InventoryQueryParams>) => {
		const pageFilter = {
			...values,
			skip: 0,
		};
		setFilter(pageFilter);
	}, [setFilter]);

	const handleFilter = useCallback((params: Partial<InventoryQueryParams>) => {
		setFilterDrawerOpen(false);
		sendFilter(params);
	}, [sendFilter]);

	const handleModalStartInventory = useCallback(() => {
		setStartModal(true);
	}, []);

	const onClose = useCallback((): void => {
		setStartModal(false);
	}, []);

	const controlledGetInventoryStatusCount = useCallback(() => {
		if (!skipStatusCountRef.current) {
			getInventoryStatusCount();
		}
		skipStatusCountRef.current = false;
	}, [getInventoryStatusCount]);

	const countActiveFilters = useCallback((): number => Object.entries(filter)
		.filter(([key, value]) => {
			const excludedKeys = ['type', 'status', 'skip', 'take', 'orderBy'];
			if (excludedKeys.includes(key)) {
				return false;
			}
			return value !== '' && value !== undefined;
		})
		.length, [filter]);

	const filterMemo = useMemo(
		() => (
			<DrawerFilter
				open={isFilterDrawerOpen}
				onClose={toggleFilterDrawer}
			>
				<InventoryFilter
					handlerValueFilter={handleFilter}
					initialValues={filter}
				/>
			</DrawerFilter>
		),
		[
			filter,
			handleFilter,
			isFilterDrawerOpen,
			toggleFilterDrawer,
		],
	);

	const dashboardHeaderButtons = useMemo(
		(): PageHeaderButtonProps[] => [
			{
				variant: 'contained',
				color: 'primary',
				type: 'submit',
				fullWidth: true,
				onClick: handleModalStartInventory,
				text: 'Iniciar',
			},
		],
		[handleModalStartInventory],
	);

	const tableHeaderButtons = useMemo(
		(): PageHeaderButtonProps[] => [
			{
				variant: 'contained',
				color: 'primary',
				onClick: toggleFilterDrawer,
				text: 'Filtro',
				badgeContent: countActiveFilters(),
				badgeTitle: 'Filtros ativos',
				startIcon: <FilterListIcon />,
			},
		],
		[toggleFilterDrawer, countActiveFilters],
	);

	const statusCards: Record<number, CardDefinition<InventoryStatus>> = useMemo(
		() => getInventoryTaskHeaderStatusCards(theme) as Record<
      number,
      CardDefinition<InventoryStatus>>,
		[theme],
	);

	const dashboardSections: SectionDefinition<
    InventoryType,
    InventoryStatusCountResponse,
    InventoryStatus>[] = useMemo(() => getInventoryDashboardSections(theme) as SectionDefinition<
      InventoryType,
      InventoryStatusCountResponse,
      InventoryStatus>[], [theme]);

	const dashboardConfig = useDashboard(
		statusCount,
		dashboardSections,
		statusCards,
		handleStatusCardClick,
		controlledGetInventoryStatusCount,
	);

	const inventoryDashboardMemo = useMemo(() => (
		<DefaultDashboard
			sections={dashboardConfig}
			defaultExpanded={expandedSection || InventoryType.PARTIAL}
		/>
	), [dashboardConfig, expandedSection]);

	const viewData = useMemo(() => getInventoryViewTitle(
		currentView,
		{ color: 'primary', fontSize: 'large' },
	), [currentView]);

	return (
		<>
			<Box className="content">
				<PageHeader
					title={viewData.title}
					icon={viewData.icon}
					buttons={
						showTable
							? [
								{
									text: 'Voltar',
									onClick: navigateBackToDashboard,
									startIcon: <ArrowBackIcon />,
									variant: 'outlined',
								},
								...tableHeaderButtons,
							]
							: dashboardHeaderButtons
					}
				/>
				{filterMemo}
				{loading && !showTable && (
					<Box sx={loadingSx}>
						<Loading />
					</Box>
				)}
				<Fade in={!showTable} unmountOnExit>
					<Box>
						{inventoryDashboardMemo}
					</Box>
				</Fade>
				{showTable && (
					<InventoryTable
						inventories={inventories}
						loading={loading}
						inventoryPage={inventoryPage}
						onChangePage={onChangePage}
						onChangePageSize={onChangePageSize}
						onSortModelChange={onSortModelChange}
						sortModel={sortModel}
						inventoryCount={inventoryCount}
						inventoryTake={inventoryTake}
						handleChangeDateInventERP={handleChangeDateInventERP}
						deleteInventory={deleteInventory}
						cancelInventory={cancelInventory}
						filter={filter}
						currentView={currentView}
						expandedSection={expandedSection}
					/>
				)}
			</Box>
			<StartInventoryModal
				open={startModal}
				onClose={onClose}
				handleConfirmStartInventory={startInventory}
			/>
		</>
	);
};

export default Inventory;

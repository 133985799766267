import React, { useCallback, useEffect, useRef } from 'react';
import {
	Box,
	Typography,
	Paper,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { useSnackbar } from 'notistack';
import Input from '../../Common/Form/Input';
import { formatBarCode, removeMaskBarCode } from '../../../helpers/masks';
import { SaveTaskType } from '../../../containers/InventoryTaskInvoice/InventoryTaskInvoiceAssets';
import Button from '../../Common/Button/Button';

interface ExpeditionListProps {
	tasksPendingPost: SaveTaskType[]
	handleFinishTask(data: SaveTaskType[]): void;
	saleOrder: string;
}

const ExpeditionFinish = ({
	tasksPendingPost,
	saleOrder,
	handleFinishTask,
}: ExpeditionListProps): JSX.Element => {
	const { values } = useFormikContext<{
		locationDestiny: string;
		locationDestinyConfirmation: string;
	}>();
	const { enqueueSnackbar } = useSnackbar();
	const inputRef = useRef<HTMLInputElement>(null);

	const handleFinishTasks = useCallback((): void => {
		if (removeMaskBarCode(
			values.locationDestiny,
		) === (removeMaskBarCode(values.locationDestinyConfirmation))
		) {
			handleFinishTask(tasksPendingPost);
		} else {
			enqueueSnackbar('Localização de destino inválida.', {
				variant: 'error',
			});
		}
	}, [values.locationDestiny,
		values.locationDestinyConfirmation,
		handleFinishTask,
		tasksPendingPost,
		enqueueSnackbar]);

	const handleBlurOrEnter = useCallback((event: React.KeyboardEvent<HTMLInputElement>): void => {
		if ((event.key === 'Tab') || (event.key === 'Enter')) {
			event.preventDefault();
			handleFinishTasks();
		}
	}, [handleFinishTasks]);

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	}, [tasksPendingPost]);

	return (
		<Box padding={1} sx={{ mt: 4 }}>
			<Typography variant="h6" align="center">
				Confirme a Localização Destino
			</Typography>

			<Typography align="center">
				{`Localização de Destino: ${formatBarCode(values.locationDestiny)}`}
			</Typography>

			<Typography align="center">
				{`Pedido de Venda: ${saleOrder}`}
			</Typography>

			<Paper sx={{ boxShadow: 'none', marginTop: 6, marginBottom: 2 }}>
				<Input.InputField
					id="locationDestinyConfirmation"
					name="locationDestinyConfirmation"
					label="Digite o código da localização"
					autoComplete="off"
					InputProps={{
						inputProps: {
							min: 0,
							step: 'any',
						},
					}}
					fullWidth
					autoFocus
					format={(value) => formatBarCode(value)}
					onKeyDown={handleBlurOrEnter}
				/>
			</Paper>
			<Button
				sx={{ mt: 2, width: '100%' }}
				size="large"
				variant="contained"
				onClick={handleFinishTasks}
				disabled={removeMaskBarCode(
					values.locationDestiny,
				) !== (removeMaskBarCode(values.locationDestinyConfirmation))}
			>
				Confirmar
			</Button>
		</Box>
	);
};

export default ExpeditionFinish;

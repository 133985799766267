/* eslint-disable react/jsx-props-no-spreading */
import React, {
	Dispatch,
	Reducer,
	useMemo,
	useReducer,
} from 'react';
import {
	NavigateFunction,
	useNavigate,
} from 'react-router-dom';
import {
	OptionsObject,
	SnackbarKey,
	SnackbarMessage,
	useSnackbar,
} from 'notistack';
import { AxiosError } from 'axios';
import InventoryPresentational from '../../components/Inventory/Inventory';
import {
	IInventory,
	InventoryQueryParams,
	InventoryType,
	IStatusCount,
	ParamsStartInventory,
} from './InventoryAssets';
import {
	cancelInventory,
	deleteInventory,
	getInventories,
	getInventoryStatusCount,
	startInventoryAll,
	updateDateInventERP,
} from '../../services/inventory';

enum ActionType {
	LOADING,
	INVENTORY,
	STATUS_COUNT,
}

interface IState {
	loading: boolean;
	inventories: IInventory[];
	count: number;
	inventoryCount: number;
	inventoryPage: number;
	inventoryTake: number;
	statusCount: IStatusCount;
}

type TAction =
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| { type: ActionType.INVENTORY; payload: {
		inventories: IInventory[];
		inventoryCount: number;
		inventoryTake: number;
		inventoryPage: number
	} }
	| {
		type: ActionType.STATUS_COUNT;
		payload: {
			statusCount: IStatusCount;
		}
	};

interface IInventoryActions {
	setLoading(loading: boolean): void;
	getInventories(params: Partial<InventoryQueryParams>): void;
	startInventory(params: ParamsStartInventory): void;
	handleChangeDateInventERP: (inventoryId: string, dateInventERP: Date | string | null) => void;
	deleteInventory(inventoryId: string): void;
	cancelInventory(inventoryId: string, justification: string): void;
	getInventoryStatusCount: () => void;
}

const initialState: IState = {
	loading: false,
	inventories: [],
	count: 0,
	inventoryPage: 0,
	inventoryCount: 0,
	inventoryTake: 10,
	statusCount: {
		full: {
			pending: 0,
			progress: 0,
			finished: 0,
			canceled: 0,
		},
		partial: {
			pending: 0,
			progress: 0,
			finished: 0,
			canceled: 0,
		},
	},
};

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.INVENTORY:
			return {
				...state,
				inventories: action.payload.inventories,
				inventoryPage: action.payload.inventoryPage,
				inventoryCount: action.payload.inventoryCount,
				inventoryTake: action.payload.inventoryTake,
			};
		case ActionType.STATUS_COUNT:
			return { ...state, statusCount: action.payload.statusCount };
		default:
			throw new Error();
	}
};

let lastQueryParams: InventoryQueryParams;

const InventoryActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
	navigate: NavigateFunction,
): IInventoryActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		getInventories(queryParams: InventoryQueryParams) {
			actions.setLoading(true);
			const take = queryParams.take ?? 10;
			const skip = queryParams.skip ?? 0;
			const params = {
				...queryParams,
				skip: skip * take,
				take: queryParams.take ?? 10,
			};
			getInventories(params).then((response) => {
				dispatch({
					type: ActionType.INVENTORY,
					payload: {
						inventories: response.data.inventorys,
						inventoryPage: queryParams.skip ?? 0,
						inventoryCount: response.data.count,
						inventoryTake: take,
					},
				});
				lastQueryParams = params;
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
					variant: 'error',
				});
			}).finally(() => actions.setLoading(false));
		},
		startInventory(params: ParamsStartInventory) {
			actions.setLoading(true);
			if (params.type === InventoryType.FULL) {
				startInventoryAll({
					dateInventERP: params.dateInventERP,
				}).then((response) => {
					enqueueSnackbar(response.data.message, { variant: 'success' });
					navigate(`edit/${response.data.inventoryId}`);
				}).catch((error: AxiosError) => {
					enqueueSnackbar(error.response?.data.message
				|| 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
						variant: 'error',
					});
				}).finally(() => {
					actions.setLoading(false);
				});
			}

			if (params.type === InventoryType.PARTIAL) {
				actions.setLoading(false);
				navigate('edit', { state: params });
			}
		},
		handleChangeDateInventERP: (inventoryId: string, dateInventERP: Date | string | null) => {
			actions.setLoading(true);
			updateDateInventERP(
				inventoryId,
				dateInventERP,
			).then((response) => {
				enqueueSnackbar(response.data.message || 'Data alterada com sucesso!', {
					variant: 'success',
				});
				actions.getInventories(lastQueryParams);
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
					variant: 'error',
				});
			}).finally(() => actions.setLoading(false));
		},
		deleteInventory(inventoryId: string) {
			actions.setLoading(true);
			deleteInventory(inventoryId).then((response) => {
				enqueueSnackbar(response.data.message || 'Inventário deletado com sucesso!', {
					variant: 'success',
				});
				actions.getInventories(lastQueryParams);
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
					variant: 'error',
				});
			}).finally(() => actions.setLoading(false));
		},
		cancelInventory(inventoryId: string, justification: string) {
			actions.setLoading(true);
			cancelInventory(inventoryId, justification).then((response) => {
				enqueueSnackbar(response.data.message || 'Inventário cancelado com sucesso!', {
					variant: 'success',
				});
				actions.getInventories(lastQueryParams);
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
					variant: 'error',
				});
			}).finally(() => actions.setLoading(false));
		},
		getInventoryStatusCount() {
			getInventoryStatusCount()
				.then((response) => {
					const statusCount: IStatusCount = {
						full: {
							pending: response.data.full.pending,
							progress: response.data.full.progress,
							finished: response.data.full.finished,
							canceled: response.data.full.canceled,
						},
						partial: {
							pending: response.data.partial.pending,
							progress: response.data.partial.progress,
							finished: response.data.partial.finished,
							canceled: response.data.partial.canceled,
						},
					};
					dispatch({
						type: ActionType.STATUS_COUNT,
						payload: {
							statusCount,
						},
					});
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(
						error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.',
						{ variant: 'error' },
					);
				});
		},
	};

	return actions;
};

const Inventory = (): JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(reducer, initialState);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();

	const actions = useMemo(
		() => InventoryActions(dispatch, enqueueSnackbar, navigate),
		[enqueueSnackbar, navigate],
	);

	return (
		<InventoryPresentational
			{...state}
			{...actions}
		/>
	);
};

export default Inventory;

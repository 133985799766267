import React from 'react';
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { InventoryTaskInvoiceQueryParams } from '../interfaces/InventoryTaskInvoiceQueryParams';
import { InventoryTaskStatusHeader } from '../enums/InventoryTaskStatusHeader';
import { InventoryTaskStatus } from '../enums/InventoryTaskStatus';
import { InventoryTaskInvoiceType } from '../enums/InventoryTaskInvoiceType';
import { InventoryTaskAction } from '../enums/InventoryTaskAction';
import { StatusTaskMap } from '../containers/InventoryTaskInvoice/InventoryTaskInvoiceAssets';
import { defaultFilterContextScreen, createStatusTaskMap } from '../constants/inventoryTask';

interface VisibleColumnsConfig {
	checkbox: boolean;
	document: boolean;
	documentType: boolean;
	businessPartner: boolean;
	totalValue: boolean;
	emissionDate: boolean;
	printAction: boolean;
	expandAction: boolean;
	saleOrder: boolean;
}

interface CurrentView {
	type: InventoryTaskInvoiceType;
	status: InventoryTaskStatusHeader;
}

interface ViewTitleResult {
	title: string;
	icon: React.ReactNode;
}

interface IconOptions {
    color?: 'inherit' | 'primary' | 'secondary' | 'action' | 'disabled' | 'error';
    fontSize?: 'small' | 'medium' | 'large' | 'inherit';
}

export const getVisibleColumns = (
	filter: InventoryTaskInvoiceQueryParams,
): VisibleColumnsConfig => {
	const { type } = filter;

	const baseConfig = {
		checkbox: true,
		document: true,
		documentType: true,
		businessPartner: true,
		totalValue: true,
		emissionDate: true,
		printAction: false,
		expandAction: true,
		saleOrder: false,
	};

	if (type === InventoryTaskInvoiceType.MOVE) {
		return {
			...baseConfig,
			documentType: false,
			businessPartner: false,
			totalValue: false,
			saleOrder: false,
		};
	}

	if (type === InventoryTaskInvoiceType.DISPATCH) {
		return {
			...baseConfig,
			printAction: true,
			saleOrder: false,
		};
	}

	if (type === InventoryTaskInvoiceType.SUPPLY) {
		return {
			...baseConfig,
			printAction: false,
			saleOrder: true,
		};
	}

	return baseConfig;
};

export const getAdjustedColWidths = (filter: InventoryTaskInvoiceQueryParams): string[] => {
	if (filter.type === InventoryTaskInvoiceType.MOVE) {
		return ['3%', '42%', '0%', '0%', '0%', '52%', '0%', '3%'];
	}

	if (filter.type === InventoryTaskInvoiceType.DISPATCH) {
		if (filter.status === InventoryTaskStatusHeader.PENDING
			|| filter.status === InventoryTaskStatusHeader.IN_PROGRESS) {
			return ['3%', '15%', '15%', '24%', '21%', '14%', '5%', '3%'];
		}
		return ['3%', '20%', '15%', '30%', '24%', '5%', '0%', '3%'];
	}

	if (filter.type === InventoryTaskInvoiceType.SUPPLY) {
		return ['3%', '15.5%', '15.5%', '21.5%', '17.5%', '15.5%', '8.5%', '3%'];
	}

	return ['3%', '15%', '15%', '25%', '20%', '15%', '5%', '2%'];
};

export const getLabelColumnQuantityCounted = (filter: InventoryTaskInvoiceQueryParams): string => {
	let labelStart = '';
	const labelEnd = 'realizada';

	const type = filter.type !== undefined ? filter.type : defaultFilterContextScreen;

	switch (type) {
		case InventoryTaskAction.DISPATCH:
			labelStart = 'Expedição';
			break;
		case InventoryTaskAction.MOVE:
			labelStart = 'Transferência';
			break;
		default:
			labelStart = 'Armazenagem';
	}

	return labelStart.concat(` ${labelEnd}`);
};

export const getLabelStatusTask = (
	status: InventoryTaskStatus,
	filter: InventoryTaskInvoiceQueryParams,
): string => {
	let statusMap: StatusTaskMap = {} as StatusTaskMap;

	const type = filter.type !== undefined ? filter.type : defaultFilterContextScreen;

	switch (type) {
		case InventoryTaskAction.DISPATCH:
			statusMap = createStatusTaskMap({ operatingLabel: 'Em Expedição' });
			break;
		case InventoryTaskAction.MOVE:
			statusMap = createStatusTaskMap({
				operatingLabel: 'Em Transferência',
			});
			break;
		case InventoryTaskAction.SUPPLY:
			statusMap = createStatusTaskMap({
				operatingLabel: 'Em Armazenagem',
			});
			break;
		default:
			statusMap = createStatusTaskMap({ operatingLabel: 'Em Armazenagem' });
	}
	return statusMap[status]?.label || 'Status Desconhecido';
};

export const getTotalVisibleColumns = (
	visibleColumns: VisibleColumnsConfig,
): number => Object.values(visibleColumns).filter(Boolean).length;

export const getViewTitle = (
	currentView: CurrentView | null,
	iconOptions: IconOptions = { color: 'primary', fontSize: 'large' },
): ViewTitleResult => {
	if (!currentView) {
		return {
			title: 'Kanban de Estoque',
			icon: <ViewKanbanIcon color={iconOptions.color} fontSize={iconOptions.fontSize} />,
		};
	}

	let typeText = '';
	let typeIcon: React.ReactNode = null;

	switch (currentView.type) {
		case InventoryTaskInvoiceType.SUPPLY:
			typeText = 'Armazenagem';
			typeIcon = <WarehouseIcon color={iconOptions.color} fontSize={iconOptions.fontSize} />;
			break;
		case InventoryTaskInvoiceType.DISPATCH:
			typeText = 'Expedição';
			typeIcon = <LocalShippingIcon color={iconOptions.color} fontSize={iconOptions.fontSize} />;
			break;
		case InventoryTaskInvoiceType.MOVE:
			typeText = 'Transferência';
			typeIcon = (
				<SwapHorizontalCircleIcon
					color={iconOptions.color}
					fontSize={iconOptions.fontSize}
				/>
			);
			break;
		default:
			typeText = 'Desconhecido';
			typeIcon = null;
	}

	let statusText = '';
	let statusIcon: React.ReactNode = null;

	switch (currentView.status) {
		case InventoryTaskStatusHeader.PENDING:
			statusText = 'Pendente';
			statusIcon = (
				<PendingActionsOutlinedIcon
					color={iconOptions.color}
					fontSize={iconOptions.fontSize}
				/>
			);
			break;
		case InventoryTaskStatusHeader.IN_PROGRESS:
			statusText = 'Em Andamento';
			statusIcon = (
				<HourglassTopIcon
					color={iconOptions.color}
					fontSize={iconOptions.fontSize}
				/>
			);
			break;
		case InventoryTaskStatusHeader.COMPLETED:
			statusText = 'Concluído';
			statusIcon = (
				<CheckCircleOutlineIcon
					color={iconOptions.color}
					fontSize={iconOptions.fontSize}
				/>
			);
			break;
		case InventoryTaskStatusHeader.CANCELED:
			statusText = 'Cancelado';
			statusIcon = (
				<CancelOutlinedIcon
					color={iconOptions.color}
					fontSize={iconOptions.fontSize}
				/>
			);
			break;
		default:
			statusText = 'Desconhecido';
			statusIcon = null;
	}

	return {
		title: `${typeText} - ${statusText}`,
		icon: typeIcon,
	};
};

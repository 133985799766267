import React from 'react';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { StatusInvoice } from '../containers/FiscalDocumentEntry/FiscalDocumentEntryAssets';

interface ViewTitleResult {
	title: string;
	icon: React.ReactNode;
}
interface CurrentView {
	status: StatusInvoice;
}
interface IconOptions {
	color?: 'inherit' | 'primary' | 'secondary' | 'action' | 'disabled' | 'error';
	fontSize?: 'small' | 'medium' | 'large' | 'inherit';
}
export const getInvoiceViewTitle = (
	currentView: CurrentView | null,
	iconOptions: IconOptions = { color: 'primary', fontSize: 'large' },
): ViewTitleResult => {
	if (!currentView) {
		return {
			title: 'Documento Fiscal',
			icon: <ReceiptIcon color={iconOptions.color} fontSize={iconOptions.fontSize} />,
		};
	}
	const { status } = currentView;

	switch (status) {
		case StatusInvoice.CREATED:
			return {
				title: 'NFs Recebidas',
				icon: <PendingActionsOutlinedIcon
					color="warning"
					fontSize={iconOptions.fontSize}
				/>,
			};
		case StatusInvoice.TASKS_ASSIGNED:
			return {
				title: 'Tarefas Criadas',
				icon: <HourglassTopIcon color="info" fontSize={iconOptions.fontSize} />,
			};
		case StatusInvoice.STORAGE_COMPLETED:
			return {
				title: 'Armazenamento Completo',
				icon: <CheckCircleOutlineIcon color="success" fontSize={iconOptions.fontSize} />,
			};
		case StatusInvoice.TASKS_CANCELED:
			return {
				title: 'Tarefas Canceladas',
				icon: <CancelOutlinedIcon color="error" fontSize={iconOptions.fontSize} />,
			};
		default:
			return {
				title: 'Entradas Fiscais',
				icon: <ReceiptIcon color={iconOptions.color} fontSize={iconOptions.fontSize} />,
			};
	}
};

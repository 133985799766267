import React, {
	useCallback, useContext, useMemo, useState,
} from 'react';
import { styled, Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import LineAxisIcon from '@mui/icons-material/LineAxis';
import { Link, useLocation } from 'react-router-dom';
import { Stack, Tooltip } from '@mui/material';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import LogoutIcon from '@mui/icons-material/Logout';
import LockIcon from '@mui/icons-material/Lock';
import BusinessIcon from '@mui/icons-material/Business';
import Avatar from '@mui/material/Avatar';
import { logout } from '../../services/auth';
import DrawerMenu from './DrawerMenu';
import { drawerWidth } from '../../constants/menu';
import ChangePasswordDialog from '../ChangePassword/ChangePasswordDialog';
import usePermission from '../../hooks/usePermission';
import ChangeLoginPreferenceDialog from '../ChangeLoginPreference/ChangeLoginPreferenceDialog';
import CompanyBranchModal from '../../containers/CompanyBranchModal/CompanyBranchModal';
import { BranchContext } from '../../contexts/BranchContext';
import useCurrentUser from '../../hooks/useCurrentUser';

const navbar: SxProps<Theme> = {
	boxShadow: 0,
	backgroundColor: '#fff',
	borderBottom: '1px solid #e1e1e1',
};

const AppBar: any = styled(MuiAppBar, {
	shouldForwardProp: (prop: any) => prop !== 'open',
})(({ theme, open }: any) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const toolbar: SxProps<Theme> = {
	display: 'flex',
	gap: '2',
};

const imageBox: SxProps<Theme> = {
	display: 'flex',
};

const image: (menuOpen: boolean) => SxProps<Theme> = (menuOpen) => {
	let style: SxProps<Theme> = {
		height: '100%',
		width: '10rem',
	};

	if (!menuOpen) {
		style = { ...style, marginLeft: '1rem' };
	}

	return style;
};

const userAvatar: SxProps<Theme> = {
	width: 25,
	height: 25,
	bgcolor: 'primary.main',
	fontSize: '0.7rem',
	cursor: 'pointer',
};

const menuUserAvatar: SxProps<Theme> = {
	width: 32,
	height: 32,
	bgcolor: 'primary.main',
	fontSize: '0.9rem',
	mr: 2,
};

const menuUserSx: SxProps<Theme> = {
	px: 2,
	py: 1.5,
	pb: 2,
	pt: 2,
	'&:hover': { bgcolor: 'transparent' },
	'&:focus': { bgcolor: 'transparent' },
	'&.Mui-focusVisible': { bgcolor: 'transparent' },
	'&:active': { bgcolor: 'transparent' },
	cursor: 'default',
};

const branchSelectorButton: SxProps<Theme> = {
	mr: 2,
	px: 1.5,
	py: 0.5,
	borderRadius: 2,
	backgroundColor: 'rgba(240, 249, 255, 0.8)',
	borderColor: 'rgba(25, 118, 210, 0.12)',
	color: 'primary.dark',
	transition: 'all 0.2s ease-in-out',
	display: 'flex',
	alignItems: 'center',
	boxShadow: '0px 1px 3px rgba(0,0,0,0.05)',
	'&:hover': {
		backgroundColor: 'rgba(240, 249, 255, 0.95)',
		borderColor: 'rgba(25, 118, 210, 0.25)',
		boxShadow: '0px 2px 5px rgba(0,0,0,0.08)',
	},
	'&.Mui-disabled': {
		backgroundColor: 'rgba(0, 0, 0, 0.04)',
		color: 'rgba(0, 0, 0, 0.38)',
		borderColor: 'rgba(0, 0, 0, 0.08)',
	},
};

const Navbar = (): JSX.Element => {
	const [menuOpen, setMenuOpen] = useState<boolean>(false);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [openChangePassword, setOpenChangePassword] = useState<boolean>(false);
	const [openLoginPreference, setOpenLoginPreference] = useState<boolean>(false);
	const [openCompanyModal, setOpenCompanyModal] = useState<boolean>(false);
	const companyPermission = usePermission('COMPANY');
	const { refreshTriggerBranch } = useContext(BranchContext);
	const { userName, userInitials } = useCurrentUser();
	const location = useLocation();
	const currentPath = location.pathname;

	const handleMenu = useCallback((event: React.MouseEvent<HTMLElement>): void => {
		setAnchorEl(event.currentTarget);
	}, []);

	const handleClose = useCallback((): void => {
		setAnchorEl(null);
	}, []);

	const handleLogout = useCallback(() => {
		logout();
	}, []);

	const handleOpenChangePasswordDialog = useCallback(() => {
		setOpenChangePassword(true);
	}, []);

	const handleCloseChangePasswordDialog = useCallback(() => {
		setOpenChangePassword(false);
	}, []);

	const handleOpenChangeLoginPreferenceDialog = useCallback(() => {
		setOpenLoginPreference(true);
	}, []);

	const handleCloseChangeLoginPreferenceDialog = useCallback(() => {
		setOpenLoginPreference(false);
	}, []);

	const toggleDrawer = useCallback((): void => {
		setMenuOpen(!menuOpen);
	}, [menuOpen]);

	const handleOpenCompanyModal = useCallback(() => {
		setOpenCompanyModal(true);
	}, []);

	const menuItems = useMemo(() => [
		{
			label: 'Preferência de login multiempresa',
			onClick: handleOpenChangeLoginPreferenceDialog,
			icon: <BusinessIcon fontSize="small" />,
			divider: false,
		},
		{
			label: 'Alterar senha',
			onClick: handleOpenChangePasswordDialog,
			icon: <LockIcon fontSize="small" />,
			divider: true,
		},
		{
			label: 'Logout',
			onClick: handleLogout,
			icon: <LogoutIcon fontSize="small" color="error" />,
			divider: false,
		},
	], [handleLogout, handleOpenChangePasswordDialog, handleOpenChangeLoginPreferenceDialog]);

	return (
		<>
			<AppBar position="fixed" color="primary" sx={navbar} open={menuOpen}>
				<Toolbar sx={toolbar}>
					<IconButton
						size="large"
						edge="start"
						aria-label="menu"
						onClick={toggleDrawer}
						sx={{
							...(menuOpen && { display: 'none' }),
						}}
					>
						<MenuIcon color="primary" />
					</IconButton>
					<Box component={Link} to="/" sx={imageBox}>
						<Box component="img" alt="Logo" src="/api/configuration/logo" sx={image(menuOpen)} />
					</Box>
					{companyPermission?.isAdmin && (
						<Stack direction="row" spacing={2} ml={2} alignItems="center">
							<Link to="/business-intelligence">
								<Button variant="text" startIcon={<LineAxisIcon />}>
									Business Intelligence
								</Button>
							</Link>
						</Stack>
					)}
					<Stack direction="row" spacing={1} alignItems="center" sx={{ ml: 'auto' }}>
						<Tooltip title="Selecionar Empresa/Filial">
							<Button
								variant="outlined"
								size="small"
								onClick={handleOpenCompanyModal}
								startIcon={(
									<BusinessIcon
										fontSize="small"
										sx={{
											color: 'primary.main',
											transition: 'transform 0.2s ease',
											transform: 'scale(1.1)',
										}}
									/>
								)}
								sx={branchSelectorButton}
								disabled={!refreshTriggerBranch.branchId || currentPath.includes('/edit')}
							>
								<Typography
									variant="body2"
									noWrap
									sx={{
										maxWidth: 200,
										fontWeight: 500,
										textOverflow: 'ellipsis',
										overflow: 'hidden',
									}}
								>
									{refreshTriggerBranch.branchName || 'Selecione uma filial'}
								</Typography>
							</Button>
						</Tooltip>
						<Stack direction="row" spacing={1} alignItems="center" sx={{ ml: 'auto' }}>
							<Tooltip title={userName}>
								<Avatar
									onClick={handleMenu}
									sx={userAvatar}
									aria-label="account of current user"
									aria-controls="menu-appbar"
									aria-haspopup="true"
								>
									{userInitials}
								</Avatar>
							</Tooltip>
							<Menu
								sx={{
									mt: '45px',
									'& .MuiPaper-root': {
										borderRadius: 2,
										minWidth: 280,
										boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.15)',
									},
								}}
								id="menu-appbar"
								anchorEl={anchorEl}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								keepMounted
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								open={Boolean(anchorEl)}
								onClose={handleClose}
							>
								<MenuItem
									sx={menuUserSx}
									disableRipple
								>
									<ListItemIcon>
										<Avatar sx={menuUserAvatar}>
											{userInitials}
										</Avatar>
									</ListItemIcon>
									<Typography sx={{ fontSize: '0.95rem', fontWeight: 400 }}>
										{userName}
									</Typography>
								</MenuItem>
								<Divider sx={{ mt: 1, mb: 1 }} />
								{menuItems.flatMap((menuItem, index) => [
									<MenuItem
										key={menuItem.label}
										onClick={() => {
											menuItem.onClick();
											handleClose();
										}}
										sx={{
											px: 2,
											py: 1.5,
											'&:hover': {
												bgcolor: 'rgba(25, 118, 210, 0.08)',
											},
										}}
									>
										<ListItemIcon>
											{menuItem.icon}
										</ListItemIcon>
										<Typography variant="body2">
											{menuItem.label}
										</Typography>
									</MenuItem>,
									menuItem.divider ? <Divider key={`divider-${index}`} sx={{ my: 1 }} /> : null,
								])}
							</Menu>
						</Stack>
					</Stack>
				</Toolbar>
			</AppBar>
			<DrawerMenu open={menuOpen} toggleDrawer={toggleDrawer} />
			<ChangePasswordDialog
				open={openChangePassword}
				handleClose={handleCloseChangePasswordDialog}
			/>
			<ChangeLoginPreferenceDialog
				open={openLoginPreference}
				onClose={handleCloseChangeLoginPreferenceDialog}
			/>
			<CompanyBranchModal
				open={openCompanyModal}
				handleClose={() => setOpenCompanyModal(false)}
			/>
		</>
	);
};

export default Navbar;

import React from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import { format } from 'date-fns';
import TruncateTooltipCell from '../components/Common/Datagrid/TruncateTooltipCell';
import { getBase64Image } from '../helpers/Utils';
import { currencyBRLMask } from '../helpers/intl';

export const productColumns: GridColDef[] = [
	{
		field: 'image',
		headerName: 'Imagem',
		width: 120,
		align: 'center',
		renderCell: (params: GridRenderCellParams) => {
			const imageDataUrl = getBase64Image(params.row.imageB64);
			const boxStyle = {
				width: '50px',
				height: '50px',
				backgroundImage: `url(${imageDataUrl})`,
				backgroundSize: 'cover',
				backgroundRepeat: 'no-repeat',
				backgroundPosition: 'center',
				borderRadius: '50%',
			};

			return (
				<Box style={boxStyle} />
			);
		},
	},
	{
		field: 'code',
		headerName: 'Código',
		flex: 1,
	},
	{
		field: 'description',
		headerName: 'Descrição',
		flex: 1.5,
		renderCell: (params: GridRenderCellParams) => (
			<TruncateTooltipCell value={params.value as string || '-'} />
		),
	},
	{
		field: 'erpCode',
		headerName: 'Cód. ERP',
		flex: 1,
		renderCell: (params: GridRenderCellParams) => (
			<TruncateTooltipCell value={params.value as string || '-'} />
		),
	},
	{
		field: 'erpDescription',
		headerName: 'Desc. ERP',
		flex: 1,
		renderCell: (params: GridRenderCellParams) => (
			<TruncateTooltipCell value={params.value as string || '-'} />
		),
	},
	{
		field: 'familyCode',
		headerName: 'Cód. Família',
		flex: 1,
		renderCell: (params: GridRenderCellParams) => (
			<TruncateTooltipCell value={params.value as string || '-'} />
		),
	},
	{
		field: 'familyDescription',
		headerName: 'Desc. Família',
		flex: 1,
		renderCell: (params: GridRenderCellParams) => (
			<TruncateTooltipCell value={params.value as string || '-'} />
		),
	},
	{
		field: 'barCode',
		headerName: 'Cód. de Barras',
		flex: 1,
		renderCell: (params: GridRenderCellParams) => (
			<TruncateTooltipCell value={params.value as string || '-'} />
		),
	},
	{
		field: 'primaryMeasureId',
		headerName: '1ª UM',
		flex: 1,
		sortable: false,
		renderCell: (params: GridRenderCellParams) => (
			<TruncateTooltipCell value={params.value as string || '-'} />
		),
	},
	{
		field: 'secondaryMeasureId',
		headerName: '2ª UM',
		flex: 1,
		sortable: false,
		renderCell: (params: GridRenderCellParams) => (
			<TruncateTooltipCell value={params.value as string || '-'} />
		),
	},
	{
		field: 'typeId',
		headerName: 'Tipo',
		flex: 1,
		renderCell: (params: GridRenderCellParams) => (
			<TruncateTooltipCell value={params.value as string || '-'} />
		),
	},
	{
		field: 'qualityControl',
		headerName: 'Controle de Qualidade',
		flex: 1,
		renderCell: (params) => (params.value ? 'Sim' : 'Não'),
	},
	{
		field: 'locateControl',
		headerName: 'Controle de Localização',
		flex: 1,
		renderCell: (params) => (params.value ? 'Sim' : 'Não'),
	},
	{
		field: 'apportionmentControl',
		headerName: 'Controle de Rateio',
		flex: 1,
		renderCell: (params) => (params.value ? 'Sim' : 'Não'),
	},
	{
		field: 'createdAt',
		headerName: 'Criado em',
		flex: 1,
		renderCell: (params: GridRenderCellParams) => (
			<TruncateTooltipCell value={(format(new Date(params.value), 'dd/MM/yyyy HH:mm')) || '-'} />
		),
	},
	{
		field: 'updatedAt',
		headerName: 'Atualizado em',
		flex: 1,
		renderCell: (params: GridRenderCellParams) => (
			<TruncateTooltipCell value={(format(new Date(params.value), 'dd/MM/yyyy HH:mm')) || '-'} />
		),
	},
	{
		field: 'costInv',
		headerName: 'Custo Médio',
		flex: 1,
		align: 'right',
		renderCell: (params: GridRenderCellParams) => (
			<TruncateTooltipCell value={(currencyBRLMask(params.value)) || '-'} />
		),
	},
	{
		field: 'costInvDate',
		headerName: 'Data Custo Médio',
		flex: 1,
		renderCell: (params: GridRenderCellParams) => (
			<TruncateTooltipCell value={params.value ? format(new Date(params.value), 'dd/MM/yyyy HH:mm') : '-'} />
		),
	},
	{
		field: 'costSPF',
		headerName: 'Custo Form. Preço',
		flex: 1,
		renderCell: (params: GridRenderCellParams) => (
			<TruncateTooltipCell value={(currencyBRLMask(params.value)) || '-'} />
		),
	},
	{
		field: 'costSPFDate',
		headerName: 'Data Form. Preço',
		flex: 1,
		renderCell: (params: GridRenderCellParams) => (
			<TruncateTooltipCell value={params.value ? format(new Date(params.value), 'dd/MM/yyyy HH:mm') : '-'} />
		),
	},
	{
		field: 'history',
		headerName: 'Histórico',
		flex: 1,
		sortable: false,
		renderCell: (params: GridRenderCellParams) => (
			<TruncateTooltipCell value={params.value as string || '-'} />
		),
	},
];

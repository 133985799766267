import { IStatusCount } from '../containers/Consumption/ConsumptionAssets';
import { StatusInvoice } from '../containers/FiscalDocumentEntry/FiscalDocumentEntryAssets';
import { InventoryTaskStatus } from '../enums/InventoryTaskStatus';
import { InventoryTaskStatusHeader } from '../enums/InventoryTaskStatusHeader';

export const getStatusCounts = <T extends Record<string, unknown>>(
	counts: T | undefined,
	defaultValues: T,
): T => counts || defaultValues;

export const calculateTotal = (counts: Record<string, number>): number => Object
	.values(counts).reduce((sum, count) => sum + count, 0);

export const defaultStatusCounts = {
	pending: 0,
	inProgress: 0,
	completed: 0,
	canceled: 0,
};

export const getStatusCountsForConsumption = <T extends Record<string, number>>(
	statusData: IStatusCount<T> | undefined,
	category: keyof IStatusCount<T>,
): { counts: T; total: number } => {
	const defaultCounts = defaultStatusCounts as unknown as T;
	const counts = getStatusCounts(statusData?.[category], defaultCounts);
	const total = calculateTotal(counts as unknown as Record<string, number>);

	return { counts, total };
};

export const getStatusEnum = (key: number): InventoryTaskStatusHeader => {
	switch (key) {
		case 0:
			return InventoryTaskStatusHeader.PENDING;
		case 1:
			return InventoryTaskStatusHeader.IN_PROGRESS;
		case 2:
			return InventoryTaskStatusHeader.COMPLETED;
		case 3:
			return InventoryTaskStatusHeader.CANCELED;
		default:
			return InventoryTaskStatusHeader.PENDING;
	}
};

export const getStatusColor = (status: InventoryTaskStatus): 'success' | 'info' | 'warning' | 'default' | 'error' => {
	switch (status) {
		case InventoryTaskStatus.FINISHED:
			return 'success';
		case InventoryTaskStatus.OPERATING:
			return 'info';
		case InventoryTaskStatus.WAITING:
			return 'info';
		case InventoryTaskStatus.PENDING:
			return 'warning';
		case InventoryTaskStatus.CANCELED:
			return 'error';
		default:
			return 'default';
	}
};

export const getStatusNameFromEnum = (statusEnum: number): string => {
	switch (statusEnum) {
		case InventoryTaskStatusHeader.PENDING: return 'pending';
		case InventoryTaskStatusHeader.IN_PROGRESS: return 'inProgress';
		case InventoryTaskStatusHeader.COMPLETED: return 'completed';
		case InventoryTaskStatusHeader.CANCELED: return 'canceled';
		default: return 'pending';
	}
};

export const getInvoiceStatusFromEnum = (statusEnum: number): string => {
	switch (statusEnum) {
		case StatusInvoice.CREATED: return 'created';
		case StatusInvoice.TASKS_ASSIGNED: return 'tasksAssigned';
		case StatusInvoice.STORAGE_COMPLETED: return 'storageCompleted';
		case StatusInvoice.TASKS_CANCELED: return 'tasksCanceled';
		default: return 'desconhecido';
	}
};

export const getInventoryStatusFromEnum = (statusEnum: number): string => {
	switch (statusEnum) {
		case InventoryTaskStatusHeader.PENDING: return 'pending';
		case InventoryTaskStatusHeader.IN_PROGRESS: return 'progress';
		case InventoryTaskStatusHeader.COMPLETED: return 'finished';
		case InventoryTaskStatusHeader.CANCELED: return 'canceled';
		default: return 'desconhecido';
	}
};

import React, { useEffect, useRef } from 'react';
import {
	Typography,
	Card,
	CardContent,
	CardActions,
} from '@mui/material';
import { useFormikContext } from 'formik';
import Input from '../../Common/Form/Input';
import { TaskStorageFormik } from '../../../containers/Mobile/Storage/StorageAssets';
import { formatBarCode } from '../../../helpers/masks';
import Button from '../../Common/Button/Button';

interface StorageStartProps {
	handleBarCodeOrigin: (barCodeOrigin: string) => void;
}

const StorageStartCheck = ({
	handleBarCodeOrigin,
}: StorageStartProps): JSX.Element => {
	const { values } = useFormikContext<TaskStorageFormik>();
	const barcodeInputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (barcodeInputRef.current) {
			barcodeInputRef.current.focus();
		}
	}, []);

	const handleBlurOrEnter = (event:React.KeyboardEvent<HTMLInputElement>):void => {
		if ((event.key === 'Tab') || (event.key === 'Enter')) {
			event.preventDefault();
			handleBarCodeOrigin(values.barCodeOrigin);
		}
	};

	return (
		<Card>
			<CardContent>
				<Typography textAlign="center" variant="h6" gutterBottom>
					Conferência
				</Typography>
				<Typography textAlign="center" variant="subtitle1" gutterBottom>
					Informe a localização de origem
				</Typography>

				<Input.InputField
					id="barCodeOrigin"
					name="barCodeOrigin"
					label="Código de Barras da Localização"
					autoComplete="off"
					fullWidth
					format={(value) => formatBarCode(value).toUpperCase()}
					required
					inputRef={barcodeInputRef}
					onKeyDown={(
						event: React.KeyboardEvent<HTMLInputElement>,
					) => handleBlurOrEnter(event)}
				/>
			</CardContent>
			<CardActions>
				<Button
					variant="contained"
					size="large"
					fullWidth
					onClick={() => handleBarCodeOrigin(values.barCodeOrigin)}
				>
					Confirmar
				</Button>
			</CardActions>
		</Card>
	);
};

export default StorageStartCheck;

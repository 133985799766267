import React from 'react';
import InventoryIcon from '@mui/icons-material/Inventory';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import { InventoryType } from '../containers/Inventory/InventoryAssets';
import { InventoryStatus } from '../enums/inventory';

interface ViewTitleResult {
	title: string;
	icon: React.ReactNode;
}

interface CurrentView {
	type: InventoryType;
	status: InventoryStatus;
}

interface IconOptions {
	color?: 'inherit' | 'primary' | 'secondary' | 'action' | 'disabled' | 'error' | 'warning' | 'info' | 'success';
	fontSize?: 'small' | 'medium' | 'large' | 'inherit';
}

const getStatusColor = (status: InventoryStatus): IconOptions['color'] => {
	switch (status) {
		case InventoryStatus.PENDING:
			return 'warning';
		case InventoryStatus.PROGRESS:
			return 'info';
		case InventoryStatus.FINISHED:
			return 'success';
		case InventoryStatus.CANCELED:
			return 'error';
		default:
			return 'primary';
	}
};

export const getInventoryViewTitle = (
	currentView: CurrentView | null,
	iconOptions: IconOptions = { color: 'primary', fontSize: 'large' },
): ViewTitleResult => {
	if (!currentView) {
		return {
			title: 'Inventário',
			icon: <InventoryIcon color={iconOptions.color} fontSize={iconOptions.fontSize} />,
		};
	}

	const statusColor = getStatusColor(currentView.status);

	let typeText = '';
	let typeIcon: React.ReactNode = null;

	switch (currentView.type) {
		case InventoryType.FULL:
			typeText = 'Inventário (Geral)';
			typeIcon = <AssignmentIcon color={statusColor} fontSize={iconOptions.fontSize} />;
			break;
		case InventoryType.PARTIAL:
			typeText = 'Inventário (Parcial)';
			typeIcon = (
				<ContentPasteSearchIcon
					color={statusColor}
					fontSize={iconOptions.fontSize}
				/>
			);
			break;
		default:
			typeText = 'Inventário';
			typeIcon = <InventoryIcon color={iconOptions.color} fontSize={iconOptions.fontSize} />;
	}

	let statusText = '';

	switch (currentView.status) {
		case InventoryStatus.PENDING:
			statusText = 'Pendente';
			break;
		case InventoryStatus.PROGRESS:
			statusText = 'Em Andamento';
			break;
		case InventoryStatus.FINISHED:
			statusText = 'Concluido';
			break;
		case InventoryStatus.CANCELED:
			statusText = 'Cancelado';
			break;
		default:
			statusText = '';
	}
	return {
		title: statusText ? `${typeText} - ${statusText}` : typeText,
		icon: typeIcon,
	};
};

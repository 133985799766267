import React, { useMemo } from 'react';
import {
	Box, Paper, Stack, Typography,
} from '@mui/material';
import {
	DataGrid, GridColDef, GridRenderCellParams,
} from '@mui/x-data-grid';
import { useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { InventoryItemsByIdParams } from '../../../interfaces/InventoryParams';
import { PageHeaderButtonProps } from '../../../interfaces/PageHeaderInterface';
import { PageHeader } from '../../Common/PageHeader/PageHeader';
import { formatBarCode } from '../../../helpers/masks';
import useDataGridManagement from '../../../hooks/useDataGridManagement';
import { IInventoryInfo, IInventoryItems } from '../../../containers/Inventory/InventoryAssets';
import { formatUTCDateToLocal } from '../../../helpers/DateUtils';
import TruncateTooltipCell from '../../Common/Datagrid/TruncateTooltipCell';
import useInventoryNavigation from '../../../hooks/useInventoryNavigation';

interface InventorySummaryByOperatorReportProps {
	loading: boolean
	inventoryItems: IInventoryItems[]
	getInventoryItemsById(id: string, queryParams?: InventoryItemsByIdParams): void;
	inventoryItemsCount: number;
	inventoryItemsTake: number;
	inventoryItemsPage: number;
	inventoryInfo: IInventoryInfo;
	reportByOperatorPDF(id: string): void;
}

const InventorySummaryByOperatorReport = ({
	loading,
	inventoryItems,
	getInventoryItemsById,
	inventoryItemsCount,
	inventoryItemsTake,
	inventoryItemsPage,
	inventoryInfo,
	reportByOperatorPDF,
}: InventorySummaryByOperatorReportProps): JSX.Element => {
	const { id } = useParams();
	const { navigateBackToTable } = useInventoryNavigation();

	const {
		sortModel,
		onChangePage,
		onChangePageSize,
		onSortModelChange,
	} = useDataGridManagement<InventoryItemsByIdParams>({
		initialPageSize: inventoryItemsTake,
		initialPage: inventoryItemsPage,
		fetchData: (params) => {
			if (id) {
				return getInventoryItemsById(id, params);
			}
			return Promise.resolve(null);
		},
	});

	const columns: GridColDef[] = [
		{
			field: 'locationName',
			headerName: 'Localização',
			flex: 1,
			renderCell: (params: GridRenderCellParams) => (
				formatBarCode(params.row.inventoryItem.location.barCode)
			),
		},
		{
			field: 'productDescription',
			headerName: 'Produto',
			flex: 2,
			renderCell: (params: GridRenderCellParams<string>) => (
				<TruncateTooltipCell value={`${params.row.inventoryItem.product.code} - ${params.row.inventoryItem.product.description}`} />
			),
		},
		{
			field: 'operator',
			headerName: 'Operador',
			flex: 1,
			valueFormatter: (params) => (params.value ? params.value.name : 'Auto'),
		},
		{
			field: 'countNumber',
			headerName: 'Contagem',
			flex: 1,
			renderCell: (params: GridRenderCellParams) => (`${params.row.countNumber}ª`),
		},
		{
			field: 'quantity',
			headerName: 'Quantidade',
			flex: 1,
		},
	];

	const headerButtonsProps = useMemo(
		(): PageHeaderButtonProps[] => [
			{
				text: 'Voltar',
				onClick: navigateBackToTable,
				startIcon: <ArrowBackIcon />,
				variant: 'outlined',
			},
			{
				variant: 'contained',
				color: 'primary',
				type: 'submit',
				fullWidth: true,
				onClick: () => {
					if (id) {
						reportByOperatorPDF(id);
					}
				},
				text: 'Imprimir',
			},
		],
		[id, navigateBackToTable, reportByOperatorPDF],
	);

	return (
		<Box>
			<PageHeader
				title="Relatório de resumo do inventário por operador"
				buttons={headerButtonsProps}
			/>

			<Box component={Paper} p={2}>
				<Box mb={2}>
					<Stack direction="row" spacing={1}>
						<Typography fontWeight="bold">Número do Documento:</Typography>
						<Typography>{inventoryInfo.document}</Typography>
					</Stack>
					<Stack direction="row" spacing={1}>
						<Typography fontWeight="bold">Data:</Typography>
						<Typography>{inventoryInfo.date ? formatUTCDateToLocal(inventoryInfo.date, 'dd/MM/yyyy') : ''}</Typography>
					</Stack>
					<Stack direction="row" spacing={1}>
						<Typography fontWeight="bold">Responsável:</Typography>
						<Typography>{inventoryInfo.responsibleName}</Typography>
					</Stack>
				</Box>

				<DataGrid
					rows={inventoryItems}
					columns={columns}
					autoHeight
					loading={loading}
					rowCount={inventoryItemsCount}
					pagination
					paginationMode="server"
					sortingMode="server"
					rowsPerPageOptions={[10, 25, 50, 100]}
					pageSize={inventoryItemsTake}
					page={inventoryItemsPage}
					onPageChange={onChangePage}
					onPageSizeChange={onChangePageSize}
					onSortModelChange={onSortModelChange}
					sortModel={sortModel}
				/>
			</Box>
		</Box>
	);
};

export default InventorySummaryByOperatorReport;

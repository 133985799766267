import React from 'react';
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import { Theme } from '@mui/material/styles';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { CardDefinition, SectionDefinition } from '../hooks/useDashboard';
import { IStatusCount } from '../containers/Consumption/ConsumptionAssets';
import { InvoiceStatusCountResponse } from '../containers/InventoryTaskInvoice/InventoryTaskInvoiceAssets';
import { InventoryTaskStatusHeader } from '../enums/InventoryTaskStatusHeader';
import { InventoryTaskInvoiceType } from '../enums/InventoryTaskInvoiceType';
import { ConsumptionType } from '../enums/ConsumptionType';
import { FiscalDocumentoEntryStatusCountResponse, StatusInvoice } from '../containers/FiscalDocumentEntry/FiscalDocumentEntryAssets';
import { getInventoryStatusFromEnum, getStatusNameFromEnum } from './StatusCount';
import { InventoryStatusCountResponse, InventoryType } from '../containers/Inventory/InventoryAssets';

export const getInventoryTaskHeaderStatusCards = (
	theme: Theme,
): Record<number, CardDefinition> => ({
	[InventoryTaskStatusHeader.PENDING]: {
		key: InventoryTaskStatusHeader.PENDING,
		title: 'Pendentes',
		icon: <PendingActionsOutlinedIcon />,
		color: theme.palette.warning.main,
	},
	[InventoryTaskStatusHeader.IN_PROGRESS]: {
		key: InventoryTaskStatusHeader.IN_PROGRESS,
		title: 'Em Andamento',
		icon: <HourglassTopIcon />,
		color: theme.palette.info.main,
	},
	[InventoryTaskStatusHeader.COMPLETED]: {
		key: InventoryTaskStatusHeader.COMPLETED,
		title: 'Concluídos',
		icon: <CheckCircleOutlineIcon />,
		color: theme.palette.success.main,
	},
	[InventoryTaskStatusHeader.CANCELED]: {
		key: InventoryTaskStatusHeader.CANCELED,
		title: 'Cancelados',
		icon: <CancelOutlinedIcon />,
		color: theme.palette.error.main,
	},
});

export const getConsumptionDashboardSections = (
	theme: Theme,
): SectionDefinition<number, IStatusCount, number>[] => [
	{
		id: ConsumptionType.REQUISITION,
		title: 'Requisição',
		icon: <AssignmentIcon fontSize="inherit" />,
		iconColor: theme.palette.primary.main,
		accordionColor: theme.palette.common.white,
		totalCountFn: (data: IStatusCount) => Object.values(data.requisition)
			.reduce((sum, val) => sum + val, 0),
		totalTooltip: 'Total de requisições',
		getStatusCount: (data: IStatusCount, statusKey: number) => {
			const statusName = getStatusNameFromEnum(statusKey);
			return (data.requisition as Record<string, number>)[statusName] || 0;
		},
	},
	{
		id: ConsumptionType.DEVOLUTION,
		title: 'Devolução',
		icon: <AssignmentReturnIcon fontSize="inherit" />,
		iconColor: theme.palette.primary.main,
		accordionColor: theme.palette.common.white,
		totalCountFn: (data: IStatusCount) => Object.values(data.devolution)
			.reduce((sum, val) => sum + val, 0),
		totalTooltip: 'Total de devoluções',
		getStatusCount: (data: IStatusCount, statusKey: number) => {
			const statusName = getStatusNameFromEnum(statusKey);
			return (data.devolution as Record<string, number>)[statusName] || 0;
		},
	},
];

export const getInventoryTaskDashboardSections = (
	theme: Theme,
): SectionDefinition<number, InvoiceStatusCountResponse, number>[] => [
	{
		id: InventoryTaskInvoiceType.SUPPLY,
		title: 'Armazenagem',
		icon: <WarehouseIcon fontSize="inherit" />,
		iconColor: theme.palette.primary.main,
		accordionColor: theme.palette.common.white,
		totalCountFn: (data: InvoiceStatusCountResponse) => Object.values(data.supply)
			.reduce((sum, val) => sum + val, 0),
		totalTooltip: 'Total de tarefas de armazenagem',
		getStatusCount: (
			data: InvoiceStatusCountResponse,
			statusKey: number,
		) => {
			const statusName = getStatusNameFromEnum(statusKey);
			return (data.supply as Record<string, number>)[statusName] || 0;
		},
	},
	{
		id: InventoryTaskInvoiceType.MOVE,
		title: 'Transferência',
		icon: <SwapHorizontalCircleIcon fontSize="inherit" />,
		iconColor: theme.palette.primary.main,
		accordionColor: theme.palette.common.white,
		totalCountFn: (data: InvoiceStatusCountResponse) => Object.values(data.move)
			.reduce((sum, val) => sum + val, 0),
		totalTooltip: 'Total de transferências',
		getStatusCount: (
			data: InvoiceStatusCountResponse,
			statusKey: number,
		) => {
			const statusName = getStatusNameFromEnum(statusKey);
			return (data.move as Record<string, number>)[statusName] || 0;
		},
	},
	{
		id: InventoryTaskInvoiceType.DISPATCH,
		title: 'Expedição',
		icon: <LocalShippingIcon fontSize="inherit" />,
		iconColor: theme.palette.primary.main,
		accordionColor: theme.palette.common.white,
		totalCountFn: (data: InvoiceStatusCountResponse) => Object.values(data.dispatch)
			.reduce((sum, val) => sum + val, 0),
		totalTooltip: 'Total de tarefas de expedição',
		getStatusCount: (
			data: InvoiceStatusCountResponse,
			statusKey: number,
		) => {
			const statusName = getStatusNameFromEnum(statusKey);
			return (data.dispatch as Record<string, number>)[statusName] || 0;
		},
	},
];

export const getInvoiceStatusCards = (
	theme: Theme,
): Record<number, CardDefinition<StatusInvoice>> => ({
	[StatusInvoice.CREATED]: {
		key: StatusInvoice.CREATED,
		title: 'NFs Recebidas',
		icon: <PendingActionsOutlinedIcon />,
		color: theme.palette.warning.main,
	},
	[StatusInvoice.TASKS_ASSIGNED]: {
		key: StatusInvoice.TASKS_ASSIGNED,
		title: 'Tarefas Criadas',
		icon: <HourglassTopIcon />,
		color: theme.palette.info.main,
	},
	[StatusInvoice.STORAGE_COMPLETED]: {
		key: StatusInvoice.STORAGE_COMPLETED,
		title: 'Armazenamento Completo',
		icon: <CheckCircleOutlineIcon />,
		color: theme.palette.success.main,
	},
	[StatusInvoice.TASKS_CANCELED]: {
		key: StatusInvoice.TASKS_CANCELED,
		title: 'Tarefas Canceladas',
		icon: <CancelOutlinedIcon />,
		color: theme.palette.error.main,
	},
});

export const getInvoiceDashboardSections = (
	theme: Theme,
): SectionDefinition<number, FiscalDocumentoEntryStatusCountResponse, StatusInvoice>[] => [
	{
		id: 0,
		title: 'Entradas Fiscais',
		icon: <ReceiptLongIcon fontSize="inherit" />,
		iconColor: theme.palette.primary.main,
		accordionColor: theme.palette.common.white,
		totalCountFn: (data: FiscalDocumentoEntryStatusCountResponse) => (data.created || 0)
		+ (data.tasksAssigned || 0)
		+ (data.tasksCanceled || 0)
		+ (data.storageCompleted || 0),
		totalTooltip: 'Total de entradas fiscais',
		getStatusCount: (data: FiscalDocumentoEntryStatusCountResponse, statusKey: StatusInvoice) => {
			switch (statusKey) {
				case StatusInvoice.CREATED:
					return data.created || 0;
				case StatusInvoice.TASKS_ASSIGNED:
					return data.tasksAssigned || 0;
				case StatusInvoice.STORAGE_COMPLETED:
					return data.storageCompleted || 0;
				case StatusInvoice.TASKS_CANCELED:
					return data.tasksCanceled || 0;
				default:
					return 0;
			}
		},
	},
];

export const getInventoryDashboardSections = (
	theme: Theme,
): SectionDefinition<number, InventoryStatusCountResponse, number>[] => [
	{
		id: InventoryType.PARTIAL,
		title: 'Parcial',
		icon: <ContentPasteSearchIcon fontSize="inherit" />,
		iconColor: theme.palette.primary.main,
		accordionColor: theme.palette.common.white,
		totalCountFn: (data: InventoryStatusCountResponse) => Object.values(data.partial)
			.reduce((sum, val) => sum + val, 0),
		totalTooltip: 'Total de inventários parciais',
		getStatusCount: (data: InventoryStatusCountResponse, statusKey: number) => {
			const statusName = getInventoryStatusFromEnum(statusKey);
			return (data.partial as Record<string, number>)[statusName] || 0;
		},
	},
	{
		id: InventoryType.FULL,
		title: 'Geral',
		icon: <AssignmentIcon fontSize="inherit" />,
		iconColor: theme.palette.primary.main,
		accordionColor: theme.palette.common.white,
		totalCountFn: (data: InventoryStatusCountResponse) => Object.values(data.full)
			.reduce((sum, val) => sum + val, 0),
		totalTooltip: 'Total de inventários gerais',
		getStatusCount: (data: InventoryStatusCountResponse, statusKey: number) => {
			const statusName = getInventoryStatusFromEnum(statusKey);
			return (data.full as Record<string, number>)[statusName] || 0;
		},
	},
];

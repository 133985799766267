import React, {
	useCallback, useMemo, useState,
} from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Box from '@mui/material/Box';
import { Fade, Paper, useTheme } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Loading } from '../Common/Loading';
import DrawerFilter from '../Common/DrawerFilter';
import useDataGridManagement from '../../hooks/useDataGridManagement';
import {
	ReleaseTasksParams,
	RemoveTasksParams,
	ReprocessTasksParams,
} from '../../interfaces/InventoryTaskInvoiceQueryParams';
import { PageHeader } from '../Common/PageHeader/PageHeader';
import { PageHeaderButtonProps } from '../../interfaces/PageHeaderInterface';
import { InventoryTaskStatus } from '../../enums/InventoryTaskStatus';
import { ProductQueryParams } from '../../interfaces/ProductQueryParams';
import {
	IProductWithoutDetails,
} from '../../containers/Product/ProductAssets';
import { InventoryTaskAction } from '../../enums/InventoryTaskAction';
import { LocationProductQueryParams } from '../../interfaces/LocationProduct';
import ConsumptionTable from './ConsumptionTable';
import { ILocation } from '../../containers/Location/LocationAssets';
import ConsumptionProductionTaskFilter from './ConsumptionTaskFilter';
import { ConsumptionProductionTaskQueryParams } from '../../interfaces/ConsumptionProductionTaskQueryParams';
import {
	IConsumption,
	IStatusCount,
	CreateConsumptionTasksParams,
	ConsumptionProductionTask,
} from '../../containers/Consumption/ConsumptionAssets';
import { ConsumptionType } from '../../enums/ConsumptionType';
import ConsumptionDrawer from './Drawers/ConsumptionDrawer';
import { getStatusEnum } from '../../helpers/StatusCount';
import { CardDefinition, SectionDefinition, useDashboard } from '../../hooks/useDashboard';
import DefaultDashboard from '../Common/Dashboard/DefaultDashboard';
import { getConsumptionDashboardSections, getInventoryTaskHeaderStatusCards } from '../../helpers/dashboardConfig';
import { InventoryTaskStatusHeader } from '../../enums/InventoryTaskStatusHeader';
import { getConsumptionViewTitle } from '../../helpers/consumptionTaskHekper';

interface ConsumptionTaskProps {
  loading: boolean;
  tasksPages: number;
  tasksPage: number;
  tasksTake: number;
  products: IProductWithoutDetails[];
  locations: ILocation[];
  consumptions: IConsumption[];
  statusCount: IStatusCount;
  releaseTasks: (data: ReleaseTasksParams) => void;
  reprocessLocationTasks: (data: ReprocessTasksParams) => void;
  finishTask: (data: RemoveTasksParams) => void;
  getProducts: (queryParams: ProductQueryParams) => void;
  getLocations: (queryParams?: LocationProductQueryParams) => void;
  createConsumptionTasks: (params: CreateConsumptionTasksParams) => void;
  getConsumptionProduction: (
    params: ConsumptionProductionTaskQueryParams,
  ) => void;
  getConsumptionCountByStatus: () => void;
}

interface TaskGroup {
  tasksIds: Array<string>;
}

export interface SelectedTasks {
  [key: string]: TaskGroup;
}

const ConsumptionTask = ({
	loading,
	tasksPages,
	tasksPage,
	tasksTake,
	products,
	locations,
	consumptions,
	statusCount,
	releaseTasks,
	reprocessLocationTasks,
	finishTask,
	getProducts,
	getLocations,
	createConsumptionTasks,
	getConsumptionProduction,
	getConsumptionCountByStatus,
}: ConsumptionTaskProps): JSX.Element => {
	const [selectedRows, setSelectedRows] = useState<SelectedTasks>({} as SelectedTasks);
	const [rowsIndex, setRowsIndex] = useState<Array<number>>([]);
	const [allSelected, setAllSelected] = useState(false);
	const [isFilterDrawerOpen, setFilterDrawerOpen] = useState(false);
	const [isConsumptionDrawerOpen, setIsConsumptionDrawerOpen] = useState(false);
	const [showTable, setShowTable] = useState(false);
	const [expandedSection, setExpandedSection] = useState<ConsumptionType | null>(
		ConsumptionType.REQUISITION,
	);
	const [
		currentView, setCurrentView] = useState<{
			type: ConsumptionType;
			status: InventoryTaskStatusHeader
		} | null>(null);

	const theme = useTheme();

	const {
		filter, setFilter, onChangePage, onChangePageSize,
	} = useDataGridManagement<ConsumptionProductionTaskQueryParams>({
		initialFilterValues: { skip: 0, action: InventoryTaskAction.CONSUMPTION },
		initialPageSize: tasksTake,
		initialPage: tasksPage,
		fetchData: (params) => {
			if (showTable) {
				getConsumptionProduction(params);
			}
		},
	});

	const handleStatusCardClick = useCallback((sectionId: ConsumptionType, statusKey: number) => {
		const statusEnum = getStatusEnum(statusKey);

		setFilter({
			action: InventoryTaskAction.CONSUMPTION,
			type: sectionId,
			statusHeader: statusEnum,
			skip: 0,
		});
		setCurrentView({
			type: sectionId,
			status: statusKey,
		});

		setExpandedSection(sectionId);
		setShowTable(true);
	}, [setFilter]);

	const resetValueScreen = useCallback(() => {
		setAllSelected(false);
		setRowsIndex([]);
		setSelectedRows({} as SelectedTasks);
	}, []);

	const handleConsumptionProductionDrawer = useCallback(
		async (open: boolean) => {
			setIsConsumptionDrawerOpen(open);
		},
		[],
	);

	const getConsumptionTaskIdsByType = useCallback(
		(
			allTasks: ConsumptionProductionTask[],
			statusProduct: InventoryTaskStatus,
		) => {
			let tasksIds: string[] = [];

			tasksIds = allTasks
				.filter(
					(task) => task.status === statusProduct
          && (task.locationOriginBarCode === undefined
          || task.locationDestinyBarCode === undefined),
				)
				.map((taskData) => taskData.id);

			return tasksIds;
		},
		[],
	);

	const handlerReleaseTask = useCallback(() => {
		const allTasksIds: ReleaseTasksParams = {
			tasks: Object.values(selectedRows).flatMap(
				(selectedRow) => selectedRow.tasksIds,
			),
		};

		releaseTasks(allTasksIds);
		resetValueScreen();
	}, [selectedRows, releaseTasks, resetValueScreen]);

	const selectedAllPendingsRows = useCallback(() => {
		const indexs: Array<number> = [];
		const rowsSelected: SelectedTasks = {} as SelectedTasks;
		const selectedAll = !allSelected;

		if (selectedAll) {
			consumptions.forEach((consumption, index) => {
				const eligibleTasks = consumption.tasks.filter(
					(taskItem) => taskItem.status === InventoryTaskStatus.PENDING
						&& Boolean(taskItem.locationOriginBarCode)
						&& Boolean(taskItem.locationDestinyBarCode),
				);

				if (eligibleTasks.length > 0) {
					indexs.push(index);

					const taskIds = eligibleTasks.map((taskData) => taskData.id);

					rowsSelected[consumption.id] = {
						tasksIds: [...taskIds],
					};
				}
			});
		}

		setAllSelected(selectedAll);
		setRowsIndex(indexs);
		setSelectedRows(rowsSelected);
	}, [allSelected, consumptions]);

	const handleSelectedRowsChange = useCallback(
		(rowIndex: number): void => {
			const consumption = consumptions[rowIndex] ?? {};
			const consumptionId = consumption.id;

			if (consumptionId) {
				setRowsIndex((data) => [...data, rowIndex]);
				consumption.selected = !consumption.selected;

				const consumptionTaskFilter = consumption.tasks
					.filter(
						(taskItem) => taskItem.status === InventoryTaskStatus.PENDING
            && Boolean(taskItem.locationDestinyBarCode)
            && Boolean(taskItem.locationOriginBarCode),
					)
					.map((taskData) => taskData.id);

				setSelectedRows((prevSelectedTasks) => {
					const newSelectedTasks = { ...prevSelectedTasks };

					if (newSelectedTasks[consumptionId] && consumption.selected) {
						newSelectedTasks[consumptionId] = {
							tasksIds: [...consumptionTaskFilter],
						};
					} else if (consumption.selected) {
						newSelectedTasks[consumptionId] = {
							tasksIds: [
								...(newSelectedTasks[consumptionId]?.tasksIds || []),
								...consumptionTaskFilter,
							],
						};
					} else {
						setRowsIndex((indexs) => indexs.filter((index) => index !== rowIndex));
						delete newSelectedTasks[consumptionId];
					}

					return newSelectedTasks;
				});
			}
		},
		[consumptions],
	);

	const handlerChangeProduct = useCallback(
		(rowIndex: number, indexProduct: number, checked: boolean) => {
			const consumption = consumptions[rowIndex];
			const consumptionTask = consumption.tasks[indexProduct];
			const consumptionTaskId = consumptionTask.id;

			if (consumption?.id) {
				setSelectedRows((prevSelectedTasks) => {
					const newSelectedTasks = { ...prevSelectedTasks };
					const currentTasksIds = newSelectedTasks[consumption.id]?.tasksIds ?? [];

					if (checked) {
						newSelectedTasks[consumption.id] = {
							tasksIds: [...currentTasksIds, consumptionTaskId],
						};

						const consumptionTaskFilter = consumption.tasks
							.filter((taskItem) => taskItem.status === InventoryTaskStatus.PENDING
					&& Boolean(taskItem.locationOriginBarCode)
					&& Boolean(taskItem.locationDestinyBarCode))
							.map((taskData) => taskData.id);

						if (newSelectedTasks[consumption.id]?.tasksIds.length
							=== consumptionTaskFilter.length) {
							// eslint-disable-next-line no-param-reassign
							consumptions[rowIndex].selected = true;
							setRowsIndex((indexs) => [...indexs, rowIndex]);
						}
					} else {
						newSelectedTasks[consumption.id] = {
							tasksIds: currentTasksIds.filter((id) => id !== consumptionTaskId),
						};

						setRowsIndex((indexs) => indexs.filter((index) => index !== rowIndex));
						if (newSelectedTasks[consumption.id]?.tasksIds.length === 0) {
							delete newSelectedTasks[consumption.id];
						}
					}

					return newSelectedTasks;
				});
			}
		},
		[consumptions],
	);

	const buttonReprocessLocationEnabled = useMemo(() => consumptions.some(
		(consumption) => consumption.tasks.some(
			(task) => (!task.locationDestinyBarCode || !task.locationOriginBarCode)
        && task.status === InventoryTaskStatus.PENDING,
		),
	), [consumptions]);

	const handlerReprocessLocation = useCallback(() => {
		const tasksIdsWithoutDestiny = consumptions
			.filter((consumption) => consumption.tasks.filter((
				task,
			) => task.status === InventoryTaskStatus.PENDING))
			.flatMap((consumption) => getConsumptionTaskIdsByType(
				consumption.tasks,
				InventoryTaskStatus.PENDING,
			));

		reprocessLocationTasks({ tasks: tasksIdsWithoutDestiny });
		resetValueScreen();
	}, [
		consumptions,
		getConsumptionTaskIdsByType,
		reprocessLocationTasks,
		resetValueScreen,
	]);

	const toggleFilterDrawer = useCallback(() => {
		setFilterDrawerOpen((value) => !value);
	}, []);

	const handleConsumptionProductionFilter = useCallback(
		(filterData: ConsumptionProductionTaskQueryParams) => {
			const updatedFilter = {
				...filterData,
				type: filter.type !== undefined
					? filter.type : filterData.type,
				statusHeader: filter.statusHeader !== undefined
					? filter.statusHeader : filterData.statusHeader,
			};

			setFilter(updatedFilter);
			toggleFilterDrawer();
		},
		[filter.statusHeader, filter.type, setFilter, toggleFilterDrawer],
	);

	const handlerFinishTask = useCallback((taskIds: Array<string>) => {
		if (taskIds?.length) {
			finishTask({ tasks: taskIds });
			resetValueScreen();
		}
	}, [finishTask, resetValueScreen]);

	const handleBackToDashboard = useCallback(() => {
		setShowTable(false);
		setCurrentView(null);
		resetValueScreen();

		setFilter({
			skip: 0,
			action: InventoryTaskAction.CONSUMPTION,
		});

		getConsumptionCountByStatus();
	}, [getConsumptionCountByStatus, resetValueScreen, setFilter]);

	const dashboardHeaderButtons: PageHeaderButtonProps[] = useMemo(
		() => [
			{
				text: 'Consumir',
				variant: 'contained',
				onClick: () => handleConsumptionProductionDrawer(true),
				startIcon: <MoveToInboxIcon />,
			},
		],
		[handleConsumptionProductionDrawer],
	);

	const tableHeaderButtons: PageHeaderButtonProps[] = useMemo(
		() => [
			{
				text: 'Filtros',
				variant: 'contained',
				onClick: toggleFilterDrawer,
				startIcon: <FilterListIcon />,
			},
			{
				show: Object.keys(selectedRows).length > 0,
				text: 'Liberar tarefas',
				variant: 'contained',
				onClick: handlerReleaseTask,
			},
			{
				show: consumptions.some((consumption) => consumption.tasks
					.some((task) => task.status === InventoryTaskStatus.PENDING
					&& Boolean(task.locationOriginBarCode)
					&& Boolean(task.locationDestinyBarCode))),
				text: !allSelected ? 'Marcar Pendentes' : 'Desmarcar Pendentes',
				variant: 'contained',
				onClick: selectedAllPendingsRows,
				startIcon: !allSelected ? (
					<CheckBoxOutlineBlankIcon />
				) : (
					<CheckBoxIcon />
				),
			},
			{
				show: buttonReprocessLocationEnabled,
				text: 'Reprocessar Localização',
				variant: 'contained',
				color: 'warning',
				onClick: handlerReprocessLocation,
			},
		],
		[
			toggleFilterDrawer,
			selectedRows,
			handlerReleaseTask,
			consumptions,
			allSelected,
			selectedAllPendingsRows,
			buttonReprocessLocationEnabled,
			handlerReprocessLocation,
		],
	);

	const consumptionProductFilterMemo = useMemo(
		() => (
			<DrawerFilter open={isFilterDrawerOpen} onClose={toggleFilterDrawer}>
				<ConsumptionProductionTaskFilter
					handleConsumptionProductionFilter={handleConsumptionProductionFilter}
					initialValues={filter}
				/>
			</DrawerFilter>
		),
		[isFilterDrawerOpen, toggleFilterDrawer, handleConsumptionProductionFilter, filter],
	);

	const consumptionProductionDrawerMemo = useMemo(
		() => (
			<ConsumptionDrawer
				open={isConsumptionDrawerOpen}
				onClose={() => setIsConsumptionDrawerOpen(false)}
				locations={locations}
				getLocations={getLocations}
				products={products}
				getProducts={getProducts}
				createConsumptionTasks={createConsumptionTasks}
			/>
		),
		[
			createConsumptionTasks,
			getLocations,
			getProducts,
			isConsumptionDrawerOpen,
			locations,
			products,
		],
	);

	const statusCards: Record<number, CardDefinition<InventoryTaskStatusHeader>> = useMemo(
		() => getInventoryTaskHeaderStatusCards(theme) as Record<
			number,
			CardDefinition<InventoryTaskStatusHeader>>,
		[theme],
	);

	const dashboardSections: SectionDefinition<
		ConsumptionType,
		IStatusCount,
		InventoryTaskStatusHeader>[] = useMemo(
			() => getConsumptionDashboardSections(theme) as SectionDefinition<
			ConsumptionType,
			IStatusCount,
			InventoryTaskStatusHeader>[],
			[theme],
		);

	const dashboardConfig = useDashboard(
		statusCount,
		dashboardSections,
		statusCards,
		handleStatusCardClick,
		getConsumptionCountByStatus,
	);

	const consumptionDashboardMemo = useMemo(() => (
		<DefaultDashboard
			sections={dashboardConfig}
			defaultExpanded={expandedSection || ConsumptionType.REQUISITION}
		/>
	), [dashboardConfig, expandedSection]);

	const viewData = useMemo(() => getConsumptionViewTitle(
		currentView,
		{ color: 'primary', fontSize: 'large' },
	), [currentView]);

	return (
		<Box className="content" sx={{ overflowY: 'hidden' }}>
			<PageHeader
				title={viewData.title}
				icon={viewData.icon}
				buttons={
					showTable
						? [
							{
								text: 'Voltar',
								onClick: handleBackToDashboard,
								startIcon: <ArrowBackIcon />,
								variant: 'outlined',
							},
							...tableHeaderButtons,
						]
						: dashboardHeaderButtons
				}
			/>
			{loading && <Loading />}
			{consumptionProductionDrawerMemo}
			<Fade in={!showTable} unmountOnExit>
				<Box>
					{consumptionDashboardMemo}
				</Box>
			</Fade>
			<Fade in={showTable} unmountOnExit>
				<Paper sx={{ p: 2, boxShadow: () => theme.shadows[2] }}>
					{consumptionProductFilterMemo}
					<ConsumptionTable
						loading={loading}
						consumptions={consumptions}
						selectedRows={selectedRows}
						handleSelectedRowsChange={handleSelectedRowsChange}
						rowsIndex={rowsIndex}
						onChangePageSize={onChangePageSize}
						onChangePage={onChangePage}
						filter={filter}
						handlerChangeProduct={handlerChangeProduct}
						handlerFinishTask={handlerFinishTask}
						tasksPages={tasksPages}
						tasksPage={tasksPage}
						tasksTake={tasksTake}
					/>
				</Paper>
			</Fade>
		</Box>
	);
};

export default ConsumptionTask;

import React, { SyntheticEvent, useCallback, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import AutocompleteMui, {
	AutocompleteInputChangeReason,
} from '@mui/material/Autocomplete';
import { LoadingButton } from '@mui/lab';
import { Form, Formik } from 'formik';

import {
	AutocompleteChangeReason,
	CircularProgress,
	Grid,
} from '@mui/material';
import Input from '../../Common/Form/Input';
import Autocomplete from '../../Common/Form/Autocomplete';
import {
	InventoryTaskTransferProductParams,
} from '../../../interfaces/InventoryTaskInvoiceQueryParams';
import {
	defaultTrasferProductParams,
	validationSchemaTrasnferTask,
} from '../../../constants/inventoryTask';
import {
	ILocationProductData,
	IProductWithoutDetails,
} from '../../../containers/Product/ProductAssets';
import { ProductQueryParams } from '../../../interfaces/ProductQueryParams';
import { formatBarCode } from '../../../helpers/masks';

export interface TransferProductModalProps {
  open: boolean;
  loading: boolean;
  products: IProductWithoutDetails[];
  productLocations: ILocationProductData[];
  onClose: (open: boolean) => void;
  onSubmit: (trasferData: InventoryTaskTransferProductParams) => void;
  getProductLocationsData: (productId: string) => Promise<void>;
  getProducts: (queryParams: ProductQueryParams) => void;
}

const TransferProductModal = ({
	loading,
	open,
	products,
	productLocations,
	onClose,
	onSubmit,
	getProductLocationsData,
	getProducts,
}: TransferProductModalProps): JSX.Element => {
	const [locationDestinyTextFormatted, setLocationDestinyTextFormatted] = useState('');

	const handlerInputProduct = useCallback(
		async (
			productData: IProductWithoutDetails | null,
			reason: AutocompleteChangeReason,
			setFieldValue: (
        field: string,
        value: string,
        shouldValidate?: boolean
      ) => void,
		) => {
			const productInfo = {
				productId: productData?.id || '',
				product: '' as any,
				productCode: '' as any,
			};

			const isNotEventClearOrRemove = !['clear', 'removeOption'].includes(
				reason,
			);

			if (isNotEventClearOrRemove) {
				await getProductLocationsData(productInfo.productId);
				productInfo.productCode = productData?.code || '';
				productInfo.product = productData;
			}

			if (!isNotEventClearOrRemove) {
				setFieldValue('productQuantity', '0');
				setFieldValue('locationOriginId', '');
				setFieldValue('locationDestinyId', '');
			}

			setFieldValue('product', productInfo.product || '');
			setFieldValue('productId', productInfo.productId || '');
			setFieldValue('productCode', productInfo.productCode);
		},
		[getProductLocationsData],
	);

	const filterDestinyLocation = useCallback(
		(originId: string, locations: ILocationProductData[]) => {
			if (!originId) {
				return [];
			}

			return locations.filter((locationData) => locationData.id !== originId);
		},
		[],
	);

	return (
		<Dialog open={open} onClose={onClose} fullWidth>
			<Formik
				onSubmit={onSubmit}
				initialValues={defaultTrasferProductParams}
				validationSchema={validationSchemaTrasnferTask({ productLocations })}
				validateOnChange={false}
				validateOnBlur={false}
			>
				{({
					values, errors, setFieldValue,
				}) => (
					<Form noValidate>
						<DialogTitle>Transferência</DialogTitle>
						<DialogContent dividers>
							<Grid container spacing={2}>
								<Grid item xs={12} md={12} sm={12}>
									<AutocompleteMui
										onChange={(
											event: SyntheticEvent,
											newValue: IProductWithoutDetails | null,
											reason: AutocompleteChangeReason,
										) => {
											handlerInputProduct(
												newValue,
												reason,
												setFieldValue,
											);
										}}
										onInputChange={(
											event: SyntheticEvent,
											value: string,
											reason: AutocompleteInputChangeReason,
										) => {
											const defaultFilterProduct = { skip: 0 };

											if (value?.length > 2 && reason === 'input') {
												getProducts({
													...defaultFilterProduct,
													description: value,
												});
											} else if (reason === 'clear') {
												setFieldValue('product', null);
												setFieldValue('productId', '');
												getProducts(defaultFilterProduct);
											}
										}}
										id="product"
										getOptionLabel={(product) => `${product.code} - ${product.description}`}
										options={products}
										sx={{ mt: 2 }}
										renderInput={(params) => (
											<TextField
												// eslint-disable-next-line react/jsx-props-no-spreading
												{...params}
												fullWidth
												label="Produto"
												placeholder="Produto"
												error={Boolean(errors.productId)}
												helperText={errors.productId}
												InputProps={{
													...params.InputProps,
													endAdornment: (
														<>
															{loading ? (
																<CircularProgress color="inherit" size={26} />
															) : null}
															{params.InputProps.endAdornment}
														</>
													),
												}}
											/>
										)}
									/>
								</Grid>
								<Grid item xs={12} sm={12} md={12}>
									<Input.InputField
										label="Quantidade"
										name="productQuantity"
										type="number"
										sx={{
											'& input[type=number]': {
												MozAppearance: 'textfield',
											},
											'& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
												WebkitAppearance: 'none',
												margin: 0,
											},
										}}
										fullWidth
										required
									/>
								</Grid>
								<Grid item xs={12} sm={12} md={12}>
									<Autocomplete
										label="Localização de Origem"
										valueLabel="barCode"
										valueKey="id"
										labelKey="locationName"
										name="locationOriginId"
										options={productLocations}
										disabled={!values.productId}
										formatBarCode
										fullWidth
										closeOnSelect
										required
									/>
								</Grid>
								<Grid item xs={12} sm={12} md={12}>
									<Autocomplete
										label="Localização de Destino"
										valueLabel="barCode"
										valueKey="id"
										labelKey="locationName"
										name="locationDestinyId"
										disabled={!values.productId || !values.locationOriginId}
										options={filterDestinyLocation(
											values.locationOriginId,
											productLocations,
										)}
										fullWidth
										formatBarCode
										inputValue={locationDestinyTextFormatted}
										onInputChange={(
											_event: SyntheticEvent,
											newInputValue: string,
											reason: AutocompleteInputChangeReason,
										) => {
											if (reason === 'input') {
												const locationDestinyFormatted = formatBarCode(newInputValue);
												setLocationDestinyTextFormatted(locationDestinyFormatted);
											} else if (reason === 'reset') {
												setLocationDestinyTextFormatted(newInputValue);
											}
										}}
										closeOnSelect
										isOptionEqualToValue={(option: string, value: string) => option === value}
										required
									/>
								</Grid>
							</Grid>
						</DialogContent>
						<DialogActions sx={{ gap: 1 }}>
							<Button onClick={() => onClose(false)}>Cancelar</Button>
							<LoadingButton
								type="submit"
								variant="contained"
								loading={loading}
							>
								Salvar
							</LoadingButton>
						</DialogActions>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
};

export default TransferProductModal;

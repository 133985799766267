import React, { ReactNode } from 'react';
import { GridToolbarContainer } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import { Add } from '@mui/icons-material';

interface GridToolBarProps {
	onAddClick?: () => void;
	children?: ReactNode;
}

const GridAddToolBar = ({ onAddClick, children }: GridToolBarProps): JSX.Element => (
	<GridToolbarContainer>
		{onAddClick && (
			<Button
				startIcon={<Add />}
				sx={{ fontSize: '0.835rem' }}
				onClick={() => {
					onAddClick();
				}}
			>
				Adicionar
			</Button>
		)}
		{children}
	</GridToolbarContainer>
);

GridAddToolBar.defaultProps = {
	children: null,
	onAddClick: null,
};

export const ToolbarAddComponent = (onAddClick?: () => void, children?: ReactNode) => () => (
	<GridAddToolBar onAddClick={onAddClick}>
		{children}
	</GridAddToolBar>
);

export default GridAddToolBar;

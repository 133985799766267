import React, {
	useEffect,
} from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { Loading } from '../../../Common/Loading';
import InventoryNotFirst from './NotFirstCount/InventoryNotFirst';
import InventoryFirst from './FirstCount/InventoryStartFirst';
import { ValidateInventoryParams } from '../../../../containers/Mobile/Inventory/InventoryAssets';
import { InventoryDetails, InventoryType } from '../../../../containers/Inventory/InventoryAssets';

interface InventoryMobileProps {
	loading: boolean
	getInventoryById(id: string, params: any): void;
	handleValidateInventory(params: ValidateInventoryParams): void;
	inventoryDetails: InventoryDetails | null;
}

const Inventory = ({
	loading,
	getInventoryById,
	inventoryDetails,
	handleValidateInventory,
}: InventoryMobileProps):JSX.Element => {
	const { id } = useParams();

	useEffect(() => {
		if (id) {
			getInventoryById(id, { skip: 0 });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	if (loading || inventoryDetails === null) {
		return (
			<Box padding={1}>
				<Loading />
			</Box>
		);
	}

	return (
		<Box>
			{inventoryDetails.countNumber === 1
			&& inventoryDetails?.type !== InventoryType.PARTIAL
				? (
					<InventoryFirst
						handleValidateInventory={handleValidateInventory}
						countNumber={inventoryDetails.countNumber}
					/>
				)
				: (
					<InventoryNotFirst
						inventoryTask={inventoryDetails.inventoryTask || null}
						countNumber={inventoryDetails.countNumber}
						handleValidateInventory={handleValidateInventory}
					/>
				)}
		</Box>
	);
};

export default Inventory;

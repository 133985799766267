import React, { useCallback } from 'react';
import { Form, Formik } from 'formik';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import SwitchField from '../Common/Form/Switch';
import Input from '../Common/Form/Input';
import { UserQueryParams } from '../../interfaces/UserQueryParams';
import { filterObject } from '../../helpers/Utils';

interface IFilterProps {
    sendFilter: (values: Omit<UserQueryParams, 'skip'>) => void
}

interface IForm {
    name: string;
    login: string;
    active: boolean;
}

const initialValues = {
	name: '',
	login: '',
	active: true,
};

const UserFilter = ({
	sendFilter,
}: IFilterProps): JSX.Element => {
	const onSubmit = useCallback((values: IForm) => {
		sendFilter(filterObject(values));
	}, [sendFilter]);

	const onReset = useCallback((handleReset) => {
		sendFilter({});
		handleReset();
	}, [sendFilter]);

	return (
		<Paper sx={{ p: 2, width: '100%', mb: 2 }}>
			<Formik
				initialValues={initialValues}
				onSubmit={onSubmit}
			>
				{({ handleReset }) => (
					<Form>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<Input.InputField
									id="name"
									name="name"
									label="Nome"
									autoComplete="off"
									fullWidth
								/>
							</Grid>
							<Grid item xs={6}>
								<Input.InputField
									id="login"
									name="login"
									label="Login/Email"
									autoComplete="off"
									fullWidth
								/>
							</Grid>
							<Grid item xs={6} md={2}>
								<SwitchField
									name="active"
									label="Inativo/Ativo"
								/>
							</Grid>
							<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
								<Button variant="outlined" onClick={() => onReset(handleReset)}>
									Restaurar
								</Button>
								<Button variant="contained" type="submit">
									Filtrar
								</Button>
							</Grid>
						</Grid>
					</Form>
				)}
			</Formik>
		</Paper>
	);
};

export default UserFilter;

import { AxiosResponse } from 'axios';
import { ITask } from '../containers/Mobile/TaskAssets';
import api from './api';
import { IMessageResponse } from './saleOrder';
import { SaveTaskType } from '../containers/InventoryTaskInvoice/InventoryTaskInvoiceAssets';

type ResponseTasksOrigin = {
  data: ITask[];
  count: number;
};

export const getLocationDestiny = (): Promise<AxiosResponse<ResponseTasksOrigin>> => api.get<ResponseTasksOrigin>('/inventory-task?action=2&status[]=1&status[]=2');

export const saveTakeTaskBatch = (data: SaveTaskType[]): Promise<AxiosResponse<IMessageResponse>> => api.post<SaveTaskType[], AxiosResponse<IMessageResponse>>('/inventory-task/takeTask/batch', data);
